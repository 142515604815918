import { GetAccelerateProductSupportGroupDetailsQuery } from "../../../../gql/graphql";
import { Clock16 } from "@bphxd/ds-core-react/lib/icons/index";
import { getHref, getIcon, getShiftHours } from "./utils";
import { Button } from "../../../../components/button";
import { ListGroup } from "@bphxd/ds-core-react";

export function SupportGroupDetails({
  group,
}: {
  group: GetAccelerateProductSupportGroupDetailsQuery["product"]["supportGroups"][0];
}) {
  return (
    <ListGroup>
      <div className="d-flex flex-column">
        <span className="mb-0 fw-light">{group.name}</span>
        <span className="small fw-light">{group.description}</span>
        <span className="small fw-light d-flex mt-3">
          <Clock16 className="x5-me-1" />
          {getShiftHours(group.userShifts ?? [])}
        </span>
        {group.contactMethod?.type !== "DM" && group.contactMethod?.value && (
          <Button
            iconPosition="start"
            className="bg-white d-flex justify-content-center my-5"
            Icon={() => getIcon(group.contactMethod?.type)}
            target="_blank"
            level="tertiary"
            theme="standard"
            rounded="pill"
            size="xs"
            data-testid="contact-support-group"
            name="contact-support-group"
            trackingEventProps={{
              step: "contact support group",
            }}
            href={getHref(group.contactMethod)}
          >
            <span className="ps-3">Contact support group</span>
          </Button>
        )}
      </div>
    </ListGroup>
  );
}
