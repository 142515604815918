"use client";
import { useEffect, useState } from "react";
import { GET_USER_BY_EMAIL } from "../api/users/gql";
import { useApolloClient } from "@apollo/client";

type UserData = {
  userId: string;
  name?: string;
  title: string;
  email: string;
  photo?: string;
};

export function useFetchUsers(emails: string[]) {
  const [users, setUsers] = useState<UserData[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const client = useApolloClient();
  useEffect(() => {
    setIsLoading(true);
    // Fetch user data for each email in the array
    const fetchUsersData = async () => {
      const usersData = await Promise.all(
        emails.map(async (email) => {
          const response = await client.query({
            query: GET_USER_BY_EMAIL,
            variables: { email },
          });
          const user = response.data.getUserByEmail;

          if (response) {
            return {
              name: user.givenName ? `${user.givenName || ""} ${user.surname || ""}`.trim() : undefined,
              title: user.jobTitle,
              userId: user.userId,
              email,
              photo: user.photo,
            } as UserData;
          }
          return null;
        }),
      );

      setUsers(usersData.filter((user): user is UserData => user !== null));
    };

    if (emails) {
      try {
        fetchUsersData();
      } finally {
        setIsLoading(false);
      }
    }
  }, [JSON.stringify(emails)]);

  return { users, isLoading };
}
