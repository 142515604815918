import { YallaDeploymentStatus } from "../../../../../../../gql/graphql";

export const DEPLOYMENT_STATUS_MAP = {
  [YallaDeploymentStatus.Succeeded]: "Succeeded",
  [YallaDeploymentStatus.Failed]: "Failed",
  [YallaDeploymentStatus.Notstarted]: "Not started",
  [YallaDeploymentStatus.Inprogress]: "In progress",
  [YallaDeploymentStatus.Superseded]: "Superseded",
  NA: "N/A",
};
