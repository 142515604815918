import { UserCard } from "../../components/cards/user-card";
import { Loader } from "../../components/spinners/loading-spinner";
import { useFetchUsers } from "../../hooks/use-fetch-users";

export function LeadMembers({ emails, loading }: { emails: string[]; loading: boolean }) {
  const { users, isLoading: userDetailsLoading } = useFetchUsers(emails);
  const leadTitles = ["Product Manager", "Pod Technical Lead", "Design Lead"];

  // Early return for loading states
  if (loading || userDetailsLoading) {
    return (
      <div className="d-flex flex-column gap-2">
        <Loader size="md" />
      </div>
    );
  }

  // Conditional rendering if users are available
  if (users && users.length > 0) {
    return (
      <div className="d-flex flex-column gap-2">
        {users.map(({ userId, name, photo }, index) => (
          <div className="mb-4" key={userId + index}>
            <UserCard title={leadTitles[index]} photoUrl={photo} name={name} />
          </div>
        ))}
      </div>
    );
  }

  return null;
}
