import { GetAccelerateProductSupportGroupDetailsQuery } from "../../../../gql/graphql";
import { Accordion } from "@bphxd/ds-core-react";
import { SupportGroupContent } from "./support-group-content";
import { SupportGroupDetails } from "./support-group-details";
import { SupportGroupActions } from "./support-group-actions";

export function SupportGroupItem({
  group,
  index,
  onEditSupportGroupClick,
  permissions,
  shouldShowActions,
}: {
  group: GetAccelerateProductSupportGroupDetailsQuery["product"]["supportGroups"][0];
  shouldShowActions: boolean;
  index: number;
  onEditSupportGroupClick: (opsGroupId: string) => void;
  permissions: any;
}) {
  return (
    <Accordion flush size="md" square stayOpen defaultOpen={String(index)} key={group.opsGroupId} tag="div">
      <Accordion.Item className="border-bottom" tag="div">
        <Accordion.Header targetId={String(index + 1)} tag="div">
          <div className="d-flex justify-content-between w-100">
            <SupportGroupDetails group={group} />
            {shouldShowActions && (
              <SupportGroupActions
                group={group}
                permissions={permissions}
                onEditSupportGroupClick={onEditSupportGroupClick}
              />
            )}
          </div>
        </Accordion.Header>
        <Accordion.Body accordionId={String(index + 1)}>
          <SupportGroupContent group={group} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
