import { YallaInstanceHealthStatus, YallaInstanceSyncStatus } from "../../../../gql/graphql";

export const SYNC_STATUS_INFO =
  "The current synchronisation state of configuration changes and deployment specifications.";
export const HEALTH_STATUS_INFO =
  "The current operational state, indicating the service instance is responsive and functioning as intended.";

export function getInstanceHealthStatus(syncStatus: YallaInstanceSyncStatus): string {
  switch (syncStatus) {
    case YallaInstanceSyncStatus.Synced:
      return "border-success";
    case YallaInstanceSyncStatus.Syncing:
      return "border-warning";
    case YallaInstanceSyncStatus.OutOfSync:
      return "border-danger";
    case YallaInstanceSyncStatus.Unknown:
      return "border-secondary";
  }
  return "";
}

export function getHealthStatus(
  instances: Array<{
    __typename?: "YallaServiceInstance";
    id: string;
    health: YallaInstanceHealthStatus;
  }>,
): { className: string; message: string } {
  if (instances.length === 0) {
    return { className: "", message: "No instances available." };
  }

  // Count the number of instances for each health status
  const healthCounts = instances.reduce(
    (counts, instance) => {
      counts[instance.health] = (counts[instance.health] || 0) + 1;
      return counts;
    },
    {} as Record<YallaInstanceHealthStatus, number>,
  );

  // Treat Healthy and Progressing as "healthy" states
  const totalHealthyOrProgressing =
    (healthCounts[YallaInstanceHealthStatus.Healthy] || 0) + (healthCounts[YallaInstanceHealthStatus.Progressing] || 0);

  // All instances are Healthy or Progressing
  if (totalHealthyOrProgressing === instances.length) {
    return {
      className: "border-success",
      message: "All instances are running with no issues",
    };
  }

  // If exactly one instance is not Healthy or Progressing
  if (totalHealthyOrProgressing === instances.length - 1) {
    return {
      className: "border-warning",
      message: "One instance has a health and sync issue",
    };
  }

  // Count the total number of Degraded and Missing statuses
  const unhealthyStatuses = [YallaInstanceHealthStatus.Degraded, YallaInstanceHealthStatus.Missing];
  const totalUnhealthy = unhealthyStatuses.reduce((sum, status) => sum + (healthCounts[status] || 0), 0);

  // If more than one instance is Degraded or Missing
  if (totalUnhealthy > 1) {
    return {
      className: "border-danger",
      message: `${totalUnhealthy} instances have health and sync issues`,
    };
  }

  return { className: "", message: "" };
}
