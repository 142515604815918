import { Button } from "../../components/button";
import { useUserL3Entities } from "../profile/hooks/use-user-l3-entities";
import { Loader } from "../../components/spinners/loading-spinner";

interface CreateProductButtonProps {
  onClick: () => void;
}

export function CreateProductButton({ onClick }: CreateProductButtonProps) {
  const { userL3Entities, userL3EntitiesLoading } = useUserL3Entities();
  if (userL3Entities.length === 0) {
    return null;
  }
  if (userL3EntitiesLoading) {
    return (
      <div className="d-flex">
        <Loader size="md" />
        <p className="small fw-light ms-3">
          We are loading permissions to verify if your role allows you to create products.
        </p>
      </div>
    );
  }

  return (
    <Button
      data-testid="create-a-product-cta"
      className="bg-primary"
      name="create-a-product-from-my-products-page-side-cta"
      onClick={onClick}
      level="tertiary"
      rounded="pill"
      size="sm"
      theme="standard"
    >
      Create a product
    </Button>
  );
}
