import { useQuery } from "@apollo/client";
import { GET_All_PRODUCTS } from "../../../api/products/gql";

export function useAllProducts() {
  const { data, refetch, loading, error } = useQuery(GET_All_PRODUCTS, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
  });

  return { data, refetch, loading, error };
}
