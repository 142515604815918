import { useLocation } from "../../../../hooks/use-location";
import { updateQueryParamsAsSearchParams } from "../../../../utils/update-search-params";
import Pluralise from "../../../../utils/pluralise";

import { Alert } from "@bphxd/ds-core-react";
import { Button } from "../../../../components/button";
import { Link } from "../../../../components/Link";

import { TABS } from "../../../../views/profile/profile-product-page";
import { useRaiseAlertModal } from "../../../../views/profile/products/support-groups/raise-alert-modal-provider";
import { AlertType } from "./alerts-and-incidents";

type AlertsProps = {
  alerts: AlertType[];
  disableRaiseAlert?: boolean;
};

export function Alerts({ alerts, disableRaiseAlert }: AlertsProps) {
  const { pathname, search: query } = useLocation();
  const nonResolvedAlerts = alerts.filter((alert) => alert.alertStatus !== "RESOLVED");
  const { openModal } = useRaiseAlertModal();

  return (
    <div className="bg-white rounded-5 border px-5 py-6 mt-6">
      <div className="d-flex justify-content-between mb-5">
        <h5 className="mb-0">Alerts</h5>
        <Button
          level="tertiary"
          theme="standard"
          rounded="pill"
          size="xs"
          name="raise-product-alert"
          onClick={() => openModal(undefined!)}
          disabled={disableRaiseAlert}
        >
          Raise alert
        </Button>
      </div>
      {alerts.length ? (
        <Alert color="warning">
          <p className="fw-light">
            {nonResolvedAlerts.length} alert{Pluralise(nonResolvedAlerts.length)} raised
          </p>
        </Alert>
      ) : (
        <Alert color="neutral">
          <p className="fw-light">No alerts raised</p>
        </Alert>
      )}
      <Link
        href={`${pathname}?${updateQueryParamsAsSearchParams(query, { tab: TABS.alerts })}`}
        className="fw-light small"
      >
        See details
      </Link>
    </div>
  );
}
