"use client";
import { ReactNode, useEffect } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { ModalFooter } from "reactstrap";
import { Button } from "../../../../components/button";
import Select from "../../../../components/Select";
import Input from "../../../../components/Input";

export type Step1DataType = {
  opsGroupId: string;
  description: string;
};

export function Step1({
  supportGroupOptions,
  handleNextClick,
  handleDirtyForm,
}: {
  supportGroupOptions: { label: ReactNode; value: string }[];
  handleNextClick: (data: Step1DataType) => void;
  handleDirtyForm: (isDirty: boolean) => void;
} & Step1DataType) {
  const schemaObject = {
    opsGroupId: yup
      .string()
      .transform((obj) => obj?.value)
      .required("Required"),
    description: yup.string().required("Required"),
  };

  const schema = yup.object(schemaObject).required();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid, isDirty },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    handleDirtyForm(isDirty);
  }, [isDirty, handleDirtyForm]);

  async function onSubmit(data: FieldValues) {
    const values = {
      opsGroupId: data.opsGroupId,
      description: data.description,
    };

    return handleNextClick(values);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="form-step-3">
      <div className="px-5 pt-5 pb-7">
        <div className="gap-5 d-flex flex-column">
          <div>
            <label className="mb-3 small fw-light w-100">Ops group</label>
            <Controller
              name="opsGroupId"
              control={control}
              render={({ field }: any) => (
                <Select
                  {...field}
                  isMulti={false}
                  className="basic-multi-select"
                  options={supportGroupOptions}
                  isDisabled={isSubmitting}
                  isSearchable={false}
                />
              )}
            />
          </div>
          <div>
            <label className="mb-3 small fw-light w-100" htmlFor="alert-description">
              Description
            </label>
            <Controller
              name="description"
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }: any) => (
                <Input
                  id="alert-description"
                  data-testid="alert-description"
                  type="text"
                  {...field}
                  error={error?.message}
                  invalid={!!error}
                />
              )}
            />
          </div>
        </div>
      </div>
      <ModalFooter>
        <div className="w-100 d-flex justify-content-end align-items-center">
          <Button
            data-testid="next-setup-product-support-groups-button"
            type="submit"
            name="support-groups-raise-alert"
            level="primary"
            theme="standard"
            rounded="pill"
            loading={isSubmitting}
            disabled={!isValid}
          >
            Done
          </Button>
        </div>
      </ModalFooter>
    </form>
  );
}
