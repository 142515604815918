/* eslint-disable @typescript-eslint/no-explicit-any */
import { ACCELERATE_APIS } from "./config";
import { instrumentedFetch } from "./instrumented-fetch";
import { PostRequestArgs } from "../types/api-types";

export async function msGraphQuery<T = Record<string, any>>({
  spanName,
  query,
  spanAttributes = {},
  headers,
  payload,
  method = "GET",
}: PostRequestArgs) {
  return instrumentedFetch<T>({
    apiUrl: ACCELERATE_APIS.microsoftGraph.url ?? "",
    method,
    headers,
    apiResource: query,
    spanName,
    spanAttributes,
    payload,
  });
}
