import { LeadTimePipelineData, bpLeadTime, calculatePercentageRun, convertHoursToHMString } from "./metrics-tab";
import { calculateTrendLength } from "../../../../views/profile/empirical-metric-card";
import Pluralise from "../../../../utils/pluralise";

import { Accordion, TabContent, TabPane } from "@bphxd/ds-core-react";
import { Divider } from "../../../../components/divider";
import { TailRight16 } from "@bphxd/ds-core-react/lib/icons";
import { Link } from "../../../../components/Link";
import { MetricsChart } from "../../../../views/profile/metrics-chart";

type LeadTimeTabContentProps = {
  selectedPipeline: string;
  pipelineData: LeadTimePipelineData[];
  bpLeadTime: bpLeadTime;
};

export function LeadTimeTabContent({ selectedPipeline, pipelineData, bpLeadTime }: LeadTimeTabContentProps) {
  const TOOLTIP_TARGET = "tooltip-target";

  return (
    <TabContent activeTab={selectedPipeline} tabStyle="tabs" vertical>
      {pipelineData?.map((pipeline) => {
        pipeline.last30DaysLeadTime = pipeline.last30DaysLeadTime ?? 0;
        pipeline.last12MonthsLeadTime = pipeline.last12MonthsLeadTime ?? 0;
        return (
          <TabPane tabId={pipeline.id} key={pipeline.id}>
            <div className="x5-ps-6">
              <h4 className="fw-light pb-4">{pipeline.name}</h4>
              <div className="d-flex">
                <div className="w-50 border-end pe-5">
                  <p className="fw-regular mb-0">{calculateTrendLength(pipeline.trendingPeriodStartDate)}</p>
                  <h4 className="fw-light mb-0">{convertHoursToHMString(pipeline?.last30DaysLeadTime) ?? "N/A"}</h4>
                  {pipeline.last30DaysLeadTime > pipeline.last12MonthsLeadTime ? (
                    <p className="small fw-regular text-danger-dark mb-2">
                      Increased by{" "}
                      {(100 * (pipeline.last30DaysLeadTime / pipeline.last12MonthsLeadTime) - 100).toFixed(2)}% compared
                      to last 12 months average
                    </p>
                  ) : (
                    <p className="small fw-regular text-success-emphasis mb-2">
                      Decreased by{" "}
                      {(100 - 100 * (pipeline.last30DaysLeadTime / pipeline.last12MonthsLeadTime)).toFixed(2)}% compared
                      to last 12 months average
                    </p>
                  )}
                  {pipeline.last30DaysCommitToMergeTime ? (
                    <p className="fw-light small">
                      {calculatePercentageRun(pipeline.last30DaysCommitToMergeTime, pipeline.last30DaysLeadTime)}% of
                      total run is taken by step Code, Build, Test
                    </p>
                  ) : null}
                </div>
                <div className="w-50 x5-ms-5">
                  <p className="fw-regular mb-0">Last 12 months average</p>
                  <h4 className="fw-light mb-0">{convertHoursToHMString(pipeline.last12MonthsLeadTime) ?? "N/A"}</h4>
                  {pipeline.last12MonthsCommitToMergeTime && pipeline.last12MonthsLeadTime && (
                    <p className="fw-light small">
                      {((pipeline.last12MonthsCommitToMergeTime / pipeline.last12MonthsLeadTime) * 100).toFixed(2)}% of
                      total run is taken by step Code, Build, Test
                    </p>
                  )}
                </div>
              </div>
              {pipeline?.last30DaysLeadTime ? (
                <>
                  <Divider pBottom="M" pTop="M" />
                  <div>
                    <p className="fw-regular">
                      {calculateTrendLength(pipeline.trendingPeriodStartDate)}' breakdown and insights:
                    </p>
                    <div className="d-flex border-bottom border-black">
                      <p className="fs-6 mb-3 ms-4 fw-regular col-9">Stage range</p>
                      <p className="fs-6 mb-3 fw-regular col-3">Duration</p>
                    </div>
                    <div>
                      <Accordion flush size="md" square stayOpen>
                        <Accordion.Item>
                          <Accordion.Header targetId={"1"} className={TOOLTIP_TARGET}>
                            <div className="d-flex w-100 opacity-80">
                              <div className="col-9 d-flex justify-content-between align-items-center">
                                <div className="col-4">First commit in a PR</div>
                                <TailRight16 />
                                <div className="col-4">Merge to master</div>
                              </div>
                              <div className="col-3 text-end pe-4">
                                {convertHoursToHMString(pipeline.last30DaysCommitToMergeTime) ?? "N/A"}
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body accordionId={"1"}>
                            <div className="d-flex flex-column">
                              <p className="fw-light small mb-3">
                                bp's average: {convertHoursToHMString(bpLeadTime.trendingCommitToMergeHours) ?? "N/A"}
                              </p>
                              <p className="fw-light small">
                                bp's target: {convertHoursToHMString(bpLeadTime.targetValue)}
                              </p>
                              {pipeline.last30DaysCommitToMergeTime &&
                                pipeline.last30DaysCommitToMergeTime > bpLeadTime.targetValue && (
                                  <span className="small-md fw-light">
                                    Your average time over the last month was higher than bp's target for these
                                    activities. Typically, those products investing a longer time at this point would
                                    benefit from addressing factors highlighted in the following capabilities:{" "}
                                    <Link
                                      href="/best-practices/devops-maturity/capability-details/continuous-integration"
                                      className="fw-light small-md"
                                    >
                                      Continuous Integration,
                                    </Link>{" "}
                                    <Link
                                      href="/best-practices/devops-maturity/capability-details/deployment-automation"
                                      className="fw-light small-md"
                                    >
                                      Deployment Automation.
                                    </Link>
                                  </span>
                                )}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item>
                          <Accordion.Header targetId={"2"}>
                            <div className="d-flex w-100 opacity-80">
                              <div className="col-9 d-flex justify-content-between align-items-center">
                                <div className="col-4">Merge to master</div>
                                <TailRight16 />
                                <div className="col-4">Production release</div>
                              </div>
                              <div className="col-3 text-end pe-4">
                                {convertHoursToHMString(pipeline.last30DaysMergeToProdTime) ?? "N/A"}
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body accordionId={"2"}>
                            <div className="d-flex flex-column">
                              <p className="fw-light small mb-3">
                                bp's average: {convertHoursToHMString(bpLeadTime.trendingMergeToProdHours) ?? "N/A"}
                              </p>
                              <p className="fw-light small">
                                bp's target: {convertHoursToHMString(bpLeadTime.targetValue)}
                              </p>
                              {pipeline.last30DaysMergeToProdTime &&
                                pipeline.last30DaysMergeToProdTime > bpLeadTime.targetValue && (
                                  <span className="fw-lighter small-md">
                                    Your average time over the last month was higher than bp's target for these
                                    activities. Typically, those products investing a longer time at this point would
                                    benefit from addressing factors highlighted in the following capabilities:{" "}
                                    <Link
                                      href="/best-practices/devops-maturity/capability-details/trunk-based-development"
                                      className="fw-light small-md"
                                    >
                                      Trunk Based Development,
                                    </Link>{" "}
                                    <Link
                                      href="/best-practices/devops-maturity/capability-details/working-in-small-batches"
                                      className="fw-light small-md"
                                    >
                                      Small Batch Change,
                                    </Link>{" "}
                                    <Link
                                      href="best-practices/devops-maturity/capability-details/work-in-process-limits"
                                      className="fw-light small-md"
                                      hideExternalLinkIcon
                                    >
                                      Working Within Process Limits.
                                    </Link>
                                  </span>
                                )}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <div className="d-flex justify-content-between x5-px-4 bg-tertiary py-4">
                        <p className="fw-regular mb-0">Total time</p>
                        <p className="fw-regular mb-0">{convertHoursToHMString(pipeline.last30DaysLeadTime)}</p>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              {pipeline.leadTimeData.length > 0 ? (
                <>
                  <Divider pBottom="M" pTop="M" />
                  <div>
                    <p className="fw-normal">
                      Last {pipeline.leadTimeData.length} month{Pluralise(pipeline.leadTimeData.length)}' breakdown
                    </p>
                    <MetricsChart
                      bpTarget={bpLeadTime.targetValue}
                      data={pipeline.leadTimeData}
                      aSeriesProp="commitToMergeHours"
                      bSeriesProp="mergeToProdHours"
                      aSeriesName="Commit to merge"
                      bSeriesName="Merge to Production"
                    />
                  </div>
                </>
              ) : null}
            </div>
          </TabPane>
        );
      })}
    </TabContent>
  );
}
