"use client";
import { useEffect, useState } from "react";
import { AssessmentForm } from "./assessment-form";
import { Quiz } from "../../../../gql/graphql";
import { Product } from "../../../../types/ProfileTypes";

export type AssessmentQuizProps = {
  product: Product;
  PostForm: CallableFunction;
  postFormLoading: boolean;
  quiz: Quiz;
  refetchCompleted: boolean;
};

export function AssessmentQuiz(props: AssessmentQuizProps) {
  const { product, quiz } = props;
  const [formDefaultValues, setFormDefaultValues] = useState<Record<number, number | null> | undefined>();
  const [showForm, setShowForm] = useState(false);

  const quizResults = product?.newCapabilityResult?.quizResults;

  useEffect(() => {
    if (quizResults) {
      const values = quizResults
        ?.find((quizResult) => quizResult.quiz.slug === quiz.slug)
        ?.answers?.reduce<Record<number, number | null>>((acc, curr) => {
          acc[curr.question_id] = curr.option_id;
          return acc;
        }, {});
      setFormDefaultValues(values);
      setShowForm(true);
    }
  }, [quizResults]);

  return showForm ? <AssessmentForm defaultValues={formDefaultValues} {...props} /> : null;
}
