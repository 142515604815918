import { Accordion } from "@bphxd/ds-core-react";
import { Info24 } from "@bphxd/ds-core-react/lib/icons";
import { Popover } from "./tooltip/popover";
import Input from "./Input";

export type FilterOption = {
  value: string;
  icon: JSX.Element;
};

export type Filter<T> = {
  field: keyof T;
  name: string;
  toolTipText: string;
  options: FilterOption[];
};

export type FilterOptionWithCount = FilterOption & { count: number };

export type FilterWithOptionCounts<T> = Omit<Filter<T>, "options"> & {
  options: FilterOptionWithCount[];
};

type OnToggleOptionArgs<T> = {
  filter: FilterWithOptionCounts<T>;
  option: FilterOption;
  current: boolean;
};

export type FiltersProps<T> = {
  filter: FilterWithOptionCounts<T>;
  onToggleOption: (args: OnToggleOptionArgs<T>) => void;
  checkedOptions: Set<string>;
};

export function Filters<T>({ filter, onToggleOption, checkedOptions }: FiltersProps<T>) {
  const filterId = filter.name.toLowerCase().replace(" ", "-");
  return (
    <Accordion flush size="md" square stayOpen tag="div" defaultOpen={"1"}>
      <Accordion.Item key={"1"}>
        <Accordion.Header targetId={"1"}>
          <div className="d-flex justify-content-between w-100">
            <p className="fw-normal small mb-0">{filter.name}</p>
            <Info24
              className="opacity-60 x5-me-2"
              id={filterId}
              name={filterId}
              type="button"
              onClick={(event) => event.stopPropagation()}
              tabIndex={0}
            />
            <Popover
              trigger="focus"
              target={filterId}
              testId={`${filterId}-tooltip-text`}
              title={filter.name}
              placement="right"
              toolTipText={filter.toolTipText}
            />
          </div>
        </Accordion.Header>
        <Accordion.Body accordionId={"1"}>
          {filter.options.map((option) => {
            const checked = checkedOptions.has(option.value);
            return (
              <div key={option.value} className="my-0 d-flex align-items-center gap-3 mt-3">
                <Input
                  type="checkbox"
                  id={`statuskey:${option.value}`}
                  name={option.value}
                  value={option.value}
                  onChange={() => onToggleOption({ filter, option, current: checked })}
                  checked={checked}
                />
                <label htmlFor={`statuskey:${option.value}`} className="w-100 d-flex justify-content-between">
                  <div className="d-flex align-items-center gap-2">{option.icon}</div>
                </label>
                <span className="small-md fw-light opacity-90" data-testid={`${option.value}-count`}>
                  {option.count}
                </span>
              </div>
            );
          })}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
