import { calculatePeriodLength, convertHoursToHMString } from "./metrics-tab";
import Pluralise from "../../../../utils/pluralise";
import { Link } from "../../../../components/Link";

import { LAST_12_MONTHS } from "../../../../views/profile/metric-period";
import { Metric } from "../../../../components/cards/metric-info-data";
import { DoraMetrics } from "../../../../gql/graphql";

export function MetricCardHighlight({
  metricName,
  metrics,
  period,
}: {
  metricName: Metric;
  metrics?: DoraMetrics;
  period: string;
}) {
  if (metricName === Metric.LeadTime && metrics) {
    const leadTimes = (metrics.leadTime?.perPipeline?.slice() ?? [])
      .map((pipeline) => (period === LAST_12_MONTHS ? pipeline.leadTimeHours : pipeline.trendingLeadTimeHours))
      .filter((a) => a);
    leadTimes.sort((a, b) => {
      if (a && b) {
        return a - b;
      }
      return 0;
    });
    const shortestPipeline = leadTimes[0] ?? 0;
    const longestPipeline = leadTimes[leadTimes.length - 1] ?? 0;

    return (
      <div className="d-flex flex-column pt-3">
        <p className="fw-light small mb-2">
          Longest lead time in this period is <strong>{convertHoursToHMString(longestPipeline)}</strong>
        </p>
        <p className="fw-light small mb-0">
          Shortest lead time in this period is <strong>{convertHoursToHMString(shortestPipeline)}</strong>
        </p>
      </div>
    );
  } else if (metricName === Metric.DeployFrequency && metrics) {
    const pipelines = (metrics.deploymentFrequency?.perPipeline?.slice() ?? [])
      .map((pipeline) =>
        period === LAST_12_MONTHS
          ? {
              ...pipeline.pipeline,
              deploymentFrequency:
                pipeline.periodDeploymentCount /
                calculatePeriodLength(pipeline.periodStartDate, pipeline.periodEndDate),
            }
          : {
              ...pipeline.pipeline,
              deploymentFrequency: pipeline.trendingPeriodDeploymentCount,
            },
      )
      .filter((a) => a);
    pipelines.sort((a, b) => a.deploymentFrequency - b.deploymentFrequency);
    const mostFrequentPipeline = pipelines[pipelines.length - 1];
    return (
      <div className="d-flex flex-column pt-3">
        <p className="fw-light small mb-2">
          This score is calculated from {pipelines?.length ?? 0} pipeline{Pluralise(pipelines?.length ?? 0)}. The most
          frequently run pipeline{" "}
          <Link
            hideExternalLinkIcon
            href={`https://dev.azure.com/${mostFrequentPipeline.organizationName}/${mostFrequentPipeline.projectName}/_build?definitionId=${mostFrequentPipeline.id}`}
          >
            {mostFrequentPipeline.name}
          </Link>{" "}
          {period === LAST_12_MONTHS ? (
            <>
              has an average deployment frequency of {Math.round(mostFrequentPipeline.deploymentFrequency)} time
              {Pluralise(mostFrequentPipeline.deploymentFrequency)} per month.
            </>
          ) : (
            <>
              has deployed {Math.round(mostFrequentPipeline.deploymentFrequency)} time
              {Pluralise(mostFrequentPipeline.deploymentFrequency)}.
            </>
          )}
        </p>
      </div>
    );
  }

  return null;
}
