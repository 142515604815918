import { useMemo } from "react";
import { LinkButton } from "../../../../components/link-button";
import { Right24 } from "@bphxd/ds-core-react/lib/icons/index";
import { getFragmentData, graphql } from "../../../../gql/index";
import { useQuery } from "@apollo/client";
import { Loader } from "../../../../components/spinners/loading-spinner";
import { getHealthStatus } from "../utils/index";
import { capitalize } from "lodash";

export const YallaEnvironmentData = graphql(`
  fragment YallaEnvironmentData on YallaEnvironment {
    instances {
      id
      health
      name
      syncStatus
      platformName
      serviceName
      updatedAt
      deployment {
        updatedAt
        sourceCommitMessage
      }
    }
  }
`);

export const GetEnvironments = graphql(`
  query GetProductEnvironmentById($productId: String!) {
    product(id: $productId) {
      name
      yallaPlatform {
        name
        environments {
          name
          ...YallaEnvironmentData
        }
      }
    }
  }
`);

export function useEnvironments(productId?: string) {
  const { data, loading, error } = useQuery(GetEnvironments, {
    variables: { productId: productId ?? "" },
    skip: !productId,
    fetchPolicy: "cache-and-network",
  });
  return { data, loading, error };
}

type EnvironmentProps = {
  productId?: string;
};

export const Environments = ({ productId }: EnvironmentProps) => {
  const { data, loading } = useEnvironments(productId);
  if (!productId) {
    return null;
  }
  const environments = data?.product?.yallaPlatform?.environments;

  const sortedEnvironments = useMemo(() => {
    if (!environments) return [];
    return environments.slice().sort((a, b) => a.name.localeCompare(b.name));
  }, [environments]);

  const processedEnvironments = useMemo(() => {
    if (!sortedEnvironments) return [];
    return sortedEnvironments.map((environment) => {
      const fragmentData = getFragmentData(YallaEnvironmentData, environment);
      const { className, message } = getHealthStatus(fragmentData.instances);
      return {
        ...environment,
        className,
        message,
      };
    });
  }, [sortedEnvironments]);

  return loading && !environments ? (
    <div className="d-flex align-items-center">
      <Loader size="lg" />
    </div>
  ) : (
    <>
      {processedEnvironments.map(({ name, className, message }) => {
        return (
          <div key={name} className={`bg-white rounded-bottom-3 border-top border-2 x5-px-4 py-6 mt-6 ${className}`}>
            <div className="d-flex justify-content-between mb-5">
              <div className="d-flex align-items-start mt-2 flex-column ">
                <LinkButton
                  data-testid={`environment-${name}`}
                  name={name}
                  Icon={Right24}
                  iconPosition={"end"}
                  level="quartenary"
                  rounded="circle"
                  size="sm"
                  href={`/profile/product/${productId}/environment/${name}?product_name=${data?.product.name}&environment_name=${name}`}
                  className="px-0 border-0"
                  hideExternalLinkIcon
                >
                  <p className="mb-0 lead fw-light">{capitalize(name)}</p>
                </LinkButton>
                <div className="mb-0 small  opacity-70 fw-light lh-1-5 small mt-2">{message}</div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
