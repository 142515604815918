import { useLocation } from "../../../hooks/use-location";
import { useMutation } from "@apollo/client";
import { DELETE_PRODUCT, GET_ACCELERATE_PRODUCTS } from "../../../api/products/gql";
import { Product } from "../../../types/ProfileTypes";
import { useToast } from "../../../hooks/use-toast";
import { ConfirmationModal } from "../../../components/modals/confirmation-modal";

type ModalProps = {
  isModalOpen: boolean;
  toggle: () => void;
  selectedProduct: Product;
};

export function DeleteProductModal({ isModalOpen, toggle, selectedProduct }: ModalProps) {
  const { displayToast } = useToast();
  const { router, search } = useLocation();
  const productName = selectedProduct.name;
  const [deleteProduct, { loading, error, reset }] = useMutation(DELETE_PRODUCT, {
    refetchQueries: [{ query: GET_ACCELERATE_PRODUCTS }],
    onCompleted: () => {
      handleClose();
      const sourcePath = search.source ?? "my-products";
      router?.push(`/${sourcePath}`);
      displayToast(`Successfully deleted ${productName}`);
    },
    onError: () => {},
  });

  async function handleDeleteClick() {
    await deleteProduct({
      variables: {
        deleteProductId: selectedProduct?.id,
      },
    });
  }

  function handleClose() {
    toggle();
    reset();
  }

  return (
    <ConfirmationModal
      name={productName}
      isOpen={isModalOpen}
      title={`Delete ${productName} product?`}
      message={`You will lose all the data associated with this product, like product maturity score, improvement areas and more.`}
      toggle={handleClose}
      onConfirm={handleDeleteClick}
      cancelButtonText={"Cancel"}
      confirmButtonText={loading ? "Loading" : "Delete"}
    >
      {error ? (
        <div className="my-2 gap-4 d-flex flex-column justify-content-center">
          {error.graphQLErrors.map(({ message }, index) => (
            <p key={index} className="fs-6 text-center text-danger-dark">
              {message}
            </p>
          ))}
        </div>
      ) : null}
    </ConfirmationModal>
  );
}
