import { DeploymentFrequencyPipelineData } from "./metrics-tab";
import { calculateTrendLength } from "../../../../views/profile/empirical-metric-card";
import Pluralise from "../../../../utils/pluralise";

import { TabContent, TabPane } from "@bphxd/ds-core-react";
import { Divider } from "../../../../components/divider";
import { MetricsChart } from "../../../../views/profile/metrics-chart";

type DeploymentFrequencyTabContentProps = {
  selectedPipeline: string;
  pipelineData: DeploymentFrequencyPipelineData[];
  // bpDeploymentFrequency: bpDeploymentFrequency;
};

export function DeploymentFrequencyTabContent({ selectedPipeline, pipelineData }: DeploymentFrequencyTabContentProps) {
  return (
    <TabContent activeTab={selectedPipeline} tabStyle="tabs" vertical>
      {pipelineData?.map((pipeline) => {
        pipeline.last30DaysDeployments = pipeline.last30DaysDeployments ?? 0;
        pipeline.last12MonthsDeployments = pipeline.last12MonthsDeployments ?? 0;
        return (
          <TabPane tabId={pipeline.id} key={pipeline.id}>
            <div className="x5-ps-6">
              <h4 className="fw-light pb-4">{pipeline.name}</h4>
              <div className="d-flex">
                <div className="w-50 border-end pe-5">
                  <p className="fw-regular mb-0">
                    Deploys over the {calculateTrendLength(pipeline.trendingPeriodStartDate).toLowerCase()}
                  </p>
                  <h4 className="fw-light mb-0">{pipeline?.last30DaysDeployments ?? "N/A"}</h4>
                  <>
                    {pipeline.last30DaysDeployments > pipeline.last12MonthsDeployments ? (
                      <p className="small fw-regular text-success-emphasis mb-2">
                        Increased by{" "}
                        {(100 * (pipeline.last30DaysDeployments / pipeline.last12MonthsDeployments) - 100).toFixed(2)}%
                        compared to last {pipeline.periodLength} average
                      </p>
                    ) : (
                      <p className="small fw-regular text-danger-dark mb-2">
                        Decreased by{" "}
                        {(100 - 100 * (pipeline.last30DaysDeployments / pipeline.last12MonthsDeployments)).toFixed(2)}%
                        compared to last {pipeline.periodLength} average
                      </p>
                    )}
                  </>
                </div>
                <div className="w-50 x5-ms-5">
                  <p className="fw-regular mb-0">Average monthly deploys over the last {pipeline.periodLength}</p>
                  <h4 className="fw-light mb-0">{pipeline?.last12MonthsDeployments ?? "N/A"}</h4>
                </div>
              </div>
              {pipeline.deploymentData.length > 0 ? (
                <>
                  <Divider pBottom="M" pTop="M" />
                  <div>
                    <p className="fw-normal">
                      Last {pipeline.deploymentData.length} month{Pluralise(pipeline.deploymentData.length)}' breakdown
                    </p>
                    <MetricsChart data={pipeline.deploymentData} aSeriesProp="deployments" aSeriesName="Deployments" />
                  </div>
                </>
              ) : null}
            </div>
          </TabPane>
        );
      })}
    </TabContent>
  );
}
