import { Add16, Info24 } from "@bphxd/ds-core-react/lib/icons/index";
import { Popover } from "../../../../components/tooltip/popover";
import { Button } from "../../../../components/button";

export function SupportGroupHeader({
  showAddNewButton,
  onSetupSupportGroupsClick,
}: {
  showAddNewButton: boolean;
  onSetupSupportGroupsClick: () => void;
}) {
  return (
    <div className="d-flex justify-content-between mb-5">
      <div className="d-flex">
        <h5 className="mb-0 text-center">Support groups</h5>
        <div id="supportGroupsId" className="cursor-pointer">
          <Info24 className="opacity-60" name="supportGroupsId" type="button" tabIndex={0} />
          <Popover
            target="supportGroupsId"
            testId="support-group-tooltip-test-id"
            title="Support Groups"
            placement="right"
            trigger="focus"
            toolTipText={
              "By setting a support group you'll be able to set schedules that ensure the right person is always available to respond quickly to incidents and outages related to your product."
            }
          />
        </div>
      </div>
      <div>
        {showAddNewButton && (
          <Button
            iconPosition="start"
            Icon={Add16}
            level="tertiary"
            theme="standard"
            rounded="pill"
            size="xs"
            data-testid="add-new-support-group"
            name="add-new-support-group"
            className="text-nowrap"
            trackingEventProps={{
              step: "add new support group",
            }}
            onClick={onSetupSupportGroupsClick}
          >
            Add new
          </Button>
        )}
      </div>
    </div>
  );
}
