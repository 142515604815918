import { Link } from "../../../../components/Link";
import { ExternalLink16 } from "@bphxd/ds-core-react/lib/icons/index";

export function SetSupportGroupsInfo() {
  return (
    <div className="px-5 py-6 mt-6 bg-white border rounded-5">
      <div className="mb-5 d-flex flex-column justify-content-between">
        <h5 className="mb-0">Support groups</h5>
        <p className="mt-4 fw-light small">
          By setting a support group you'll be able to set schedules that ensure the right person is always available to
          quickly respond to incidents and outages related to your product.
        </p>
        <p className="fw-light small">To set up a support group, you will need to have an Ops group defined first.</p>
        <Link
          className="mt-3 fw-light small"
          name="set-up-ops-groups-in-service-now"
          hideExternalLinkIcon={true}
          href="https://bp.service-now.com/myservicehub"
        >
          Set Ops Groups in ServiceNow
          <ExternalLink16 />
        </Link>
        <Link
          className="mt-3 fw-light small"
          name="more-info-about-ops-groups"
          hideExternalLinkIcon={true}
          href="/knowledgehub/gWn9Zj"
        >
          More information about Ops groups
        </Link>
      </div>
    </div>
  );
}
