"use client";
import { useCallback, useMemo, useState } from "react";

import { useLocation } from "../../../../hooks/use-location";

import { Card } from "@bphxd/ds-core-react";
import { Col, Row } from "reactstrap";
import { SearchInput } from "../../../../components/Input";
import { Divider } from "../../../../components";
import { SortByDropdown } from "../../../../components/dropdown-menu";
import { formatMillisToDateTime } from "../../../../utils/dates";
import { capitalize } from "../../../../utils/helpers/utils";
import {
  YallaDeployment,
  YallaInstanceHealthStatus,
  YallaInstanceSyncStatus,
  YallaService,
  YallaServiceInstance,
} from "../../../../gql/graphql";
import { HealthBadge, SyncBadge } from "./status-badges";
import { Right16 } from "@bphxd/ds-core-react/lib/icons";
import { LinkButton } from "../../../../components/link-button";
import { Loader } from "../../../../components/spinners/loading-spinner";
import { Link } from "../../../../components/Link";
import { HEALTH_STATUS_INFO, SYNC_STATUS_INFO } from "../utils";
import { FilterGroup } from "../../../../components/filter-group";

export enum SORT_ENUMS {
  NAME_ASC = "name (A-Z)",
  NAME_DESC = "name (Z-A)",
}

function formatReleaseMessage(release: Omit<YallaDeployment, "artifactUri" | "createdAt" | "sourceCommitUri">) {
  const releasedToAllEnvironments = release.deploymentSteps.every(({ status }) => status === "SUCCEEDED");
  const latestDeploy = release.deploymentSteps.findLast(({ status }) => status !== "NOTSTARTED");
  const releasedToAllEnvironmentsMessage = releasedToAllEnvironments
    ? "all environments"
    : `environment: ${latestDeploy?.environment}`;
  const dateString = formatMillisToDateTime(new Date(release.updatedAt).valueOf());
  if (latestDeploy?.status === "INPROGRESS") {
    return `Latest release in progress in ${releasedToAllEnvironmentsMessage} - ${dateString}`;
  } else if (latestDeploy?.status === "SUCCEEDED") {
    return `Latest release deployed successfully in ${releasedToAllEnvironmentsMessage} - ${dateString}`;
  } else if (latestDeploy?.status === "FAILED") {
    return `Latest release failed in ${releasedToAllEnvironmentsMessage} - ${dateString}`;
  }

  return "The latest release has not yet been deployed";
}

type ServiceTransposedData = {
  environments: string[];
  healthStatuses: string[];
  syncStatuses: string[];
  statusColor: string;
};

type EnrichedService = YallaService & ServiceTransposedData;

const MAX_STATUS_VALUE = 5;
function transposeInstancesData(instances: YallaServiceInstance[]) {
  let minStatusValue = MAX_STATUS_VALUE;
  const healthStatusValues: { [key in YallaInstanceHealthStatus]: number } = {
    [YallaInstanceHealthStatus.Healthy]: MAX_STATUS_VALUE,
    [YallaInstanceHealthStatus.Progressing]: 4,
    [YallaInstanceHealthStatus.Unknown]: 3,
    [YallaInstanceHealthStatus.Missing]: 3,
    [YallaInstanceHealthStatus.Degraded]: 2,
    [YallaInstanceHealthStatus.Suspended]: 1,
  };

  const syncStatusValues: { [key in YallaInstanceSyncStatus]: number } = {
    [YallaInstanceSyncStatus.Synced]: MAX_STATUS_VALUE,
    [YallaInstanceSyncStatus.Syncing]: 4,
    [YallaInstanceSyncStatus.Unknown]: 3,
    [YallaInstanceSyncStatus.OutOfSync]: 1,
  };

  const environments: string[] = [];
  const healthStatuses: string[] = [];
  const syncStatuses: string[] = [];

  instances.forEach(({ environment, health, syncStatus }) => {
    environments.push(environment);
    healthStatuses.push(health);
    syncStatuses.push(syncStatus);

    const healthValue = healthStatusValues[health];
    const syncValue = syncStatusValues[syncStatus];
    minStatusValue = Math.min(minStatusValue, healthValue, syncValue);
  });

  // TODO: this needs a better variable name
  let statusColor = "border-bp-green-500";
  if (minStatusValue === 1) {
    statusColor = "border-danger-dark";
  } else if (minStatusValue === 2) {
    statusColor = "border-bp-orange-500";
  } else if (minStatusValue < MAX_STATUS_VALUE) {
    statusColor = "text-default";
  }

  return { environments, healthStatuses, syncStatuses, statusColor };
}

export function ServicesTab({ productName, services }: { productName?: string; services?: YallaService[] }) {
  const { router, search } = useLocation();
  const { search_services, sort_by } = search;
  const searchServicesInput = (search_services as string) ?? "";

  const isLoading = !services || !productName;

  const sortFields = new Map(Object.entries(SORT_ENUMS));
  const sortBy = (sort_by as string) ?? SORT_ENUMS.NAME_ASC;

  const enhancedServices: EnrichedService[] | undefined = useMemo(() => {
    return services?.map((service) => {
      const transposedData = transposeInstancesData(service.instances);
      return {
        ...service,
        ...transposedData,
      };
    });
  }, [services]);

  const additionalFilterFunction = useCallback(
    (service: EnrichedService | undefined): boolean => {
      if (!service) {
        return false;
      }
      return (
        service.name.toLowerCase().includes(searchServicesInput.toLowerCase()) ||
        service.instances.some((instance) =>
          instance.environment.toLowerCase().includes(searchServicesInput.toLowerCase()),
        )
      );
    },
    [searchServicesInput],
  );

  const [filterFunction, setFilterFunction] = useState<(service: EnrichedService) => boolean>(
    () => additionalFilterFunction,
  );

  const sortedServices: EnrichedService[] | undefined = useMemo(() => {
    if (!filterFunction) {
      return enhancedServices;
    }
    return enhancedServices?.filter(filterFunction).toSorted((a, b) => {
      if (sortFields.get(sortBy) === SORT_ENUMS.NAME_ASC) {
        return a.name.localeCompare(b.name);
      }
      if (sortFields.get(sortBy) === SORT_ENUMS.NAME_DESC) {
        return b.name.localeCompare(a.name);
      }
      return a.name.localeCompare(b.name);
    });
  }, [enhancedServices, filterFunction, sortBy]);

  if (isLoading) {
    return (
      <div className="d-flex">
        <div className="mx-auto d-flex align-items-baseline gap-4">
          <p className="fw-light mb-0">Loading...</p>
          <Loader size="sm" />
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="d-flex align-items-center mb-5 gap-4 w-75 pe-3">
        <SearchInput
          value={searchServicesInput}
          onChange={(val) =>
            router?.push(
              {
                query: { ...search, search_services: val as string },
              },
              "",
              { shallow: true },
            )
          }
          placeholder="Search services"
        />
        <div className="w-50 opacity-70">
          <SortByDropdown sortFields={sortFields} currentField={sortBy} />
        </div>
      </div>

      <Row>
        <Col xs="9">
          <div className="row">
            {sortedServices?.map((service, index) => {
              const { healthStatuses, instances, syncStatuses, statusColor } = service;
              return (
                <Col xs="12" md="6" key={service.name + index} className="mb-4">
                  <div className={"rounded-3 border-top border-2 " + statusColor}></div>
                  <Card rounded="4" className="g-col-4 p-6 d-flex h-100 flex-column justify-content-between rounded-3">
                    <div className="d-flex lead align-items-center text-left">
                      <span className="lead fw-normal">{capitalize(service.name)}</span>
                      <LinkButton
                        name="go"
                        Icon={Right16}
                        iconOnly
                        level="quartenary"
                        rounded="circle"
                        size="sm"
                        href={`/profile/product/${search.product_id}/service/${service.name}?product_name=${productName}&platform_name=${service.platformName}`}
                      />
                    </div>
                    <Divider pTop="XS" pBottom="S" />
                    <div className="d-flex gap-3">
                      <Col className="d-flex flex-column gap-3">
                        {instances.map((instance) => (
                          <>
                            <Link
                              key={index}
                              className="opacity-70"
                              href={`/profile/product/${search.product_id}/service/${service.name}/instance/${instance.name}?product_name=${productName}&platform_name=${service.platformName}`}
                            >
                              {capitalize(instance.environment)}
                            </Link>
                          </>
                        ))}
                      </Col>
                      <Col className="d-flex flex-column gap-3 align-items-start">
                        {healthStatuses.map((health, idx) => (
                          <HealthBadge
                            key={idx}
                            status={health as YallaInstanceHealthStatus}
                            labelClassName="opacity-70"
                          />
                        ))}
                      </Col>
                      <Col className="d-flex flex-column gap-3 align-items-start">
                        {syncStatuses.map((syncStatus, idx) => (
                          <SyncBadge
                            key={idx}
                            status={syncStatus as YallaInstanceSyncStatus}
                            labelClassName="opacity-70"
                          />
                        ))}
                      </Col>
                    </div>
                    <div>
                      <Divider pTop="S" pBottom="XS" />
                      <p className="small fw-light lh-1-5 fs-7 mb-0">
                        {service.deployments.length > 0
                          ? formatReleaseMessage(
                              service.deployments.toSorted(
                                (a, b) => new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf(),
                              )[0],
                            )
                          : "No release info found"}
                      </p>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </div>
        </Col>
        <Col xs="3">
          <FilterGroup
            filters={[
              {
                name: "Health status",
                field: "healthStatuses",
                toolTipText: HEALTH_STATUS_INFO,
                options: Object.values(YallaInstanceHealthStatus).map((status) => ({
                  value: status,
                  icon: <HealthBadge status={status} labelClassName="opacity-90 small-md fw-light" />,
                })),
              },
              {
                name: "Sync status",
                field: "syncStatuses",
                toolTipText: SYNC_STATUS_INFO,
                options: Object.values(YallaInstanceSyncStatus).map((status) => ({
                  value: status,
                  icon: <SyncBadge status={status} labelClassName="opacity-90 small-md fw-light" />,
                })),
              },
            ]}
            items={enhancedServices ?? []}
            setFilterFunction={setFilterFunction}
            additionalFilterFunction={additionalFilterFunction}
          />
        </Col>
      </Row>
    </div>
  );
}
