import { Modal as M } from "../../../../components/modal";

import { LinkSnowEntityForm } from "./form";

import { Product } from "../../../../types/ProfileTypes";

type ModalProps = {
  isModalOpen: boolean;
  toggle: () => void;
  selectedProduct: Product;
};

export function Modal({ isModalOpen, toggle, selectedProduct }: ModalProps) {
  const { l3Entity } = selectedProduct;

  return (
    <M
      size="lg"
      backdrop="static"
      isOpen={isModalOpen}
      hasCancelDialog
      header={l3Entity?.name ? `Edit ServiceNow Level 3 entity link` : "Link a ServiceNow Level 3 entity"}
      onClose={toggle}
    >
      <LinkSnowEntityForm selectedProduct={selectedProduct} onFormSubmit={toggle} />
    </M>
  );
}
