import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from "../button";
import { Close } from "@bphxd/ds-core-react";
import { textToSlug } from "../../utils/helpers/utils";
import { PropsWithChildren } from "react";

type ConfirmationModalProps = PropsWithChildren<{
  isOpen: boolean;
  toggle: () => void;
  title: string;
  message: string;
  cancelButtonText: string;
  confirmButtonText: string;
  onConfirm: () => void;
  name: string;
  loading?: boolean;
}>;
export function ConfirmationModal({
  isOpen,
  toggle,
  title,
  message,
  cancelButtonText,
  confirmButtonText,
  onConfirm,
  name,
  children,
  loading,
}: ConfirmationModalProps) {
  // if children are present content will be longer and hence the design changes
  const bodyAlignment = children ? "text-start" : "text-center";
  const footerAlignment = children ? "text-end" : "text-center";
  return (
    <Modal
      data-testid={`${textToSlug(name)}-modal`}
      size="md"
      isOpen={isOpen}
      toggle={toggle}
      className="modal-dialog-centered"
    >
      <ModalHeader className="border-0" close={<Close onClick={toggle} />} />
      <ModalBody className={`${bodyAlignment} p-0 x5-mx-8 mb-7`}>
        <h4 className={"fw-light"}>{title}</h4>
        <p className="fw-light mb-0">{message}</p>
        {children}
      </ModalBody>
      <ModalFooter className={`${footerAlignment} p-0 d-block`}>
        <div className="my-4 x5-me-3">
          <Button
            data-testid={`${textToSlug(name)}-button-cancel-dialog`}
            onClick={toggle}
            type="button"
            className="x5-me-1"
            name="dialog-cancel-button"
            level="tertiary"
            theme="standard"
            rounded="pill"
            disabled={loading}
          >
            {cancelButtonText}
          </Button>
          <Button
            data-testid={`${textToSlug(name)}-button-confirm-dialog`}
            onClick={onConfirm}
            type="button"
            name="dialog-confirm-button"
            level="primary"
            theme="standard"
            rounded="pill"
            loading={loading}
          >
            {confirmButtonText}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}
