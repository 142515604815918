import { convertToDate } from "../../utils/dates";
import { trackEvent } from "../../utils/event-tracker";
import { textToSlug } from "../../utils/helpers/utils";
import { getDisplayedScore } from "../bestPractice/MaturityComponents/display-score";

import { DropdownItem, Row } from "reactstrap";
import { CircleCheck24, Edit24, Trash24 } from "@bphxd/ds-core-react/lib/icons";
import { HelpMeImproveMyCapabilityButton } from "../../components/button";
import { Link } from "../../components/Link";

import { ImprovementGoal } from "../../api/products/gql";
import { DropdownKebabMenu } from "../../components/kebab-menu";
import { QuizResult } from "../../gql/graphql";

type ImprovementAreaItemProps = {
  goal: ImprovementGoal;
  quizResult?: QuizResult;
  productName?: string;
  canEdit?: boolean;
  onEditClick: (goal: ImprovementGoal) => void;
  onRemoveClick: (goal: ImprovementGoal) => void;
};

export function ImprovementAreaItem({
  goal,
  quizResult,
  productName,
  canEdit,
  onEditClick,
  onRemoveClick,
}: ImprovementAreaItemProps) {
  const { targetScore, completionDate } = goal;
  if (!quizResult) return null;

  return (
    <Row className="border-bottom py-4 d-flex align-items-center jusitify-content-between">
      <div className="ps-0 d-flex flex-column flex-grow-1 w-30">
        <div>
          <Link
            href={`/best-practices/devops-maturity/capability-details/${textToSlug(quizResult?.quiz?.name)}`}
            onClick={(e) => e.stopPropagation()}
          >
            <p className="small fw-light mb-0">{quizResult?.quiz.name}</p>
          </Link>
        </div>
        {targetScore && completionDate ? (
          <small className="opacity-80">
            Improve to {targetScore} by {convertToDate(completionDate)}
          </small>
        ) : null}
      </div>
      <div className="d-flex align-items-center ps-0" style={{ width: 365 }}>
        <div className="d-flex gap-2 me-7">
          <p className="small fw-light mb-0">
            Current score: <span className="fw-normal">{getDisplayedScore(quizResult?.score?.toFixed(1))}</span>
          </p>
        </div>
        <div className="d-flex gap-2 me-4">
          {targetScore && (quizResult?.score ?? 0) > targetScore ? (
            <p className="small fw-light mb-0 text-brand-primary d-flex align-items-center">
              <CircleCheck24 className="me-2" />
              Achieved
            </p>
          ) : (
            <HelpMeImproveMyCapabilityButton
              capabilityName={quizResult?.quiz.name as string}
              trackingEventProps={{
                productName,
                capabilityName: quizResult?.quiz.name,
              }}
            />
          )}
        </div>
        {canEdit && (
          <div className="ms-auto">
            <DropdownKebabMenu
              trackingEventProps={{
                name: "button_improvement-area-kebab-menu_click",
                product: productName,
              }}
              size="md"
            >
              <DropdownItem
                className="ps-5"
                onClick={() => {
                  onEditClick(goal);
                  trackEvent({
                    name: "button_edit-improvement-area_click",
                    product: productName,
                    capability: quizResult?.quiz.name,
                  });
                }}
                data-testid="edit-improvement-area"
              >
                <Edit24 className="btn-icon-prefix" /> Edit
              </DropdownItem>
              <DropdownItem
                className="ps-5"
                onClick={() => {
                  onRemoveClick(goal);
                  trackEvent({
                    name: "button_remove-improvement-area_click",
                    product: productName,
                    capability: quizResult?.quiz.name,
                  });
                }}
                data-testid="remove-improvement-area"
              >
                <Trash24 className="btn-icon-prefix" />
                Remove
              </DropdownItem>
            </DropdownKebabMenu>
          </div>
        )}
      </div>
    </Row>
  );
}
