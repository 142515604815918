import { useQuery } from "@apollo/client";
import { GET_LIST_QUIZZES } from "../../../api/quizzes/gql";
import { Quiz } from "../../../gql/graphql";

export enum QuizType {
  DORA = "DORA",
  DORA_QUICK_CHECK = "DORA_QUICK_CHECK",
}

type UseQuizzesOutput = {
  quizzes?: Quiz[];
  isLoadingQuizzes: boolean;
  refetchQuizzes?: () => void;
};

export function useQuizzes({ quizTypes }: { quizTypes: QuizType[] }): UseQuizzesOutput {
  const {
    data: { quizzes } = { quizzes: undefined },
    loading: isLoadingQuizzes,
    refetch: refetchQuizzes,
  } = useQuery(GET_LIST_QUIZZES, {
    fetchPolicy: "no-cache",
    variables: {
      quizTypes,
    },
  });

  return { quizzes, isLoadingQuizzes, refetchQuizzes };
}
