"use client";
import { useEffect } from "react";
import { useWebsocket } from "../../../hooks/use-websocket";

type YallaPlatformUpdatedEventData = {
  service_name: string | null;
};

type YallaPlatformUpdatedEvent = {
  id: string;
  data: YallaPlatformUpdatedEventData;
};

const PLATFORM_UPDATED_EVENT = "PLATFORM_UPDATED";

export const usePlatformUpdatedEvent = ({
  platformName,
  serviceName,
  handleEvent,
}: {
  platformName?: string;
  serviceName?: string;
  environment?: string;
  handleEvent: () => void;
}) => {
  const event = useWebsocket(platformName ? `/events/${PLATFORM_UPDATED_EVENT}/${platformName}` : undefined);

  const eventHandler = (ev: YallaPlatformUpdatedEvent) => {
    if (!ev?.data?.service_name || ev?.data?.service_name?.includes(serviceName!)) {
      handleEvent();
    }
    return;
  };

  useEffect(() => {
    if (!event || !serviceName) {
      return;
    }

    event.on(PLATFORM_UPDATED_EVENT, eventHandler);

    // Cleanup the event listener on unmount or when dependencies change
    return () => {
      event.off(PLATFORM_UPDATED_EVENT, eventHandler);
    };
  }, [platformName, serviceName, handleEvent]);
};
