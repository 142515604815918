import { GetAccelerateProductSupportGroupDetailsQuery } from "../../../../gql/graphql";
import { Alert } from "@bphxd/ds-core-react";
import { Info24 } from "@bphxd/ds-core-react/lib/icons/index";
import { Link } from "../../../../components/Link";
import _ from "lodash";
import { UserCard } from "../../../../components/cards/user-card";
import { Button } from "../../../../components/button";
import { Teams } from "../../../../icons/media/teams";
import { Slack } from "../../../../icons/media/index";

export function SupportGroupContent({
  group,
}: {
  group: GetAccelerateProductSupportGroupDetailsQuery["product"]["supportGroups"][0];
}) {
  if (group.isOnboarding) {
    return (
      <Alert color="info">
        <div className="d-flex py-3">
          <div className="me-2">
            <Info24 className="ms-n3 mt-n3" />
          </div>
          <div className="fs-6 lh-1-5">
            <p className="mb-3 fw-light opacity-90">This support group is being onboarded.</p>
            <p className="fw-light opacity-90">
              It can take up to 5 minutes for the onboarding process to be completed. Once it is done, the team will be
              able to manage support schedules and users will be able to raise alerts against it.
            </p>
          </div>
        </div>
      </Alert>
    );
  }

  if (!group.areOnCallMembersPublic) {
    return (
      <p className="fw-light small mb-3">
        This support group has chosen not to display the list of online members. However, please feel free to contact
        us, and someone from our team will be happy to assist you.
      </p>
    );
  }

  if (group && !group.users) {
    return (
      <p className="fw-light small mb-3">
        No schedule has been set for this support group.{" "}
        <Link name="set-support-group-schedule-now" href={group.links?.manageScheduleLink}>
          Set schedule now
        </Link>
      </p>
    );
  }

  if (group.users?.length) {
    return (
      <>
        <p className="fw-light small">Currently on call</p>
        {_.uniqBy(group.users, "userId").map(({ givenName, surname, jobTitle, userId, photo, contact }) => {
          const contactLink = contact?.teams ? contact.teams : contact?.slack;
          return (
            <div key={userId} className="d-flex justify-content-between align-items-center mb-4">
              <div className="col-11">
                <UserCard key={userId} name={`${givenName} ${surname}`} title={jobTitle ?? ""} photoUrl={photo ?? ""} />
              </div>
              {contactLink && (
                <div className="col-1 text-center">
                  <Button
                    data-testid={`contact-button-${givenName}`}
                    theme="standard"
                    level="primary"
                    rounded="circle"
                    className="bg-white d-flex justify-content-center"
                    Icon={() => (contact?.teams ? <Teams height={16} width={16} /> : <Slack height={16} width={16} />)}
                    onClick={() => window.open(contactLink, "_blank")}
                    iconOnly
                    name="contact-user"
                    size="xs"
                  />
                </div>
              )}
            </div>
          );
        })}
      </>
    );
  }
  return (
    <p className="fw-light small">
      Unfortunately, nobody is on-call for this support group right now.
      {/* Support hours will resume on <span className="fw-normal">{convertToDate(onCallStartTime)}</span>. */}
    </p>
  );
}
