"use client";
import { ListGroup, ListGroupItem } from "../../../../components/list-group";
import { DropdownItem } from "reactstrap";
import { UserCard } from "../../../../components/cards/user-card";
import { DropdownKebabMenu } from "../../../../components/kebab-menu";
import { Remove24 } from "@bphxd/ds-core-react/lib/icons";
import { trackEvent } from "../../../../utils/event-tracker";
import { ProductResponse } from "../../../../gql/graphql";
import { useMemo } from "react";
import { Badge } from "@bphxd/ds-core-react";
import { useIsUserMember } from "../../../../hooks/use-is-user-member";

type ProfileMembersProps = {
  product: ProductResponse;
  onRemoveUser: (id: string) => void;
};

export function TeamMembers({ product, onRemoveUser }: ProfileMembersProps) {
  const members = product.members;
  const userIsMember = useIsUserMember(product);
  const sortedMembers = useMemo(() => {
    if (!members) {
      return [];
    }
    const filteredMembers = members
      .filter((member) => member.enabled !== false && member.givenName && member.surname)
      .map((member) => {
        const isTeamAdmin = [
          product.ownerId?.toLowerCase(),
          product.techLeadId?.toLowerCase(),
          ...(product.delegates?.map((user) => user.email?.toLowerCase()) ?? []),
        ].includes(member.email?.toLowerCase() ?? "");
        const isOnSupport = product?.supportGroups
          ?.flatMap(({ users }) => users)
          .flatMap((user) => user?.email?.toLowerCase())
          .includes(member.email?.toLowerCase());

        return {
          ...member,
          isTeamAdmin,
          isOnSupport,
        };
      });

    const bothAdminAndSupportMembers = filteredMembers
      .filter((member) => member.isTeamAdmin && member.isOnSupport)
      .toSorted((a, b) => (a.surname ?? "").localeCompare(b.surname ?? ""));
    const adminMembers = filteredMembers
      .filter((member) => member.isTeamAdmin && !member.isOnSupport)
      .toSorted((a, b) => (a.surname ?? "").localeCompare(b.surname ?? ""));
    const supportMembers = filteredMembers
      .filter((member) => !member.isTeamAdmin && member.isOnSupport)
      .toSorted((a, b) => (a.surname ?? "").localeCompare(b.surname ?? ""));
    const remainingMembers = filteredMembers
      .filter((member) => !member.isOnSupport && !member.isTeamAdmin)
      .toSorted((a, b) => (a.surname ?? "").localeCompare(b.surname ?? ""));
    return [...bothAdminAndSupportMembers, ...adminMembers, ...supportMembers, ...remainingMembers];
  }, members);

  if (members?.length === 0) {
    return (
      <div className="p-6 m-6 text-center w-100">
        <ListGroup>
          <p className="p-4 m-4 lead fs-5 fw-normal">There are currently no members</p>
        </ListGroup>
      </div>
    );
  }

  return (
    <ListGroup>
      {sortedMembers.map((member) => {
        const { userId, email, givenName, surname, jobTitle, photo, isTeamAdmin, isOnSupport } = member;

        return (
          <ListGroupItem key={userId}>
            <div className="py-2 w-100">
              <div className="d-flex align-items-center justify-content-between">
                <UserCard name={`${givenName} ${surname}`} title={jobTitle!} photoUrl={photo!}></UserCard>
                <div className="h-100 d-flex align-items-center">
                  {isTeamAdmin && (
                    <Badge className="h-100 me-4" color="info-subtle" data-testid={`admin-${userId}`}>
                      Admin
                    </Badge>
                  )}
                  {isOnSupport && (
                    <Badge className="h-100" color="info-subtle" data-testid={`support-${userId}`}>
                      Support
                    </Badge>
                  )}
                  {!isTeamAdmin && userIsMember && (
                    <DropdownKebabMenu
                      trackingEventProps={{
                        name: "button_team-member-menu_click",
                        teamMember: email,
                      }}
                      size="md"
                    >
                      <DropdownItem
                        onClick={() => {
                          onRemoveUser(email!);
                          trackEvent({
                            name: "button_team-member-remove_click",
                            teamMember: email,
                          });
                        }}
                      >
                        <Remove24 className="me-2" /> Remove team member
                      </DropdownItem>
                    </DropdownKebabMenu>
                  )}
                </div>
              </div>
            </div>
          </ListGroupItem>
        );
      })}
    </ListGroup>
  );
}
