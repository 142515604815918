import { Slack } from "../../../../icons/media/index";
import { Teams } from "../../../../icons/media/teams";
import { Mail } from "react-feather";
import { formatMillisToTime, isToday, isTomorrow } from "../../../../utils/dates";
import { GetAccelerateProductSupportGroupDetailsQuery } from "../../../../gql/graphql";

export function getIcon(contactType: string) {
  let icon;

  switch (contactType) {
    case "SLACK":
      icon = <Slack height={16} width={16} />;
      break;
    case "TEAMS":
      icon = <Teams height={16} width={16} />;
      break;
    case "EMAIL":
      icon = <Mail height={16} width={16} />;
      break;
    default:
      icon = <Mail height={16} width={16} />;
      break;
  }
  return icon;
}

export function getHref(contactMethod: { type: string; value: string }) {
  if (contactMethod.type === "EMAIL") {
    return `https://outlook.office.com/mail/deeplink/compose?to=${contactMethod.value}`;
  }
  return contactMethod.value;
}

export function getAvailableOpsGroups(product?: GetAccelerateProductSupportGroupDetailsQuery["product"]) {
  return product?.l3Entity?.opsGroups
    ?.filter((group) => {
      const supportGroupAlreadyMapped =
        Boolean(group?.supportGroup) ||
        product?.supportGroups?.some((supportGroup) => supportGroup.opsGroupId === group.id);

      return !supportGroupAlreadyMapped;
    })
    .filter((group) => !group.name.startsWith("DEV"));
}

export function getSelectedOpsGroup(
  product: GetAccelerateProductSupportGroupDetailsQuery["product"],
  selectedGroup?: GetAccelerateProductSupportGroupDetailsQuery["product"]["supportGroups"][0],
) {
  return product?.l3Entity?.opsGroups
    ?.filter((group) => {
      const supportGroupAlreadyMapped =
        Boolean(group?.supportGroup) ||
        product?.supportGroups?.some((supportGroup) => supportGroup.opsGroupId === group.id);

      return group.id === selectedGroup?.opsGroupId || !supportGroupAlreadyMapped;
    })
    .filter((group) => !group.name.startsWith("DEV"));
}

export function areOpsGroupsPresent(product?: GetAccelerateProductSupportGroupDetailsQuery["product"]) {
  const orgOptions = getAvailableOpsGroups(product);
  return !!(orgOptions && orgOptions?.length > 0);
}

export function getShiftHours(
  userShifts: GetAccelerateProductSupportGroupDetailsQuery["product"]["supportGroups"][0]["userShifts"],
) {
  let minStart = Infinity;
  let maxEnd = -Infinity;
  if (userShifts?.length === 0) {
    return "Offline";
  }

  userShifts?.forEach((shift) => {
    const shiftStart = shift.start ?? Infinity;
    const shiftEnd = shift.end ?? -Infinity;
    if (userShifts.length === 1) {
      minStart = shiftStart;
      maxEnd = shiftEnd;
      return `${minStart} - ${maxEnd}`;
    }
    if (shiftStart < minStart) {
      minStart = shiftStart;
    }
    if (shiftEnd > maxEnd) {
      maxEnd = shiftEnd;
    }
  });

  if (minStart === Infinity || maxEnd === -Infinity) {
    return "No schedule set";
  }
  const today = isToday(minStart);
  const tomorrow = isTomorrow(maxEnd);
  const finalMinStart = formatMillisToTime(minStart);
  const finalMaxEnd = formatMillisToTime(maxEnd);
  return `Online${today ? " today" : ""} from ${finalMinStart} - ${tomorrow ? "tomorrow " : ""}${finalMaxEnd}`;
}
