import { ProductsQuery } from "../../../gql/graphql";
import { useMemo } from "react";

export function useSortedProducts(myProductsList: ProductsQuery["products"], starredProductId?: string | null) {
  return useMemo(() => {
    if (!starredProductId) return myProductsList;

    const starredIndex = myProductsList.findIndex((product) => product.id === starredProductId);
    if (starredIndex === -1) return myProductsList;

    const updatedProducts = [...myProductsList];
    const [starredProduct] = updatedProducts.splice(starredIndex, 1);
    updatedProducts.unshift(starredProduct);

    return updatedProducts;
  }, [myProductsList, starredProductId]);
}
