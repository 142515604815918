import { useFeature } from "featurehub-react-sdk";
import { NotificationTray } from "./notification-tray";
import { UserAvatar } from "../navigation/navbar-top";

export function Header() {
  const FE_SIDE_MEGA_MENU = useFeature("FE_SIDE_MEGA_MENU");
  return FE_SIDE_MEGA_MENU ? (
    <div className="z-1 mb-10">
      <div className="position-absolute top-0 end-0">
        <div className="d-flex x5-me-7 mt-3 align-self-end">
          <div className="x5-me-2">
            <NotificationTray />
          </div>
          <UserAvatar />
        </div>
      </div>
    </div>
  ) : null;
}
