import { getKHubAPI } from "../rest-api";

export const endpoint = (repository: string, path: string) => `v1/documents/uuid?repository=${repository}&path=${path}`;

export async function getUUID({ repository, path }: { repository: string; path: string }) {
  try {
    const response = await getKHubAPI<{ uuid: string }>({
      spanName: "Get khub document UUID",
      query: endpoint(repository, path),
    });

    return response.body;
  } catch (error: any) {
    throw new Error(error);
  }
}
