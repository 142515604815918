"use client";
import { useState } from "react";
import { useMutation } from "@apollo/client";

import { trackEvent } from "../../../../utils/event-tracker";
import { useToast } from "../../../../hooks/use-toast";
import { useCreateKhubArea } from "../../../../hooks/use-create-khub-area";
import {
  CREATE_ACCELERATE_PRODUCT,
  EDIT_ACCELERATE_PRODUCT,
  GET_ACCELERATE_PRODUCTS,
} from "../../../../api/products/gql";

import { NO_PRODUCTS_SELECTED, Step1Form } from "./step-1";
import { Step2Form } from "./step-2";
import { Step3Form } from "./step-3";
import { NoSNOWEntity } from "./no-snow-entity-selected";

import { L3Entity } from "../../../../types/ProfileTypes";
import { BasicProductFieldsFragment, ProductResponse } from "../../../../gql/graphql";
import { useLocation } from "../../../../hooks/use-location";

type WizardProps = {
  loading: boolean;
  onClose: () => void;
  selectedProduct?: BasicProductFieldsFragment;
  l3Entities: L3Entity[];
};

export type Step1Data = {
  l3Uid: string;
};

export type Step2Data = {
  name: string;
  description: string;
};

export type Step3Data = {
  createAreaInKhub: string;
};

const NO_SNOW_MESSAGE_STEP = -1;

export function Wizard({ onClose, loading, l3Entities, selectedProduct }: WizardProps) {
  const [currentStep, setCurrentStep] = useState(1);
  const { router } = useLocation();

  const [step1Data, setStep1Data] = useState<Step1Data>({
    l3Uid: selectedProduct?.l3EntityUid ?? "",
  });
  const [step2Data, setStep2Data] = useState<Step2Data>({
    name: selectedProduct?.name || "",
    description: selectedProduct?.description || "",
  });
  const [step3Data, setStep3Data] = useState<Step3Data>({
    createAreaInKhub: "true",
  });
  const { displayToast, displayError } = useToast();

  const [createAreaInKhub] = useCreateKhubArea({ type: "product" });

  const [createAccelerateProduct, { error: createError }] = useMutation(CREATE_ACCELERATE_PRODUCT, {
    refetchQueries: [{ query: GET_ACCELERATE_PRODUCTS }],
    onCompleted: async ({ createProduct }) => {
      trackEvent({
        name: "product_created",
        productName: createProduct?.name,
        productDescription: createProduct?.description,
        l3Entity: createProduct?.l3Entity?.name,
        createAreaInKhub: step3Data.createAreaInKhub,
      });
    },
    onError: () => {
      displayError("Unable to create the product");
    },
  });
  const [editAccelerateProduct, { error: editError }] = useMutation(EDIT_ACCELERATE_PRODUCT, {
    refetchQueries: [
      { query: GET_ACCELERATE_PRODUCTS, variables: { id: selectedProduct?.id, date: new Date().getTime() } },
    ],
    onCompleted: () => {
      displayToast(`Successfully edited ${step2Data?.name}`);
      onClose();
    },
    onError: () => {
      displayError("Unable to edit the product");
      onClose();
    },
  });
  // This is a common bug when reaching for Apollo errors
  const errors = createError
    ? // @ts-ignore
      createError.networkError?.result?.errors
    : editError
      ? editError.graphQLErrors
      : null;

  function goToPreviousStep() {
    setCurrentStep((currentStep) => currentStep - 1);
  }

  async function handleStep1Submit(data: Step1Data) {
    setStep1Data(data);
    if (data?.l3Uid === NO_PRODUCTS_SELECTED) {
      setCurrentStep(NO_SNOW_MESSAGE_STEP);
    } else {
      setCurrentStep(2);
    }
  }
  async function handleStep2Submit(data: Step2Data) {
    setStep2Data(data);
    if (selectedProduct?.id) {
      await editAccelerateProduct({
        variables: {
          id: selectedProduct.id,
          ...step1Data,
          ...data,
        },
      });
    } else {
      setCurrentStep(3);
    }
  }
  async function handleStep3Submit(data: Step3Data) {
    if (!data) {
      return;
    }
    setStep3Data(data);
    const results = await createAccelerateProduct({
      variables: {
        ...step1Data,
        ...step2Data,
      },
    });
    const resultingProduct = results?.data?.createProduct as ProductResponse;

    if (results.errors) {
      return displayError("Unable create product");
    }

    if (data.createAreaInKhub === "true") {
      await createAreaInKhub({
        product: resultingProduct,
      });
    }

    onClose();
    displayToast(`Successfully created ${step2Data?.name}`);
    router?.push(`/profile/product/${resultingProduct.id}`);
  }

  return (
    <>
      <div style={{ display: currentStep === 1 ? "block" : "none" }}>
        <Step1Form
          {...step1Data}
          loading={loading}
          l3Entities={l3Entities}
          onSubmit={handleStep1Submit}
          editingExistingProduct={!!selectedProduct}
        />
      </div>
      <div style={{ display: currentStep === 2 ? "block" : "none" }}>
        <Step2Form
          {...step2Data}
          editingExistingProduct={!!selectedProduct}
          onBack={goToPreviousStep}
          onSubmit={handleStep2Submit}
          errors={errors}
        />
      </div>
      <div style={{ display: currentStep === 3 ? "block" : "none" }}>
        <Step3Form
          {...step3Data}
          onBack={goToPreviousStep}
          onSubmit={handleStep3Submit}
          type="product"
          errors={errors}
        />
      </div>
      <div
        style={{
          display: currentStep === NO_SNOW_MESSAGE_STEP ? "block" : "none",
        }}
      >
        <NoSNOWEntity onClose={onClose} />
      </div>
    </>
  );
}
