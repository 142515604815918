import { Avatar } from "@bphxd/ds-core-react";
import { getInitials } from "../../utils/user-initials";

export type UserCardProps = {
  photoUrl?: string;
  title: string;
  name?: string;
};

export function UserCard({ photoUrl, title, name }: UserCardProps) {
  const initials = getInitials(name);

  return (
    <div className="d-flex align-items-center flex-shrink-1">
      <Avatar
        className="col"
        image={`${photoUrl ? `data:image/jpeg;base64, ${photoUrl}` : ""}`}
        alt={name}
        initials={initials}
        size="sm"
      />

      <div className="col-11 px-4">
        <div className="mb-0 fs-6 fw-normal">{initials ? name : "Unknown User"}</div>
        <div className="mb-0 fw-light fs-7 text-truncate">{title}</div>
      </div>
    </div>
  );
}
