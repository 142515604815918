import { useQuery } from "@apollo/client";
import { useUserL3Entities } from "./use-user-l3-entities";

import { usePlatformUpdatedEvent } from "./use-platform-updated";
import { useWebsocket } from "../../../hooks/use-websocket";
import { graphql } from "../../../gql";

export const PRODUCT_QUERY = graphql(`
  query GetAccelerateProductDetails($id: String!) {
    product(id: $id) {
      id
      name
      description
      memberIds
      ownerId
      designLeadId
      techLeadId
      delegates {
        email
      }
      l3EntityUid
      createdDate
      lastUpdatedById
      createdById
      lastUpdated
      yallaPlatform {
        name
      }
      cloudSpending {
        cloudabilityName
        cloudabilityViewId
        previousMonth
        currentMonth
        currentMonthEstimate
      }
      linkedResources {
        id
        description
        url
      }
    }
  }
`);

export const SLOW_FIELDS_FROM_PRODUCT_QUERY = graphql(`
  query GetAccelerateProductDetailsThatAreVerySlow($id: String!) {
    product(id: $id) {
      id
      l3Entity {
        serviceNowId
        name
        tier {
          name
          parentName
        }
        description
      }
      yallaPlatform {
        name
        products {
          id
          name
          platformName
          services {
            id
            name
            platformName
            instances {
              id
              name
              syncStatus
              version
              health
              environment
            }
            deployments {
              id
              serviceName
              status
              updatedAt
              version
              deploymentSteps {
                environment
                status
              }
            }
          }
        }
      }
      newCapabilityResult {
        productId
        lastUpdated
        score
        groupedByCategory {
          categoryName
          lastUpdated
          quizResults {
            answers {
              option {
                id
                label
                value
              }
              option_id
              question {
                back
                id
                isRecentlyUpdated
                metric
                options {
                  id
                  label
                  value
                }
                question
              }
              question_id
            }
            entityId
            lastUpdated
            maxScore
            quiz {
              category
              description
              href
              isRecentlyUpdated
              maxScore
              name
              questions {
                back
                id
                isRecentlyUpdated
                metric
                question
              }
              quizType
              slug
            }
            quizType
            score
          }
          score
        }
        quizResults {
          answers {
            option {
              id
              label
              value
            }
            option_id
            question {
              question
              back
              id
              isRecentlyUpdated
              metric
              options {
                id
                label
                value
              }
            }
            question_id
          }
          entityId
          lastUpdated
          maxScore
          quiz {
            category
            description
            href
            isRecentlyUpdated
            maxScore
            name
            questions {
              back
              id
              isRecentlyUpdated
              metric
              question
              options {
                id
                label
                value
              }
            }
            quizType
            slug
          }
          quizType
          score
        }
      }
    }
  }
`);
const USER_SPECIFIC_PRODUCT_QUERY = graphql(`
  query GetAccelerateProductDetailsForASpecificUser($id: String!) {
    product(id: $id) {
      id
      permissions {
        edit
        delete
        linkage
      }
      repositoryInfo {
        repositories {
          name
          id
          link
        }
        organisationName
        projectName
      }
      members {
        email
        jobTitle
        photo
        givenName
        surname
        userId
        enabled
      }
    }
  }
`);

export function useProductDetails({ id }: { id: string }) {
  const { userL3Entities, userL3EntitiesLoading } = useUserL3Entities();

  const { data, refetch, loading } = useQuery(PRODUCT_QUERY, {
    variables: { id },
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    skip: !id,
  });
  const {
    data: userData,
    refetch: refetchUserData,
    loading: loadingUserData,
  } = useQuery(USER_SPECIFIC_PRODUCT_QUERY, {
    variables: { id },
    skip: !id,
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  });
  const {
    data: slowFields,
    refetch: refetchSlowFields,
    loading: loadingSlowFields,
  } = useQuery(SLOW_FIELDS_FROM_PRODUCT_QUERY, {
    variables: { id },
    skip: !id,
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  });

  const productUpdateWebSocket = useWebsocket(`/events/PRODUCT_UPDATED/${id}`);
  productUpdateWebSocket?.on("PRODUCT_UPDATED", () => refetch());
  usePlatformUpdatedEvent({
    platformName: data?.product?.yallaPlatform?.name,
    handleEvent: () => {
      console.log("Platform updated event");
      refetch();
      refetchUserData();
      refetchSlowFields();
    },
  });

  const product = data?.product;

  return {
    product: product ? { ...product, ...userData?.product, ...slowFields?.product } : undefined,
    refetch,
    loading: loading || loadingUserData || loadingSlowFields,
    userL3EntitiesLoading,
    isOwnerOrDelegate: userL3Entities.length > 0,
  };
}
