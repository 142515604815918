"use client";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";
import { Col, Row } from "reactstrap";
import { Datepicker, Step, Steps } from "@bphxd/ds-core-react";
import { Trash24 } from "@bphxd/ds-core-react/lib/icons";
import {
  YallaDeploymentStatus,
  type YallaConfigurationChange,
  type YallaDeploymentStep,
  type YallaDeploymentChange,
  YallaChangeStatus,
  type YallaEnvironmentChange,
  YallaChangeKind,
  type GetYallaChangesQuery,
} from "../../gql/graphql";
import { DEPLOYMENT_STATUS_MAP } from "../../app/profile/product/[product_id]/service/[service_id]/components/deployment-status-map";
import { useLocation } from "../../hooks/use-location";
import { SearchInput } from "../../components/Input";
import { ListGroup, ListGroupItem } from "../../components/list-group";
import { Link } from "../../components/Link";
import { Button } from "../../components/button";
import { DeploymentStatusIcon } from "../../app/profile/product/[product_id]/service/[service_id]/components/deployment-status-icon";
import { capitalize } from "../../utils/helpers/utils";
import { useDebounce } from "../../hooks/use-debounce";
import { useQuery } from "@apollo/client";
import { graphql } from "../../gql/index";
import { ChangeStatus } from "../../app/profile/product/[product_id]/service/[service_id]/components/change-status";
import { usePlatformUpdatedEvent } from "./hooks/use-platform-updated";
import { PaginationHOC } from "./pagination";
import { FilterGroup } from "../../components/filter-group";
import { Filter } from "../../components/filters";

function urlWithoutUsername(url: string) {
  /**
   * Removes any username from the url, e.g. https://foo@dev.com becomes https://dev.com.
   * #7799842 has been raised to address this issue on the backend.
   */
  return url.replace(/\/\/[^@]+@/, "//");
}

const SEARCH_DEBOUNCE_DELAY = 350;

const GET_CHANGES = graphql(`
  query GetYallaChanges($name: String!, $fromDate: DateTime, $toDate: DateTime) {
    getYallaPlatform(name: $name) {
      name
      relatedChanges(fromDate: $fromDate, toDate: $toDate) {
        ... on YallaConfigurationChange {
          commit
          commitUrl
          date
          description
          kind
          platformName
          serviceName
          environmentChanges {
            environment
            status
          }
        }
        ... on YallaDeploymentChange {
          commit
          commitUrl
          date
          deployment {
            id
            status
            sourceCommitUri
            sourceCommitMessage
            updatedAt
            version
            serviceName
            deploymentSteps {
              environment
              status
            }
            artifactUri
            createdAt
            kind
            platformName
          }
          description
          kind
          platformName
          serviceName
        }
      }
    }
  }
`);

type GenericStep = YallaDeploymentStep | YallaEnvironmentChange | { environment: string; status: "NA" };

type EnrichedChange = (YallaConfigurationChange | YallaDeploymentChange) & {
  environments: string[];
  status: YallaDeploymentStatus | YallaChangeStatus;
  deploymentSteps: GenericStep[];
};

type ChangesProps = {
  platformName: string;
  serviceName?: string;
};

const PAGINATION_RANGE_COUNT = 4;

export function Changes({ platformName, serviceName }: ChangesProps) {
  const todayDate = DateTime.now().toJSDate();
  const oneWeekBackDate = DateTime.now().minus({ week: 1 }).toJSDate();
  const [fromDate, setFromDate] = useState<Date | null>(oneWeekBackDate);
  const [untilDate, setUntilDate] = useState<Date | null>(todayDate);

  const { data, refetch } = useQuery<GetYallaChangesQuery>(GET_CHANGES, {
    variables: { name: platformName, fromDate, toDate: untilDate },
  });

  const services = useMemo(() => {
    return [
      ...(data?.getYallaPlatform?.relatedChanges ?? []).reduce((acc: Set<string>, change) => {
        acc.add(change.serviceName);
        return acc;
      }, new Set<string>()),
    ];
  }, [data?.getYallaPlatform?.relatedChanges]);

  /**
   * Uses the naive heuristic that all services under the same product are deployed to all environments.
   */
  const allEnvironments = useMemo(() => {
    const envs = new Set<string>();
    data?.getYallaPlatform?.relatedChanges?.forEach((change) => {
      if ("deployment" in change) {
        change.deployment?.deploymentSteps?.forEach((step) => envs.add(step.environment));
      }
    });
    return envs;
  }, [data?.getYallaPlatform?.relatedChanges]);

  const changes: EnrichedChange[] | undefined = data?.getYallaPlatform?.relatedChanges.map(
    (chg: YallaConfigurationChange | YallaDeploymentChange) =>
      "environmentChanges" in chg
        ? {
            ...chg,
            environments: chg.environmentChanges.map((env) => env.environment),
            status: chg.environmentChanges.some((envChg) => envChg.status === YallaChangeStatus.Failed)
              ? YallaChangeStatus.Failed
              : YallaChangeStatus.Succeeded,
            deploymentSteps:
              [...allEnvironments]?.map(
                (env: string) =>
                  chg.environmentChanges.find((envChg) => envChg.environment === env) ?? {
                    environment: env,
                    status: "NA",
                  },
              ) ?? [],
          }
        : {
            ...chg,
            environments: chg.deployment?.deploymentSteps.map((step) => step.environment) ?? [],
            status: chg.deployment?.status ?? YallaDeploymentStatus.Succeeded, // Needs to come from latest change status
            deploymentSteps: chg.deployment?.deploymentSteps ?? [],
          },
  );

  const environments = useMemo(() => {
    if (!changes) {
      return [];
    }
    return [...new Set(changes?.flatMap((dep) => dep.environments).flat())];
  }, [changes]);

  usePlatformUpdatedEvent({
    serviceName,
    handleEvent: () => {
      console.log("Platform updated event");
      refetch();
    },
  });

  const { router, search } = useLocation();
  const { search_deployments } = search;

  // Separate state for immediate input and debounced search
  const [inputValue, setInputValue] = useState((search_deployments as string) ?? "");
  const debouncedSearchValue = useDebounce(inputValue, SEARCH_DEBOUNCE_DELAY);
  const [activePage, setActivePage] = useState(0);
  const [paginateLimit, setPaginateLimit] = useState(10);
  const [paginationRange, setPaginationRange] = useState({
    start: 0,
    end: PAGINATION_RANGE_COUNT,
  });

  useEffect(() => {
    router?.push({ query: { ...search, search_deployments: debouncedSearchValue } }, "", { shallow: true });
  }, [debouncedSearchValue]);

  const additionalFilterFunction = useCallback(
    (change: EnrichedChange | undefined): boolean => {
      const serviceMatch = serviceName ? change?.serviceName.toLowerCase() === serviceName.toLowerCase() : true;
      const searchMatch =
        inputValue === "" ||
        (!!change &&
          "deployment" in change &&
          change?.deployment?.version.toLowerCase().includes(inputValue.toLowerCase())) ||
        change?.description?.toLowerCase().includes(inputValue.toLowerCase());
      return serviceMatch && searchMatch;
    },
    [inputValue, fromDate, untilDate],
  );
  const [filterFunction, setFilterFunction] = useState<(item: EnrichedChange) => boolean>(
    () => additionalFilterFunction,
  );

  function applyFilter(items: EnrichedChange[] | undefined) {
    if (!filterFunction) {
      return items;
    }
    return items?.filter(filterFunction);
  }

  const sortedChanges = useMemo(() => {
    if (!filterFunction) {
      return changes;
    }
    return applyFilter(changes)?.toSorted((a, b) => {
      return new Date(b.date).valueOf() - new Date(a.date).valueOf();
    });
  }, [changes, filterFunction]);

  const paginatedChanges = useMemo(() => {
    const startIndex = activePage * paginateLimit;
    const endIndex = startIndex + paginateLimit;
    return sortedChanges?.slice(startIndex, endIndex) || [];
  }, [sortedChanges, activePage, paginateLimit]);

  function handleDateChange(from?: Date, until?: Date) {
    const query = { ...search };
    if (from) {
      setFromDate(from);
    }

    if (until) {
      setUntilDate(until);
    }

    router?.push({ query }, "", { shallow: true });
  }

  function clearDate(field: "from" | "until") {
    const query = { ...search };
    if (field === "from") {
      setFromDate(null);
    } else if (field === "until") {
      setUntilDate(null);
    }
    router?.push({ query }, "", { shallow: true });
  }

  const filters: Filter<EnrichedChange>[] = [
    {
      name: "Status",
      field: "status",
      toolTipText: "The overall status of either the deployment or configuration change.",
      options: [
        {
          value: "SUCCEEDED",
          icon: (
            <>
              <DeploymentStatusIcon status={YallaDeploymentStatus.Succeeded} />
              <span>{DEPLOYMENT_STATUS_MAP[YallaDeploymentStatus.Succeeded]}</span>
            </>
          ),
        },
        {
          value: "INPROGRESS",
          icon: (
            <>
              <DeploymentStatusIcon status={YallaDeploymentStatus.Inprogress} />
              <span>{DEPLOYMENT_STATUS_MAP[YallaDeploymentStatus.Inprogress]}</span>
            </>
          ),
        },
        {
          value: "FAILED",
          icon: (
            <>
              <DeploymentStatusIcon status={YallaDeploymentStatus.Failed} />
              <span>{DEPLOYMENT_STATUS_MAP[YallaDeploymentStatus.Failed]}</span>
            </>
          ),
        },
        {
          value: "NOTSTARTED",
          icon: (
            <>
              <DeploymentStatusIcon status={YallaDeploymentStatus.Notstarted} />
              <span>{DEPLOYMENT_STATUS_MAP[YallaDeploymentStatus.Notstarted]}</span>
            </>
          ),
        },
        {
          value: "SUPERSEDED",
          icon: (
            <>
              <DeploymentStatusIcon status={YallaDeploymentStatus.Superseded} />
              <span>{DEPLOYMENT_STATUS_MAP[YallaDeploymentStatus.Superseded]}</span>
            </>
          ),
        },
      ],
    },
    {
      name: "Change type",
      field: "kind",
      toolTipText: "The type of change. Either Deployment or Configuration.",
      options: [
        {
          value: YallaChangeKind.Deployment,
          icon: <span>Deployment</span>,
        },
        {
          value: YallaChangeKind.ConfigurationChange,
          icon: <span>Configuration</span>,
        },
      ],
    },
    ...(serviceName
      ? []
      : [
          {
            name: "Services",
            field: "serviceName",
            toolTipText: "The service that the deployment is associated with.",
            options: services.map((serviceName) => ({
              value: serviceName,
              icon: <span>{serviceName}</span>,
            })),
          } satisfies Filter<EnrichedChange>,
        ]),
    {
      name: "Environment",
      field: "environments",
      toolTipText: "The environments to which the change or deployment applies to.",
      options: environments.map((env) => ({ value: env, icon: <span>{env}</span> })),
    },
  ];

  function stepCompletionStatus(overallStatus: string, step: GenericStep) {
    if (overallStatus === "SUPERSEDED") {
      return "incomplete";
    }
    switch (step.status) {
      case YallaDeploymentStatus.Inprogress:
        return "active";
      case YallaDeploymentStatus.Succeeded:
        return "complete";
      default:
        return "incomplete";
    }
  }

  function stepDescription(overallStatus: string, step: GenericStep) {
    return overallStatus === "SUPERSEDED" ? "Superseded" : DEPLOYMENT_STATUS_MAP[step.status];
  }

  return (
    <>
      <div className="d-flex align-items-center mb-5 gap-4 w-75">
        <SearchInput
          value={inputValue}
          onChange={(val) => setInputValue(val as string)}
          placeholder="Search deployments"
        />
        <div className="w-50">
          {/* TODO: adjust this to use a "most recent" sorting option */}
          {/*   <SortByDropdown sortFields={sortFields} currentField={sortBy} />   */}
        </div>
      </div>
      <Row>
        <Col xs="9">
          {(paginatedChanges?.length ?? 0) > 0 && (
            <ListGroup>
              {paginatedChanges?.map((change, index) => {
                const { commit, commitUrl, description, deploymentSteps } = change;
                const activeStep = deploymentSteps.findLastIndex(
                  (step) =>
                    step.status === YallaDeploymentStatus.Succeeded || step.status === YallaDeploymentStatus.Inprogress,
                );
                return (
                  <ListGroupItem key={commit + index}>
                    <div className="grid w-100">
                      <div className="g-col-6 d-flex flex-column gap-2 justify-content-center">
                        <Link href={commitUrl ? urlWithoutUsername(commitUrl) : undefined} hideExternalLinkIcon={true}>
                          <p className="mb-0 small fw-light lh-1-5 truncate-2-lines">{description}</p>
                        </Link>
                        <ChangeStatus change={change} />
                      </div>
                      <div className="g-col-6 w-100 justify-content-center">
                        <style jsx>{`
                          .step-line {
                            color: rgb(255, 255, 255);
                          }
                        `}</style>
                        <Steps
                          size="sm"
                          activeStep={activeStep > -1 ? activeStep + 1 : -1}
                          showNumberOnComplete={
                            deploymentSteps[activeStep]?.status === YallaDeploymentStatus.Inprogress
                          }
                          className="w-100"
                        >
                          {deploymentSteps.map((step) => {
                            const statusText = step.status === YallaDeploymentStatus.Failed ? "error" : undefined;

                            return (
                              <Step
                                key={step.environment}
                                label={capitalize(step.environment)}
                                description={stepDescription(change.status, step)}
                                completionStatus={stepCompletionStatus(change.status, step)}
                                status={statusText}
                              />
                            );
                          })}
                        </Steps>
                      </div>
                    </div>
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          )}
        </Col>
        <Col xs="3">
          <div className="pe-4 mt-3 d-flex flex-column gap-5">
            <div className="d-flex flex-column gap-3">
              <p className="small mb-0">Date range</p>
              <div className="d-flex align-items-center">
                <Datepicker
                  className="bg-white w-100"
                  bsSize="md"
                  placeholder="From..."
                  value={fromDate}
                  options={{
                    maxDate: untilDate ? new Date(untilDate) : Date.now(),
                    onChange: (date: Date[]) => {
                      const [from] = date;
                      handleDateChange(from, undefined);
                    },
                  }}
                />
                {fromDate && (
                  <div>
                    <Button
                      level="quartenary"
                      name="clear_from_date"
                      data-testid="clear_from_date"
                      iconOnly
                      Icon={Trash24}
                      onClick={() => {
                        clearDate("from");
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center">
                <Datepicker
                  className="bg-white w-100"
                  bsSize="md"
                  placeholder="Until..."
                  value={untilDate}
                  options={{
                    minDate: fromDate,
                    maxDate: Date.now(),
                    onChange: (date: Date[]) => {
                      const [until] = date;
                      handleDateChange(undefined, until);
                    },
                  }}
                />
                {untilDate && (
                  <div>
                    <Button
                      level="quartenary"
                      name="clear_until_date"
                      iconOnly
                      Icon={Trash24}
                      onClick={() => {
                        clearDate("until");
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <FilterGroup
              key="deployments-filters"
              filters={filters}
              items={changes ?? []}
              setFilterFunction={setFilterFunction}
              additionalFilterFunction={additionalFilterFunction}
            />
          </div>
        </Col>
      </Row>
      {(changes && applyFilter(changes)?.length) || 0 > paginatedChanges?.length ? (
        <PaginationHOC
          count={applyFilter(changes)?.length ?? 0}
          paginateLimit={paginateLimit}
          setPaginateLimit={setPaginateLimit}
          activePage={activePage}
          setActivePage={setActivePage}
          paginationRangeCount={PAGINATION_RANGE_COUNT}
          paginationRange={paginationRange}
          setPaginationRange={setPaginationRange}
        />
      ) : null}
    </>
  );
}
