import { Placement } from "@popperjs/core/lib/enums";
import { PopoverBody, PopoverHeader, UncontrolledPopover } from "reactstrap";

type PopoverProps = {
  target: string;
  testId: string;
  title: string;
  toolTipText: string | React.ReactNode;
  placement?: Placement;
  trigger?: "focus" | "hover" | "legacy";
};

export function Popover({ target, testId, title, placement = "bottom", toolTipText, trigger = "hover" }: PopoverProps) {
  return (
    <UncontrolledPopover
      placement={placement}
      target={target}
      data-testid={testId}
      className="bg-secondary dark"
      trigger={trigger}
    >
      <PopoverHeader>{title}</PopoverHeader>
      <PopoverBody>{toolTipText}</PopoverBody>
    </UncontrolledPopover>
  );
}
