import { RobotWave, GlobeRecognition, ComputerGraph, DiagramStrategy } from "../icons/brand";

export function ProductCapabilityIcon(props: { [x: string]: unknown; category: string }) {
  const { category, ...attributes } = props;
  switch (category.toLowerCase()) {
    case "technical":
      return <RobotWave {...attributes} />;
    case "process":
      return <DiagramStrategy {...attributes} />;
    case "measurement":
      return <ComputerGraph {...attributes} />;
    case "cultural":
      return <GlobeRecognition {...attributes} />;
    default:
      return null;
  }
}
