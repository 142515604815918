import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { capitalize } from "../../../../utils/helpers/utils";

import { Form } from "reactstrap";
import { FormHeading } from "../../../../components/form-heading";
import { FormGroup } from "../../../../components/form-group";
import Input, { InputErrorMessage } from "../../../../components/Input";
import { ModalFooter } from "../../../../components/modal-footer";

import { Step3Data } from "./wizard";
import { Alert } from "@bphxd/ds-core-react";
import { Alert24 } from "@bphxd/ds-core-react/lib/icons/index";

type Step3FormProps = {
  onBack: () => void;
  onSubmit: (data: any) => Promise<void>;
  errors?: { message: string; extensions: string }[];
  type: "team" | "product";
  error?: string;
} & Step3Data;

export function Step3Form({ onSubmit, onBack, type, error, errors, ...data }: Step3FormProps) {
  const options = ["true", "false"];
  const schemaObject = {
    createAreaInKhub: yup.string().oneOf(options).required("Required"),
  };

  const schema = yup.object(schemaObject).required();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: data,
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)} data-testid="form-step-3">
      <FormHeading title={`${capitalize(type)} area in Knowledge Hub`} currentStep={3} totalSteps={3} />
      {errors?.length
        ? errors.map(({ message }, index) => <InputErrorMessage key={index}>{message}</InputErrorMessage>)
        : null}
      <p className="fw-light">
        While you're here, why not add a dedicated area in Knowledge Hub where you can keep your {type} documentation in
        one easy-to-find place.
      </p>
      <Controller
        name="createAreaInKhub"
        control={control}
        render={({ field }) => (
          <div className="d-flex flex-column gap-4">
            {options.map((value, index) => (
              <label
                htmlFor={`create-product-area-${value}`}
                className="fw-light ms-3 cursor-pointer"
                key={index}
                style={{ width: 140 }}
              >
                <FormGroup
                  check
                  className={`d-flex mt-0 justify-content-center border ${
                    value === control._formValues.createAreaInKhub ? "border-dark" : "border-1"
                  } rounded p-5 fw-light`}
                >
                  <Input
                    {...field}
                    className="me-3"
                    id={`create-product-area-${value}`}
                    data-testid={`form-step-3-radio-${index}`}
                    trackingName="create-product-radio"
                    type="radio"
                    value={value}
                    checked={value === control._formValues.createAreaInKhub}
                  />
                  {value == "true" ? "Yes" : "No"}
                </FormGroup>
              </label>
            ))}
          </div>
        )}
      />
      <p className="lead d-flex align-items-center justify-content-center" style={{ minHeight: 56 }}>
        {isSubmitting && (
          <Alert color="warning" className="text-center mt-8" Icon={Alert24}>
            Your request can take up to 60 seconds to complete. Please don't close this modal.
          </Alert>
        )}
        {error && <span className="text-danger-dark">{error}</span>}
      </p>
      <ModalFooter
        testIdContext={`create-${type}-step-3`}
        backButtonEventName={`create-${type}-khub-area-step-3-back`}
        nextButtonEventName={`create-${type}-khub-area-step-3-submit`}
        nextButtonText="Create"
        currentStep={3}
        totalSteps={3}
        handleNextClick={onSubmit}
        handleBackClick={onBack}
        isNextButtonLoading={isSubmitting}
        backButtonText="Back"
        showBackButton
      />
    </Form>
  );
}
