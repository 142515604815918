import { GetAccelerateProductSupportGroupDetailsQuery } from "../../../../gql/graphql.ts";
import { SupportGroupItem } from "./support-group-item";
import { getAvailableOpsGroups } from "./utils.tsx";

type SupportGroupListProps = {
  product: GetAccelerateProductSupportGroupDetailsQuery["product"];
  onEditSupportGroupClick: (opsGroupId: string) => void;
};
export function SupportGroupList({ product, onEditSupportGroupClick }: SupportGroupListProps) {
  const showActions = !!getAvailableOpsGroups(product);
  return (
    <>
      {product?.supportGroups &&
        product?.supportGroups.map((group, index) => (
          <SupportGroupItem
            key={group.opsGroupId + index}
            group={group}
            index={index}
            shouldShowActions={showActions}
            onEditSupportGroupClick={onEditSupportGroupClick}
            permissions={product.permissions}
          />
        ))}
    </>
  );
}
