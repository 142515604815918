import { Alert, Badge } from "@bphxd/ds-core-react";
import { Alert24 } from "@bphxd/ds-core-react/lib/icons/index";
import { Button } from "../../components/button";

type LinkingAlertMessageProps = {
  showAlertMessage: boolean;
  showButton: boolean;
  handleButtonClick: () => void;
  productName?: string;
  message?: string;
};

export function LinkingAlertMessage({
  showAlertMessage,
  showButton,
  handleButtonClick,
  productName,
  message,
}: LinkingAlertMessageProps) {
  if (!showAlertMessage) {
    return null;
  }
  const alertMessage = showButton
    ? "Before the team can take maturity assessments, this product must be linked to a ServiceNow Level 3 entity."
    : "Before the team can take maturity assessments, this product must be linked to a ServiceNow Level 3 entity. A product manager, tech lead or operational delegate can link it.";

  return (
    <div>
      {showAlertMessage ? (
        <Alert color="warning" className="d-flex flex-column">
          <div className="d-flex">
            <Alert24 />
            <p className="ms-2 fw-light">{message ? message : alertMessage}</p>
          </div>
          {showButton && (
            <div>
              <Button
                className="my-3"
                data-testid="link-to-service-now-l3-entity"
                name={`link_product_to_servicenow_product_overview_alert_${productName}`}
                onClick={handleButtonClick}
                level="tertiary"
                theme="standard"
                rounded="pill"
                size="xs"
              >
                Link to ServiceNow Level 3 entity
              </Button>
            </div>
          )}
        </Alert>
      ) : null}
    </div>
  );
}

type LinkingButtonProps = {
  showButton: boolean;
  handleButtonClick: () => void;
};
export function LinkingButton({ showButton, handleButtonClick }: LinkingButtonProps) {
  return showButton ? (
    <Button
      className="ms-n4"
      data-testid="link-to-service-now-l3-entity"
      name="link_product_to_servicenow_product_overview"
      onClick={handleButtonClick}
      level="quartenary"
      theme="standard"
      rounded="pill"
      size="sm"
    >
      Link to ServiceNow Level 3 entity
    </Button>
  ) : (
    <p className="mb-2 small fw-light opacity-70">This product is not linked to an L3 Snow entity</p>
  );
}

type LinkingSnowButtonProps = {
  handleButtonClick: () => void;
  productName?: string;
};

export function LinkingSnowButton({ handleButtonClick, productName }: LinkingSnowButtonProps) {
  return (
    <Button
      className="mt-3"
      name={`link-to-service-now-my-products_${productName}`}
      onClick={handleButtonClick}
      level="tertiary"
      theme="standard"
      rounded="pill"
      size="xs"
    >
      Link to ServiceNow
    </Button>
  );
}

export function LinkingBadge() {
  return (
    <Badge color="warning-subtle" className="mb-2">
      Pending servicenow link
    </Badge>
  );
}

export function LinkingSnowAlert() {
  return (
    <Alert color="warning" className="mb-6 d-flex flex-column">
      <div className="d-flex">
        <Alert24 />
        <p className="ms-2 fw-light">
          One or more products listed below are not linked to a ServiceNow Level 3 entity. A product must be linked so
          the teams working on it can take a maturity assessment. A product manager, tech lead or operational delegate
          can link their product.
        </p>
      </div>
    </Alert>
  );
}
