import { Link } from "../../../../components/Link";
import { Accordion } from "@bphxd/ds-core-react";

export function RepositoriesCommonlyAskedQuestions({ onEditClick }: { onEditClick: () => void }) {
  const COMMON_REPO_QUESTIONS = [
    {
      question: "Why repositories are important for my product?",
      id: 1,
      answer: (
        <>
          <p className="fs-6 fw-normal lh-1-5">
            Connecting your ADO repositories to your product in Accelerate boosts your productivity by allowing you to
            explore your own and other bp products’ repositories with the help of Accelerate’s AI assistant, Axel.
          </p>
          <Link href="/knowledgehub/dVpGi4" className="fw-light small">
            Learn more about repositories
          </Link>
        </>
      ),
    },
    {
      question: "How can I edit the repositories list?",
      id: 2,
      answer: (
        <>
          <p className="fs-6 fw-light lh-1-5">
            Only Product managers, tech leads and operational delegates can{" "}
            <Link href="#" onClick={onEditClick}>
              edit the connected repositories.
            </Link>
          </p>
        </>
      ),
    },
  ];

  return (
    <div className="bg-white rounded-5 border px-5 py-6 mt-6">
      <h5 className="fw-light">Commonly asked questions</h5>
      <Accordion flush>
        {COMMON_REPO_QUESTIONS.map(({ question, answer, id }) => {
          return (
            <Accordion.Item key={id}>
              <Accordion.Header targetId={String(id)}>
                <p className="fw-normal m-0 opacity-80">{question}</p>
              </Accordion.Header>
              <Accordion.Body accordionId={String(id)}>{answer}</Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  );
}
