import { ExternalLink16, Info24 } from "@bphxd/ds-core-react/lib/icons/index";
import { ListGroup, ListGroupItem } from "../../../../components/list-group";
import { Button } from "../../../../components/button";
import { AlertDetail } from "./alert-detail";
import { Popover } from "../../../../components/tooltip/popover";
import { Link } from "../../../../components/Link";

import { useRaiseAlertModal } from "../../../../views/profile/products/support-groups/raise-alert-modal-provider";
import { GetAccelerateProductSupportGroupDetailsQuery } from "../../../../gql/graphql";
import { getAllAlerts } from "../../../../views/profile/products/support-groups/support-groups";

export type AlertType = GetAccelerateProductSupportGroupDetailsQuery["product"]["supportGroups"][0]["alerts"][0] & {
  groupName: string;
};

type AlertsAndIncidentsProps = {
  product: GetAccelerateProductSupportGroupDetailsQuery["product"];
};
export function AlertsAndIncidents({ product }: AlertsAndIncidentsProps) {
  const alerts = getAllAlerts(product);
  const { selectedGroup, openModal } = useRaiseAlertModal();

  return (
    <>
      <ListGroup>
        <div className="d-flex justify-content-between mt-5">
          <div className="d-flex">
            <h5 className="mb-0">Alerts</h5>
            <div id="alertsId" className="cursor-pointer">
              <Info24 className="opacity-60" name="alertsId" type="button" tabIndex={0} />
              <Popover
                target="alertsId"
                testId="alert-tooltip-test-id"
                title="Alerts"
                trigger="focus"
                placement="right"
                toolTipText={"Alerts information is shown below"}
              />
            </div>
          </div>
          <Button
            level="tertiary"
            theme="standard"
            rounded="pill"
            size="sm"
            name="raise new alert"
            onClick={() => openModal(selectedGroup)}
            disabled={product?.supportGroups?.every((group) => group.isOnboarding)}
          >
            Raise new alert
          </Button>
        </div>
        {!alerts.length && <p className="fw-light mt-5 mb-5">No alerts raised</p>}
        <div className="mt-3">
          {alerts?.map((alert, index) => (
            <ListGroupItem key={index}>
              <AlertDetail alert={alert} />
            </ListGroupItem>
          ))}
        </div>
      </ListGroup>
      <div className="mt-7">
        <ListGroup>
          <div className="d-flex justify-content-between mt-5 mb-5">
            <div className="d-flex">
              <h5 className="mb-0">Incidents</h5>
              <div id="incidentId" className="cursor-pointer">
                <Info24 className="opacity-60" name="incidentId" type="button" tabIndex={0} />
                <Popover
                  target="incidentId"
                  trigger="focus"
                  testId="incidents-tooltip-test-id"
                  title="Incidents"
                  placement="right"
                  toolTipText={"Incidents are shown here"}
                />
              </div>
            </div>
            <Link
              className="fw-light small text-center center"
              name="see-details-in-service-now"
              hideExternalLinkIcon={true}
              href="https://bp.service-now.com/myservicehub"
            >
              See more details in ServiceNow
              <span className="x5-ms-1">
                <ExternalLink16 />
              </span>
            </Link>
          </div>
        </ListGroup>
      </div>
    </>
  );
}
