"use client";
import { Avatar } from "@bphxd/ds-core-react";
import { useEffect, useState } from "react";
import { msGraphQuery } from "../../../../api/platform-api";
import { getInitials } from "../../../../utils/user-initials";

export type TeamMemberInfoProps = {
  className?: string;
  displayName: string;
  email?: string;
};

export function TeamMemberInfo({ displayName, email }: TeamMemberInfoProps) {
  const [memberPhotoUrl, setMemberPhotoUrl] = useState<string | null>();
  useEffect(() => {
    if (!email) {
      setMemberPhotoUrl(null);
      return;
    }
    msGraphQuery({
      spanName: "User Profile Pic",
      query: `users/${email}/photo/$value`,
      spanAttributes: {},
    }).then((o) => {
      try {
        setMemberPhotoUrl(URL.createObjectURL(o.body as Blob));
      } catch (e) {
        setMemberPhotoUrl(null);
      }
    });
  }, [email]);

  return (
    <div className="d-flex">
      {memberPhotoUrl ? (
        <img className="border rounded-8 me-2 ms-n2" style={{ width: "32px" }} alt="profile" src={memberPhotoUrl} />
      ) : (
        <Avatar size="sm" className="component-card__user-photo me-2 ms-n2" initials={getInitials(displayName)} />
      )}
      <div className="small mt-2 ms-1">{displayName}</div>
    </div>
  );
}
