import { Check16, Delete16, Reload16, Remove16 } from "@bphxd/ds-core-react/lib/icons";
import { YallaDeploymentStatus } from "../../../../../../../gql/graphql";

export function DeploymentStatusIcon({ status, className }: { status: YallaDeploymentStatus; className?: string }) {
  const deploymentStatusIconMap = {
    INPROGRESS: Reload16,
    SUCCEEDED: Check16,
    FAILED: Remove16,
    NOTSTARTED: Delete16,
    SUPERSEDED: Delete16,
  };
  const Icon = deploymentStatusIconMap[status] ?? Remove16;
  return <Icon className={className} />;
}
