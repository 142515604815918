"use client";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { env } from "next-runtime-env";

import { trackEvent } from "../utils/event-tracker";

import { CREATE_PRODUCT_LINKED_RESOURCE } from "../api/products/gql";
import { createKhubRepository } from "../api/khub/v1/documents";
import { getUUID } from "../api/khub/v2/other";

import { PRODUCT_AREA_RESOURCE_DESCRIPTION } from "../views/profile/products/resources/product-linked-resources";

import { Product } from "../types/ProfileTypes";

type CreateKhubAreaMutationProps = {
  product: Product;
  onCompleted?: () => void;
};

type KhubAreaType = "product" | "team";

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function createProductArea({
  productName,
  description,
  parentTeamName,
  contentOwners,
}: {
  productName: string;
  description: string;
  parentTeamName: string;
  contentOwners: string[];
}) {
  let count = 0;
  let repositoryName;
  while (!repositoryName) {
    const { name } = await createKhubRepository({
      name: `${productName}${count ? `_${count}` : ""}`,
      description,
      parentTeamName,
      contentOwners,
    });
    if (name) {
      repositoryName = name;
    } else {
      count++;
    }
  }

  if (repositoryName) {
    trackEvent({
      name: "product_area_created_successfully",
      repositoryName,
      l3Entity: parentTeamName,
    });
  }

  let uuid;
  while (!uuid) {
    uuid = (
      await getUUID({
        repository: repositoryName,
        path: `${repositoryName}.md`,
      })
    ).uuid;
    if (!uuid) {
      await delay(4 * 1_000);
    }
  }

  const endpoint = `knowledgehub/${uuid}`;

  return {
    repositoryName,
    resourceUrl: `${env("NEXT_PUBLIC_API_URL")?.replace(/api\.?/g, "")}${endpoint}`,
  };
}

export function useCreateKhubArea({
  type,
}: {
  type: KhubAreaType;
}): [(props: CreateKhubAreaMutationProps) => Promise<void>, { loading: boolean; error: string }] {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [createProductLinkedResource] = useMutation(CREATE_PRODUCT_LINKED_RESOURCE);

  return [
    async function ({ product, onCompleted }: CreateKhubAreaMutationProps) {
      setLoading(true);

      const contentOwners = [
        product.owner?.username,
        product.techLead?.username,
        product.designLead?.username,
        ...(product.delegates?.map(({ username }: { username: string }) => username) ?? []),
      ].filter((username): username is string => typeof username === "string");

      const formattedProductName = product.name.replace(/\s/g, "_");

      if (type === "product") {
        try {
          const { resourceUrl } = await createProductArea({
            productName: formattedProductName,
            description: product.description ?? product.l3Entity?.description ?? "",
            parentTeamName: product.l3Entity?.name ?? "",
            contentOwners,
          });

          await createProductLinkedResource({
            variables: {
              description: PRODUCT_AREA_RESOURCE_DESCRIPTION,
              url: resourceUrl,
              productId: product.id,
            },
          });
          if (onCompleted) {
            onCompleted();
          }
        } catch {
          setLoading(false);
          setError("Something went wrong while trying to make your product area.");
          trackEvent({
            name: "product_area_creation_failed",
            repositoryName: formattedProductName,
            l3Entity: product.l3Entity?.name ?? "",
          });
        }
      }
    },
    { loading, error },
  ];
}
