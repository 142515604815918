"use client";
import { useMutation } from "@apollo/client";
import { Form, SUBMIT_PRODUCT_QUIZ } from "../../../../api/quizzes/gql";
import { trackEvent } from "../../../../utils/event-tracker";

import { Modal } from "../../../../components/modal";
import { AssessmentQuiz } from "./assessment-quiz";
import { Quiz } from "../../../../gql/graphql";
import { Product } from "../../../../types/ProfileTypes";
import { useUser } from "../../../../hooks/use-user";
import { useState } from "react";
import { useToast } from "../../../../hooks/use-toast";

type ProductMaturityModalProps = {
  product: Product;
  form?: Form;
  setForm: any;
  refetchProduct: CallableFunction;
  quiz: Quiz | undefined;
  onQuizComplete?: (submitProductQuizResult: any) => void;
  isModalOpen: boolean;
  setIsModalOpen: (b: boolean) => void;
};
export function ProductMaturityModal({
  product,
  quiz,
  refetchProduct,
  isModalOpen,
  setIsModalOpen,
}: ProductMaturityModalProps) {
  const userProfile = useUser();
  const [refetchCompleted, setRefetchCompleted] = useState<boolean>(true);
  const { displayToast } = useToast();
  const [PostForm, { loading }] = useMutation(SUBMIT_PRODUCT_QUIZ, {
    async onCompleted({ submitProductQuizResult }) {
      setRefetchCompleted(false);
      if (refetchProduct) {
        await refetchProduct();
      }
      setRefetchCompleted(true);

      displayToast(`Successfully submitted ${submitProductQuizResult?.quiz.name} assessment`);

      trackEvent({
        name: "user_completed_product_quiz",
        user: userProfile?.email,
        productName: product.name,
        quiz: submitProductQuizResult?.quiz.name,
      });
      if (refetchCompleted) {
        setIsModalOpen(false);
      }
    },
  });

  return (
    <Modal
      size={"lg"}
      backdrop="static"
      isOpen={isModalOpen}
      cancelConfirmation={"quiz-modal-close"}
      onClose={() => setIsModalOpen(false)}
    >
      {quiz && (
        <AssessmentQuiz
          product={product}
          PostForm={PostForm}
          postFormLoading={loading}
          quiz={quiz}
          refetchCompleted={refetchCompleted}
        />
      )}
    </Modal>
  );
}
