import { PropsWithChildren } from "react";
import { trackEvent } from "../utils/event-tracker";
import { useToggle } from "../hooks/useToggle";

import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Kebab16, Kebab24, Remove16, Remove24 } from "@bphxd/ds-core-react/lib/icons";

export function DropdownKebabMenu({
  id,
  trackingEventProps,
  openOnTheRight,
  size,
  children,
}: PropsWithChildren<{
  id?: string;
  size?: string;
  trackingEventProps: any;
  openOnTheRight?: boolean;
}>) {
  const [isMenuOpen, toggleIsMenuOpen] = useToggle();

  function Icon({ size }: { size?: string }) {
    if (isMenuOpen) {
      if (size === "sm") {
        return <Remove16 className="absolute-center" />;
      } else {
        return <Remove24 className="absolute-center" />;
      }
    } else {
      if (size === "sm") {
        return <Kebab16 className="absolute-center" />;
      } else {
        return <Kebab24 className="absolute-center" />;
      }
    }
  }

  return (
    <Dropdown
      isOpen={isMenuOpen}
      toggle={toggleIsMenuOpen}
      onClick={(ev) => {
        ev.stopPropagation();
        ev.preventDefault();
      }}
    >
      <DropdownToggle
        color="tertiary"
        className="btn-square rounded-circle"
        data-testid={id ? `dropdown-kebab-menu-${id}` : "dropdown-kebab-menu"}
        onClick={() => trackEvent(trackingEventProps)}
        size="sm"
        tag="div"
        tabIndex={0}
      >
        <Icon size={size} />
      </DropdownToggle>
      <DropdownMenu flip={openOnTheRight} container="body">
        {children}
      </DropdownMenu>
    </Dropdown>
  );
}
