"use client";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";

import { CREATE_SUPPORT_GROUP, UPDATE_SUPPORT_GROUP } from "../../../../api/products/gql";

import { Step1, Step1DataType } from "./step1";
import { useToast } from "../../../../hooks/use-toast.tsx";
import { Modal } from "../../../../components/modal";
import { ContactMethodType, GetAccelerateProductSupportGroupDetailsQuery } from "../../../../gql/graphql";
import { getSelectedOpsGroup } from "../support-groups/utils.tsx";
import { SUPPORT_GROUP_QUERY } from "../support-groups/support-groups";

export function SetupProductSupportGroupModal({
  product,
  isOpen,
  toggle,
  selectedGroup,
}: {
  product: GetAccelerateProductSupportGroupDetailsQuery["product"];
  isOpen: boolean;
  toggle: () => void;
  selectedGroup?: GetAccelerateProductSupportGroupDetailsQuery["product"]["supportGroups"][0];
}) {
  const [step1Data, setStep1Data] = useState<Step1DataType>({
    opsGroupId: "",
    name: "",
    description: "",
    contactMethod: {
      type: ContactMethodType.Dm,
      value: "",
    },
  });
  const [isDirty, setIsDirty] = useState(false);
  const { displayToast } = useToast();

  const mutationOptions = ({ editing }: { editing: boolean }) => ({
    refetchQueries: [
      {
        query: SUPPORT_GROUP_QUERY,
        variables: { id: product.id, date: new Date().getTime() },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: (data: any) => {
      displayToast(
        editing
          ? `You successfully edit the ${data.updateSupportGroup.name} product support group.`
          : `You successfully created the ${data.createSupportGroup.name} product support group for ${product.name}.`,
      );
      toggle();
    },
    onError: () => {},
  });

  const [createSupportGroup] = useMutation(CREATE_SUPPORT_GROUP, mutationOptions({ editing: false }));
  const [updateSupportGroup] = useMutation(UPDATE_SUPPORT_GROUP, mutationOptions({ editing: true }));

  async function handleSubmit(data: Step1DataType) {
    const variables = {
      data: {
        ...data,
        productId: product.id,
      },
    };

    if (selectedGroup) {
      return updateSupportGroup({ variables });
    }
    // @ts-expect-error
    return createSupportGroup({ variables });
  }

  function resetForm() {
    setStep1Data({
      opsGroupId: "",
      name: "",
      description: "",
      contactMethod: {
        type: ContactMethodType.Dm,
        value: "",
      },
      areOnCallMembersPublic: true,
    });
  }

  useEffect(() => {
    if (selectedGroup) {
      setStep1Data({
        opsGroupId: selectedGroup?.opsGroupId ?? "",
        name: selectedGroup?.name ?? "",
        description: selectedGroup?.description ?? "",
        contactMethod: selectedGroup?.contactMethod ?? {
          type: ContactMethodType.Dm,
          value: "",
        },
        areOnCallMembersPublic: selectedGroup?.areOnCallMembersPublic ?? true,
      });
    } else {
      resetForm();
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  const contactMethodOptions = [
    { label: "Teams channel", value: ContactMethodType.Teams },
    { label: "Slack channel", value: ContactMethodType.Slack },
    { label: "Email distribution", value: ContactMethodType.Email },
    { label: "Direct message to member on-call", value: ContactMethodType.Dm },
  ];
  const orgOptions =
    getSelectedOpsGroup(product, selectedGroup)?.map((group) => {
      function UserList({ users }: { users?: (typeof group)["users"] }) {
        if (!users) {
          return (
            <div className="fw-light small" key={group.id}>
              0 members
            </div>
          );
        }

        const firstTwoUsers = users.slice(0, 2).map(({ givenName, surname }) => `${givenName} ${surname}`);
        if (users.length > 2) {
          return (
            <div className="fw-light small">
              {firstTwoUsers.join(", ")}, and {users.length - 2} more members
            </div>
          );
        } else {
          return <div className="fw-light small">{firstTwoUsers.join("and ")}</div>;
        }
      }

      return {
        label: (
          <div className="d-flex flex-column">
            <div className="fw-normal">{group.name}</div>
            <div>
              <UserList users={group?.users} />
            </div>
          </div>
        ),
        value: group.id,
      };
    }) || [];

  return (
    <Modal
      isOpen={isOpen}
      onClose={toggle}
      showHeader
      hasCancelDialog={isDirty}
      header={`${selectedGroup ? "Edit" : "Set up"} product support group`}
    >
      <Step1
        handleNextClick={async (data) => {
          setStep1Data(data);
          return handleSubmit(data);
        }}
        orgOptions={orgOptions}
        contactMethodOptions={contactMethodOptions}
        handleDirtyForm={setIsDirty}
        {...step1Data}
      />
    </Modal>
  );
}
