import { useProductDropdownContext } from "../../providers/product-dropdown-provider";
import { DropdownMenu } from "../../components/dropdown-menu";
import { BulletList24, Select24 } from "@bphxd/ds-core-react/lib/icons/index";
import { DropdownItem } from "reactstrap";
import { useLocation } from "../../hooks/use-location";
import { LinkButton } from "../../components/link-button";
import { Link } from "../../components/Link";
import { useEffect, useMemo, useState } from "react";
import { ProductFavorite } from "./product-favorite";
import { useSortedProducts } from "../profile/hooks/use-sorted-products";

function TruncatedText({ text }: { text?: string }) {
  const truncated = useMemo(() => {
    const MAX_LENGTH = 18;
    return text && text.length > MAX_LENGTH ? `${text.substring(0, MAX_LENGTH)}...` : text;
  }, [text]);

  return <>{truncated}</>;
}
export function ProductDropdown() {
  const { myProductsList, allProducts, selectedProduct, setSelectedProduct, starredProductId } =
    useProductDropdownContext();
  const { search } = useLocation();
  const [hoveredProductId, setHoveredProductId] = useState<string | null>(null);

  const sortedProducts = useSortedProducts(myProductsList, starredProductId);

  useEffect(() => {
    const selected =
      allProducts?.find((product) => product.id === search.product_id) ||
      myProductsList.find((product) => product.id === starredProductId) ||
      myProductsList[0];

    if (selected && selected.id !== selectedProduct?.id) {
      setSelectedProduct(selected);
    }
  }, [search.product_id, starredProductId, myProductsList, allProducts, selectedProduct, setSelectedProduct]);

  function isFavorite(id: string) {
    return starredProductId === id;
  }

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Link
        title={selectedProduct?.name}
        className="nav-link p-0"
        href={`/profile/product/${selectedProduct?.id}/?tab=1-overview`}
      >
        <TruncatedText text={selectedProduct?.name} />
      </Link>
      <DropdownMenu toggle={<Select24 />} trackingEventProps={{ name: "mega-menu-select-product-dropdown" }}>
        {sortedProducts.map((product) => (
          <DropdownItem
            key={product.id}
            onMouseEnter={() => setHoveredProductId(product.id)}
            onMouseLeave={() => setHoveredProductId(null)}
            className={`w-100 ${isFavorite(product.id) ? "border-bottom " : ""}`}
          >
            <div className="d-flex align-items-center justify-content-between w-100 overflow-hidden">
              <LinkButton
                title={product.name}
                href={`/profile/product/${product.id}?tab=1-overview`}
                className="dropdown-item flex-grow-1 text-truncate ps-0"
                onClick={() => setSelectedProduct(product)}
                name={product.name}
                level="quartenary"
                rounded="circle"
                size="sm"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "220px",
                }}
              >
                <TruncatedText text={product?.name} />
              </LinkButton>
              {isFavorite(product.id) && <ProductFavorite productId={product.id} />}
              {!isFavorite(product.id) ? (
                <div className="ms-auto" style={{ visibility: hoveredProductId === product.id ? "visible" : "hidden" }}>
                  <ProductFavorite productId={hoveredProductId} />
                </div>
              ) : null}
            </div>
          </DropdownItem>
        ))}
        <DropdownItem className="border-top">
          <LinkButton
            href="/all-products"
            className="dropdown-item ps-0"
            Icon={BulletList24}
            name="All-products"
            level="quartenary"
            rounded="circle"
            size="sm"
          >
            All products
          </LinkButton>
        </DropdownItem>
      </DropdownMenu>
    </div>
  );
}
