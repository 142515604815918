"use client";
import { useState } from "react";
import { useBreadCrumbs } from "../../hooks/use-breadcrumbs";
import { useProductDetails } from "./hooks/use-product-details.ts";
import { useLocation } from "../../hooks/use-location";
import { getDisplayedScore } from "../bestPractice/MaturityComponents/display-score";
import { Alert, Badge } from "@bphxd/ds-core-react";
import { Col, Row, TabPane } from "reactstrap";
import { PageSection, PageWithNoPadding } from "../../components/page-template";
import { NavTabs, TabContent } from "../../components/NavTabs";
import { ProductMaturity } from "./products/product-maturity";
import { ProductKebabMenu } from "../../components/product-kebab-menu";
import { EventContext } from "../../components/event-context";
import { PageHeader } from "../../components/page-header";
import { InfoCard } from "../../components/cards/info-card";
import { Modal as EditProductModal } from "./products/create/modal";
import { LinkingAlertMessage } from "./linking-alert-message";
import { Product } from "../../types/ProfileTypes";
import { OverviewTab } from "../../app/profile/product/components/overview-tab.tsx";
import { Repositories } from "../../app/profile/product/components/repositories.tsx";
import { AddReposToProductModal } from "./products/repos/add-modal";
import { useQuizResult } from "../bestPractice/hooks/use-quiz-result";
import { QuizType, useQuizzes } from "../bestPractice/hooks/use-quizzes";
import { SetAndEditProductMetricsModal } from "./products/metrics/set-and-edit-modal";
import { RepositoriesCommonlyAskedQuestions } from "../../app/profile/product/components/repositories-commonly-asked-questions.tsx";
import { Link } from "../../components/Link";
import { SupportGroups } from "./products/support-groups/support-groups";
import { GetAccelerateProductDetailsQuery, GetAccelerateProductDetailsThatAreVerySlowQuery } from "../../gql/graphql";
import { MembersTab } from "../../app/profile/product/components/members-tab.tsx";
import { ServicesTab } from "../../app/profile/product/components/services-tab.tsx";
import { ProductAdminTooltip } from "./products/product-admin-tool-tip";
import { useFeature } from "featurehub-react-sdk";
import { Loader, LoadingSpinner } from "../../components/spinners/loading-spinner.tsx";
import { useLocalStorage } from "../../hooks/use-local-storage";
import { EMPIRICAL_ALERT_DISMISS_FLAG } from "../home/welcome-page";
import { ToolsTab } from "../../app/profile/product/components/tools-tab.tsx";
import { Environments } from "../../app/profile/product/components/environments.tsx";
import { Changes } from "./changes.tsx";
import { ProductBreadCrumbs } from "./products/product-bread-crumbs";

const PRODUCT_DETAILS_CONTEXT = "from-product-details-page";

export enum TABS {
  overview = "1-overview",
  services = "2-services",
  environments = "3-environments",
  deployments = "4-deployments",
  tools = "5-tools",
  repositories = "6-repositories",
  members = "7-members",
  alerts = "8-alerts",
  maturity = "9-maturity",
  metrics = "10-metrics",
}

export type ProductPageProps = {
  productInfoFromServer?: GetAccelerateProductDetailsQuery["product"] &
    GetAccelerateProductDetailsThatAreVerySlowQuery["product"];
};

export function ProfileProductPage(props: ProductPageProps) {
  const { router, search } = useLocation();
  const id = search.product_id as string;
  const productName = search.product_name as string;
  const userIsAdmin = search.user_is_admin === "true";

  const {
    product: fullProductInfo,
    refetch: refetchProduct,
    loading,
    isOwnerOrDelegate,
    userL3EntitiesLoading,
  } = useProductDetails({ id });
  const isLoading = loading && !props.productInfoFromServer;

  const product = fullProductInfo ?? props.productInfoFromServer;

  const FE_HIDE_EMPIRICAL_DATA = useFeature("FE_HIDE_EMPIRICAL_DATA");

  const activeTab = (search.tab as string) ?? TABS.overview;

  const [breadcrumbs] = useBreadCrumbs({ text: product?.name ?? productName ?? "Product" });

  const { quizzes } = useQuizzes({
    quizTypes: [QuizType.DORA_QUICK_CHECK],
  });
  const quiz = quizzes?.find((quiz) => quiz.slug === "product-metrics");

  const tabs = [
    { id: 1, name: TABS.overview, description: "Overview" },
    {
      id: 2,
      name: TABS.maturity,
      description: "Maturity",
    },
    {
      id: 4,
      name: TABS.alerts,
      description: "Alerts",
    },
    {
      id: 6,
      name: TABS.repositories,
      description: "Repositories",
    },
    {
      id: 7,
      name: TABS.members,
      description: "Team members",
    },
  ].concat(
    product?.yallaPlatform?.name || activeTab === TABS.services
      ? [
          {
            id: 3,
            name: TABS.environments,
            description: "Environments",
          },
          {
            id: 4,
            name: TABS.deployments,
            description: "Deployments",
          },
          {
            id: 8,
            name: TABS.services,
            description: "Services",
          },
        ]
      : [],
  );

  if (product?.yallaPlatform?.name && product?.name.toLowerCase() === "accelerate") {
    tabs.push({
      id: 4,
      name: TABS.tools,
      description: "Tools",
    });
  }

  const isDevOpsSRE = product?.l3Entity?.tier?.name === "DevOps & SRE";
  const [isEditProductModalOpen, setIsEditProductModalOpen] = useState(false);
  const [isAddRepoModalOpen, setAddRepoModalOpen] = useState(false);
  const [isSetMetricsModalOpen, setMetricsModalOpen] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState<string | undefined>();

  const [dismissedMessage, setDismissedMessage] = useLocalStorage(EMPIRICAL_ALERT_DISMISS_FLAG);

  const { quizResult } = useQuizResult({
    entityId: product?.id || "",
    quizSlug: "product-metrics",
  });
  const FE_SIDE_MEGA_MENU = useFeature("FE_SIDE_MEGA_MENU");

  return (
    <>
      <PageWithNoPadding
        name={product?.name ? "user-profile-product" : ""}
        title={product?.name ?? "Product"}
        backgroundClassName="bg-secondary"
        trackingEventProps={product?.name ? { productName: product.name } : {}}
      >
        <div className="bg-white">
          <PageSection>
            {FE_HIDE_EMPIRICAL_DATA && !dismissedMessage && (
              <Alert color="warning" className="text-center" dismissible onClick={() => setDismissedMessage("true")}>
                <span className="text-center ms-2 fw-light">
                  Product empirical metrics have been disabled. Please read{" "}
                  {<Link href="/knowledgehub/Yw7fLL">The Accelerate product metrics approach</Link>} and ensure your
                  perceived metrics are up-to-date.
                </span>
              </Alert>
            )}
            {FE_SIDE_MEGA_MENU && <ProductBreadCrumbs breadcrumbs={breadcrumbs} />}
            <PageHeader breadcrumbs={FE_SIDE_MEGA_MENU ? [] : breadcrumbs} />
            <Row className="mb-4">
              <Col xs="8">
                <div className="mt-6 d-flex align-items-center">
                  <div className="d-flex align-self-center">
                    <h1 className="my-0 fw-light">{productName ?? product?.name}</h1>
                    {product ? (
                      <EventContext.Provider value={PRODUCT_DETAILS_CONTEXT}>
                        <ProductKebabMenu
                          product={product as Product}
                          refetchProduct={refetchProduct}
                          l3DataLoading={loading}
                        />
                      </EventContext.Provider>
                    ) : (
                      <div className="position-relative align-self-center mx-4">
                        <Loader size="sm" />
                      </div>
                    )}
                  </div>
                  {userIsAdmin && <ProductAdminTooltip />}
                </div>
                <Badge color="info-subtle">Product</Badge>
              </Col>
              <Col xs="4">
                <InfoCard
                  title={"North star"}
                  subTitle={isDevOpsSRE ? "Product maturity score" : "Coming soon"}
                  id={"north-star"}
                  toolTipTitle={"North Star"}
                  toolTipText={
                    "The North Star shows the product maturity score by default. If the product does not yet have a maturity score, then this will be blank until at least one team assessment is completed for the product.\n\nIn future, product managers will be able to choose other metrics as their North Star."
                  }
                >
                  {isDevOpsSRE ? (
                    <h2 className="p-0 mb-0 fw-light display-2">
                      {getDisplayedScore(product?.newCapabilityResult?.score)}
                    </h2>
                  ) : (
                    <div className="d-flex" style={{ height: "70px" }}>
                      {loading && <LoadingSpinner />}
                    </div>
                  )}
                </InfoCard>
              </Col>
            </Row>

            <NavTabs
              activeTab={activeTab}
              tabs={tabs}
              onTabSelection={(tab: string) => {
                router?.push(
                  {
                    query: { ...router.query, tab },
                  },
                  undefined,
                  { shallow: true },
                );
              }}
              trackingEventProps={product?.name ? { productName: product.name } : {}}
            />
          </PageSection>
        </div>
        <PageSection>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={TABS.overview}>
              <OverviewTab
                productId={id}
                // @ts-ignore
                product={product}
                refetchProduct={refetchProduct}
                loading={isLoading}
                l3DataLoading={userL3EntitiesLoading}
                isOwnerOrDelegate={isOwnerOrDelegate}
                setIsEditProductModalOpen={setIsEditProductModalOpen}
              />
            </TabPane>
            <TabPane tabId={TABS.maturity}>
              {!isLoading ? (
                <LinkingAlertMessage
                  showAlertMessage={!product?.l3EntityUid}
                  showButton={isOwnerOrDelegate}
                  productName={product?.name}
                  handleButtonClick={() => {
                    setIsEditProductModalOpen(true);
                  }}
                />
              ) : null}
              <div className="d-flex">
                {product && (
                  <ProductMaturity
                    // @ts-ignore
                    product={product}
                    refetchProduct={refetchProduct}
                  />
                )}
              </div>
            </TabPane>
            <TabPane tabId={TABS.tools}>
              <ToolsTab />
            </TabPane>
            <TabPane tabId={TABS.deployments}>
              {product?.yallaPlatform && <Changes platformName={product?.yallaPlatform.name} />}
            </TabPane>
            {activeTab === TABS.environments && (
              <TabPane tabId={TABS.environments}>
                <Environments productId={product?.id} />
              </TabPane>
            )}
            <TabPane tabId={TABS.members}>
              <MembersTab
                refetchProduct={refetchProduct}
                // @ts-ignore
                product={product}
              />
            </TabPane>
            <TabPane tabId={TABS.alerts}>
              <SupportGroups showIncidents productId={product?.id} />
            </TabPane>

            <TabPane tabId={TABS.repositories}>
              <div className="d-flex row mt-n4">
                <div className="col-8">
                  {product && (
                    <Repositories
                      onEditClick={() => {
                        setAddRepoModalOpen(true);
                      }}
                      // @ts-ignore
                      product={product}
                    />
                  )}
                </div>
                {
                  //@ts-ignore
                  product?.repositoryInfo?.repositories.length !== 0 ? (
                    <div className="mt-n5 col-4">
                      <RepositoriesCommonlyAskedQuestions onEditClick={() => setAddRepoModalOpen(true)} />
                    </div>
                  ) : null
                }
              </div>
            </TabPane>
            <TabPane tabId={TABS.services}>
              <ServicesTab
                productName={product?.name}
                // @ts-ignore
                services={product?.yallaPlatform?.products?.flatMap(({ services }) => services)}
              />
            </TabPane>
          </TabContent>
        </PageSection>
      </PageWithNoPadding>
      {product && (
        <EditProductModal
          isModalOpen={isEditProductModalOpen}
          toggle={() => {
            setIsEditProductModalOpen(!isEditProductModalOpen);
          }}
          // @ts-ignore
          selectedProduct={product}
        />
      )}
      {product && (
        <AddReposToProductModal
          // @ts-ignore
          product={product}
          isOpen={isAddRepoModalOpen}
          toggle={() => setAddRepoModalOpen(false)}
          onSetMetricsClick={() => setMetricsModalOpen(true)}
        />
      )}
      {product && quiz && (
        <SetAndEditProductMetricsModal
          isOpen={isSetMetricsModalOpen}
          toggle={() => {
            setMetricsModalOpen(false);
            setSelectedMetric(undefined);
          }}
          // @ts-ignore
          product={product}
          quiz={quiz}
          selectedMetric={selectedMetric}
          previousAnswers={quizResult?.answers}
        />
      )}
    </>
  );
}
