import { env } from "next-runtime-env";
import { Link } from "./Link";
import React from "react";

export function Footer() {
  let buildString = "";
  if (env("NEXT_PUBLIC_DEPLOY_ENVIRONMENT") && env("NEXT_PUBLIC_DEPLOY_VERSION")) {
    const environment = env("NEXT_PUBLIC_DEPLOY_ENVIRONMENT")?.replace(/platform-frontend?./, "");

    buildString = `v${env("NEXT_PUBLIC_DEPLOY_VERSION")}-Next.js ${environment}`;
  }
  return (
    <footer className=" bg-secondary border-top">
      <div className="d-flex justify-content-between align-items-center x5-px-8 pt-6 bg-white">
        <p className="text-muted small fw-normal w-30">
          Accelerate helps product teams manage their technology and implement solutions.
        </p>
        <nav className="d-flex gap-3">
          <Link href="/" className="small text-muted text-decoration-none">
            Home
          </Link>
          <Link
            href="https://basproducts.atlassian.net/wiki/company-hub"
            className="small text-muted text-decoration-none"
            target="_blank"
            rel="noopener noreferrer"
          >
            Confluence
          </Link>
          <Link
            href="https://app.slack.com/client/T0220L0L9NU/C02PL5KMJGY"
            className="small text-muted text-decoration-none"
            target="_blank"
            rel="noopener noreferrer"
          >
            Submit feedback
          </Link>
        </nav>
      </div>
      <div className="d-flex justify-content-between align-items-center x5-px-8 pt-6">
        <p className="text-muted fw-normal small pb-0">BP p.l.c. Copyright © 1996-2025</p>
        <p className="text-muted fw-normal small pb-0">Version: {buildString ? buildString : "Next.js local build"}</p>
      </div>
    </footer>
  );
}
