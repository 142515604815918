import { ListGroup } from "../../components/list-group";
import { Link } from "../../components/Link";
import { Button } from "../../components/button";

type SetSupportGroupsProps = {
  onEditClick: () => void;
  userHasPermissions: boolean;
};

export function ConnectRepositoriesCard({ onEditClick, userHasPermissions }: SetSupportGroupsProps) {
  return (
    <ListGroup>
      <div className="d-flex justify-content-between py-5">
        <div className="d-flex flex-column">
          <div>
            <div className="d-flex flex-column">
              <>
                <h5 className="fw-light">There are no connected repositories to your product.</h5>
                <p className="small fw-light">
                  Connecting your ADO repositories to your product in Accelerate boosts your productivity by allowing
                  you to explore your own and other bp products' repositories with the help of Accelerate's AI
                  assistant, Axel.
                </p>
                <p className="small fw-light">
                  Only Product managers, Tech leads and Operational delegates can perform this action.
                </p>
                <div className="d-flex gap-4">
                  {userHasPermissions && (
                    <Button
                      level="tertiary"
                      theme="standard"
                      rounded="pill"
                      size="sm"
                      name="Edit"
                      onClick={onEditClick}
                    >
                      Connect Repositories
                    </Button>
                  )}
                  <Link
                    className="fw-light small mt-3"
                    name="more-info-about-repos"
                    hideExternalLinkIcon={true}
                    href="/knowledgehub/dVpGi4"
                  >
                    Learn about repositories
                  </Link>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </ListGroup>
  );
}
