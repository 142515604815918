import { convertToDate } from "../../utils/dates";

export const POWERAPP_IMPORT_DATE = new Date("2023-12-01").getTime(); // 1st Dec 2023

export function ProductDescription({
  createdDate,
  updatedDate,
  createdByEmail,
  updatedByEmail,
}: {
  createdDate?: number | null;
  updatedDate?: number | null;
  createdByEmail?: string | null;
  updatedByEmail?: string | null;
}) {
  const isPowerAppImport = createdDate && createdDate > 1 && createdDate === POWERAPP_IMPORT_DATE;
  let text = "This product was created through Accelerate";
  if (updatedDate && updatedByEmail) {
    const date = convertToDate(Number(updatedDate));
    text = `This product page was last updated on ${date} by `;
  } else if (isPowerAppImport) {
    const date = convertToDate(Number(createdDate ? new Date(createdDate) : new Date()));
    text = `This product was imported automatically on ${date} as part of a migration from the bp assessment PowerApp`;
  } else if (createdDate && createdByEmail) {
    const date = convertToDate(Number(createdDate));
    text = `This product page was created on ${date} by `;
  }
  return (
    <p className="small fw-light opacity-100" data-testid="product-created-date">
      {text}
      {updatedByEmail || createdByEmail ? (
        <a href={`mailto:${updatedByEmail || createdByEmail}`}>{updatedByEmail || createdByEmail}</a>
      ) : null}
      .
    </p>
  );
}
