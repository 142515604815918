import { DecoratedTooltip as Tooltip } from "../../../components/tooltip";

export const ProductAdminTooltip = () => {
  return (
    <Tooltip
      name={"Product admin"}
      description={
        "As a product admin, you have special permissions for this product. Admin roles are designated in ServiceNow. To edit your role, go to ServiceNow."
      }
    ></Tooltip>
  );
};
