import { useQuery } from "@apollo/client";
import { GET_QUIZ_RESULT } from "../../../api/quizzes/gql";
import { QuizResult } from "../../../gql/graphql";

type UseQuizResultOutput = {
  quizResult?: QuizResult;
  loading: boolean;
  refetch?: () => void;
};

type UseQuizResultsProps = {
  entityId: string;
  quizSlug: string;
};

export function useQuizResult({ entityId, quizSlug }: UseQuizResultsProps): UseQuizResultOutput {
  const { data, loading, refetch } = useQuery(GET_QUIZ_RESULT, {
    fetchPolicy: "no-cache",
    skip: !entityId,
    variables: {
      entityId,
      quizSlug,
    },
  });

  return {
    quizResult: data?.productQuizResult,
    loading,
    refetch,
  };
}
