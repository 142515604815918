"use client";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Row, Col } from "reactstrap";
import { Toast } from "../components/toast";
import { NotificationType, MapEventToNotification } from "../utils/notification-mapper";
import { HelpAndSupportFormModal } from "../forms/helpAndSupport/modal";
import { useWebsocket } from "../hooks/use-websocket";
import { MegaMenu2 } from "../navigation/mega-menu";
import { NavbarTop } from "../navigation/navbar-top";
import ExceptionPage from "../views/exception/exception-page";
import { useAppActions, useIsHelpAndSupportModalOpen, useToasts } from "../hooks/use-app-store";
import { useLocation } from "../hooks/use-location";
import { useFeature, useFeatureHub } from "featurehub-react-sdk";
import { DismissableBanner } from "../components/dismissable-banner";
import { SideMegaMenu } from "../navigation/mega-menu/side-mega-menu";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { Footer as FooterV2 } from "../components/footer-v2";

export const SIDE_MENU_COLLAPSED_WIDTH = "60px";
export const SIDE_MENU_EXPANDED_WIDTH = "240px";

function Banners() {
  return (
    <div className="d-flex flex-column">
      <div className="align-self-center">
        <DismissableBanner color={"info"} name={"khub-migration-notice-banner-2"} show={true}>
          Accelerate Documents (Knowledge Hub) have been{" "}
          <a href="https://accelerate.bpglobal.com/knowledgehub/chB6Vb">migrated to Confluence.</a>{" "}
          <strong>
            You can now access the migrated documentation spaces{" "}
            <a href="https://basproducts.atlassian.net/wiki/spaces">here</a>.{" "}
          </strong>
        </DismissableBanner>
      </div>
    </div>
  );
}

export const PageLayout: React.FC<PropsWithChildren> = (props) => {
  const websocket = useWebsocket();
  const location = useLocation();
  const { client } = useFeatureHub();
  useEffect(() => {
    if (client.repository().readyness === "Ready") {
      const featureFlags = Object.fromEntries(
        Array.from(client.repository().simpleFeatures(), ([key, value]) => [key, value === "true"]),
      );
      console.table(featureFlags);
    }
  }, [client.repository().readyness]);

  const { setToasts, setIsHelpAndSupportModalOpen } = useAppActions();

  const toasts = useToasts();
  const isHelpAndSupportModalOpen = useIsHelpAndSupportModalOpen();
  const FE_SIDE_MEGA_MENU = useFeature("FE_SIDE_MEGA_MENU");

  function handleNewNotification(notification: NotificationType) {
    const { id, title } = MapEventToNotification(notification);
    appendToast({ id, title });
  }

  function appendToast({ id, title = "You have a new notification" }: { id: string; title?: string }) {
    if (id) {
      setToasts([
        ...toasts,
        <Toast autohide key={id}>
          {title}
        </Toast>,
      ]);
    }
  }

  const [showLabels, setShowLabels] = useState(true);
  useEffect(() => {
    if (websocket) {
      websocket?.on("NEW_NOTIFICATION", handleNewNotification);
      return () => {
        websocket?.off("NEW_NOTIFICATION", handleNewNotification);
      };
    }
  }, [websocket]);

  // Handle screen resizing and set showLabels state accordingly
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setShowLabels(false);
      } else {
        setShowLabels(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const errorHandler = (error: any, info: any) => {
    console.error({ error, info });
  };

  return (
    <>
      <HelpAndSupportFormModal show={isHelpAndSupportModalOpen} onHide={() => setIsHelpAndSupportModalOpen(false)} />

      {FE_SIDE_MEGA_MENU ? null : (
        <>
          <NavbarTop />
          <MegaMenu2 />
        </>
      )}
      {FE_SIDE_MEGA_MENU ? (
        <div
          className="d-flex overflow-x-hidden w-100"
          style={{
            width: showLabels
              ? `calc(100% - ${SIDE_MENU_EXPANDED_WIDTH})`
              : `calc(100% - ${SIDE_MENU_COLLAPSED_WIDTH})`,
            transition: "width 0.3s ease-in-out",
          }}
        >
          <SideMegaMenu showLabels={showLabels} setShowLabels={setShowLabels} />
          <div
            className="main-content flex-grow-1 d-flex flex-column overflow-y-auto overflow-x-hidden w-100"
            style={{
              marginLeft: showLabels ? SIDE_MENU_EXPANDED_WIDTH : SIDE_MENU_COLLAPSED_WIDTH,
              transition: "margin-left 0.3s ease-in-out",
              minHeight: "100vh",
            }}
          >
            <Header />
            <Banners />
            <div className="flex-grow-1 py-0" role="main">
              {props.children}
            </div>
            <FooterV2 />
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
          }}
        >
          <div className="app-container position-relative">
            <Row className="main-content">
              <Col className={`col main px-0 py-0`} role="main">
                <ErrorBoundary
                  key={location.pathname}
                  fallbackRender={() => <ExceptionPage setIsHelpAndSupportModalOpen={setIsHelpAndSupportModalOpen} />}
                  onError={errorHandler}
                >
                  {props.children}
                </ErrorBoundary>
              </Col>
              {toasts.length > 0 && (
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: "2rem",
                  }}
                >
                  {toasts}
                </div>
              )}
            </Row>
          </div>
        </div>
      )}

      {FE_SIDE_MEGA_MENU ? null : <Footer />}
    </>
  );
};
