export const INCOMPLETE = "\u2014";
const NA_SCORE = -1;
const NA = "N/A";

type Props = {
  score?: string | number;
};

export function scoreIsDisplayable(score: string | number | null | undefined): boolean {
  return !(score === null || score === undefined || Number.isNaN(score) || Number.isNaN(Number(score)));
}

export function getDisplayedScore(score: number | null | string | undefined): string {
  if (!scoreIsDisplayable(score)) {
    return INCOMPLETE;
  }
  if (Number(score) === NA_SCORE) {
    return NA;
  }
  const scoreAsNumber = Number(score).toFixed(1);
  return `${scoreAsNumber}`;
}

export function DisplayScore({ score }: Props) {
  return (
    <div className="my-auto d-flex align-items-baseline" data-testid="display-score">
      <h3 className="fw-light mb-0">{getDisplayedScore(score)}</h3>
      <p className="fw-normal mb-0">/10</p>
    </div>
  );
}
