"use client";

import { HttpLink, from } from "@apollo/client";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { env } from "next-runtime-env";
import { ACCELERATE_APIS } from "../../api/config";
import { requestAccessToken } from "../../utils/helpers/msal-helper";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { ApolloClient, ApolloNextAppProvider, InMemoryCache } from "@apollo/experimental-nextjs-app-support";

if (process.env.NODE_ENV !== "production") {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

// have a function to create a client for you
function makeClient() {
  const httpLink = new HttpLink({
    uri: env("NEXT_PUBLIC_APOLLO_URL"),
    credentials: "same-origin",
    headers: {
      "Apollo-Require-Preflight": "true",
      "client-name": `Web App [web]`,
      "client-version": `1.0.0`,
      lang: "en",
      Accept: "multipart/mixed; deferSpec=20220824, application/json",
    },
  });

  const withToken = setContext(async (_, { headers }) => {
    const token = await requestAccessToken(ACCELERATE_APIS.apollo.scopes);
    const Authorization = token ? `Bearer ${token}` : null;

    return {
      headers: {
        ...headers,
        Authorization,
      },
    };
  });

  // Error Link
  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, extensions, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, extensions:${extensions?.code} Location: ${locations}, Path: ${path}`,
        ),
      );
    }
    if (networkError) {
      try {
        // @ts-expect-error
        JSON.parse(networkError.bodyText);
      } catch (e) {
        console.error(e);
        // @ts-expect-error
        if (networkError.bodyText) {
          // @ts-expect-error
          networkError.message = networkError.bodyText;
          console.log(networkError.message);
        }
      }
      console.log(`[Network error]: ${networkError}`);
    }
    return forward(operation);
  });

  // Cache Policy
  const cache = new InMemoryCache({
    typePolicies: {
      YallaPlatform: { keyFields: ["name"] },
      CapabilityResult: { keyFields: ["productId"] },
    },
  });

  return new ApolloClient({
    cache,
    link: from([errorLink, withToken, httpLink]),
    credentials: "include",
  });
}

// you need to create a component to wrap your app in
export function ApolloWrapper({ children }: React.PropsWithChildren) {
  return <ApolloNextAppProvider makeClient={makeClient}>{children}</ApolloNextAppProvider>;
}
