"use client";
import { useState } from "react";
import { Loader } from "../../../../components/spinners/loading-spinner";
import { ProductResponse } from "../../../../gql/graphql";
import { TeamMembers } from "./team-members";
import { TeamAddMembersModal } from "../../../../views/profile/teams/details/team-add-members-modal";
import { UserOption } from "../../../../views/profile/teams/details/team-members-dropdown";
import { Button } from "../../../../components/button";
import { Accordion } from "@bphxd/ds-core-react";
import { useMutation } from "@apollo/client";
import { ADD_PRODUCT_TEAM_MEMBERS, REMOVE_PRODUCT_TEAM_MEMBERS } from "../../../../api/products/gql";
import { useIsUserMember } from "../../../../hooks/use-is-user-member";

const COMMON_QUESTIONS = [
  {
    question: "What is an Admin role and how can I give admin rights to a member?",
    answer: (
      <>
        <p className="fs-6 fw-normal lh-1-5">
          Admins come from ServiceNow so, this are PMs, Tech Leads, and Operational delegates
        </p>
      </>
    ),
  },
  {
    question: "What is a Support role and how can I give support rights to a member?",
    answer: (
      <>
        <p className="fs-6 fw-normal lh-1-5">A support role is someone on the support groups</p>
      </>
    ),
  },
];

type MembersTabProps = {
  product?: ProductResponse;
  refetchProduct: CallableFunction;
};

export function MembersTab({ product, refetchProduct }: MembersTabProps) {
  const productId = product?.id;
  const members = product?.members;
  const [isAddMemberOpen, setIsAddMemberOpen] = useState(false);

  const [addNewMembers] = useMutation(ADD_PRODUCT_TEAM_MEMBERS);
  const [removeMembers] = useMutation(REMOVE_PRODUCT_TEAM_MEMBERS);

  async function handleAddTeamMembers(members: UserOption[]) {
    await addNewMembers({
      variables: {
        productId: productId ?? "",
        memberEmails: members.map((member) => member.resource.userPrincipalName),
      },
    });
    refetchProduct();
  }

  async function handleRemoveMember(id: string) {
    await removeMembers({
      variables: {
        productId: productId ?? "",
        memberEmails: [id],
      },
    });
    refetchProduct();
  }

  const userIsMember = useIsUserMember(product);

  return (
    <div className="d-flex row mt-n4">
      <div className="col-8">
        {typeof product === "undefined" || typeof members === "undefined" ? (
          <Loader />
        ) : (
          <TeamMembers product={product} onRemoveUser={handleRemoveMember} />
        )}
      </div>
      <div className="mt-n5 col-4">
        {userIsMember && (
          <div className="px-5 py-6 mt-6 bg-white border rounded-5">
            <Button
              className="text-center w-100"
              data-testid="add-team-member-button"
              name="add-team-members"
              onClick={() => setIsAddMemberOpen(true)}
              level="tertiary"
              theme="standard"
              rounded="pill"
            >
              Add team members
            </Button>
          </div>
        )}
        <div className="mt-6 bg-white border w-100 p-7 rounded-5">
          <h5 className="fw-light">Commonly asked questions</h5>
          <Accordion flush>
            {COMMON_QUESTIONS.map(({ question, answer }, index) => {
              return (
                <Accordion.Item key={index}>
                  <Accordion.Header targetId={String(index)}>
                    <p className="m-0 fw-normal opacity-80">{question}</p>
                  </Accordion.Header>
                  <Accordion.Body accordionId={String(index)}>{answer}</Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div>
      </div>
      {isAddMemberOpen && (
        <TeamAddMembersModal onSubmit={handleAddTeamMembers} onClose={() => setIsAddMemberOpen(false)} />
      )}
    </div>
  );
}
