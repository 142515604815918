"use client";
import { useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { DateTime } from "luxon";
import { useLocation } from "../../../../hooks/use-location";
import { updateQueryParams, updateQueryParamsAsSearchParams } from "../../../../utils/update-search-params";
import Pluralise from "../../../../utils/pluralise";
import { GET_METRICS_FOR_BP } from "../../../../api/products/gql";

import {
  Check24,
  Down24,
  Remove24,
  Right24,
  Search24,
  TriangleDown16,
  TriangleUp16,
} from "@bphxd/ds-core-react/lib/icons";
import { Tabs } from "@bphxd/ds-core-react";
import { Col, DropdownItem, InputGroup, InputGroupText, Row } from "reactstrap";
import Input from "../../../../components/Input";
import { DropdownMenu } from "../../../../components/dropdown-menu";
import { Loader } from "../../../../components/spinners/loading-spinner";
import { HelpMeImproveMyCapabilityButton } from "../../../../components/button";
import { LeadTimeTabContent } from "./metric-lead-time-tab-content";
import { DeploymentFrequencyTabContent } from "./metric-deployment-frequency-tab-content";

import { LeadTimeData, Metrics, TrendingLeadTimeData } from "../../../../types/ProfileTypes";
import { DeploymentFrequencyModel, LeadTime } from "../../../../gql/graphql";

export type LeadTimeChartData = {
  leadTimeHours: number;
  mergeToProdHours: number;
  commitToMergeHours: number;
  month: string | null;
};

export interface LeadTimePipelineData {
  id: string;
  name: string;
  leadTimeData: LeadTimeChartData[];
  last30DaysLeadTime?: number;
  last12MonthsLeadTime?: number;
  last12MonthsCommitToMergeTime?: number;
  last30DaysCommitToMergeTime?: number;
  last30DaysMergeToProdTime?: number;
  trendingPeriodStartDate?: number;
}

export type DeploymentChartData = {
  deployments: number;
  month: string | null;
};

export interface DeploymentFrequencyPipelineData {
  id: string;
  name: string;
  deploymentData: DeploymentChartData[];
  last30DaysDeployments?: number;
  last12MonthsDeployments?: number;
  trendingPeriodStartDate?: number;
  periodLength: string;
}

export type bpLeadTime = Omit<TrendingLeadTimeData, "trendingPeriodStartDate"> &
  Omit<LeadTimeData, "periodStartDate" | "periodEndDate"> & {
    targetValue: number;
  };

type bpDoraMetrics = {
  doraMetrics: {
    leadTime: bpLeadTime;
  };
};

export enum SelectedMetrics {
  LEAD_TIME = "Lead time",
  DEPLOY_FREQUENCY = "Deploy frequency",
}

const ALL_PIPELINES = "all";

export function convertHoursToHMString(hours?: number) {
  if (!hours) {
    return;
  }
  const h = Math.floor(hours);
  const m = Math.round((hours - h) * 60);
  return `${h}h ${m}m`;
}

export function calculatePercentageRun(commitToMerge: number, leadTime: number) {
  return ((commitToMerge / leadTime) * 100).toFixed(2);
}

function formatPeriodLength(periodStartDate: number | undefined, periodEndDate: number | undefined) {
  const length = Math.round(calculatePeriodLength(periodStartDate, periodEndDate));
  return `${length} month${Pluralise(length)}`;
}

export function calculatePeriodLength(periodStartDate: number | undefined, periodEndDate: number | undefined) {
  if (!periodStartDate || !periodEndDate) {
    return 1;
  }
  return Math.max(DateTime.fromMillis(periodEndDate).diff(DateTime.fromMillis(periodStartDate), "months").months, 1);
}

function calculateAverageDeploys(
  periodCount: number | undefined,
  periodStartDate: number | undefined,
  periodEndDate: number | undefined,
) {
  if (!periodCount || !periodStartDate || !periodEndDate) {
    return 0;
  }

  return Math.round(periodCount / calculatePeriodLength(periodStartDate, periodEndDate));
}

function getLeadTimeData(id: string, data: LeadTime[]): LeadTimeChartData[] {
  if (id === ALL_PIPELINES) {
    return data.map((x) => {
      return {
        leadTimeHours: x.leadTimeHours ? parseFloat(x.leadTimeHours.toFixed(2)) : 0,
        commitToMergeHours: x.commitToMergeHours ? parseFloat(x.commitToMergeHours.toFixed(2)) : 0,
        mergeToProdHours: x.mergeToProdHours ? parseFloat(x.mergeToProdHours.toFixed(2)) : 0,
        month: DateTime.fromMillis(x.periodStartDate).monthShort as string,
      };
    });
  }
  return data
    .map((x) => {
      const info = x.perPipeline && x.perPipeline.find((p) => p.pipeline.id === id);
      if (!info) {
        return;
      }
      return {
        leadTimeHours: info.leadTimeHours ? parseFloat(info.leadTimeHours?.toFixed(2)) : 0,
        commitToMergeHours: info.commitToMergeHours ? parseFloat(info.commitToMergeHours?.toFixed(2)) : 0,
        mergeToProdHours: info.mergeToProdHours ? parseFloat(info.mergeToProdHours?.toFixed(2)) : 0,
        month: DateTime.fromMillis(x.periodStartDate).monthShort,
      };
    })
    .filter((x) => Boolean(x)) as LeadTimeChartData[];
}

function getDeploymentData(id: string, data: DeploymentFrequencyModel[]): DeploymentChartData[] {
  if (id === ALL_PIPELINES) {
    return data.map((x) => {
      return {
        deployments: x?.periodDeploymentCount ?? 0,
        month: DateTime.fromMillis(x.periodStartDate).monthShort,
      };
    });
  }
  return data
    .map((x) => {
      const info = x.perPipeline && x.perPipeline.find((p) => p.pipeline.id === id);
      if (!info) {
        return;
      }
      return {
        deployments: info?.periodDeploymentCount ?? 0,
        month: DateTime.fromMillis(x.periodStartDate).monthShort,
      };
    })
    .filter((x) => Boolean(x)) as DeploymentChartData[];
}

export function MetricsTab({ metrics }: { metrics?: Metrics }) {
  const { router, search, pathname } = useLocation();
  const [searchInput, setSearchInput] = useState("");
  const selectedMetric = search?.metric
    ? SelectedMetrics[search.metric as keyof typeof SelectedMetrics]
    : SelectedMetrics.LEAD_TIME;

  const { data: bpData } = useQuery<bpDoraMetrics>(GET_METRICS_FOR_BP);

  const currentLeadTime = metrics?.leadTime;
  const historicLeadTimes = metrics?.historicLeadTimes?.slice() ?? [];
  historicLeadTimes.sort((a, b) => a.periodStartDate - b.periodStartDate);
  const leadTimePipelineData = [
    {
      id: ALL_PIPELINES,
      name: "All pipelines",
      leadTimeData: getLeadTimeData(ALL_PIPELINES, historicLeadTimes),
      last12MonthsLeadTime: currentLeadTime?.leadTimeHours,
      last12MonthsCommitToMergeTime: currentLeadTime?.commitToMergeHours,
      last30DaysLeadTime: currentLeadTime?.trendingLeadTimeHours,
      last30DaysCommitToMergeTime: currentLeadTime?.trendingCommitToMergeHours,
      last30DaysMergeToProdTime: currentLeadTime?.trendingMergeToProdHours,
      trendingPeriodStartDate: currentLeadTime?.trendingPeriodStartDate ?? 0,
    },
  ].concat(
    currentLeadTime?.perPipeline?.map((x) => {
      return {
        id: x?.pipeline?.id,
        name: x?.pipeline?.name,
        leadTimeData: getLeadTimeData(x?.pipeline.id, historicLeadTimes),
        last12MonthsLeadTime: x?.leadTimeHours,
        last12MonthsCommitToMergeTime: x?.commitToMergeHours,
        last30DaysLeadTime: x?.trendingLeadTimeHours,
        last30DaysCommitToMergeTime: x?.trendingCommitToMergeHours,
        last30DaysMergeToProdTime: x?.trendingMergeToProdHours,
        trendingPeriodStartDate: x?.trendingPeriodStartDate ?? 0,
      };
    }) ?? [],
  );

  const currentDeploymentFrequency = metrics?.deploymentFrequency;
  const historicDeploymentFrequency = metrics?.historicDeploymentFrequencies?.slice() ?? [];
  historicDeploymentFrequency.sort((a, b) => a.periodStartDate - b.periodStartDate);
  const deploymentFrequencyPipelineData = [
    {
      id: ALL_PIPELINES,
      name: "All pipelines",
      deploymentData: getDeploymentData(ALL_PIPELINES, historicDeploymentFrequency),
      last30DaysDeployments: currentDeploymentFrequency?.trendingPeriodDeploymentCount ?? 0,
      last12MonthsDeployments: calculateAverageDeploys(
        currentDeploymentFrequency?.periodDeploymentCount,
        currentDeploymentFrequency?.periodStartDate,
        currentDeploymentFrequency?.periodEndDate,
      ),
      periodLength: formatPeriodLength(
        currentDeploymentFrequency?.periodStartDate,
        currentDeploymentFrequency?.periodEndDate,
      ),
      trendingPeriodStartDate: currentDeploymentFrequency?.trendingPeriodStartDate ?? 0,
    },
  ].concat(
    currentDeploymentFrequency?.perPipeline?.map((x) => ({
      id: x?.pipeline.id,
      name: x?.pipeline.name,
      deploymentData: getDeploymentData(x?.pipeline.id, historicDeploymentFrequency),
      last30DaysDeployments: x?.trendingPeriodDeploymentCount ?? 0,
      last12MonthsDeployments: calculateAverageDeploys(x?.periodDeploymentCount, x?.periodStartDate, x?.periodEndDate),
      trendingPeriodStartDate: x?.trendingPeriodStartDate ?? 0,
      periodLength: formatPeriodLength(x?.periodStartDate, x?.periodEndDate),
    })) ?? [],
  );

  const pipelines =
    selectedMetric === SelectedMetrics.LEAD_TIME ? leadTimePipelineData : deploymentFrequencyPipelineData;

  const filteredPipelines = pipelines
    ?.slice(1)
    //@ts-ignore
    ?.filter((pipeline) => pipeline.name.toLowerCase().includes(searchInput.toLowerCase()));

  const [sortField, setSortField] = useState<"name" | "duration">("name");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const sortPipeline = (field: "name" | "duration") => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
  };

  const sortedPipelines = useMemo(() => {
    if (!filteredPipelines) {
      return [];
    }
    return [...filteredPipelines].sort((a, b) => {
      if (sortField === "name") {
        return sortOrder === "asc" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
      } else if (sortField === "duration") {
        if ("last30DaysLeadTime" in a && "last30DaysLeadTime" in b) {
          return sortOrder === "asc"
            ? (a.last30DaysLeadTime || 0) - (b.last30DaysLeadTime || 0)
            : (b.last30DaysLeadTime || 0) - (a.last30DaysLeadTime || 0);
        } else if ("last30DaysDeployments" in a && "last30DaysDeployments" in b) {
          return sortOrder === "asc"
            ? (a.last30DaysDeployments || 0) - (b.last30DaysDeployments || 0)
            : (b.last30DaysDeployments || 0) - (a.last30DaysDeployments || 0);
        }
        return 0;
      }
      return 0;
    });
  }, [filteredPipelines, sortField, sortOrder]);

  const selectedPipeline = (search?.pipeline ?? pipelines?.[0]?.id ?? ALL_PIPELINES) as string;

  if (!metrics) {
    return <Loader />;
  }

  return (
    <div>
      <div className="d-flex justify-content-between">
        <DropdownMenu
          toggle={
            <h3 className="fw-light mb-0">
              {selectedMetric} <Down24 />
            </h3>
          }
          trackingEventProps={{ category: "Lead Time", action: "Change Metric" }}
        >
          {Object.entries(SelectedMetrics)
            .slice(0, undefined)
            .map(([key, value]) => (
              <DropdownItem
                key={key}
                onClick={() =>
                  router?.push({
                    pathname,
                    query: updateQueryParams(search, { metric: key, id: "" }),
                  })
                }
                className="d-flex justify-content-between gap-4"
              >
                {value} {value === selectedMetric && <Check24 />}
              </DropdownItem>
            ))}
        </DropdownMenu>

        <HelpMeImproveMyCapabilityButton capabilityName={selectedMetric} size="md" />
      </div>
      <div className="mt-7 bg-white p-6">
        <Row>
          <Col xs="5" className="pe-0">
            <InputGroup className="input-group-merge mb-4 pe-4" size="">
              <Input
                data-testid="pipeline-filter-input"
                type="text"
                value={searchInput}
                className={`form-control-prepended ${searchInput ? "form-control-appended" : ""} `}
                placeholder="Filter by pipeline"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.preventDefault();
                  setSearchInput(e.target.value);
                }}
              />
              <div className="input-group-prepend">
                <InputGroupText>
                  <Search24 />
                </InputGroupText>
              </div>
              {searchInput && (
                <div className="input-group-append cursor-pointer" onClick={() => setSearchInput("")}>
                  <InputGroupText>
                    <Remove24 />
                  </InputGroupText>
                </div>
              )}
            </InputGroup>
            <p className="fs-6 fw-light small">
              {sortedPipelines.length} pipeline{Pluralise(sortedPipelines)}
            </p>
            <div className="d-flex justify-content-between mx-1 border-bottom border-black pb-4">
              <div className="d-flex ms-5 cursor-pointer" onClick={() => sortPipeline("name")}>
                <p className="small align-self-center">Pipeline</p>
                <div className="d-flex flex-column ms-2 ">
                  <TriangleUp16 className="mb-n3" />
                  <TriangleDown16 />
                </div>
              </div>
              <div className="d-flex me-5 cursor-pointer" onClick={() => sortPipeline("duration")}>
                <p className="small align-self-center">
                  {selectedMetric === SelectedMetrics.LEAD_TIME
                    ? "Average duration over the last 30 days"
                    : "Deploys over the last 30 days"}
                </p>
                <div className="d-flex flex-column ms-2 ">
                  <TriangleUp16 className="mb-n3" />
                  <TriangleDown16 />
                </div>
              </div>
            </div>
            <Tabs vertical tabStyle="standard" className="pb-0 mb-0 h-auto">
              {!searchInput && (
                <Tabs.Item
                  className={(selectedPipeline === ALL_PIPELINES ? "border-dark-subtle " : "") + "border-bottom"}
                >
                  <Tabs.Link
                    active={selectedPipeline === ALL_PIPELINES}
                    className="border-start-0 cursor-pointer w-100 d-flex justify-content-between"
                    href={`${pathname}?${updateQueryParamsAsSearchParams(search, { pipeline: ALL_PIPELINES })}`}
                    onClick={(ev) => {
                      ev.preventDefault();
                      ev.stopPropagation();
                      router?.push({
                        pathname,
                        query: updateQueryParams(search, { pipeline: ALL_PIPELINES, id: "" }),
                      });
                    }}
                  >
                    All pipelines <Right24 />
                  </Tabs.Link>
                </Tabs.Item>
              )}

              {sortedPipelines?.map((pipeline) => {
                return (
                  <Tabs.Item
                    key={pipeline.id}
                    className={(selectedPipeline === pipeline.id ? "border-dark-subtle " : "") + "ps-4 border-bottom"}
                  >
                    <Tabs.Link
                      active={selectedPipeline === pipeline.id}
                      className="border-start-0 cursor-pointer w-100 d-flex justify-content-between"
                      href={`${pathname}?${updateQueryParamsAsSearchParams(search, { pipeline: pipeline.id })}`}
                      onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        router?.push({
                          pathname,
                          query: updateQueryParams(search, { pipeline: pipeline.id, id: "" }),
                        });
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between">
                        <p>{pipeline.name}</p>
                        {"last30DaysLeadTime" in pipeline &&
                          (pipeline?.last30DaysLeadTime ? (
                            <p>{convertHoursToHMString(pipeline?.last30DaysLeadTime)}</p>
                          ) : null)}
                        {"last30DaysDeployments" in pipeline && <p>{pipeline?.last30DaysDeployments}</p>}
                      </div>
                      <Right24 />
                    </Tabs.Link>
                  </Tabs.Item>
                );
              })}
            </Tabs>
          </Col>
          <Col xs="7" className="ps-0">
            {selectedMetric === SelectedMetrics.LEAD_TIME && bpData?.doraMetrics.leadTime && (
              <LeadTimeTabContent
                selectedPipeline={selectedPipeline}
                pipelineData={leadTimePipelineData as LeadTimePipelineData[]}
                bpLeadTime={bpData?.doraMetrics.leadTime}
              />
            )}
            {selectedMetric === SelectedMetrics.DEPLOY_FREQUENCY && (
              <DeploymentFrequencyTabContent
                selectedPipeline={selectedPipeline}
                pipelineData={deploymentFrequencyPipelineData}
              />
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}
