"use client";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";

import { useToast } from "../../../../hooks/use-toast";

import { CREATE_SUPPORT_ALERT } from "../../../../api/products/gql";

import { Modal } from "../../../../components/modal";
import { Step1, Step1DataType } from "./step1";
import { GetAccelerateProductSupportGroupDetailsQuery } from "../../../../gql/graphql";
import { SUPPORT_GROUP_QUERY } from "../support-groups/support-groups";

export function RaiseAlertModal({
  product,
  isOpen,
  toggle,
  selectedGroup,
}: {
  product: GetAccelerateProductSupportGroupDetailsQuery["product"];
  isOpen: boolean;
  toggle: () => void;
  selectedGroup?: GetAccelerateProductSupportGroupDetailsQuery["product"]["supportGroups"][0];
}) {
  const [step1Data, setStep1Data] = useState<Step1DataType>({
    opsGroupId: "",
    description: "",
  });

  const { displayToast } = useToast();
  const [createAlert] = useMutation(CREATE_SUPPORT_ALERT, {
    refetchQueries: [SUPPORT_GROUP_QUERY],
    awaitRefetchQueries: true,
    onError: () => {},
  });

  async function handleSubmit(data: Step1DataType) {
    const response = await createAlert({ variables: { data } });
    if (response.data?.createAlert) {
      let supportGroupName = "";
      if (response.data?.createAlert.supportGroup) {
        supportGroupName = response.data.createAlert.supportGroup.name;
      } else {
        supportGroupName =
          product?.supportGroups?.find(({ opsGroupId }) => opsGroupId === data.opsGroupId)?.name ?? product.name;
      }
      displayToast(`Your alert for ${supportGroupName} was raised successfully`);
      toggle();
    }
  }

  function resetForm() {
    setStep1Data({
      opsGroupId: "",
      description: "",
    });
  }

  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (selectedGroup) {
      setStep1Data({
        opsGroupId: selectedGroup?.opsGroupId ?? "",
        description: "",
      });
    } else {
      resetForm();
    }
  }, [selectedGroup]);

  const supportGroupOptions =
    product?.supportGroups
      ?.filter((group) => !group?.isOnboarding)
      .map((group) => {
        return {
          label: (
            <div className="d-flex flex-column">
              <div className="fw-normal">{group.name}</div>
              <div className="fw-light small">{group.description}</div>
            </div>
          ),
          value: group.opsGroupId,
        };
      }) || [];

  return (
    <Modal isOpen={isOpen} onClose={toggle} header={`Raise alert for ${product.name}`} hasCancelDialog={isDirty}>
      <Step1
        handleNextClick={async (data) => {
          setStep1Data(data);
          return handleSubmit(data);
        }}
        supportGroupOptions={supportGroupOptions}
        handleDirtyForm={setIsDirty}
        {...step1Data}
      />
    </Modal>
  );
}
