import { Nav, Logo } from "@bphxd/ds-core-react";
import {
  BulletList24,
  CollapseLeft24,
  CollapseRight24,
  Electric24,
  Megaphone24,
  Support24,
} from "@bphxd/ds-core-react/lib/icons/index";
import { useAppActions } from "../../hooks/use-app-store";
import { Link } from "../../components/Link";
import Cube from "../../icons/brand/cube";
import Book from "../../icons/brand/book";
import AxelSearch from "../../icons/axel/axel-search";
import { LinkButton } from "../../components/link-button";
import { SIDE_MENU_COLLAPSED_WIDTH, SIDE_MENU_EXPANDED_WIDTH } from "../../app/page-layout";
import { Add24 } from "@bphxd/ds-core-react/lib/icons";
import { useProductDropdownContext } from "../../providers/product-dropdown-provider";
import { ProductDropdown } from "../../views/products/product-dropdown";

type SideMegaMenuProps = {
  showLabels: boolean;
  setShowLabels: (value: boolean) => void;
};

export function SideMegaMenu({ showLabels, setShowLabels }: SideMegaMenuProps) {
  const { setIsHelpAndSupportModalOpen } = useAppActions();
  const { myProductsList, selectedProduct } = useProductDropdownContext();
  const sidebarWidth = showLabels ? SIDE_MENU_EXPANDED_WIDTH : SIDE_MENU_COLLAPSED_WIDTH;

  return (
    <div
      className="overflow-y-auto flex-shrink-0 vh-100 position-fixed top-0 bg-white z-1"
      style={{
        width: sidebarWidth,
        minWidth: sidebarWidth,
        maxWidth: sidebarWidth,
        transition: "width 0.3s ease-in-out",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}
    >
      <div
        className="nav-sidebar-sm d-flex flex-column flex-shrink-0 text-default vh-100"
        style={{ borderRight: "1px solid #dee2e6" }}
      >
        <ul className="d-flex flex-column list-unstyled flex-grow-1">
          <div className="border-bottom">
            <li className="d-flex justify-content-start align-items-center my-4">
              <Link href={"/"}>
                <Logo height={72} />
              </Link>
              {showLabels && (
                <Link href={"/"} className="text-decoration-none">
                  <p className="fw-normal fw-light lh-1-75 text-dark-gray-700 opacity-80 mb-0 ">Accelerate</p>
                </Link>
              )}
            </li>
          </div>

          <Nav vertical className="d-flex flex-column flex-grow-1">
            <Nav.Item className={`py-4 border-bottom ${showLabels ? "x5-mx-3" : ""}`}>
              {showLabels ? (
                <LinkButton
                  name="yalla-find-out-more"
                  href="/axel"
                  className="ps-3"
                  level="secondary"
                  theme="standard"
                  size="md"
                  Icon={() => <AxelSearch />}
                >
                  <span className="opacity-80"> Axel Search </span>
                </LinkButton>
              ) : (
                <Link className="nav-link" href="/axel">
                  <AxelSearch className="nav-link-icon" />
                </Link>
              )}
            </Nav.Item>
            <Nav.Item className="py-3">
              <Link className="nav-link" href="/all-products">
                <BulletList24 className="nav-link-icon" />
                {showLabels && <span>All products</span>}
              </Link>
            </Nav.Item>
            <Nav.Item className="py-3 border-bottom">
              {myProductsList.length > 0 ? (
                <div className="d-flex x5-ms-3 x5-ps-1">
                  {selectedProduct ? (
                    <Link href={`/profile/product/${selectedProduct?.id}?tab=1-overview`}>
                      <Cube className="nav-link-icon x5-me-1" />
                    </Link>
                  ) : (
                    <Cube className="nav-link-icon" />
                  )}
                  {showLabels && <ProductDropdown />}
                </div>
              ) : (
                <Link className="nav-link" href="/all-products">
                  <Add24 className="nav-link-icon" />
                  {showLabels && <span>Join product</span>}
                </Link>
              )}
            </Nav.Item>
            <Nav.Item className="py-3">
              <Link className="nav-link" href="/khub/library">
                <Book className="nav-link-icon" />
                {showLabels && <span>Documentation</span>}
              </Link>
            </Nav.Item>
            <Nav.Item className="py-3">
              <Link className="nav-link" href="/technology/tools?context=tools">
                <Electric24 className="nav-link-icon" /> {showLabels && <span>Tools</span>}
              </Link>
            </Nav.Item>
            <Nav.Item className="py-3">
              <Link className="nav-link" href="#" onClick={() => setIsHelpAndSupportModalOpen(true)}>
                <Support24 className="nav-link-icon" /> {showLabels && <span>Support</span>}
              </Link>
            </Nav.Item>

            <div className="mt-auto">
              <Nav.Item className="py-3 border-bottom">
                <Link className="nav-link" href="/whats-new">
                  <Megaphone24 className="nav-link-icon" /> {showLabels && <span>What's new</span>}
                </Link>
              </Nav.Item>
              <Nav.Item className="py-3 text-center">
                <Link className="nav-link" href="#" onClick={() => setShowLabels(!showLabels)}>
                  {showLabels ? (
                    <CollapseLeft24 className="nav-link-icon" />
                  ) : (
                    <CollapseRight24 className="nav-link-icon" />
                  )}
                </Link>
              </Nav.Item>
            </div>
          </Nav>
        </ul>
      </div>
    </div>
  );
}
