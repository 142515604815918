import { useLocation } from "../../../hooks/use-location";

import { PageSection, PageWithNoPadding } from "../../../components/page-template";
import { Check24, Clock24, Delete24, Remove24, Right24 } from "@bphxd/ds-core-react/lib/icons";
import { Col, Row } from "reactstrap";
import { LinkButton } from "../../../components/link-button";
import { PageHeader } from "../../../components/page-header";
import { PageSource, useBreadCrumbs } from "../../../hooks/use-breadcrumbs";
import { AdoptionStatusFilters } from "../../../components/tools/adoption-status-filters";
import { useState } from "react";
import { SearchInput } from "../../../components/Input";
import { SortByDropdown } from "../../../components/dropdown-menu";
import { SORT_ENUMS } from "../../../app/profile/product/components/services-tab";
import { parseAsStringEnum, useQueryState } from "nuqs";
import { createReverseLookup } from "../../../components/tools/utils";
import { LabelDisplayMap, Labels, ToolNames, toolsList } from "../../../components/tools/tools-list";

function getIcon(label: keyof typeof Labels) {
  switch (label) {
    case Labels.Strategic:
      return <Check24 className="x5-me-2 text-success" />;
    case Labels.Supported:
      return <Delete24 className="x5-me-2 text-bp-CO2-blue-400" />;
    case Labels.ComingSoon:
      return <Clock24 className="x5-me-2 text-secondary" />;
    case Labels.Deprecated:
      return <Remove24 className="x5-me-2 text-danger" />;
  }
}

const toolNameReverseLookup = createReverseLookup(ToolNames);

export const getToolName = (toolName: string): string => {
  const key = toolNameReverseLookup[toolName];
  const overrides: Partial<Record<keyof typeof ToolNames, string>> = {
    MixPanel: "mixpanel", // Override only MixPanel
  };

  return key ? overrides[key] ?? toolName : toolName;
};

export default function AllToolsPage({ toolsList }: AllToolsPageProps) {
  const { router } = useLocation();
  const [searchTools, setSearchTools] = useQueryState("search_tools", { defaultValue: "" });
  const [sortBy] = useQueryState(
    "sort_by",
    parseAsStringEnum<SORT_ENUMS>(Object.values(SORT_ENUMS)).withDefault(SORT_ENUMS.NAME_ASC),
  );

  const searchInput = searchTools;
  const sortFields = new Map(Object.entries(SORT_ENUMS));
  const [breadcrumbs] = useBreadCrumbs({});
  const [adoptionStatusFilters, setAdoptionStatusFilters] = useState<string[]>([]);

  const filteredTools = toolsList.filter((item) => item.name.toLowerCase().includes(searchInput.toLowerCase()));

  const labelPriority: Record<Labels, number> = {
    [Labels.Strategic]: 1,
    [Labels.ComingSoon]: 2,
    [Labels.Supported]: 3,
    [Labels.Deprecated]: 4,
  };

  const sortedTools = filteredTools.sort((a, b) => {
    const priorityA = labelPriority[a.label];
    const priorityB = labelPriority[b.label];

    if (priorityA !== priorityB) {
      return priorityA - priorityB; // Sort by priority first
    }
    return sortBy === SORT_ENUMS.NAME_ASC ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
  });

  return (
    <PageWithNoPadding name="all-tools" title="all tools" backgroundClassName="bg-secondary">
      <div className="bg-white pb-9">
        <PageSection>
          <PageHeader
            breadcrumbs={breadcrumbs}
            pageTitle="All tools"
            pageSubTitle={
              <p className="lead fw-light mb-5">
                View self-service tools for coders at bp, and find service requests to get access, set up projects and
                more.
              </p>
            }
          />
        </PageSection>
      </div>
      <div className="bg-secondary">
        <PageSection>
          <Row className="mt-8">
            <Col xs="9" md="7" xl="5">
              <SearchInput
                value={searchInput}
                onChange={(val) => setSearchTools(val as string)}
                placeholder="Search tools"
              />
            </Col>
            <Col xs="3">
              <div className="opacity-70">
                <SortByDropdown sortFields={sortFields} currentField={sortBy} />
              </div>
            </Col>
          </Row>
        </PageSection>
        <PageSection>
          <Row className="mt-8">
            <Col xs="12" md="10" xl="8">
              <Row>
                {sortedTools
                  .filter((item) =>
                    adoptionStatusFilters.length === 0 ? true : adoptionStatusFilters.includes(item.label),
                  )
                  .map(({ id, name, icon, description, label }, index) => {
                    const toolUrl =
                      router?.asPath.replace(/\?.*/, `/tool/${getToolName(name)}?source=${PageSource.AllTools}`) ??
                      "/router/not/ready";
                    return (
                      <Col xs="6" key={id} className={`${index % 2 === 0 ? "mb-5" : ""}`}>
                        <div className="align-items-center bg-white rounded-5 py-6 x5-px-5">
                          <div className="d-flex align-items-center mt-2 ">
                            <img src={icon} alt={name} className="x5-me-2" style={{ width: "20px", height: "20px" }} />
                            <LinkButton
                              name={name}
                              Icon={Right24}
                              iconPosition={"end"}
                              level="quartenary"
                              rounded="circle"
                              size="sm"
                              href={toolUrl}
                              className="px-0 border-0"
                            >
                              <p className="mb-0 lead fw-light lh-1-5">{name}</p>
                            </LinkButton>
                          </div>
                          <p className="mb-0 small opacity-70 fw-light lh-1-5 small x5-ms-1 mt-5 ">{description}</p>
                          <hr />
                          <div className="d-flex">
                            {getIcon(label)}
                            <p className="mb-0 opacity-80 fw-light lh-1-5 ">{LabelDisplayMap[label]}</p>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
            </Col>
            <Col xs="3">
              <AdoptionStatusFilters
                toolsList={toolsList}
                adoptionStatusFilters={adoptionStatusFilters}
                setAdoptionStatusFilters={setAdoptionStatusFilters}
              />
            </Col>
          </Row>
        </PageSection>
      </div>
    </PageWithNoPadding>
  );
}

type AllToolsPageProps = {
  toolsList: typeof toolsList;
};

// 🔹 Use `getStaticProps` to generate the page at build time
export async function getStaticProps() {
  return {
    props: {
      toolsList,
    },
  };
}
