"use client";
import { useEffect } from "react";
import { Nav, NavItem, NavLink, TabContent as RSTabContent, TabContentProps } from "reactstrap";
import { textToSlug } from "../utils/helpers/utils";
import { trackEvent } from "../utils/event-tracker";

export type NavTabProps = {
  activeTab: string;
  tabs: {
    id: string | number;
    name: string;
    description: string;
    disabled?: boolean;
  }[];
  onTabSelection: (name: string) => void;
  trackingEventProps?: { [key: string]: string };
};

export function NavTabs(props: NavTabProps) {
  const { activeTab, tabs, onTabSelection } = props;
  const trackingEventProps = props.trackingEventProps || {};
  useEffect(() => {
    if (activeTab) {
      trackEvent({
        name: "tab_viewed",
        tab: activeTab,
        ...trackingEventProps,
      });
    }
  }, [activeTab, JSON.stringify(trackingEventProps)]);

  return (
    <Nav tabs className="bg-transparent">
      {tabs
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ id, name, description, disabled }, index) => {
          const active = activeTab === name;
          return (
            <NavItem key={`${index}-${id}-${name}`}>
              <NavLink
                tag="button"
                data-testid={`${textToSlug(description)}-tab`}
                active={active}
                className={disabled ? "not-allowed" : "clickable"}
                style={{
                  color: active ? "var(--bs-default-rgb)" : "",
                }}
                tabIndex={0}
                onClick={() => onTabSelection(name)}
                disabled={disabled}
              >
                {description}
              </NavLink>
            </NavItem>
          );
        })}
    </Nav>
  );
}

export function TabContent(props: TabContentProps) {
  return (
    <RSTabContent className="pt-7 border-0" {...props}>
      {props.children}
    </RSTabContent>
  );
}
