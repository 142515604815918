import { gql } from "@apollo/client";
import { graphql } from "../../gql/index";

/**
 * Queries
 */

export const GET_LIST_QUIZZES = gql`
  query Quizzes($quizTypes: [QuizType!]) {
    quizzes(quizTypes: $quizTypes) {
      name
      slug
      category
      quizType
      isRecentlyUpdated
      questions {
        id
        metric
        question
        isRecentlyUpdated
        back
        options {
          id
          label
          value
        }
      }
    }
  }
`;

/**
 * Mutations
 */

export type Form = { [key: string]: QuizQuestion[] };
type QuizQuestion = {
  question_id: number;
  option_id: null | number;
};

const QUIZ_FRAGMENT = gql`
  fragment QuizParts on Quiz {
    category
    description
    href
    isRecentlyUpdated
    maxScore
    name
    questions {
      back
      id
      isRecentlyUpdated
      metric
      options {
        id
        label
        value
      }
      question
    }
    quizType
    slug
  }
`;

export const GET_QUIZ_RESULT = gql`
  ${QUIZ_FRAGMENT}
  query QuizResult($entityId: String!, $quizSlug: QuizSlug!) {
    productQuizResult(entityId: $entityId, quizSlug: $quizSlug) {
      answers {
        option_id
        question_id
      }
      entityId
      lastUpdated
      maxScore
      quiz {
        ...QuizParts
      }
      quizType
      score
    }
  }
`;

export const SUBMIT_PRODUCT_QUIZ = graphql(/* GraphQL */ `
  mutation SubmitProductQuizResult($data: ProductSubmissionInput!) {
    submitProductQuizResult(data: $data) {
      score
      quiz {
        name
        slug
      }
    }
  }
`);
