import { ListGroupItem } from "../../../../../../../../../components/list-group";
import { Link } from "../../../../../../../../../components/Link";
import { formatMillisToDateTime } from "../../../../../../../../../utils/dates";
import { CarSide16, Settings16 } from "@bphxd/ds-core-react/lib/icons/index";
import { YallaChangeKind, type YallaChange } from "../../../../../../../../../gql/graphql";
import { ReactNode } from "react";

type ChangeInfoItemProps = {
  change: YallaChange;
};

type KindToDisplayTextType = {
  [key in YallaChangeKind]: { icon: ReactNode; text: string };
};

const kindToDisplayText: KindToDisplayTextType = {
  [YallaChangeKind.Deployment]: { icon: <CarSide16 data-testid="deployment-icon" />, text: "Deployment" },
  [YallaChangeKind.ConfigurationChange]: {
    icon: <Settings16 data-testid="configuration-icon" />,
    text: "Configuration change",
  },
};

export function changeKindDisplay(kind: YallaChangeKind) {
  return (
    kindToDisplayText[kind] ?? {
      icon: null,
      text: "Unknown change",
    }
  );
}

export function ChangeInfoItem(props: ChangeInfoItemProps) {
  const { description, date, commitUrl, kind } = props.change;
  const descriptionWithoutCommitHash = description?.replace(/\[.*?]\s*/, "");
  const { icon, text } = changeKindDisplay(kind);

  return (
    <ListGroupItem>
      <div>
        <div className="d-flex flex-column pt-5 pb-4 w-100">
          {commitUrl && (
            <Link href={commitUrl}>
              <span className="fw-light small-md mb-0 truncate-2-lines" data-testid="current-deployment-message">
                {descriptionWithoutCommitHash}
              </span>
            </Link>
          )}

          <div className="d-flex align-items-center mt-2 ">
            {icon}
            <p className="mb-0 small opacity-70 fw-light lh-1-5 x5-ms-1">{text}</p>
          </div>

          <p className="fw-light small opacity-70 mb-0 mt-2" data-testid="current-deployment-date">
            {formatMillisToDateTime(new Date(date).valueOf())}
          </p>
        </div>
      </div>
    </ListGroupItem>
  );
}
