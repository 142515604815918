import React, { createContext, useContext, useState } from "react";
import { GetAccelerateProductSupportGroupDetailsQuery } from "../../../../gql/graphql";
import { Product } from "../../../../types/ProfileTypes";

type RaiseAlertModalContextType = {
  isModalOpen: boolean;
  selectedGroup?: GetAccelerateProductSupportGroupDetailsQuery["product"]["supportGroups"][0];
  openModal: (group: GetAccelerateProductSupportGroupDetailsQuery["product"]["supportGroups"][0] | undefined) => void;
  closeModal: () => void;
  product?: Product;
  setProduct: (product: Product) => void;
};

const RaiseAlertModalContext = createContext<RaiseAlertModalContextType | undefined>(undefined);

export const RaiseAlertModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<
    GetAccelerateProductSupportGroupDetailsQuery["product"]["supportGroups"][0] | undefined
  >();
  const [product, setProduct] = useState<Product | undefined>();

  const openModal = (group?: GetAccelerateProductSupportGroupDetailsQuery["product"]["supportGroups"][0]) => {
    setSelectedGroup(group);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedGroup(undefined);
  };

  return (
    <RaiseAlertModalContext.Provider value={{ isModalOpen, selectedGroup, openModal, closeModal, product, setProduct }}>
      {children}
    </RaiseAlertModalContext.Provider>
  );
};

export const useRaiseAlertModal = () => {
  const context = useContext(RaiseAlertModalContext);
  if (!context) {
    throw new Error("useRaiseAlertModal must be used within a RaiseAlertModalProvider");
  }
  return context;
};
