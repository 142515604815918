"use client";
import { useState } from "react";
import { DeleteResourceModal } from "./delete-resource-modal";
import { EditResourceModal } from "./edit-resource-modal";
import { DropdownKebabMenu } from "../../../../../components/kebab-menu";
import { DropdownItem } from "reactstrap";
import { Edit24, Trash24 } from "@bphxd/ds-core-react/lib/icons";
import { trackEvent } from "../../../../../utils/event-tracker";
import { Product, LinkedResource } from "../../../../../types/ProfileTypes";

type ResourceMenuProps = {
  resource: LinkedResource;
  product: Product;
};

export function ResourceMenu({ resource, product }: ResourceMenuProps) {
  const [isDeleteResourceModalOpen, setIsDeleteResourceModalOpen] = useState(false);
  const [isEditResourceModalOpen, setIsEditResourceModalOpen] = useState(false);

  return (
    <>
      <DeleteResourceModal
        isModalOpen={isDeleteResourceModalOpen}
        toggle={() => {
          setIsDeleteResourceModalOpen(!isDeleteResourceModalOpen);
        }}
        resource={resource}
        product={product}
      />
      <EditResourceModal
        isModalOpen={isEditResourceModalOpen}
        toggle={() => {
          setIsEditResourceModalOpen(!isEditResourceModalOpen);
        }}
        resource={resource}
        product={product}
      />
      <DropdownKebabMenu
        trackingEventProps={{
          name: "button_key-resource-menu_click",
          productName: product.name,
        }}
        size="sm"
      >
        <DropdownItem
          onClick={() => {
            setIsEditResourceModalOpen(true);
            trackEvent({
              name: "button_key-resource-menu-edit",
              productName: product.name,
              resourceName: resource.description,
            });
          }}
        >
          <Edit24 className="me-2" /> Edit resource
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            setIsDeleteResourceModalOpen(true);
            trackEvent({
              name: "button_key-resource-menu-delte_click",
              productName: product.name,
              resourceName: resource.description,
            });
          }}
        >
          <Trash24 className="me-2" /> Delete resource
        </DropdownItem>
      </DropdownKebabMenu>
    </>
  );
}
