import { useUser } from "../../../hooks/use-user";

/**
 * Determines if the current logged-in user has privileged access to a given product.
 *
 * A privileged user is defined as:
 * - The product owner
 * - A tech lead
 * - A delegate (user listed as a delegate in the product)
 *
 * The function checks the user's email against the list of privileged users, ensuring
 * case-insensitive email comparison.
 *
 * @param {Product} product - The product object containing details about delegates, owner, and tech lead.
 *
 * @returns {boolean} - Returns true if the current user is a privileged user, false otherwise.
 */
export const useIsUserPrivileged = (product?: {
  ownerId?: string | null;
  techLeadId?: string | null;
  delegates?: Array<{
    __typename?: "User";
    email?: string | null;
  }> | null;
}): boolean => {
  const currentUser = useUser();

  if (!currentUser?.email) {
    return false;
  }

  const currentUserEmail = currentUser.email.toLowerCase();

  // Check if the current user is the owner, tech lead, or a delegate
  return (
    (product?.ownerId?.toLowerCase() === currentUserEmail ||
      product?.techLeadId?.toLowerCase() === currentUserEmail ||
      product?.delegates?.some((delegate) => delegate?.email?.toLowerCase() === currentUserEmail)) ??
    false
  );
};
