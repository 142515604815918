import { Breadcrumbs } from "../../../components/page-header";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { ProductDropdown } from "../../products/product-dropdown";
import { Link } from "../../../components/Link";

export function ProductBreadCrumbs({ breadcrumbs }: { breadcrumbs?: Breadcrumbs[] }) {
  return (
    <div className="pt-7">
      <Breadcrumb>
        <ProductDropdown />
        {breadcrumbs && breadcrumbs?.length >= 3 ? (
          breadcrumbs.slice(3)?.map(({ text, href }, index) => {
            const active = breadcrumbs?.slice(3).length - 1 === index && !href;
            return (
              <BreadcrumbItem key={index} active={active}>
                {active ? text : <Link href={href}>{text}</Link>}
              </BreadcrumbItem>
            );
          })
        ) : (
          <li className="breadcrumb-item" style={{ userSelect: "none" }}>
            &nbsp;
          </li>
        )}
      </Breadcrumb>
    </div>
  );
}
