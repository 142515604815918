export enum ToolNames {
  MixPanel = "MixPanel / Snowplow",
  AppSec = "AppSec platform",
  ArgoCD = "ArgoCD",
  AzureDevOps = "Azure DevOps",
  ArtifactoryJFrog = "Artifactory",
  Confluence = "Confluence",
  FeatureHub = "Feature Hub",
  Grafana = "Grafana Stack",
  Podman = "Podman",
  Playwright = "Playwright",
  VisualStudioProfessional = "Visual Studio Professional",
  VSCode = "VS Code",
  Yalla = "Yalla",
  JetBrainsIDEs = "JetBrains IDEs",
  DockerDesktop = "Docker Desktop",
  Slack = "Slack",
  SonarQube = "SonarQube",
  DBeaver = "DBeaver",
  Docker = "Docker",
  Terraform = "Terraform",
  Ansible = "Ansible Tower",
  AppCenter = "App Center",
  Jenkins = "Jenkins",
  Splunk = "Splunk",
  Contentful = "Contentful",
  DockerCLIForWSL = "Docker CLI for WSL",
  UseBruno = "Bruno",
  GrafanaOnCall = "Grafana OnCall",
}

export enum Labels {
  Strategic = "Strategic",
  ComingSoon = "ComingSoon",
  Supported = "Supported",
  Deprecated = "Deprecated",
}

export const LabelDisplayMap: Record<keyof typeof Labels, string> = {
  Strategic: "Strategic",
  ComingSoon: "Coming soon",
  Supported: "Supported",
  Deprecated: "Deprecated",
};

export type Tool = {
  icon: string;
  name: string;
  id: string;
  description: string;
  label: Labels;
};

export const toolsList: Tool[] = [
  {
    name: ToolNames.AppSec,
    id: "appsec-platform",
    icon: "/images/checkmarx.svg",
    description:
      "AppSec provides an integrated security service with seamless onboarding—triggering SAST, SCA, API, IaC, and DAST scans from a single SCM event for end-to-end code security",
    label: Labels.Strategic,
  },
  {
    name: ToolNames.ArtifactoryJFrog,
    id: "artifactory-jfrog",
    icon: "/images/artifactory.svg",
    description:
      "JFrog Artifactory is a universal artifact repository manager for managing software packages across the development lifecycle.",
    label: Labels.Strategic,
  },
  {
    name: ToolNames.AzureDevOps,
    id: "azure-devops",
    icon: "/images/azure.svg",
    description:
      "Azure DevOps (ADO) is the strategic digital platform for source code repos, version control and Agile Activity Management in bp.",
    label: Labels.Strategic,
  },
  {
    name: ToolNames.UseBruno,
    id: "use-bruno",
    icon: "/images/use-bruno.svg",
    description: "API Development tool for local development and testing.",
    label: Labels.Strategic,
  },
  {
    name: ToolNames.Confluence,
    id: "confluence",
    icon: "/images/confluence.svg",
    description:
      "Confluence is content collaboration software for bp Technology used for product, KDD and feature documentation.",
    label: Labels.Strategic,
  },
  {
    name: ToolNames.DBeaver,
    id: "dbeaver",
    icon: "/images/dbeaver.svg",
    description:
      "DBeaver is an open-source, multi-platform database tool that supports various databases through a JDBC driver.",
    label: Labels.Strategic,
  },
  {
    name: ToolNames.DockerCLIForWSL,
    id: "docker-cli-for-wsl",
    icon: "/images/docker.svg",
    description:
      "The Docker command-line interface (CLI) is a tool that lets users manage Docker containers and resources using the command line.",
    label: Labels.Strategic,
  },
  {
    name: ToolNames.FeatureHub,
    id: "feature-hub",
    icon: "/images/feature-hub.svg",
    description:
      "FeatureHub is an open-source feature flag, enabling teams to display features without needing to redeploy their applications.",
    label: Labels.Strategic,
  },
  {
    name: ToolNames.GrafanaOnCall,
    id: "grafana-oncall",
    icon: "/images/grafana.svg",
    description:
      "Grafana OnCall is an automated on-call management system that helps teams manage alerts, escalations, and incident response workflows efficiently.",
    label: Labels.Strategic,
  },
  {
    name: ToolNames.Grafana,
    id: "grafana",
    icon: "/images/grafana.svg",
    description:
      "Grafana Stack offers a full observability solution using log aggregation, tracing, metrics & visualisation.",
    label: Labels.Strategic,
  },
  {
    name: ToolNames.MixPanel,
    id: "mixpanel-snowplow",
    icon: "/images/mix-panel.svg",
    description:
      "Mixpanel is a business analytics service that tracks user interactions with web and mobile applications for targeted communication.",
    label: Labels.Strategic,
  },
  {
    name: ToolNames.Playwright,
    id: "playwright",
    icon: "/images/playwright.svg",
    description:
      "Playwright testing is a framework for automated end-to-end testing of web applications by simulating real user interactions and verifying expected outcomes.",
    label: Labels.Strategic,
  },
  {
    name: ToolNames.Podman,
    id: "podman",
    icon: "/images/podman.svg",
    description:
      "Podman is an open-source tool for managing containers on Linux systems. It's designed to make it easier to build, run, and share applications.",
    label: Labels.Strategic,
  },
  {
    name: ToolNames.SonarQube,
    id: "sonarqube",
    icon: "/images/sonar-qube.svg",
    description:
      "SonarQube is an open-source platform for continuous inspection of code quality, detecting bugs and vulnerabilities through static analysis.",
    label: Labels.Strategic,
  },
  {
    name: ToolNames.VisualStudioProfessional,
    id: "visual-studio-professional",
    icon: "/images/vs-subscriptions.svg",
    description: "Visual Studio profession is the paid visual studio subscription including editor.",
    label: Labels.Strategic,
  },
  {
    name: ToolNames.VSCode,
    id: "vs-code",
    icon: "/images/vs-code.svg",
    description:
      "VSCode is a free source-code editor by Microsoft for Windows, Linux, and macOS, supporting debugging, syntax highlighting, and extensions.",
    label: Labels.Strategic,
  },
  {
    name: ToolNames.Yalla,
    id: "yalla",
    icon: "/images/yalla.svg",
    description:
      "Yalla is the BP Internal developer platform including a standardised engineering ecosystem to deliver BP products.",
    label: Labels.Strategic,
  },
  {
    name: ToolNames.Contentful,
    id: "contentful",
    icon: "/images/contentful.svg",
    description:
      'Contentful CMS is a "headless" content management system (CMS) that allows users to create, manage, and distribute digital content across various platforms.',
    label: Labels.ComingSoon,
  },
  {
    name: ToolNames.JetBrainsIDEs,
    id: "jetbrains-ides",
    icon: "/images/jetbrains-ides.svg",
    description: "JetBrains IDEs are integrated development environments (IDEs) Supports Java, Python, Ruby, and PHP.",
    label: Labels.ComingSoon,
  },
  {
    name: ToolNames.Jenkins,
    id: "jenkins",
    icon: "/images/jenkins.svg",
    description:
      "Jenkins is an open-source automation server used for building, testing, and deploying software projects.",
    label: Labels.Supported,
  },
  {
    name: ToolNames.Splunk,
    id: "splunk",
    icon: "/images/splunk.svg",
    description:
      "Splunk is a software platform for searching, monitoring, and analyzing machine-generated data via a web-style interface.",
    label: Labels.Supported,
  },
  {
    name: ToolNames.Terraform,
    id: "terraform",
    icon: "/images/terraform.svg",
    description:
      "Terraform is an open-source infrastructure as code tool by HashiCorp, allowing users to define and provision infrastructure using a declarative configuration language.",
    label: Labels.Supported,
  },
  {
    name: ToolNames.Ansible,
    id: "ansible-tower",
    icon: "/images/ansible.svg",
    description:
      "Ansible Tower is the paid enterprise version of ansible including a web orchestration product to manage your ansible executions.",
    label: Labels.Deprecated,
  },
  {
    name: ToolNames.AppCenter,
    id: "app-center",
    icon: "/images/app-center.svg",
    description:
      "App Center is a cloud-based service for building, testing, distributing, and monitoring mobile applications.",
    label: Labels.Deprecated,
  },
  {
    name: ToolNames.DockerDesktop,
    id: "docker-desktop",
    icon: "/images/docker.svg",
    description:
      "Docker Desktop is a tool that helps developers build, run, and share containerized applications It's available for Mac, Linux, and Windows.",
    label: Labels.Deprecated,
  },
  {
    name: ToolNames.Slack,
    id: "slack",
    icon: "/images/slack.svg",
    description:
      "Slack is a chat-based team collaboration tool with channels for teams, direct message chats and plugin integrations with external apps.",
    label: Labels.Deprecated,
  },
].map((tool) => ({
  ...tool,
}));
