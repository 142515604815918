import { Col, Row } from "reactstrap";
import { Button } from "../../../../components/button";
import { Link } from "../../../../components/Link";
import { Product } from "../../../../types/ProfileTypes";
import { Question } from "../../../../gql/graphql";

type SetupMetricsProps = {
  product: Product;
  isLoading: boolean;
  onSetMetricsClick: () => void;
  totalNoOfMetrics: number;
  configuredMetrics?: Question[];
};

function formatArray(arr: string[] | undefined): string {
  if (!arr || arr.length === 0) {
    return "";
  }
  if (arr.length === 1) {
    return arr[0];
  }
  if (arr.length === 2) {
    return arr.join(` and `);
  }

  // For more than two items
  const lastItem = arr.pop();
  return `${arr.join(", ")} and ${lastItem}`;
}

export function SetupMetrics({
  product,
  isLoading,
  onSetMetricsClick,
  configuredMetrics,
  totalNoOfMetrics,
}: SetupMetricsProps) {
  const getUnSetMetricNames = (metrics: Question[]) => {
    const unSetMetricItems = metrics?.map((item) => item.metric!);
    return unSetMetricItems ? formatArray(unSetMetricItems) : [];
  };
  const areAllMetricsSet = configuredMetrics?.length === totalNoOfMetrics;
  if (areAllMetricsSet) {
    return null;
  }
  return (
    <Row>
      <Col xs="12" className="h-100 d-flex flex-column">
        <div className="bg-white border px-5 py-11 mb-6 rounded-5">
          <h5 className="fw-light x5-mx-8">Complete setting your product metrics</h5>

          {!configuredMetrics ? (
            <>
              <p className="fw-light x5-mx-8">
                Product metrics provide a clear view of your development processes and their impact on product
                performance, helping all teams understand where they are and identify areas for improvement.
              </p>

              <p className="fw-light x5-mx-8">
                At this time, we only track perception metrics, which are based on the product team’s assessment.
              </p>
            </>
          ) : (
            <>
              <p className="fw-light x5-mx-8">
                Your product has already set up {configuredMetrics?.length} out of 4 perception metrics, based on the
                product team's assessment. Please assess the {getUnSetMetricNames(configuredMetrics)} metrics.
              </p>{" "}
              <p className="fw-light x5-mx-8 pb-0 mb-5">Any member of the product team can complete this action.</p>
            </>
          )}

          <div className="d-flex">
            <Button
              data-testid="set-metrics"
              name="set-metrics"
              type="submit"
              level="tertiary"
              theme="standard"
              rounded="pill"
              size="lg"
              className="x5-mx-8"
              trackingEventProps={{
                productName: product?.name,
              }}
              loading={isLoading}
              onClick={onSetMetricsClick}
            >
              Set metrics
            </Button>
            <Link href="/knowledgehub/Yw7fLL" className="fs-6 fw-light">
              Learn about metrics
            </Link>
          </div>
        </div>
      </Col>
    </Row>
  );
}
