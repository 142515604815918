import { Dispatch, SetStateAction } from "react";
import { Accordion } from "@bphxd/ds-core-react";
import Input from "../Input";
import { LabelDisplayMap, Labels, Tool } from "./tools-list";

type AdoptionStatusFiltersProps = {
  toolsList: Tool[];
  adoptionStatusFilters: string[];
  setAdoptionStatusFilters: Dispatch<SetStateAction<string[]>>;
};

export function AdoptionStatusFilters({
  toolsList,
  adoptionStatusFilters,
  setAdoptionStatusFilters,
}: AdoptionStatusFiltersProps) {
  return (
    <Accordion flush size="md" square stayOpen tag="div" defaultOpen={"1"}>
      <Accordion.Item key={"1"}>
        <Accordion.Header targetId={"1"}>
          <div className="d-flex justify-content-between w-100">
            <p className="fw-normal small mb-0">Adoption status</p>
          </div>
        </Accordion.Header>
        <Accordion.Body accordionId={"1"}>
          {Object.values(Labels).map((option) => {
            const displayLabel = LabelDisplayMap[option];
            return (
              <div key={option} className="my-0 d-flex align-content-between gap-3 mt-3">
                <Input
                  type="checkbox"
                  id={`statuskey:${option}`}
                  name={option}
                  value={option}
                  onChange={() => {
                    setAdoptionStatusFilters((prev) =>
                      prev.includes(option) ? prev.filter((status) => status !== option) : [...prev, option],
                    );
                  }}
                  checked={adoptionStatusFilters.includes(option)}
                />

                <label
                  htmlFor={`statuskey:${option}`}
                  className="w-100 d-flex justify-content-between small-md fw-light"
                >
                  {displayLabel}
                </label>

                <span className="small-md fw-light opacity-90">
                  {toolsList &&
                    toolsList.filter((item) => {
                      return item.label === option;
                    }).length}
                </span>
              </div>
            );
          })}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
