import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Form, FormFeedback, FormText } from "reactstrap";
import Input from "../../../../components/Input";
import { FormGroup, Label } from "../../../../components/form-group";
import { FormHeading } from "../../../../components/form-heading";
import { ModalFooter } from "../../../../components/modal-footer";

import { Step2Data } from "./wizard";
import { InputErrorMessage } from "../../../../components/Input";

export type Step2FormProps = {
  onSubmit: (data: any) => Promise<void>;
  onBack: () => void;
  errors?: { message: string; extensions: string }[];
  editingExistingProduct: boolean;
} & Step2Data;

export function Step2Form({ onSubmit, onBack, errors, editingExistingProduct, ...data }: Step2FormProps) {
  const schemaObject = {
    name: yup
      .string()
      .trim("Cannot have leading or trailing white space.")
      .matches(/^[ a-zA-Z0-9&,\-().]*$/, "The product name must include only valid characters. Please try again.")
      .required("You must enter a product name."),
    description: yup
      .string()
      .trim("Cannot have leading or trailing white space.")
      .matches(/^[a-zA-Z0-9 \p{P}]*$/u, "Oops, you've entered an invalid character - please remove it.")
      .required("Please enter a description."),
  };
  const schema = yup.object(schemaObject).required();
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: data,
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)} data-testid="form-step-2">
      <FormHeading title="Details" currentStep={2} totalSteps={editingExistingProduct ? 2 : 3} />
      {errors?.length
        ? errors.map(({ message }, index) => <InputErrorMessage key={index}>{message}</InputErrorMessage>)
        : null}
      <FormGroup>
        <Label htmlFor="product_name">Name</Label>
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState: { error } }: any) => (
            <>
              <Input
                id="product_name"
                data-testid="product_name"
                type="text"
                placeholder="Enter a name..."
                {...field}
                invalid={!!error}
                makeRoomForError
              />
              <FormFeedback>{error?.message}</FormFeedback>
              <FormText>Your product name can only use a-z, A-Z, 0-9, &, -, (, ., ), white spaces and commas</FormText>
            </>
          )}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="description">Description</Label>
        <Controller
          name="description"
          control={control}
          render={({ field, fieldState: { error } }: any) => (
            <>
              <Input
                id="description"
                data-testid="description"
                type="textarea"
                placeholder="Tell us about your product"
                {...field}
                invalid={!!error}
                style={{ resize: "none" }}
                maxLength={300}
                makeRoomForError
              />
              <FormFeedback>{error?.message}</FormFeedback>
              <FormText>Write a short description to help people understand the purpose of this product.</FormText>
            </>
          )}
        />
      </FormGroup>
      <ModalFooter
        testIdContext="create-product-details"
        backButtonEventName="create-product-details"
        nextButtonEventName="create-product-details-step-2-submit"
        backButtonText="Back"
        showBackButton
        nextButtonText={editingExistingProduct ? "Edit" : "Next"}
        currentStep={2}
        totalSteps={editingExistingProduct ? 2 : 3}
        isNextButtonLoading={isSubmitting}
        isNextButtonDisabled={!isValid}
        handleBackClick={onBack}
      />
    </Form>
  );
}
