import { SVGProps } from "react";

export function Teams({ width = 24, height = 24 }: SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 11 10" xmlns="http://www.w3.org/2000/svg">
      <g fill="#111" fillRule="evenodd">
        <path d="M9.221 3.028a1.046 1.046 0 1 0 0-2.093 1.046 1.046 0 0 0 0 2.093M8.663 7.899c.067.008.136.013.205.013h.008c.897 0 1.624-.727 1.624-1.624V3.935a.441.441 0 0 0-.442-.442H8.799c.071.135.11.289.108.452v2.673c.01.454-.078.888-.244 1.281M6.116 3.016a1.512 1.512 0 1 0-1.66-1.418h.734c.512 0 .926.415.926.926v.492ZM3.5 7.714h1.69a.926.926 0 0 0 .926-.926V3.493h1.865a.437.437 0 0 1 .426.447v2.684A2.623 2.623 0 0 1 5.85 9.307a2.624 2.624 0 0 1-2.35-1.593" />
        <path d="M1.902 3.838h.875v2.25h.5v-2.25h.875v-.5h-2.25v.5Zm3.288-1.74H.926a.426.426 0 0 0-.426.426v4.264c0 .235.19.426.426.426H5.19a.426.426 0 0 0 .426-.426V2.524a.426.426 0 0 0-.426-.426Z" />
      </g>
    </svg>
  );
}
