import { Link } from "../Link";

export enum Metric {
  DeployFrequency = "Deploy frequency",
  CloudSpend = "Estimate cloud spend",
  LeadTime = "Lead time",
  ChangeFailPercentage = "Change fail percentage",
  TimeToRestore = "Time to restore",
  CoderPercentage = "Coder percentage",
}

export const empiricalMetricTooltipTexts: Record<Metric | string, React.ReactNode> = {
  [Metric.DeployFrequency]: (
    <>
      <p>Optimal: On demand</p>
      <p>Regular deployments can reflect a rhythm of continuous delivery and quick adaptation.</p>
      <p>
        Infrequent deployments can indicate manual interventions, potential bottlenecks, or hesitation due to
        uncertainty in the process.
      </p>
      <Link href="/knowledgehub/Yw7fLL">Learn more about metrics</Link>
    </>
  ),
  [Metric.LeadTime]: (
    <>
      <p>Optimal: &lt;1hr</p>
      <p>Quick transitions from code commit to deployment demonstrate a well- coordinated CI/CD pipeline.</p>
      <p>
        Prolonged lead times might signify delays in validation, lack of automation, or challenges in environment
        setups.
      </p>
      <Link href="/knowledgehub/Yw7fLL">Learn more about metrics</Link>
    </>
  ),
};

export const metricTooltipTexts: Record<Metric | string, React.ReactNode> = {
  [Metric.DeployFrequency]: (
    <>
      <p>Optimal: On demand</p>
      <p>Regular deployments can reflect a rhythm of continuous delivery and quick adaptation.</p>
      <p>
        Infrequent deployments can indicate manual interventions, potential bottlenecks, or hesitation due to
        uncertainty in the process.
      </p>
      <p>
        Note that the data shown is based on answers provided to questionnaires. A future version is planned that will
        use empirical data to provide deeper insight.
      </p>
      <Link href="/knowledgehub/Yw7fLL">Learn more about metrics</Link>
    </>
  ),
  [Metric.LeadTime]: (
    <>
      <p>Optimal: &lt;1hr</p>
      <p>Quick transitions from code commit to deployment demonstrate a well- coordinated CI/CD pipeline.</p>
      <p>
        Prolonged lead times might signify delays in validation, lack of automation, or challenges in environment
        setups.
      </p>
      <p>
        Note that the data shown is based on answers provided to questionnaires. A future version is planned that will
        use empirical data to provide deeper insight.
      </p>
      <Link href="/knowledgehub/Yw7fLL">Learn more about metrics</Link>
    </>
  ),
  [Metric.ChangeFailPercentage]: (
    <>
      <p>Optimal: 0-15%</p>
      <p>A low change fail percentage is evidence of rigorous testing and a refined quality assurance process.</p>
      <p>A high percentage may hint at overlooked flaws or inadequate pre-deployment evaluations.</p>
      <p>
        Note that the data shown is based on answers provided to questionnaires. A future version is planned that will
        use empirical data to provide deeper insight.
      </p>
      <Link href="/knowledgehub/Yw7fLL">Learn more about metrics</Link>
    </>
  ),
  [Metric.TimeToRestore]: (
    <>
      <p>Optimal: &lt;1hr</p>
      <p>Rapid restore times after setbacks showcase a proactive incident strategy and robust system architecture.</p>
      <p>
        Delays in regaining functionality can stem from a reactive approach, or perhaps gaps in understanding system
        interdependencies.
      </p>
      <p>
        Note that the data shown is based on answers provided to questionnaires. A future version is planned that will
        use empirical data to provide deeper insight.
      </p>
      <Link href="/knowledgehub/Yw7fLL">Learn more about metrics</Link>
    </>
  ),
  [Metric.CoderPercentage]: (
    <>
      <p>Optimal at bp: 70%</p>
      <p>
        The coder percentage target is that anyone in a qualifying role (such as software and platform engineer) merge
        at least one PR into main per week, for 70% of working weeks.
      </p>
      <p>
        A figure that is below this 70% target may indicate there are blockers to effective coding that need to be
        addressed.
      </p>
      <Link href="/knowledgehub/Yw7fLL">Learn more about metrics</Link>
    </>
  ),
  [Metric.CloudSpend]: (
    <>
      <p>
        Cloud spend aligning closely with projections can highlight precise resource allocation and diligent management.
      </p>
      <p>
        Spending overshoots suggest either underestimation of needs, suboptimal utilisation, or overlooked idle
        resources.
      </p>
      <p>
        The cloud spend metric is sourced from Cloudability, which provides an estimate of the current month spend for
        the level 3 entity in ServiceNow that the product is linked to. It also provides an indication of how this
        compares to the previous month.
      </p>
      <p>Don't see your data here?</p>
      <p>
        This may be because your product in Accelerate is not linked to the Level 3 entity in ServiceNow that contains
        the product's financial data. The product owner, tech lead or an operational delegate can edit the product and
        change the ServiceNow entity there.
      </p>
      <Link href="/knowledgehub/Yw7fLL">Learn more about metrics</Link>
    </>
  ),
};
export const metricRange: Record<Metric | string, { start: string; end: string }> = {
  [Metric.DeployFrequency]: { start: "<1 every 6 months", end: "On demand" },
  [Metric.LeadTime]: { start: ">6 months", end: "<1 hour" },
  [Metric.ChangeFailPercentage]: {
    start: "76-100%",
    end: "0-15%",
  },
  [Metric.TimeToRestore]: { start: ">6 months", end: "<1 hour" },
};
