import { Progress } from "reactstrap";
import { PropsWithChildren } from "react";
import { TriangleRight24 } from "@bphxd/ds-core-react/lib/icons/index";

enum LeadTimeTrend {
  OverSixMonths = "Over six months",
  OneToSixMonths = "One to six months",
  OneWeekToOneMonth = "One week to one month",
  OneDayToOneWeek = "One day to one week",
  LessThanOneDay = "Less than one day",
  LessThanOneHour = "Less than one hour",
}
export function calculateLeadTimeArrowPosition(leadTimeHours: number): {
  arrowPosition: number;
  leadTimeTrend: string;
} {
  let arrowPosition = 0;
  let leadTimeTrend = LeadTimeTrend.LessThanOneHour;
  if (leadTimeHours > 24 * 30 * 6) {
    arrowPosition = 0;
    leadTimeTrend = LeadTimeTrend.OverSixMonths;
  } else if (leadTimeHours > 24 * 30 && leadTimeHours <= 24 * 30 * 6) {
    arrowPosition = 1;
    leadTimeTrend = LeadTimeTrend.OneToSixMonths;
  } else if (leadTimeHours > 24 * 7 && leadTimeHours <= 24 * 30) {
    arrowPosition = 2;
    leadTimeTrend = LeadTimeTrend.OneWeekToOneMonth;
  } else if (leadTimeHours > 24 && leadTimeHours <= 24 * 7) {
    arrowPosition = 3;
    leadTimeTrend = LeadTimeTrend.OneDayToOneWeek;
  } else if (leadTimeHours > 1 && leadTimeHours <= 24) {
    arrowPosition = 4;
    leadTimeTrend = LeadTimeTrend.LessThanOneDay;
  } else if (leadTimeHours < 1) {
    arrowPosition = 5;
    leadTimeTrend = LeadTimeTrend.LessThanOneHour;
  }

  return { arrowPosition, leadTimeTrend };
}
enum DeployFrequency {
  LessThanSixMonths = "Less than once every six months",
  OneToSixMonths = "Between once a month and once every six months",
  OneWeekToOneMonth = "Between once a week and once a month",
  OneDayToOneWeek = "Between once a day and once a week",
  LessThanOneDay = "Between once an hour and once a day",
  MoreThanOnceAnHour = "On demand (multiple deploys a day)",
}

export function calculateDeployFrequencyArrowPosition(deploymentFrequencyPerMonth: number): {
  arrowPosition: number;
  deploymentFrequency: string;
} {
  let arrowPosition = 0;
  let deploymentFrequency = DeployFrequency.MoreThanOnceAnHour;

  if (deploymentFrequencyPerMonth < 0.5) {
    arrowPosition = 0;
    deploymentFrequency = DeployFrequency.LessThanSixMonths;
  } else if (deploymentFrequencyPerMonth >= 0.5 && deploymentFrequencyPerMonth < 1) {
    arrowPosition = 1;
    deploymentFrequency = DeployFrequency.OneToSixMonths;
  } else if (deploymentFrequencyPerMonth >= 1 && deploymentFrequencyPerMonth < 4) {
    arrowPosition = 2;
    deploymentFrequency = DeployFrequency.OneWeekToOneMonth;
  } else if (deploymentFrequencyPerMonth >= 4 && deploymentFrequencyPerMonth < 30) {
    arrowPosition = 3;
    deploymentFrequency = DeployFrequency.OneDayToOneWeek;
  } else if (deploymentFrequencyPerMonth >= 30 && deploymentFrequencyPerMonth < 60) {
    arrowPosition = 4;
    deploymentFrequency = DeployFrequency.LessThanOneDay;
  } else if (deploymentFrequencyPerMonth > 60) {
    arrowPosition = 5;
    deploymentFrequency = DeployFrequency.MoreThanOnceAnHour;
  }

  return { arrowPosition, deploymentFrequency };
}

export const METRIC_PROGRESS_BAR_COLORS = [
  {
    color: "bg-danger-light",
  },
  {
    color: "bg-danger-light",
  },
  {
    color: "bg-bp-orange-900",
  },
  {
    color: "bg-bp-orange-900",
  },
  {
    color: "bg-success-light",
  },
  {
    color: "bg-success-light",
  },
];

type ProgressBarConfig = {
  color: string;
};
type IndicatorProgressBarsProps = PropsWithChildren<{
  arrowPosition: number;
  progressBars: ProgressBarConfig[]; // Added progressBars to the component props
}>;

export const IndicatorProgressBars = ({ arrowPosition, children, progressBars }: IndicatorProgressBarsProps) => {
  return (
    <div>
      <Progress className="my-2 bg-white" multi>
        {progressBars.map((bar, index) => (
          <Progress
            bar
            color={bar.color}
            value={100 / progressBars.length}
            className={bar.color}
            style={{
              marginRight: index !== progressBars.length - 1 ? "2%" : "",
            }}
            key={index}
          >
            {index === arrowPosition && (
              <TriangleRight24
                className="align-self-center mt-n5 position-absolute"
                style={{
                  color: "#111",
                  rotate: "90deg",
                }}
              />
            )}
          </Progress>
        ))}
      </Progress>
      {children}
    </div>
  );
};
