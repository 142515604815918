import { Info24 } from "@bphxd/ds-core-react/lib/icons/index";
import { Popover } from "../tooltip/popover";
import { PropsWithChildren } from "react";

type InfoCardProps = PropsWithChildren<{
  id: string;
  title: string;
  subTitle?: string;
  score?: number;
  toolTipTitle: string;
  toolTipText: string;
  className?: string;
}>;

export function InfoCard({ className, id, title, subTitle, toolTipTitle, toolTipText, children }: InfoCardProps) {
  return (
    <div className={`bg-secondary py-4 px-6 rounded-5 ${className}`}>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column">
          <h5 className="mb-0">{title}</h5>
          <p className="small fw-light opacity-70 mb-0" data-testid={id}>
            {subTitle}
          </p>
        </div>

        <div id={`${id}-tooltip`} data-testid={`${id}-tooltip`} className="cursor-pointer">
          <Info24 className="opacity-60" name={`${id}-tooltip`} type="button" tabIndex={0} />
          <Popover
            target={`${id}-tooltip`}
            testId={`${id}-tooltip-text`}
            title={toolTipTitle}
            placement="bottom"
            trigger="focus"
            toolTipText={toolTipText}
          />
        </div>
      </div>
      <div className="d-flex align-items-baseline justify-content-between">{children}</div>
    </div>
  );
}
