"use client";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useEffect, useState } from "react";
import { Modal } from "../../../../components/modal";
import { Form } from "reactstrap";
import { FormGroup, Label } from "../../../../components/form-group";
import { ModalFooter } from "../../../../components/modal-footer";

import TeamMembersDropdown, { UserOption } from "./team-members-dropdown";
import { msGraphQuery } from "../../../../api/platform-api";
import { useQuery } from "react-query";
import { useDebounce } from "../../../../hooks/use-debounce";

export type TeamAddMembersModalProps = {
  onSubmit: (formData: UserOption[]) => void;
  onClose: () => void;
};

const SEARCH_DEBOUNCE_DELAY = 150;

export function TeamAddMembersModal({ onSubmit, onClose }: TeamAddMembersModalProps) {
  const [lookUpTeamMembers, setLookUpTeamMembers] = useState([] as UserOption[]);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([] as UserOption[]);

  const [searchMembers, setSearchMembers] = useState("");

  const debouncedSearch = useDebounce(searchMembers, SEARCH_DEBOUNCE_DELAY);
  const { isLoading, data: membersResults } = useQuery(["msGraphSearchResults", debouncedSearch], async () => {
    try {
      if (!searchMembers) {
        return;
      }
      return await msGraphQuery({
        spanName: "Users search",
        query: "search/query",
        method: "POST",
        spanAttributes: {},
        headers: {
          ConsistencyLevel: "eventual",
        },
        payload: {
          requests: [
            {
              entityTypes: ["person"],
              query: {
                queryString: searchMembers,
              },
            },
          ],
        },
      });
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      }
    }
  });

  useEffect(() => {
    if (membersResults && !isLoading && membersResults?.ok) {
      const members = membersResults.body.value[0].hitsContainers[0].hits;

      if (members) {
        members.slice(0, 3).forEach((member: UserOption) => {
          member.value = member.resource.displayName;
          member.label = `${member.resource.givenName} ${member.resource.surname} ${member.resource.userPrincipalName}`;
        });
        // remove already selected members
        const filtered = members.filter((member: UserOption) => {
          return !selectedTeamMembers.some((selected) => {
            return selected.value === member.value;
          });
        });
        setLookUpTeamMembers(filtered.slice(0, 3));
      }
    }
  }, [membersResults, isLoading]);

  const schemaObject = {
    members: yup.array().min(1, "Please specify at least one team member"),
  };

  const schema = yup.object(schemaObject).required();

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleSearchInput = (newValue: string) => {
    if (newValue?.length > 2) {
      setSearchMembers(newValue);
    }
  };

  const handleFormSubmit = () => {
    clearErrors();
    onSubmit(selectedTeamMembers);
    onClose();
    setSelectedTeamMembers([] as UserOption[]);
  };

  const handleOnChange = (newValues: UserOption[]) => {
    setLookUpTeamMembers([]);
    setSelectedTeamMembers(newValues);
  };

  return (
    <Modal size={"lg"} backdrop="static" header="Add team members" hasCancelDialog isOpen onClose={onClose}>
      <Form
        onSubmit={handleSubmit(handleFormSubmit)}
        data-testid="create-team-form-step-1"
        style={{
          minHeight: 250,
        }}
      >
        <FormGroup>
          <Label htmlFor="areaPath">Add as many team members as you like</Label>
          <Controller
            name="members"
            control={control}
            render={({ field, fieldState: { error } }: any) => (
              <>
                <TeamMembersDropdown
                  isMulti
                  placeholder="Enter an email address"
                  {...field}
                  error={error?.message}
                  invalid={!!error}
                  onChange={handleOnChange}
                  onInputChange={handleSearchInput}
                  options={lookUpTeamMembers}
                />
              </>
            )}
          />
        </FormGroup>

        <ModalFooter
          testIdContext="add-team-members-form-button"
          nextButtonText="Add"
          isNextButtonLoading={isSubmitting}
          isNextButtonDisabled={!isValid}
        />
      </Form>
    </Modal>
  );
}
