"use client";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Alert, Form } from "reactstrap";
import { Info24 } from "@bphxd/ds-core-react/lib/icons";
import Input from "../../../../components/Input";
import { ModalFooter } from "../../../../components/modal-footer";
import { ModalHeader } from "../../../../components/modal-header";
import { ChangeIndicator } from "../../../../components/change-indicator";

import { AssessmentQuizProps } from "./assessment-quiz";
import { Option, Question } from "../../../../gql/graphql";
import { useUser } from "../../../../hooks/use-user";

type FormData = Record<string, number | null>;

type AssessmentFormProps = AssessmentQuizProps & {
  defaultValues: FormData | undefined;
};

export function AssessmentForm(props: AssessmentFormProps) {
  const { product, PostForm, postFormLoading, defaultValues, quiz, refetchCompleted } = props;
  const userProfile = useUser();
  const title = quiz?.name;
  const schema = yup.object(
    quiz?.questions.reduce<Record<number, yup.NumberSchema>>((acc, item) => {
      acc[item.id] = yup.number().oneOf(
        item.options.map(({ id }) => id),
        `Please select an option for "${item.question}"`,
      );
      return acc;
    }, {}),
  );
  const { control, handleSubmit, setValue } = useForm<FormData>({
    mode: "all",
    resolver: yupResolver(schema as any),
    defaultValues,
  });

  const onSubmit = async (formData: Record<number, null | number>) => {
    // Construct the answers array using question IDs from quizStructure
    const answers = quiz?.questions?.map((question) => {
      const answerValue = formData[question.id];
      return {
        question_id: question.id,
        option_id: answerValue,
      };
    });

    await PostForm({
      variables: {
        data: {
          productId: product.id,
          quizSlug: quiz?.slug,
          answers,
          submittedBy: userProfile?.email,
        },
      },
    });
  };

  useEffect(() => {
    if (defaultValues) {
      Object.entries(defaultValues as object).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [defaultValues]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} data-testid="single-quiz">
      <ModalHeader header={`${title} assessment`} />

      {quiz?.isRecentlyUpdated && (
        <Alert color="info" className="mt-n4">
          <Info24 /> Review your answers to the questions highlighted below.
        </Alert>
      )}
      <div className="flex-row-reverse d-flex justify-content-start">
        <ul className="text-center list-unstyled d-flex w-50">
          {quiz?.questions?.[0]?.options?.map(({ label }: any) => (
            <li key={label} className="w-100">
              {label}
            </li>
          ))}
        </ul>
      </div>
      {quiz?.questions?.map(({ question, id, options, isRecentlyUpdated }: Question, index) => (
        <div key={id} className="d-flex">
          <div className="align-self-center me-3" style={{ width: 24 }}>
            {isRecentlyUpdated && <ChangeIndicator text="Recently changed" targetId={`popover-quiz-${id}`} />}
          </div>
          <div className={`d-flex py-6 ${index === 0 ? "border-top" : ""} border-bottom border-dark w-100`}>
            <div className="w-50">{question}</div>
            <ul className="p-0 m-0 d-flex align-items-center w-50">
              {options.map(({ id: optionId }: Option) => (
                <li key={optionId} className={`list-unstyled text-center w-100`}>
                  <Controller
                    name={String(id)}
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="radio"
                        value={optionId}
                        checked={field.value == optionId}
                        data-testid={`quiz-answer-${id}-${optionId}`}
                      />
                    )}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}

      <ModalFooter
        testIdContext={`${title}-quiz`}
        nextButtonEventName={`${title}-quiz-submit`}
        nextButtonText={"Submit"}
        isNextButtonLoading={postFormLoading || !refetchCompleted}
      />
    </Form>
  );
}
