"use client";
import { useState } from "react";
import { Accordion, Progress, Badge } from "@bphxd/ds-core-react";
import { Check16, Down24, Up24 } from "@bphxd/ds-core-react/lib/icons";
import { AddReposToProductModal } from "./repos/add-modal";
import { PRODUCT_AREA_RESOURCE_DESCRIPTION } from "./resources/product-linked-resources";
import { Button } from "../../../components/button";
import { Collapse } from "reactstrap";
import { Link } from "../../../components/Link";
import { ProductResponse, QuizResult } from "../../../gql/graphql";
import { areOpsGroupsPresent } from "./support-groups/utils";
import { Product } from "../../../types/ProfileTypes";
import { useIsUserPrivileged } from "./utils";
import { useSetupSupportGroupModal } from "./support-groups/support-group-provider";

export function SetupProduct({
  product,
  metricResults,
  onAddDescriptionClick,
  onAddLinkedResourceClick,
  onSetMetricsClick,
}: {
  product: ProductResponse;
  metricResults?: QuizResult;
  onAddDescriptionClick: () => void;
  onAddLinkedResourceClick: () => void;
  onSetMetricsClick: () => void;
}) {
  const userHasPermissions = useIsUserPrivileged(product);
  const { openModal } = useSetupSupportGroupModal();
  const [isAddReposToProductModalOpen, setIsAddReposToProductModalOpen] = useState(false);

  const containsLinkedResources = product.linkedResources?.some((resource) => {
    return resource.description !== PRODUCT_AREA_RESOURCE_DESCRIPTION;
  });

  const productSupport = [
    {
      title: "Set up Accelerate Support Group",
      completed: !!product?.supportGroups?.length,
      body: (
        <>
          {!areOpsGroupsPresent(product) ? (
            <p className="fw-light fs-6">
              By setting a support group you'll be able to set schedules that ensure the right person is always
              available to respond quickly to incidents and outages related to your product.
            </p>
          ) : (
            <>
              <p className="fw-light fs-6">
                Onboard {product.name} to On-Call and set up your support groups. Once this is done, you will be able to
                set a schedule that ensures the right person is always available to respond quickly to incidents and
                outages related to your product.
              </p>
              {userHasPermissions && (
                <Button
                  level="tertiary"
                  theme="standard"
                  rounded="pill"
                  size="sm"
                  name="setup-product"
                  trackingEventProps={{
                    productName: product?.name,
                    step: "set up support group",
                  }}
                  onClick={() => openModal(product)}
                >
                  {!product?.supportGroups?.length ? "Set up" : "Add new"} support group
                </Button>
              )}
            </>
          )}
        </>
      ),
    },
  ];

  const noSuitableRepos =
    product?.metrics?.repositoryIds?.length && !product?.metrics?.leadTime && !product?.metrics?.deploymentFrequency;
  const steps = [
    {
      title: "Add a product description",
      body: (
        <>
          <p className="fw-light fs-6">
            A description will help visitors understand what your product does and why it's important.
          </p>
          <Button
            level="tertiary"
            theme="standard"
            rounded="pill"
            size="sm"
            name="setup-product"
            trackingEventProps={{
              productName: product?.name,
              step: "add product description",
            }}
            onClick={onAddDescriptionClick}
          >
            {product.description ? "Edit description" : "Add description"}
          </Button>
        </>
      ),
      completed: !!product.description,
    },
    {
      title: "Add a resource link",
      body: (
        <>
          <p className="fw-light fs-6">
            Linking resources to your product will help your team and visitors find the information they need.
          </p>
          <Button
            level="tertiary"
            theme="standard"
            rounded="pill"
            size="sm"
            name="setup-product"
            trackingEventProps={{
              productName: product?.name,
              step: "add resource link",
            }}
            onClick={onAddLinkedResourceClick}
          >
            Add linked resource
          </Button>
        </>
      ),
      completed: containsLinkedResources,
    },
    {
      title: "Set your product metrics",
      body: (
        <>
          <p className="fw-light fs-6">
            Product metrics provide a clear view of your development processes and their impact on product performance,
            helping all teams understand where they are and identify areas for improvement.
          </p>
          <Button
            level="tertiary"
            theme="standard"
            rounded="pill"
            size="sm"
            name="setup-product"
            trackingEventProps={{
              productName: product?.name,
              step: "set metrics",
            }}
            onClick={onSetMetricsClick}
          >
            {metricResults ? "Update values" : "Set metrics"}
          </Button>
        </>
      ),
      completed: !!metricResults,
      requiresAttention: !metricResults,
    },
    {
      title: "Connect repos",
      body: (
        <>
          {noSuitableRepos ? (
            <p className="fw-light small">
              We could not identify any suitable pipelines. This may be because you are using classic pipelines instead
              of YAML or you don’t have a stage called “prod” in your pipelines.
              <br />
              <Link href="/knowledgehub/5mA8xV" hideExternalLinkIcon>
                Find out how we've linked the pipelines and how to make changes.
              </Link>
            </p>
          ) : (
            <p className="fw-light fs-6">
              Connecting your repositories enables easier code collaboration, exploration and quick access to technical
              solutions and automations through Axel.
            </p>
          )}
          {userHasPermissions && (
            <Button
              level="tertiary"
              theme="standard"
              rounded="pill"
              size="sm"
              name="setup-product"
              trackingEventProps={{
                productName: product.name,
                step: "connect repos",
              }}
              onClick={() => setIsAddReposToProductModalOpen(true)}
            >
              {product?.metrics?.repositoryIds?.length ? "Update repos" : "Connect repos"}
            </Button>
          )}
        </>
      ),
      completed: product?.metrics?.repositoryIds?.length ?? false,
      requiresAttention: noSuitableRepos,
    },
    ...productSupport,
  ];
  const completedSteps = steps.reduce((acc, curr) => {
    return acc + (curr.completed ? 1 : 0);
  }, 0);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="pt-5 pb-4 mb-6 bg-white border px-5 rounded-5">
        <Button
          name="setup-guide-accordion"
          className="px-0 w-100 d-flex full-opacity-on-hover align-items-center border-0"
          level="tertiary"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="d-flex w-100 justify-content-between align-items-center">
            <h5 className="mb-0 fw-light">Setup guide</h5>
            {!isOpen && (
              <p className="fw-light small x5-me-4 mb-0">
                {completedSteps} of {steps.length} steps complete
              </p>
            )}
          </div>
          {isOpen ? <Up24 /> : <Down24 />}
        </Button>
        <Collapse isOpen={isOpen}>
          <div className="w-100 mt-7 mb-4">
            <Progress color="dark" value={(completedSteps / steps.length) * 100} />
            {isOpen && (
              <p className="fw-light small mt-3">
                {completedSteps} of {steps.length} steps complete
              </p>
            )}
          </div>
          <Accordion
            defaultOpen={steps.map(({ completed }, index) => (!completed ? String(index + 1) : ""))}
            flush
            size="md"
            square
            stayOpen
          >
            {steps.map(({ title, body, completed, requiresAttention }, index) => {
              return (
                <Accordion.Item key={index}>
                  <Accordion.Header targetId={String(index + 1)}>
                    <div className="d-flex justify-content-between w-100 align-items-center">
                      <div className="d-flex">
                        {completed ? (
                          <div
                            className="rounded-4 d-flex"
                            style={{
                              backgroundColor: "#15ad5c",
                              width: 16,
                              height: 16,
                            }}
                          >
                            <Check16 className="opacity-100" color="white" />
                          </div>
                        ) : (
                          <div
                            className="opacity-100 rounded-4"
                            style={{
                              width: 16,
                              height: 16,
                              backgroundColor: "#ededed",
                            }}
                          />
                        )}
                        <span className="ms-4">{title}</span>
                      </div>
                      {requiresAttention ? (
                        <div>
                          <Badge color="warning-subtle" className="text-uppercase fw-normal">
                            Requires attention
                          </Badge>
                        </div>
                      ) : null}
                    </div>
                  </Accordion.Header>
                  <Accordion.Body accordionId={String(index + 1)} className="ps-8">
                    {body}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </Collapse>
      </div>
      <AddReposToProductModal
        product={product as Product}
        isOpen={isAddReposToProductModalOpen}
        toggle={() => setIsAddReposToProductModalOpen(false)}
        onSetMetricsClick={onSetMetricsClick}
      />
    </>
  );
}
