import { ListGroup } from "../../../../components/list-group";
import { Link } from "../../../../components/Link";
import { ExternalLink16 } from "@bphxd/ds-core-react/lib/icons/index";
import { Button } from "../../../../components/button";
import { areOpsGroupsPresent } from "./utils";
import { useIsUserPrivileged } from "../utils";
import { useSetupSupportGroupModal } from "./support-group-provider";
import { GetAccelerateProductSupportGroupDetailsQuery } from "../../../../gql/graphql";

type SetSupportGroupsProps = {
  product: GetAccelerateProductSupportGroupDetailsQuery["product"];
  isAlertsTab?: boolean;
};

export function SetSupportGroups({ product, isAlertsTab }: SetSupportGroupsProps) {
  const userHasPermissions = useIsUserPrivileged(product);
  const isOpsGroupPresent = areOpsGroupsPresent(product);
  const { openModal } = useSetupSupportGroupModal();
  return (
    <div className={`${isAlertsTab ? "w-100" : "w-70"} `}>
      <ListGroup>
        <div className={`d-flex justify-content-between ${isAlertsTab ? "py-5" : "py-11 px-xl-14"}`}>
          <div className="d-flex flex-column">
            <div>
              <div className="d-flex flex-column">
                {!isOpsGroupPresent ? (
                  <>
                    <h5 className="fw-light">
                      <b>{product.name}</b> has not set up any support groups yet.
                    </h5>
                    <p className="small fw-light">
                      By setting a support group you'll be able to set schedules that ensure the right person is always
                      available to respond quickly to incidents and outages related to your product.
                    </p>
                    <p className="small fw-light">
                      To set up a support group, you will need to have an Ops group defined first.
                    </p>
                    <Link
                      className="fw-light small mt-3"
                      name="set-up-ops-groups-in-service-now"
                      hideExternalLinkIcon={true}
                      href="https://bp.service-now.com/myservicehub"
                    >
                      Set Ops Groups in ServiceNow
                      <ExternalLink16 />
                    </Link>
                    <Link
                      className="fw-light small mt-3"
                      name="more-info-about-ops-groups"
                      hideExternalLinkIcon={true}
                      href="/knowledgehub/gWn9Zj"
                    >
                      More information about Ops groups
                    </Link>
                  </>
                ) : (
                  <div>
                    <h5 className="fw-light">
                      <b>{product.name}</b> has not set up any support groups yet.
                    </h5>
                    <p className="small fw-light">
                      Once support groups are set up, you’ll be able to raise alerts for this product, see who is on
                      call, and find any current alerts and incidents here.
                    </p>
                    <p className="small fw-light">
                      Only Product Managers, Tech Leads, and Operational Delegates can set up support groups.
                    </p>
                    {userHasPermissions && (
                      <Button
                        level="tertiary"
                        theme="standard"
                        rounded="pill"
                        size="sm"
                        name="setup-product"
                        trackingEventProps={{
                          productName: product?.name,
                          step: "set up support group",
                        }}
                        onClick={() => {
                          openModal(product);
                        }}
                      >
                        Set up support group
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </ListGroup>
    </div>
  );
}
