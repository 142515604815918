import { Col, Row } from "reactstrap";
import { CommonlyAskedQuestions } from "../../../../views/products/commonly-asked-questions";
import { Link } from "../../../../components/Link";
import { LinkButton } from "../../../../components/link-button";
import { PageSection } from "../../../../components/page-template";
import { useLocation } from "../../../../hooks/use-location";
import { Right24 } from "@bphxd/ds-core-react/lib/icons";
import { useProductDropdownContext } from "../../../../providers/product-dropdown-provider";

export enum ToolNames {
  ArgoCD = "Argo CD",
  FeatureHub = "FeatureHub",
  Grafana = "Grafana",
}

const toolsList = [
  {
    id: "1",
    name: ToolNames.ArgoCD,
    icon: (
      <img src="/images/argo-cd-logo.svg" alt="Argo CD" className="x5-me-2" style={{ width: "40px", height: "40px" }} />
    ),
    description:
      "Access resources for Argo CD, a declarative, GitOps continuous delivery tool used for managing deployments across environments.",
    link: "Argo CD Dashboard",
    linkSrc: "https://argocd.yalla.bpglobal.com/",
  },
  {
    id: "2",
    name: ToolNames.FeatureHub,
    icon: (
      <img
        src="/images/feature-hub-logo.svg"
        alt="feature hub"
        className="x5-me-2"
        style={{ width: "40px", height: "40px" }}
      />
    ),

    description:
      "View resources for FeatureHub, a feature flagging platform used for effective feature rollout across your product's environments.",
    link: "Feature Hub Dashboard",
    linkSrc: "https://mr.featurehub.bpweb.bp.com/dashboard",
  },
  {
    id: "3",
    name: ToolNames.Grafana,
    icon: (
      <img src="/images/grafana-logo.svg" alt="grafana" className="x5-me-2" style={{ width: "40px", height: "40px" }} />
    ),
    description:
      "Find important links and resources for Grafana, a tool for developing interactive dashboards, data visualizations, and alerts for your product.",
    link: "Grafana Prod Dashboard",
    linkSrc: "https://grafana.bpweb.bp.com/",
  },
];

const questionAnswers = [
  {
    question: "Why are some of my tools not here?",
    answer: (
      <>
        <p className="fw-regular small-md">
          Only strategic tools (tools available in Yalla) will be visible on this page.
        </p>
        <p className="fw-regular small-md">
          <Link href="/knowledgehub/6YJdWS" name="yalla-tools">
            Yalla docs
          </Link>
        </p>
      </>
    ),
  },
  {
    question: "Can I use tools that are not on this list?",
    answer: (
      <>
        <p className="fw-regular small-md">
          You can access additional tools from the Tools Hub and find further information on bp TRM.
        </p>
        <Link href="https://trm.bpglobal.com/" name="bp-trm" className="fw-regular small-md">
          bp TRM
        </Link>
      </>
    ),
  },
  {
    question: "When will new tools be added?",
    answer: (
      <>
        <p className="fw-regular small-md">
          More tools are being set up on Yalla and will appear here as they become available.
        </p>
      </>
    ),
  },
];

export function ToolsTab() {
  const { router } = useLocation();
  const { selectedProduct } = useProductDropdownContext();

  return (
    <PageSection>
      <Row>
        <Col xs="12" md="10" xl="8">
          <Row>
            {toolsList.map(({ id, name, icon, description, link, linkSrc }, index) => {
              const toolUrl =
                router?.asPath.replace(/\?.*/, `/tool/${name}?product_name=${selectedProduct?.name}`) ??
                "/router/not/ready";
              return (
                <Col xs="6" key={id} className={`${index % 2 === 0 ? "mb-5" : ""}`}>
                  <div className="align-items-center bg-white rounded-5 py-6 x5-px-5">
                    <div className="d-flex align-items-center mt-2 ">
                      {icon}
                      <LinkButton
                        name={name}
                        Icon={Right24}
                        iconPosition={"end"}
                        level="quartenary"
                        rounded="circle"
                        size="sm"
                        href={toolUrl}
                        className="px-0 border-0"
                      >
                        <p className="mb-0 lead fw-light lh-1-5">{name}</p>
                      </LinkButton>
                    </div>
                    <p className="mb-0 small opacity-70 fw-light lh-1-5 small x5-ms-1 mt-5 ">{description}</p>
                    <hr />
                    <Link href={linkSrc}>
                      <span className="fw-light fst-normal small-md mb-0" data-testid="link">
                        {link}
                      </span>
                    </Link>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
        <Col xs="12" md="10" xl="4">
          <div className="mb-4 bg-white p-7 rounded-5">
            <div className="gap-5 d-flex flex-column">
              <CommonlyAskedQuestions questionAnswers={questionAnswers} />
            </div>
          </div>
        </Col>
      </Row>
    </PageSection>
  );
}
