"use client";
import { PropsWithChildren, useMemo } from "react";
import { areOpsGroupsPresent } from "./utils";
import { SupportGroupHeader } from "./support-group-header";
import { SupportGroupList } from "./support-group-list";
import { useIsUserPrivileged } from "../utils";
import { useSetupSupportGroupModal } from "./support-group-provider";
import { Alert24 } from "@bphxd/ds-core-react/lib/icons/index";
import { Alert } from "@bphxd/ds-core-react";
import { Loader } from "../../../../components/spinners/loading-spinner";
import { useQuery } from "@apollo/client";
import { graphql } from "../../../../gql";
import { useRaiseAlertModal } from "./raise-alert-modal-provider";
import { RaiseAlertModal } from "../raise-alert-modal/modal";
import { useLocation } from "../../../../hooks/use-location";
import { SetSupportGroups } from "./set-support-groups";
import { AlertsAndIncidents } from "../../../../app/profile/product/components/alerts-and-incidents";
import { Alerts } from "../../../../app/profile/product/components/alerts";
import { GetAccelerateProductSupportGroupDetailsQuery } from "../../../../gql/graphql";
import { uniqBy } from "lodash";
import { getTodaysDateInTime } from "../../../../utils/helpers/utils";

export const SUPPORT_GROUP_QUERY = graphql(`
  query GetAccelerateProductSupportGroupDetails($id: String!, $date: Float!) {
    product(id: $id) {
      id
      name
      ownerId
      techLeadId
      delegates {
        email
      }
      permissions {
        edit
        delete
        linkage
      }
      l3Entity {
        serviceNowId
        opsGroups {
          id
          name
          supportGroup {
            opsGroupId
            name
          }
          users {
            _id
            givenName
            surname
          }
        }
      }
      supportGroups {
        isOnboarding
        description
        name
        areOnCallMembersPublic
        opsGroup {
          id
          name
        }
        productId
        opsGroupId
        users {
          _id
          email
          givenName
          surname
          jobTitle
          userId
          photo
          contact {
            email
            slack
            teams
          }
        }
        contactMethod {
          type
          value
        }
        alerts {
          id
          dateRaised
          dateUpdated
          alertStatus
          description
          resolutionNote
          raisedBy {
            email
            givenName
            surname
            jobTitle
            userId
            photo
            contact {
              email
              slack
              teams
            }
          }
        }
        links {
          manageEscalationPoliciesLink
          manageScheduleLink
        }
        userShifts(date: $date) {
          date
          end
          isOnCall
          scheduleName
          start
        }
      }
    }
  }
`);

type SupportGroupsProps = {
  productId?: string;
  showAlerts?: boolean;
  showIncidents?: boolean;
};

export function getAllAlerts(product: GetAccelerateProductSupportGroupDetailsQuery["product"]) {
  const allAlerts =
    product?.supportGroups
      ?.flatMap(
        (group) =>
          group.alerts?.map((alert) => ({
            ...alert,
            groupName: group.name,
          })) ?? [],
      )
      .sort((a, b) => b.dateUpdated - a.dateUpdated) ?? [];
  return uniqBy(allAlerts, "id");
}

export function SupportGroups({ productId, showAlerts, showIncidents }: SupportGroupsProps) {
  const currentDate = useMemo(getTodaysDateInTime, [productId]);
  const { search } = useLocation();
  const product_id = (search?.product_id as string) || productId;
  const { data, loading } = useQuery(SUPPORT_GROUP_QUERY, {
    variables: { id: product_id as string, date: currentDate },
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    skip: !productId,
  });
  const product = data?.product;
  const { openModal } = useSetupSupportGroupModal();
  const { isModalOpen, closeModal, selectedGroup } = useRaiseAlertModal();

  const userHasPermissions = useIsUserPrivileged(product);
  const showAddNewButton = useMemo(() => areOpsGroupsPresent(product), [product]);

  if (!product?.permissions || (loading && !product)) {
    return (
      <SupportGroupContainer>
        <div className="d-flex align-self-center">
          <Loader />
        </div>
      </SupportGroupContainer>
    );
  }

  if (!product.supportGroups) {
    return <SupportGroupError />;
  } else if (product.supportGroups.length === 0) {
    return <SetSupportGroups product={product} isAlertsTab={showAlerts} />;
  }

  return (
    <>
      <RaiseAlertModal isOpen={isModalOpen} toggle={closeModal} product={product} selectedGroup={selectedGroup} />
      {showAlerts && !loading && (
        <Alerts
          alerts={getAllAlerts(product)}
          disableRaiseAlert={product?.supportGroups?.every((group) => group.isOnboarding)}
        />
      )}
      <div className="d-flex row mt-n4">
        {showIncidents && (
          <div className="col-xl-8">
            <AlertsAndIncidents product={product} />
          </div>
        )}
        <div className={showIncidents ? `mt-xl-n5 col-xl-4` : ""}>
          <SupportGroupContainer>
            <SupportGroupHeader
              onSetupSupportGroupsClick={() => openModal(product!)}
              showAddNewButton={showAddNewButton && userHasPermissions}
            />
            <SupportGroupList
              onEditSupportGroupClick={(opsGroupId) => {
                const selectedSupportGroup = product.supportGroups?.find((group) => group.opsGroupId === opsGroupId);
                openModal(product!, selectedSupportGroup);
              }}
              product={product}
            />
          </SupportGroupContainer>
        </div>
      </div>
    </>
  );
}

function SupportGroupContainer({ children }: PropsWithChildren) {
  return <div className="bg-white rounded-5 border px-5 py-6 mt-6">{children}</div>;
}

function SupportGroupError() {
  return (
    <div className="bg-white rounded-5 border px-5 py-6 mt-6">
      <div className="d-flex justify-content-between mb-5">
        <h5 className="mb-0">Support groups</h5>
      </div>
      <Alert color="danger" Icon={Alert24}>
        <p className="small fw-light">
          Something went wrong trying to get this product's support group(s). Please try refreshing the page, and if
          this error persists, please raise a support ticket with Accelerate.
        </p>
      </Alert>
    </div>
  );
}
