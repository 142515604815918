import { Modal } from "../../../../../components/modal";
import { EditResourceForm } from "./edit-resource-form";

import { Product, LinkedResource } from "../../../../../types/ProfileTypes";

type ModalProps = {
  isModalOpen: boolean;
  toggle: () => void;
  product: Product;
  resource: LinkedResource;
};

export function EditResourceModal({ isModalOpen, toggle, product, resource }: ModalProps) {
  function handleClose() {
    toggle();
  }

  return (
    <Modal
      size="lg"
      backdrop="static"
      isOpen={isModalOpen}
      hasCancelDialog
      header="Edit Resource"
      onClose={handleClose}
    >
      <EditResourceForm onClose={toggle} selectedProduct={product} resource={resource} />
    </Modal>
  );
}
