import { useToast } from "../../../../hooks/use-toast";
import { useCreateKhubArea } from "../../../../hooks/use-create-khub-area";

import { Modal } from "../../../../components/modal";
import { Button } from "../../../../components/button";
import { Product } from "../../../../types/ProfileTypes";

type CreateProductAreaModalProps = {
  isModalOpen: boolean;
  toggle: () => void;
  selectedProduct: Product;
  refetch: CallableFunction;
};

export function CreateAreaModal({ isModalOpen, toggle, selectedProduct, refetch }: CreateProductAreaModalProps) {
  const type = "product";
  const { displayToast } = useToast();
  const [createKhubArea, { error, loading }] = useCreateKhubArea({
    type,
  });

  async function handleConfirm() {
    await createKhubArea({
      product: selectedProduct,
      onCompleted: () => {
        refetch();
        toggle();
        displayToast(`Successfully created a ${type} area for ${selectedProduct.name}`);
      },
    });
  }

  return (
    <Modal size="lg" isOpen={isModalOpen} onClose={toggle}>
      <div>
        <h3 className="fw-light text-center mb-4">Confirm that you want to create a {type} area in Knowledge Hub</h3>
        <p className="lead fw-light text-center mb-0">
          All Accelerate users will have access to the documentation in your {type} area.
        </p>
        <p className="lead d-flex align-items-center justify-content-center" style={{ minHeight: 56 }}>
          {loading && (
            <span className="fw-light">
              ⏲️ Your request can take up to 60 seconds to complete. Please don't close this modal. ⏲️
            </span>
          )}
          {error && <span className="text-danger-dark">{error}</span>}
        </p>
        <div className="d-flex align-items-center justify-content-center gap-6 mb-4">
          <Button
            name={`cancel-${type}-area-creation`}
            trackingEventProps={{ product: selectedProduct?.name }}
            onClick={toggle}
            disabled={loading}
            type="button"
            level="tertiary"
            theme="darker"
            rounded="pill"
          >
            Cancel
          </Button>
          <Button
            name={`confirm-${type}-area-creation`}
            trackingEventProps={{ product: selectedProduct?.name }}
            onClick={handleConfirm}
            loading={loading}
            type="button"
            level="primary"
            theme="darker"
            rounded="pill"
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
}
