import { env } from "next-runtime-env";
import { postKHubAPI } from "../rest-api";
import { DocumentStatus } from "../../../views/khub/enums";

export enum Endpoint {
  documents = "v1/documents",
  repositories = "v1/repositories",
}

export async function createNewPage({
  template_filename,
  repository,
  path,
  initial_status,
}: {
  repository: string;
  template_filename: string;
  path: string;
  initial_status: DocumentStatus;
}) {
  try {
    const query = Endpoint.documents;
    const response = await postKHubAPI<{
      created: string;
      message: string;
      repository: string;
      uuid: string;
    }>({
      spanName: "Create Knowledge Hub sub-page",
      query,
      payload: { template_filename, repository, path, initial_status },
    });

    return response;
  } catch (error: any) {
    throw new Error(error);
  }
}

export async function createKhubRepository({
  name,
  description,
  parentTeamName,
  contentOwners,
}: {
  name: string;
  description: string;
  parentTeamName: string;
  contentOwners: string[];
}) {
  try {
    const environment = /accelerate\.bpglobal\.com/.test(env("NEXT_PUBLIC_KNOWLEDGEHUB_URL") ?? "")
      ? "production"
      : "integration";

    const response = await postKHubAPI<{
      detail?: string;
      id: string;
      name: string;
      url: string;
      project: {
        id: string;
        name: string;
        url: string;
        visibility: "private" | "public";
      };
    }>({
      spanName: "Create Knowledge Hub repository product area",
      query: Endpoint.repositories,
      payload: {
        source_repository_id_or_name: "hidden_repository_template", // Should always be this value.
        name: name,
        template_values: {
          repo_name: name, // Should match the above.
          environment,
          site_title: name,
          site_description: description,
          team_name: parentTeamName,
          site_content_owners: { content_owners: contentOwners }, // The L3 Product Manager, Pod Tech Lead, and Operational Delegates delegates.
          display_in_library: false,
        },
      },
    });

    return response.body;
  } catch (error: any) {
    throw new Error(error);
  }
}
