"use client";
import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";

import { useToast } from "../../../../hooks/use-toast";

import { EDIT_ACCELERATE_PRODUCT, GET_REPO_INFO } from "../../../../api/products/gql";

import { Modal } from "../../../../components/modal";
import { Step1, Step1DataType } from "./step1";
import { Step2, Step2DataType } from "./step2";
import { Step3 } from "./step3";
import { Metrics, Product } from "../../../../types/ProfileTypes";
import { useQuizResult } from "../../../bestPractice/hooks/use-quiz-result";
import { Check16 } from "@bphxd/ds-core-react/lib/icons";
import { DoraMetrics } from "../../../../gql/graphql";

export type ADOResponse = {
  count: number;
  value: {
    id: string;
    name: string;
    url: string;
    state?: string;
    isDisabled?: boolean;
    pipeline?: { name: string };
    _links?: { "pipeline.web": { href: string } };
  }[];
};

export const STEPS = [
  {
    title: "Find a project",
    body: "Select a project containing the pipelines you'd like to measure. While your project may be linked to only one organisation & project, you may select more than one repo within a project.",
  },
  {
    title: "Find a repo",
    body: "Select one or more repos that contain the pipelines that deploy your product into production.",
  },
  {
    title: "Review pipelines",
    body: "",
  },
];

/**
 * As of 19/03/2024, the following orgs are processed by Product Health.
 */
const ENABLED_ORGS = [
  "digitalfoundations",
  "bp-vsts",
  "bp-oil",
  "bp-nagp",
  "bp-ist-tfs",
  "bp-frontoffice",
  "bp-digital",
  "bp-crrf",
  "bp-digital-sandbox",
  "bp-customers-products",
  "bp-enterprise",
  "bp-rnm",
  "BPInfoMgt",
  "bp-trading-shipping",
  "bp-people-culture",
  "sandbox-bp",
];

export const ADO_ORGS = [
  { id: "9a4f25d5-2832-4ca7-9c2e-0b6ac18994ad", name: "bp-gcro" },
  { id: "5c1d566e-a607-4799-801c-2a6112b04ade", name: "SONIAVADALA" },
  { id: "8ca0f9cb-041d-4609-b1cc-aad3ab93a209", name: "bp-shipping-archive" },
  {
    id: "a9989575-4992-453e-8a3c-040e40e40612",
    name: "bp-upstream-release-archive1",
  },
  { id: "f4f40678-6dc1-4a12-b473-e3fdfae88ef6", name: "HugoMakin" },
  { id: "5a7c80dd-b5dc-4958-ba3d-7014044266e4", name: "AruneshKumar" },
  {
    id: "f19a8659-d37b-4d9d-8cc7-1bb8a87a30c3",
    name: "bp-engineering-archive",
  },
  { id: "71bf77b4-7d88-4175-98b8-0376c21a1494", name: "bp-finance" },
  { id: "37c3a9f2-8163-4afe-936b-eab7b2c27b74", name: "bp-hris" },
  { id: "7ce76cee-0330-4bbd-a36b-9606eff6cb83", name: "bp-rnm" },
  { id: "9a540ca1-fed8-48d7-a55b-2981b0c8eb84", name: "bp-nagp" },
  { id: "f559c9a2-42dd-49f6-ba10-d07165bf9767", name: "bp-crrf" },
  { id: "f46ec400-6dd5-4568-98b7-d2b7bc144ca5", name: "Project-Solutions" },
  { id: "8080f862-2699-4d96-87a4-f210610629a6", name: "bp-digital-sandbox" },
  { id: "11ab0923-cad5-4995-8a14-cdbfd05c0b21", name: "SHANSHANHUANG" },
  { id: "ae5c093c-39de-4f63-b287-de851ae5a992", name: "bp-msft-alm-demos" },
  { id: "607f3511-8d52-4d3c-8fa9-700be514412b", name: "aimantabba" },
  { id: "24079413-1b5b-4c46-b0f9-1feb422a53fc", name: "CecilBardouille" },
  { id: "6277d80c-0e74-4885-b09e-8921894a74c5", name: "digitalfoundations" },
  { id: "e3ecb326-53dd-4515-86ea-949cfe743cac", name: "bp-comms-advocacy" },
  { id: "c1799bef-15ca-4c84-ad69-f9f2ea43b801", name: "bp-subsurface" },
  { id: "a912f1ac-5883-42b2-a80a-452a1c5d0323", name: "williamtaeger1" },
  { id: "ba9d0c48-e08d-4f90-ae48-07e8278bb347", name: "SSLProjects" },
  { id: "37d725de-7cce-473f-85a1-67d4b09e5d70", name: "bp-people-culture" },
  { id: "dc4585a2-6fe5-4c53-9a14-2e063a09679a", name: "TrinaHotaling" },
  { id: "f21f926d-5c51-44ff-ae79-75b6bacfbcf1", name: "bp-training" },
  { id: "597bf75a-90e0-4ffb-8a5f-dfdba21fcc96", name: "bp-chili-plugins" },
  { id: "d9334cff-f7ca-47ab-8f04-4a46a6346d38", name: "bpfinance" },
  { id: "9e0cdc4f-6262-482b-8bc5-bbf46be37f66", name: "zibeydaaliyeva" },
  { id: "a0d1e2fd-447b-4dd5-9b9f-e1b39c7bca25", name: "bp-ma-archive" },
  { id: "39c44178-1fd7-46fb-95a5-ce614006ecc6", name: "bp-quantanalytics" },
  { id: "dc688157-e60b-4642-ac0d-e375af0b1d17", name: "bp-lngtp-archive" },
  { id: "0e35a478-3448-4f5a-b5b1-210f6413b7a6", name: "ShelleyPersad" },
  { id: "452563fb-c539-4aed-b587-9edb53ba3a45", name: "epcautomation" },
  { id: "a58bd550-7c81-4543-a8d2-4e898d12a6fe", name: "OneLibrary" },
  { id: "ada7dfe7-fcfc-4cd8-ad00-7fa58dcf1462", name: "ComplexImaging" },
  { id: "23356a80-7059-42ec-a9b3-28712451f4c0", name: "yongjinxiao" },
  { id: "23f4fd92-6f97-4109-9031-2e8abee7416b", name: "bp-petchems" },
  { id: "5130530d-1d1f-4d4c-94d0-1faabda69401", name: "bp-trading-shipping" },
  { id: "ba16bd6c-dc09-4b7d-a361-08247811a385", name: "bp-ist-tfs" },
  { id: "1bd770db-c7e0-41d3-ad5a-5ccb05d7cd78", name: "TracyStill" },
  { id: "f3c917f6-859b-45c3-9b07-3757adf58c81", name: "bpupstream" },
  { id: "aedfe107-1abc-4286-a90c-a36d0634abf2", name: "ryanmeissner" },
  { id: "f82e5ac6-f6a2-4179-abea-f387d98e242a", name: "bp-digital" },
  {
    id: "39aac507-b0a3-4c7b-8af5-230a4be23beb",
    name: "bp-strategy-sustainability",
  },
  { id: "3dbeef60-e00f-4d4a-853b-04cc955ec25e", name: "BP-HR" },
  { id: "ad8662c2-474a-4041-af5c-97c05e85b6df", name: "MonoProjectModel" },
  { id: "cd4675b9-9774-4485-bbb4-9f5b025c68b8", name: "ChrisDuffy" },
  { id: "86ddce67-bdc5-4c28-8a04-f0a751f68a08", name: "bp-mobile-archive" },
  { id: "1667585c-9c73-4382-a38a-3a821bf6f2d2", name: "anirudhkouthabp" },
  { id: "a01e06e5-b07d-49fa-9029-90387b77af77", name: "bp-customers-products" },
  { id: "1d080f45-5814-43ea-8afc-6db2902e63a7", name: "bp-ut-ci-TH" },
  { id: "b45779a8-20d9-4bd4-9071-c5df3773a449", name: "noreply-dwx-wss" },
  { id: "ce29d8ac-7361-47b6-8bd2-0a0f3956915e", name: "GoM-Wells-Operations" },
  { id: "dfb50dd1-ecae-46bf-a429-824ffd85882f", name: "bp-enterprise-other" },
  { id: "92e73f48-62fd-49b8-9082-bc2bd59e9886", name: "michaelala" },
  { id: "a8d44d74-ccc9-4bf1-9ede-a860ad4a548d", name: "bp-paas-poc" },
  { id: "95c6d820-e4af-4e53-908b-8d2e5f9dfcaa", name: "bp-csl" },
  { id: "39fcefd2-2398-4e11-8b2a-05ffc34e98be", name: "MadDog2" },
  { id: "5848c6a1-75f3-41f6-b529-0c211472882d", name: "AshWTest" },
  { id: "032924fc-24cf-45e0-87f7-fb7b3e7208eb", name: "JorgeMaciaPalafox" },
  {
    id: "ba60b538-cd0b-4c46-bf58-cda235e41c55",
    name: "bp-regions-cities-solutions",
  },
  { id: "822e6107-d3f2-4de9-b1aa-ce664439d087", name: "crrf-rad" },
  { id: "b823e5ca-3c3a-4919-a82d-846168616ec2", name: "NOJV" },
  { id: "62a0f71d-60d1-447d-b37e-410f8f8b3df4", name: "bp-oil" },
  { id: "afb5caf6-b91c-4d33-b2af-2c66bf065da3", name: "ze1-b1-00-vsts" },
  { id: "c1011229-50a4-4e37-af7e-739eb203ffa9", name: "olubayolatinwo" },
  { id: "d0eefcb8-79f7-44db-a18b-65920da388ea", name: "bp-gtel" },
  {
    id: "cb0b3cae-d410-43fc-9b3e-dafa5ccf2da4",
    name: "bp-innovation-engineering",
  },
  { id: "c5d60d52-d8f8-4d67-aec0-915d5030c9ae", name: "BPInfoMgt" },
  { id: "24c07381-bb5d-4411-829e-024f5c8a480a", name: "bp-vsts" },
  { id: "74610e42-4fb4-4ba1-89a1-067dec081816", name: "bp-enterprise1" },
  { id: "72cb89fc-3633-461e-9218-69330330656c", name: "gustiharsomurdani" },
  { id: "c666af75-a73c-4d36-a79d-a85694117b56", name: "sandbox-bp" },
  { id: "6aa77db8-fe82-4806-9201-96833ccaea06", name: "brewerml" },
  { id: "98882d9c-337b-47ef-89ad-986bb040e374", name: "bp-frontoffice" },
  { id: "2895e4a6-a0c8-47f6-a3bb-d477a3a528b0", name: "AngolaPEC" },
  { id: "c36c730b-ce6c-4066-b56b-65ee8d415184", name: "bp-onerefining" },
  { id: "c6a3e0e7-63f9-4701-bf6a-f4c392523be6", name: "bp-gas-low-carbon" },
  {
    id: "cf1a5a52-aa56-49f3-bbd8-dae87c585b62",
    name: "bp-istsharepoint-archive",
  },
  {
    id: "46f4c689-6fda-4c21-ab59-76566ac90689",
    name: "bp-production-operations",
  },
  { id: "3201e458-e79d-4f87-89e6-d43ef1c737a0", name: "bp-mmdc" },
  { id: "2f0da958-f50f-4b66-9cff-e71e375858ef", name: "nehachopra1" },
  { id: "d2915d0d-0b81-4beb-bd64-fb6de4bb924a", name: "ACG-Bal-FFM" },
  { id: "4172a602-5581-4e44-a655-370c5d3ecb9a", name: "bp-vsts-sandbox" },
  { id: "1cacf8a0-50c5-4d5c-aa05-275afd89cad4", name: "bp-bpTFS-archive" },
  { id: "bbdbfa68-eb4f-457a-b3ae-72e4c28e1523", name: "bp-rnm-functions" },
  { id: "e83016fa-8d06-4122-a9b3-bce30c82a8f5", name: "amicf0" },
  { id: "d2f2b79f-f6ca-4096-b620-46bcf6b6a36e", name: "bp-upstream-release" },
  { id: "b7d58133-c895-4fc3-89af-5efabb448936", name: "bp-business-services" },
  { id: "85ecdac1-7cc1-4daa-8308-e550b487bddd", name: "bp-FOA" },
  { id: "098d7bb8-35c7-462c-82db-93382903007b", name: "bp-legal" },
  { id: "f1c70cf2-cabe-4656-9d0d-d003cd004b6d", name: "anandsomaiya" },
  { id: "96dd84c1-aa9a-4270-8fce-231e3c7eac5f", name: "TAR-Solutions" },
  { id: "4f1bcfaf-9c99-4961-a652-85a822165857", name: "bp-enterprise" },
  { id: "bc6411a7-9f86-44fa-9c6a-4d3b879693ee", name: "bpmapsextensiontest" },
].filter((org) => ENABLED_ORGS.includes(org.name));

ADO_ORGS.sort((a, b) => a.name.localeCompare(b.name));

export function AddReposToProductModal({
  product,
  isOpen,
  toggle,
  onSetMetricsClick,
}: {
  product: Product;
  isOpen: boolean;
  toggle: () => void;
  onSetMetricsClick: () => void;
}) {
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [disabledRepoError, setDisabledRepoError] = useState(false);
  const [step1Data, setStep1Data] = useState<Step1DataType>({
    organisation: "",
    project: "",
  });
  const [step2Data, setStep2Data] = useState<Step2DataType>({
    repos: [],
  });
  const { displayToast, displaySuccessModal } = useToast();
  const [getRepoInfo] = useLazyQuery(GET_REPO_INFO);
  const { quizResult: metricResults } = useQuizResult({
    entityId: product.id,
    quizSlug: "product-metrics",
  });

  const [editAccelerateProduct] = useMutation(EDIT_ACCELERATE_PRODUCT, {
    onCompleted: () => {
      displayToast(`Successfully connected repos to ${product.name}.`);
    },
    onError: () => {},
  });

  function handleNextClick() {
    setCurrentStep((step) => step + 1);
  }

  function handleBackClick() {
    setCurrentStep((step) => step - 1);
  }

  async function handleSubmit(data: Step1DataType & Step2DataType) {
    await editAccelerateProduct({
      variables: {
        id: product.id,
        repositories: data.repos.map((id) => ({
          id,
          projectName: step1Data.project,
          organizationName: step1Data.organisation,
        })),
      },
    });
    setTimeout(() => {
      handleNextClick();
    }, 1000);
  }

  async function setExistingData(metrics: DoraMetrics) {
    const repoIds = metrics.repositoryIds as string[];
    if (repoIds?.length) {
      setIsLoading(true);
      const response = await Promise.all(
        repoIds.map((repoId) => {
          return getRepoInfo({
            variables: {
              repositoryId: repoId,
            },
          });
        }),
      );
      if (response.some((res) => !res.data)) {
        setDisabledRepoError(true);
        setStep2Data({
          repos: response.filter((res) => res.data).map((res) => res.data.getRepoInfo.repositoryId),
        });
      } else {
        setStep2Data({
          repos: repoIds,
        });
      }

      const repoInfo = response.find((res) => res.data?.getRepoInfo)?.data?.getRepoInfo;
      if (repoInfo) {
        const { projectName, organisationName } = repoInfo;
        setStep1Data({
          organisation: organisationName,
          project: projectName,
        });
      }
    } else {
      setDisabledRepoError(false);
      setStep1Data({
        organisation: "",
        project: "",
      });
      setStep2Data({
        repos: repoIds,
      });
    }
    setIsLoading(false);
  }

  function resetForm() {
    setStep1Data({ organisation: "", project: "" });
    setStep2Data({ repos: [] });
    setCurrentStep(0);
  }

  useEffect(() => {
    if (product.metrics?.repositoryIds?.length) {
      setCurrentStep(0);
    } else {
      resetForm();
    }
  }, [isOpen]);

  useEffect(() => {
    if (product.metrics?.repositoryIds?.length) {
      setExistingData(product.metrics);
    }
  }, [product]);

  const onComplete = () => {
    toggle();

    if (checkIfPipelinesExist(product)) {
      let confirmButton = {};
      if (!metricResults) {
        confirmButton = {
          showConfirmationButton: true,
          confirmationButtonText: "Set perception metrics",
          confirmationButtonClick: onSetMetricsClick,
        };
      }

      setTimeout(() => {
        displaySuccessModal({
          title: "Great Job!",
          name: "product-name",
          closeButtonText: "Close",
          ...confirmButton,
          message: "",
          children: (
            <div className="d-flex flex-column">
              <div className="d-flex">
                <div>
                  <Check16 color="green" />
                </div>
                <p className="x5-ms-2 fw-light">
                  Your product now has empirical metrics for Lead Time and Deployment Frequency
                </p>
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex">
                  <div>
                    <Check16 color="green" />
                  </div>
                  <p className="x5-ms-2 fw-light">
                    We're currently working on Time to Restore and Change Fail Percentage. In the meantime, could you
                    please provide your perceptions for these two metrics?
                  </p>
                </div>
              </div>
            </div>
          ),
        });
      }, 1000);
    } else if (!metricResults) {
      setTimeout(() => {
        displaySuccessModal({
          title: "Great Job!",
          name: "product-name",
          closeButtonText: "Close",
          showConfirmationButton: true,
          confirmationButtonText: "Set perception metrics",
          confirmationButtonClick: onSetMetricsClick,
          message: "",
          children: (
            <div className="d-flex flex-column">
              <div className="d-flex">
                <div>
                  <Check16 color="green" />
                </div>
                <p className="x5-ms-2 fw-light">
                  Even though we could not identify any suitable pipelines, your product is now connected to your
                  repositories.
                </p>
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex">
                  <div>
                    <Check16 color="green" />
                  </div>
                  <p className="x5-ms-2 fw-light">
                    Be sure to set your perception metrics so that you can track your teams' progress over time.
                  </p>
                </div>
              </div>
            </div>
          ),
        });
      }, 1000);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={toggle} showHeader hasCancelDialog header="Add repos to your product">
      {currentStep === 0 && (
        <Step1
          handleNextClick={(data) => {
            if (data.organisation !== step1Data.organisation || data.project !== step1Data.project) {
              setStep2Data({ repos: [] });
            }
            setStep1Data(data);
            handleNextClick();
          }}
          disabledRepoError={disabledRepoError}
          isLoading={isLoading}
          {...step1Data}
        />
      )}
      {currentStep === 1 && (
        <Step2
          step1Data={step1Data}
          handleNextClick={async (data) => {
            setStep2Data(data);
            await handleSubmit({
              ...step1Data,
              ...data,
            });
          }}
          handleBackClick={handleBackClick}
          {...step2Data}
        />
      )}
      {currentStep === 2 && (
        <Step3
          productName={product?.name}
          metrics={product?.metrics}
          handleCloseClick={() => {
            onComplete();
          }}
          handleBackClick={handleBackClick}
        />
      )}
    </Modal>
  );
}
function checkIfPipelinesExist(product: Product) {
  const metricsToCheck: Array<keyof Metrics> = ["leadTime", "deploymentFrequency"];

  return metricsToCheck.some((metric) => {
    const metricData = product.metrics?.[metric];

    // @ts-ignore
    if (!metricData || !("perPipeline" in metricData)) {
      return false;
    }

    return (metricData.perPipeline?.length ?? 0) > 0;
  });
}
