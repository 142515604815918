"use client";
import { useMemo } from "react";
import { useUser } from "./use-user";
import { BasicProductFields } from "../pages/all-products";
import { ProductResponse } from "../gql/graphql";

/**
 * Hook to check if the current logged-in user is an admin for a given product.
 *
 * This function determines if the user has admin privileges based on their role in the product.
 * Admin roles include:
 * - The product owner
 * - The tech lead
 * - Any delegate listed as part of the product
 *
 * The function performs a case-insensitive email comparison to verify if the current user
 * matches any of these roles.
 *
 * @param  product - The product object containing details about the product's owner, tech lead, design lead, and delegates.
 *
 * @returns boolean - Returns true if the current user is an admin, false otherwise.
 */
export function useUserIsAdmin(product?: BasicProductFields | ProductResponse): boolean {
  const user = useUser();

  return useMemo(
    () =>
      [product?.ownerId, product?.techLeadId]
        .concat(product?.delegates?.flatMap((d) => d?.email ?? "") ?? [])
        .filter((email) => email)
        .some((email) => email?.toLowerCase() === (user?.email ?? "").toLowerCase()),

    [product, user],
  );
}
