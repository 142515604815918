/* eslint-disable no-unused-vars */
export enum TESTID {
  tagBar = "kh-content-tags",
  navBtns = "kh-navigation-section",
  pageTitle = "dac-page-title",
  toc = "dac-table-of-contents",
  nextBtn = "khub-next-button",
  prevBtn = "khub-previous-button",
  unhelpfulButton = "unhelpful-button",
  feedbackPopoverSubmitBtn = "feedback-popover-submit-btn",
  feedbackPopoverTextField = "report-a-problem-textarea",
  editPageSuccessModalLink = "edit-page-from-success-modal",
  mdxProfilePic = "mdx-profile-pic",
}

export enum DocumentStatus {
  DRAFT = "DRAFT",
  UNDER_REVIEW = "UNDER_REVIEW",
  PUBLISHED = "PUBLISHED",
  RETIRED = "RETIRED",
  KDD_FINAL = "KDD_FINAL",
  KDD_UNDER_REVIEW = "KDD_UNDER_REVIEW",
  KDD_IN_PROGRESS = "KDD_IN_PROGRESS",
}

export enum DocumentType {
  GENERIC = "Generic",
  KDD = "kdd-template.md",
}
