import { MODAL_HEADER_TEST_ID } from "./modal";
import { Button } from "./button";
import { ProgressIndicator } from "./progress-indicator";

const HEADER_QUERY = `[data-testid="${MODAL_HEADER_TEST_ID}"]`;

type ModalFooterProps = {
  testIdContext: string;
  nextButtonText: string;
  progress?: number;
  handleNextClick?: (data?: any) => Promise<void> | void;
  isNextButtonDisabled?: boolean;
  isNextButtonLoading?: boolean;
  backButtonText?: string;
  showBackButton?: boolean;
  handleBackClick?: () => void;
  nextButtonEventName?: string;
  backButtonEventName?: string;
  currentStep?: number;
  totalSteps?: number;
};
export function ModalFooter({
  testIdContext,
  showBackButton = false,
  nextButtonText = "Next",
  backButtonText = "Back",
  handleNextClick,
  isNextButtonDisabled = false,
  isNextButtonLoading = false,
  handleBackClick,
  nextButtonEventName,
  backButtonEventName,
  currentStep,
  totalSteps,
}: ModalFooterProps) {
  function handleBack() {
    const headerElement = typeof document !== "undefined" && document.querySelector(HEADER_QUERY);

    if (headerElement && typeof headerElement.scrollIntoView === "function") {
      headerElement.scrollIntoView();
    }
    handleBackClick && handleBackClick();
  }

  function handleNext() {
    const headerElement = typeof document !== "undefined" && document.querySelector(HEADER_QUERY);

    if (headerElement && typeof headerElement.scrollIntoView === "function") {
      headerElement.scrollIntoView();
    }

    handleNextClick && handleNextClick();
  }

  return (
    <div className="d-flex align-items-center justify-space-between justify-content-end my-6">
      {showBackButton ? (
        <Button
          data-testid={`${testIdContext}-back`}
          onClick={handleBack}
          name={backButtonEventName ?? `${testIdContext}-back`}
          type="button"
          level="tertiary"
          theme="darker"
          rounded="pill"
          size="md"
        >
          {backButtonText}
        </Button>
      ) : null}
      {currentStep && totalSteps ? (
        <div className="col align-self-center d-flex justify-content-center">
          <ProgressIndicator currentStep={currentStep} totalSteps={totalSteps} />
        </div>
      ) : null}
      <Button
        data-testid={`${testIdContext}-next`}
        name={nextButtonEventName ?? `${testIdContext}-next`}
        onClick={handleNext}
        disabled={isNextButtonDisabled}
        loading={isNextButtonLoading}
        type="submit"
        level="primary"
        theme="darker"
        rounded="pill"
        size="md"
      >
        {nextButtonText}
      </Button>
    </div>
  );
}
