"use client";
import { ReactNode, useEffect } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Col, ModalBody, ModalFooter, Row } from "reactstrap";
import { Button } from "../../../../components/button";
import Select from "../../../../components/Select";
import Input from "../../../../components/Input";
import { ContactMethodType, SupportGroupCreateArgs, SupportGroupUpdateArgs } from "../../../../gql/graphql";
import { FormGroup } from "../../../../components/form-group.tsx";
import { Label } from "@bphxd/ds-core-react";

export type Step1DataType = SupportGroupCreateArgs | SupportGroupUpdateArgs;

export function Step1({
  orgOptions,
  contactMethodOptions,
  handleNextClick,
  handleDirtyForm,
  ...props
}: {
  orgOptions: { label: ReactNode; value: string }[];
  contactMethodOptions: { label: string; value: ContactMethodType }[];
  handleNextClick: (data: Step1DataType) => void;
  handleDirtyForm: (isDirty: boolean) => void;
} & Step1DataType) {
  const schemaObject = {
    opsGroupId: yup
      .string()
      .transform((obj) => obj?.value)
      .required("Required"),
    name: yup.string().required("Required"),
    description: yup.string().optional(),
    contactMethod: yup
      .string()
      .transform((obj) => obj?.value)
      .required("Required"),
    areOnCallMembersPublic: yup.boolean().required("Required"),
    contactEndpoint: yup.string().when("contactMethod", (contactMethod: string, schema: any) => {
      if (contactMethod === ContactMethodType.Email) {
        return schema.email("Must be an email address").required("Must be an email address");
      } else if (contactMethod === ContactMethodType.Teams || contactMethod === ContactMethodType.Slack) {
        return schema.url("Must be a valid URL").required("Must be a valid URL");
      }
      return schema;
    }),
  };

  const supportMemberOptions = [
    {
      value: true,
      label: "Yes",
      description: "Users can see who is on call, and can reach out directly to them or through the selected channel.",
    },
    {
      value: false,
      label: "No",
      description: "Users will need to use the selected channel and can’t see who is currently on call",
    },
  ];

  const schema = yup.object(schemaObject).required();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid, isDirty },
    setValue,
    watch,
    trigger,
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    handleDirtyForm(isDirty);
  }, [isDirty, handleDirtyForm]);

  const selectedContactMethod = watch("contactMethod");
  let contactEndpointLabel = "Email address";

  useEffect(() => {
    trigger("contactEndpoint");
  }, [selectedContactMethod, trigger]);

  if (
    selectedContactMethod?.value === ContactMethodType.Teams ||
    selectedContactMethod?.value === ContactMethodType.Slack
  ) {
    contactEndpointLabel = selectedContactMethod.label + " link";
  }

  async function onSubmit(data: FieldValues) {
    const values = {
      opsGroupId: data.opsGroupId,
      name: data.name,
      description: data.description,
      contactMethod: {
        type: data.contactMethod,
        value: data.contactEndpoint,
      },
      areOnCallMembersPublic: data.areOnCallMembersPublic,
    };

    return handleNextClick(values);
  }

  useEffect(() => {
    if (props) {
      setValue(
        "opsGroupId",
        orgOptions.find((org) => org.value === props.opsGroupId),
      );
      setValue("name", props.name);
      setValue("description", props.description);
      setValue(
        "contactMethod",
        contactMethodOptions.find((option) => option.value === props.contactMethod?.type),
      );
      setValue("contactEndpoint", props.contactMethod?.value);
      setValue("areOnCallMembersPublic", props.areOnCallMembersPublic);
    }
  }, [JSON.stringify(props)]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="form-step-3">
      <ModalBody className="px-9 pb-7">
        <div className="gap-5 d-flex flex-column">
          <div>
            <label className="mb-3 small fw-light w-100">ServiceNow Ops Groups linked to this product</label>
            <Controller
              name="opsGroupId"
              control={control}
              render={({ field }: any) => (
                <Select
                  {...field}
                  isMulti={false}
                  className={`basic-multi-select ${props.opsGroupId ? "cursor-not-allowed opacity-50" : ""}`}
                  options={orgOptions}
                  isDisabled={isSubmitting || props.opsGroupId}
                />
              )}
            />
          </div>

          <div>
            <label className="mb-3 small fw-light w-100" htmlFor="group-name">
              Accelerate Support Group Name
            </label>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }: any) => (
                <Input
                  id="group-name"
                  data-testid="group-name"
                  type="text"
                  {...field}
                  error={error?.message}
                  invalid={!!error}
                />
              )}
            />
          </div>
          <div>
            <label className="mb-3 small fw-light w-100" htmlFor="group-description">
              Description
              <span className="ms-3 fw-normal">optional</span>
            </label>
            <Controller
              name="description"
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }: any) => (
                <Input
                  id="group-description"
                  data-testid="group-description"
                  type="text"
                  {...field}
                  error={error?.message}
                  invalid={!!error}
                />
              )}
            />
          </div>
          <div>
            <label className="mb-3 small fw-light w-100">Preferred contact method</label>
            <Controller
              name="contactMethod"
              control={control}
              render={({ field }: any) => (
                <Select
                  {...field}
                  isMulti={false}
                  className={`basic-multi-select`}
                  options={contactMethodOptions}
                  isDisabled={isSubmitting}
                />
              )}
            />
          </div>
          {selectedContactMethod?.value && selectedContactMethod.value !== ContactMethodType.Dm && (
            <div>
              <label className="mb-3 small fw-light w-100" htmlFor="group-description">
                {contactEndpointLabel}
              </label>
              <Controller
                name="contactEndpoint"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }: any) => (
                  <Input
                    id="group-contact-endpoint"
                    data-testid="group-contact-endpoint"
                    type="text"
                    {...field}
                    error={error?.message}
                    invalid={!!error}
                  />
                )}
              />
            </div>
          )}
        </div>
        <div className="mt-7">
          <label className="mb-3 small fw-light w-100">Show currently on call user</label>
          <Controller
            name="areOnCallMembersPublic"
            control={control}
            render={({ field }) => (
              <>
                {supportMemberOptions.map((type) => (
                  <Row key={type.label}>
                    <Col xs="12">
                      <FormGroup check>
                        <Input
                          {...field}
                          id={`support-members-${type.value}`}
                          data-testid={type.value}
                          trackingName="show-current-on-call-user"
                          type="radio"
                          value={type.value}
                          checked={type.value === field.value}
                          onChange={() => field.onChange(type.value)}
                        />
                        <Label check for={type.label}>
                          {type.label}
                        </Label>
                        <label className="mb-3 small fw-light w-100">{type.description}</label>
                      </FormGroup>
                    </Col>
                  </Row>
                ))}
              </>
            )}
          />
        </div>
      </ModalBody>
      <ModalFooter className="px-8">
        <div className="w-100 d-flex justify-content-end align-items-center">
          <Button
            data-testid="next-setup-product-support-groups-button"
            type="submit"
            name="setup-product-support-groups-step-1"
            level="primary"
            theme="standard"
            rounded="pill"
            loading={isSubmitting}
            disabled={!isValid}
          >
            Save
          </Button>
        </div>
      </ModalFooter>
    </form>
  );
}
