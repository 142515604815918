"use client";
import { useEffect, useState } from "react";

import { DropdownItem, FormGroup, InputGroup, InputGroupText, ModalFooter } from "reactstrap";
import { IndeterminateCheckbox } from "@bphxd/ds-core-react";
import { Check24, Filter24, Layers24, Remove24, Search24 } from "@bphxd/ds-core-react/lib/icons";
import { Loader } from "../../../../components/spinners/loading-spinner";
import { FormHeadingV2 } from "../../../../components/form-heading";
import { Button } from "../../../../components/button";
import Input from "../../../../components/Input";
import { Link } from "../../../../components/Link";
import { ProgressIndicator } from "../../../../components/progress-indicator";

import { STEPS } from "./add-modal";
import { Step1DataType } from "./step1.jsx";
import { trackEvent } from "../../../../utils/event-tracker";
import { useLazyQuery } from "@apollo/client";
import { GET_ADO_REPOS_IN_PROJECT } from "../../../../api/products/gql";
import { DropdownMenu } from "../../../../components/dropdown-menu";

enum FilterStates {
  ALL_REPOS = "all-repos",
  SELECTED_REPOS = "selected-repos",
}
export type Step2DataType = {
  repos: string[];
};

export function Step2({
  step1Data: { organisation, project },
  handleBackClick,
  handleNextClick,
  repos,
}: {
  step1Data: Step1DataType;
  handleBackClick: () => void;
  handleNextClick: ({ repos }: Step2DataType) => void;
  repos?: string[];
}) {
  const [getRepos, { data, loading: reposLoading }] = useLazyQuery<{
    getAllActiveADORepos: { id: string; name: string; isDisabled: boolean }[];
  }>(GET_ADO_REPOS_IN_PROJECT);
  const [filterState, setFilterState] = useState<FilterStates>(FilterStates.ALL_REPOS);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedRepos, setSelectedRepos] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  async function onSubmit() {
    setIsSubmitting(true);
    await handleNextClick({ repos: selectedRepos });
    setIsSubmitting(false);
  }

  const listedRepos =
    data?.getAllActiveADORepos
      .filter((repo) => !repo.isDisabled)
      .map(({ id, name }) => ({ value: id, label: name }))
      .sort((a, b) => a.label.localeCompare(b.label)) || [];
  const noReposFound = reposLoading === false && listedRepos.length === 0;
  const displayableRepos =
    filterState === FilterStates.ALL_REPOS ? listedRepos : listedRepos.filter((r) => selectedRepos.includes(r.value));

  const filteredRepos = searchValue
    ? displayableRepos.filter((r) => r.label.toLowerCase().includes(searchValue.toLowerCase()))
    : displayableRepos;

  useEffect(() => {
    if (organisation && project) {
      getRepos({ variables: { projectName: project, organisationName: organisation } });
    }
  }, [organisation, project]);

  useEffect(() => {
    setSelectedRepos(repos ?? []);
  }, [repos]);

  return (
    <>
      <div className="px-5 pt-5">
        <FormHeadingV2 currentStep={2} totalSteps={STEPS.length} title={STEPS[1].title} />
        {noReposFound ? null : (
          <>
            <p className="fw-light">{STEPS[1].body}</p>
            <div className="w-100 mb-6 d-flex align-items-center justify-content-between gap-3">
              <InputGroup className="input-group-merge" size="">
                <Input
                  className={`form-control-prepended ${searchValue ? "form-control-appended" : ""}`}
                  placeholder="Search repos"
                  value={searchValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
                />
                <div className="input-group-prepend">
                  <InputGroupText>
                    <Search24 />
                  </InputGroupText>
                </div>
                {searchValue && (
                  <div className="input-group-append cursor-pointer" onClick={() => setSearchValue("")}>
                    <InputGroupText>
                      <Remove24 />
                    </InputGroupText>
                  </div>
                )}
              </InputGroup>
              <DropdownMenu
                toggle={
                  <div className="d-flex align-items-center ps-1 pe-3 py-2">
                    {filterState === FilterStates.ALL_REPOS ? <Filter24 /> : <Layers24 />}{" "}
                    <span className="text-nowrap">
                      Filter{filterState === FilterStates.SELECTED_REPOS ? "ed" : ""} by
                    </span>
                  </div>
                }
                trackingEventProps={{
                  name: "button_filter-repos-dropdown_click",
                }}
              >
                <DropdownItem
                  onClick={() => {
                    const state = FilterStates.ALL_REPOS;
                    setFilterState(state);
                    trackEvent({
                      name: "button_filter-repos_clicked",
                      state,
                    });
                  }}
                >
                  <div className="px-2 d-flex justify-content-between w-100 gap-3">
                    <div>All repositories</div>
                    {filterState === FilterStates.ALL_REPOS && (
                      <div>
                        <Check24 />
                      </div>
                    )}
                  </div>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    const state = FilterStates.SELECTED_REPOS;
                    setFilterState(state);
                    trackEvent({
                      name: "button_filter-repos_clicked",
                      state,
                    });
                  }}
                >
                  <div className="px-2 d-flex justify-content-between w-100 gap-3">
                    <div>Selected repositories only</div>
                    {filterState === FilterStates.SELECTED_REPOS && (
                      <div>
                        <Check24 />
                      </div>
                    )}
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </div>
          </>
        )}
        {reposLoading ? (
          <Loader size="md" />
        ) : !noReposFound ? (
          <>
            <div className="w-100">
              <div className="d-flex justify-content-between align-items-baseline">
                <div className="w-100 border-bottom">
                  <FormGroup check className="mb-3">
                    <IndeterminateCheckbox
                      className="form-check-input"
                      id="optionAll"
                      checked={selectedRepos.length > 0 && selectedRepos.length === filteredRepos.length}
                      indeterminate={selectedRepos.length > 0}
                      onChange={() => {
                        if (selectedRepos.length === filteredRepos.length) {
                          setSelectedRepos([]);
                        } else {
                          setSelectedRepos(filteredRepos.flatMap((r) => r.value));
                        }
                      }}
                    />
                    <label htmlFor="optionAll" className="fs-6 cursor-pointer">
                      Select all repos
                    </label>
                  </FormGroup>
                </div>
              </div>
              <div
                className={`${filteredRepos.length > 14 ? "overflow-y-scroll" : "overflow-hidden"} overflow-x-hidden`}
                style={{ maxHeight: "calc(32vh)" }}
              >
                {filteredRepos.length
                  ? filteredRepos.map(({ label, value }, index) => {
                      const checked = selectedRepos.includes(value);
                      return (
                        <div className={`py-3 ${index > 0 ? "border-top" : ""}`} key={value}>
                          <FormGroup check className="mb-0">
                            <Input
                              className="form-check-input"
                              id={`checkbox-option-${value}`}
                              type="checkbox"
                              checked={checked}
                              onChange={() => {
                                if (checked) {
                                  setSelectedRepos((prev) => prev.filter((r) => r !== value));
                                } else {
                                  setSelectedRepos((prev) => [...prev, value]);
                                }
                              }}
                            />
                            <label htmlFor={`checkbox-option-${value}`} className="fw-light fs-6 cursor-pointer w-auto">
                              {label}
                            </label>
                          </FormGroup>
                        </div>
                      );
                    })
                  : searchValue && (
                      <p className="fw-light mt-4">
                        No repos were found with the term "{searchValue}" in the name. It's possible that you are
                        looking for a repo which has been disabled in ADO.
                      </p>
                    )}
              </div>
            </div>
          </>
        ) : (
          <>
            <p className="fw-light lead">There are no repos in your selected project.</p>
            <p className="fw-light">
              {
                "If you still can't see any repos, you will need to check that you can access the repos in this ADO project. Please log into "
              }
              <Link href={`https://dev.azure.com/${organisation}/${project}`}>Azure</Link>.
            </p>
          </>
        )}
      </div>
      <ModalFooter className="border-0">
        {selectedRepos.length > 0 && (
          <div className="pb-3 pt-4 w-100 d-flex border-top gap-3 align-items-center">
            <div
              className="d-flex justify-content-center align-items-center text-white bg-black rounded-6 w-fit fs-7 lh-1"
              style={{ width: 24, height: 22 }}
            >
              {selectedRepos.length}
            </div>
            <p className="fs-6 mb-0 w-100">Repositor{selectedRepos.length > 1 ? "ies" : "y"} selected</p>
          </div>
        )}
        <div className="w-100 d-flex justify-content-between align-items-center pt-4 border-top">
          <div className="col">
            <Button
              data-testid="previous-add-repo-button-2"
              type="button"
              name={`back-add-repo-step-2`}
              level="tertiary"
              theme="standard"
              rounded="pill"
              onClick={handleBackClick}
            >
              Previous
            </Button>
          </div>
          <div className="col align-self-center d-flex justify-content-center">
            <ProgressIndicator currentStep={2} totalSteps={STEPS.length} />
          </div>
          <div className="col text-end">
            <Button
              data-testid="next-add-repo-button-2"
              type="button"
              name="next-add-repo-step-2"
              level="primary"
              theme="standard"
              rounded="pill"
              loading={isSubmitting}
              onClick={onSubmit}
              disabled={selectedRepos.length === 0}
            >
              Next
            </Button>
          </div>
        </div>
      </ModalFooter>
    </>
  );
}
