import { ApolloError, ApolloQueryResult, useQuery } from "@apollo/client";
import { GET_CURRENT_USER } from "../../../api/profiles/gql";
import { UserProfile } from "../../../types/ProfileTypes";

export function useCurrentUserProfile(): {
  currentUserProfile: UserProfile;
  isLoadingCurrentUserProfile: boolean;
  refetchCurrentUserProfile: () => Promise<ApolloQueryResult<any>>;
  error?: ApolloError;
} {
  const {
    data,
    refetch: refetchCurrentUserProfile,
    loading: isLoadingCurrentUserProfile,
    error,
  } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  });

  return {
    currentUserProfile: data?.currentUserProfile ?? {},
    refetchCurrentUserProfile,
    isLoadingCurrentUserProfile,
    error,
  };
}
