"use client";
import { Col, Row } from "reactstrap";
import { SetupProduct } from "../../../../views/profile/products/setup-product";
import { EmpiricalMetricCard } from "../../../../views/profile/empirical-metric-card";
import { Metric, metricRange, metricTooltipTexts } from "../../../../components/cards/metric-info-data";
import { Button, HelpMeImproveMyCapabilityButton } from "../../../../components/button";
import { CloudSpendingV2, MetricCard } from "../../../../components/cards/metric-card";
import { Alert } from "@bphxd/ds-core-react";
import { Loader } from "../../../../components/spinners/loading-spinner";
import { ImprovementAreaItem } from "../../../../views/profile/improvement-area-item";
import { Add16, Check16, Edit16 } from "@bphxd/ds-core-react/lib/icons";
import { LinkingAlertMessage, LinkingButton } from "../../../../views/profile/linking-alert-message";
import { ProductDescription } from "../../../../views/profile/product-description";
import { LeadMembers } from "../../../../views/profile/lead-members";
import { ProductLinkedResources } from "../../../../views/profile/products/resources/product-linked-resources";
import {
  IndicatorProgressBars,
  METRIC_PROGRESS_BAR_COLORS,
} from "../../../../components/cards/indicator-progress-bars";
import { useQuizResult } from "../../../../views/bestPractice/hooks/use-quiz-result";
import { useState } from "react";
import { QueryResult, useQuery } from "@apollo/client";
import { GET_IMPROVEMENT_GOALS, ImprovementGoal } from "../../../../api/products/gql";
import { SetAndEditProductMetricsModal } from "../../../../views/profile/products/metrics/set-and-edit-modal";
import { QuizType, useQuizzes } from "../../../../views/bestPractice/hooks/use-quizzes";
import { CreateEditImprovementAreaModal } from "../../../../views/profile/create-edit-improvement-area-modal";
import { RemoveImprovementAreaModal } from "../../../../views/profile/remove-improvement-area-modal";
import { Repositories } from "./repositories";
import { AddReposToProductModal } from "../../../../views/profile/products/repos/add-modal";
import { SetupMetrics } from "./setup-metrics";
import { ProductResponse, Quiz, QuizResult } from "../../../../gql/graphql";
import { SupportGroups } from "../../../../views/profile/products/support-groups/support-groups";
import { useFeature } from "featurehub-react-sdk";
import { SetSupportGroupsInfo } from "../../../../views/profile/products/support-groups/support-group-info";

function getConfiguredMetrics({ quiz, quizResult }: { quiz?: Quiz; quizResult?: QuizResult }) {
  if (quiz && quizResult) {
    const questions = quiz?.questions;
    const answers = quizResult?.answers;
    return questions?.filter((question) => answers.some((answer) => answer.question_id !== question.id));
  }
}

export function OverviewTab({
  productId,
  loading,
  product,
  l3DataLoading,
  isOwnerOrDelegate,
  refetchProduct,
  setIsEditProductModalOpen,
}: {
  productId: string;
  loading: boolean;
  product?: ProductResponse;
  l3DataLoading: boolean;
  isOwnerOrDelegate: boolean;
  refetchProduct: CallableFunction;
  setIsEditProductModalOpen: (state: boolean) => void;
}) {
  const [isSetMetricsModalOpen, setIsSetMetricsModalOpen] = useState(false);
  const [openLinkResourceModal, setOpenLinkResourceModal] = useState(false);
  const [openProductAreaModal] = useState(false);
  const [isEditImprovementAreaModalOpen, setIsEditImprovementAreaModalOpen] = useState(false);
  const [isRemoveImprovementAreaModalOpen, setIsRemoveImprovementAreaModalOpen] = useState(false);
  const [selectedImprovementArea, setSelectedImprovementArea] = useState<ImprovementGoal | undefined>();
  const [isHelpfulClicked, setIsHelpfulClicked] = useState(false);
  const [isNotHelpfulClicked, setIsNotHelpfulClicked] = useState(false);
  const [isAddRepoModalOpen, setAddRepoModalOpen] = useState(false);

  const [selectedMetric, setSelectedMetric] = useState<string | undefined>();
  const { quizzes, isLoadingQuizzes } = useQuizzes({
    quizTypes: [QuizType.DORA_QUICK_CHECK],
  });
  const quiz = quizzes?.find((quiz) => quiz.slug === "product-metrics");

  const {
    data: improvementGoalsData,
    loading: loadingImprovementGoals,
  }: QueryResult<
    {
      improvementGoals?: ImprovementGoal[];
    },
    { productId: string }
  > = useQuery(GET_IMPROVEMENT_GOALS, {
    fetchPolicy: "no-cache",
    variables: { productId },
  });

  const { quizResult } = useQuizResult({
    entityId: productId,
    quizSlug: "product-metrics",
  });

  const hideEmpiricalData = useFeature("FE_HIDE_EMPIRICAL_DATA");

  const questions = (quizResult && quizResult.quiz?.questions) || [];
  const filteredQuestions = hideEmpiricalData
    ? questions
    : questions.filter((question) => {
        const metricExists =
          (product?.metrics?.leadTime && question.metric === Metric.LeadTime) ||
          (product?.metrics?.deploymentFrequency && question.metric === Metric.DeployFrequency);

        return !metricExists;
      });

  const leadEmails = [product?.ownerId, product?.techLeadId, product?.designLeadId].filter(Boolean) as string[];

  function handleUpdateMetricClick(metric: string) {
    setSelectedMetric(metric);
    setIsSetMetricsModalOpen(true);
  }

  function handleSetMetricsClick() {
    setIsSetMetricsModalOpen(true);
  }

  function handleAddImprovementArea() {
    setSelectedImprovementArea(undefined);
    setIsEditImprovementAreaModalOpen(true);
  }

  function handleEditImprovementArea(goal: ImprovementGoal) {
    setSelectedImprovementArea(goal);
    setIsEditImprovementAreaModalOpen(true);
  }

  function handleRemoveImprovementArea(goal: ImprovementGoal) {
    setSelectedImprovementArea(goal);
    setIsRemoveImprovementAreaModalOpen(true);
  }

  function handleHelpfulClick() {
    setIsHelpfulClicked(true);
    setIsNotHelpfulClicked(false);
  }

  function handleNotHelpfulClick() {
    setIsHelpfulClicked(false);
    setIsNotHelpfulClicked(true);
  }

  const configuredMetrics = getConfiguredMetrics({ quiz, quizResult });

  return (
    <>
      {!l3DataLoading && !loading ? (
        <LinkingAlertMessage
          showAlertMessage={!product?.l3EntityUid}
          showButton={isOwnerOrDelegate}
          productName={product?.name}
          handleButtonClick={() => {
            setIsEditProductModalOpen(true);
          }}
        />
      ) : null}

      <Row>
        <Col xs="10" md="12" xl="8" className="h-100 d-flex flex-column">
          {product && product.permissions?.edit && (
            <SetupProduct
              product={product}
              metricResults={quizResult}
              onAddDescriptionClick={() => setIsEditProductModalOpen(true)}
              onAddLinkedResourceClick={() => {
                setOpenLinkResourceModal(true);
                setTimeout(() => {
                  setOpenLinkResourceModal(false);
                }, 500);
              }}
              onSetMetricsClick={handleSetMetricsClick}
            />
          )}

          {hideEmpiricalData && product ? (
            <SetupMetrics
              product={product}
              isLoading={isLoadingQuizzes}
              onSetMetricsClick={handleSetMetricsClick}
              configuredMetrics={configuredMetrics}
              totalNoOfMetrics={questions.length}
            />
          ) : null}
          <>
            {!hideEmpiricalData && (
              <div className="gap-4 w-100 d-flex">
                {!!product?.metrics?.leadTime && (
                  <div className="mb-6 col-6 pe-4">
                    <EmpiricalMetricCard metricName={Metric.LeadTime} product={product} />
                  </div>
                )}
                {!!product?.metrics?.deploymentFrequency && (
                  <div className="mb-6 col-6 pe-4">
                    <EmpiricalMetricCard metricName={Metric.DeployFrequency} product={product} />
                  </div>
                )}
              </div>
            )}
            {quizResult ? (
              <div className="flex-wrap d-flex w-100">
                {filteredQuestions?.map((question, index) => {
                  if (!question) {
                    return null;
                  }
                  const { metric, options, id } = question;
                  const answer = quizResult.answers?.find(({ question_id }) => question_id === id);
                  const metricName = metric!;

                  return (
                    <div className="mb-6 d-flex col-sm-12 col-xl-6" key={index}>
                      <div className={`w-100 ${index % 2 === 0 ? "pe-xl-4" : "ps-4"}`}>
                        {answer?.option_id && (
                          <MetricCard metricName={metricName} isEnabled toolTipText={metricTooltipTexts[metricName]}>
                            <p className="mb-4 small fw-light">Your score: {options[answer?.option_id - 1]?.label}</p>

                            <IndicatorProgressBars
                              arrowPosition={answer.option_id - 1}
                              progressBars={METRIC_PROGRESS_BAR_COLORS}
                            >
                              <div className="pb-3 d-flex justify-content-between border-bottom">
                                <small>{metricRange[metricName].start}</small>
                                <small>{metricRange[metricName].end}</small>
                              </div>
                            </IndicatorProgressBars>
                            <div className="pt-4 d-flex justify-content-end h-100 align-items-end">
                              <>
                                {product?.permissions?.edit && (
                                  <Button
                                    level="quartenary"
                                    theme="standard"
                                    rounded="pill"
                                    size="xs"
                                    name="update-your-product-metrics"
                                    trackingEventProps={{
                                      productName: product?.name,
                                      capabilityName: metric,
                                    }}
                                    onClick={() => handleUpdateMetricClick(metricName)}
                                    Icon={Edit16}
                                  >
                                    Update value
                                  </Button>
                                )}
                                <HelpMeImproveMyCapabilityButton capabilityName={metricName} />
                              </>
                            </div>
                          </MetricCard>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
            <div className="mb-6 d-flex">
              <div className="col-6 pe-4">
                <MetricCard
                  metricName={"Estimated cloud spend"}
                  isEnabled={true}
                  toolTipText={metricTooltipTexts[Metric.CloudSpend]}
                >
                  <small className="mb-3 fw-light">This month</small>
                  <CloudSpendingV2 product={product} />
                </MetricCard>
              </div>
            </div>
          </>

          {typeof product?.newCapabilityResult?.score === "number" && (
            <div className="px-6 bg-white border py-7 rounded-5">
              <h5 className="mb-5 fw-light">Areas for product improvement</h5>
              <Alert color="info" className="mb-5" dismissible>
                <p className="fw-light">
                  Below are three suggested capabilities you could focus on for improvement based on your current
                  product maturity score.
                </p>
                <p className="fw-light">
                  You can remove these from your list, add others, and provide a target score and date.
                </p>
              </Alert>
              <div className="container">
                {loadingImprovementGoals ? (
                  <Loader size="sm" />
                ) : (
                  improvementGoalsData?.improvementGoals?.map((goal) => {
                    const quizResult = product?.newCapabilityResult?.quizResults?.filter(
                      (quizResult) => quizResult.quiz.slug === goal.quizSlug,
                    )[0];

                    return (
                      <ImprovementAreaItem
                        key={goal.quizSlug}
                        quizResult={quizResult}
                        goal={goal}
                        productName={product?.name}
                        canEdit={product?.permissions?.edit}
                        onEditClick={handleEditImprovementArea}
                        onRemoveClick={handleRemoveImprovementArea}
                      />
                    );
                  })
                )}
                <Row className="mt-5">
                  <Col className="ps-0 d-lg-flex justify-content-between">
                    <div>
                      <Button
                        data-testid="help-me-improve-button"
                        onClick={handleAddImprovementArea}
                        level="tertiary"
                        theme="standard"
                        rounded="pill"
                        Icon={Add16}
                        size="sm"
                        className="text-nowrap"
                        name="help-me-improve-my-product-capbility"
                        trackingEventProps={{
                          productName: product?.name,
                        }}
                      >
                        Add improvement area
                      </Button>
                    </div>
                    <div className="d-flex align-items-center mt-sm-4 mt-lg-0">
                      <p className="mb-0 fw-light small me-4">Is this helpful?</p>
                      {[
                        {
                          text: "No",
                          onClick: handleNotHelpfulClick,
                          clicked: isNotHelpfulClicked,
                        },
                        {
                          text: "Yes",
                          onClick: handleHelpfulClick,
                          clicked: isHelpfulClicked,
                        },
                      ].map(({ text, onClick, clicked }, index) => (
                        <Button
                          key={text}
                          className={index === 0 ? "me-2" : ""}
                          disabled={clicked}
                          data-testid={`is-improvement-areas-helpful-${text.toLowerCase()}`}
                          onClick={onClick}
                          level="tertiary"
                          theme="standard"
                          rounded="pill"
                          size="sm"
                          name={`is-improvement-areas-helpful-${text.toLowerCase()}`}
                          trackingEventProps={{
                            productName: product?.name,
                          }}
                        >
                          {clicked && <Check16 className="me-2 ms-n2" />} {text}
                        </Button>
                      ))}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </Col>
        <Col xs="10" md="12" xl="4" className="mt-5 mt-xl-0">
          <div className="px-5 py-6 mb-6 bg-white border rounded-5">
            <h5 className="fw-light">About</h5>
            {product?.description ? <p className="mb-5 small fw-light">{product.description}</p> : null}
            {product?.l3EntityUid ? (
              <div className="d-flex flex-column">
                <p className="mb-1 opacity-100 small fw-light">ServiceNow location:</p>
                <div className="mb-4 opacity-100 small fw-normal" data-testid={"service-now-location"}>
                  {loading && l3DataLoading ? (
                    <Loader size="sm" />
                  ) : (
                    product.l3Entity?.tier?.parentName &&
                    `${product.l3Entity?.tier?.parentName} > ${product.l3Entity?.tier?.name} > ${product.l3Entity?.name}`
                  )}
                </div>
              </div>
            ) : !l3DataLoading ? (
              <LinkingButton
                showButton={isOwnerOrDelegate}
                handleButtonClick={() => {
                  setIsEditProductModalOpen(true);
                }}
              />
            ) : null}
            {product?.memberIds && product.name ? (
              <p className="mb-4 opacity-100 small fw-light">{`${product.memberIds.length} people are working on the ${product?.name} product`}</p>
            ) : null}
            <ProductDescription
              createdDate={product?.createdDate}
              updatedDate={product?.lastUpdated}
              createdByEmail={product?.createdById}
              updatedByEmail={product?.lastUpdatedById}
            />
            <p className="small fw-light mt-7">Product leads:</p>
            <LeadMembers loading={loading} emails={leadEmails} />
          </div>
          <ProductLinkedResources
            product={product}
            isLoading={loading}
            refetchProduct={refetchProduct}
            openProductAreaModal={openProductAreaModal}
            openLinkResourceModal={openLinkResourceModal}
          />
          {product && (
            <Repositories
              onEditClick={() => {
                setAddRepoModalOpen(true);
              }}
              product={product}
            />
          )}
          {product && product.supportGroups?.length === 0 && !l3DataLoading ? <SetSupportGroupsInfo /> : null}
          <SupportGroups showAlerts productId={product?.id} />
        </Col>
      </Row>
      {quiz && product && (
        <SetAndEditProductMetricsModal
          isOpen={isSetMetricsModalOpen}
          toggle={() => {
            setIsSetMetricsModalOpen(false);
            setSelectedMetric(undefined);
          }}
          product={product}
          quiz={quiz}
          selectedMetric={selectedMetric}
          previousAnswers={quizResult?.answers}
        />
      )}

      {product && (
        <AddReposToProductModal
          product={product}
          isOpen={isAddRepoModalOpen}
          toggle={() => setAddRepoModalOpen(false)}
          onSetMetricsClick={() => setIsSetMetricsModalOpen(true)}
        />
      )}

      <CreateEditImprovementAreaModal
        isOpen={isEditImprovementAreaModalOpen}
        productId={productId}
        goal={selectedImprovementArea}
        toggle={() => setIsEditImprovementAreaModalOpen(false)}
        quizResults={product?.newCapabilityResult?.quizResults}
      />

      <RemoveImprovementAreaModal
        isOpen={isRemoveImprovementAreaModalOpen}
        goal={selectedImprovementArea as ImprovementGoal}
        toggle={() => setIsRemoveImprovementAreaModalOpen(false)}
      />
    </>
  );
}
