"use client";
import { useMemo, useState } from "react";
import { getDisplayedScore, INCOMPLETE } from "../../bestPractice/MaturityComponents/display-score";

import { Col, Row } from "reactstrap";
import { Accordion } from "@bphxd/ds-core-react/";
import { Link } from "../../../components/Link";
import { DevOpsCapabilityIcon } from "../../../components/devops-capability-icon";
import { ChangeIndicator } from "../../../components/change-indicator";

import { ProductCapabilityIcon } from "../../../components/product-capability-icon";
import { Button } from "../../../components/button.tsx";
import { Checklist24 } from "@bphxd/ds-core-react/lib/icons/index";
import { useUser } from "../../../hooks/use-user.ts";
import { Answer, ProductResponse, Quiz } from "../../../gql/graphql.ts";
import { Progress } from "@bphxd/ds-core-react";
import { QuizType, useQuizzes } from "../../bestPractice/hooks/use-quizzes.ts";
import { ProductMaturityModal } from "./maturity-modal/product-maturity-modal.tsx";

const COMMON_QUESTIONS = [
  {
    question: "How is the product maturity score calculated?",
    answer: (
      <>
        <p className="fs-6 fw-normal lh-1-5">
          We calculate the Product score from the average of scores for each quiz in each of the of the Product
          capability groups; Cultural, Measurement, Process and Technical.
        </p>
        <Link className="fw-light small fs-6" href="/knowledgehub/SqP2H6">
          Read more about the scoring.
        </Link>
      </>
    ),
  },
  {
    question: "When is the maturity score calculated?",
    answer: (
      <p className="fs-6 fw-normal lh-1-5">
        The maturity score is calculated when all the product capabilities have been assessed.
      </p>
    ),
  },
  {
    question: "How are the product capability scores calculated?",
    answer: (
      <>
        <p className="fs-6 fw-normal lh-1-5">
          Each question has the same possible answers, with scores ranging from 0 to 8 points. Your final score is
          calculated on a scale of 10 by comparing your total points to the maximum possible score. For example, if you
          answer 4 questions and score 16 points out of a possible 32, your final score would be 5 out of 10
        </p>
        <Link className="fw-light small fs-6" href="/knowledgehub/SqP2H6">
          Read more about the scoring.
        </Link>
      </>
    ),
  },
  {
    question: "Why might a capability group have no score?",
    answer: (
      <>
        <p className="fs-6 fw-normal lh-1-5">
          If no score is shown for a capability group, this is because this product has pending quizzes.
        </p>
      </>
    ),
  },
];

function generateSvgName(slug: string) {
  return slug.toUpperCase().replace(/-/g, "_");
}

export function checkIsMember(product: ProductResponse, userEmail?: string | null): boolean {
  if (!product || !userEmail) return false;

  const allEmails = [
    ...(product.memberIds ?? []),
    product.ownerId ?? "",
    product.techLeadId ?? "",
    product.designLeadId ?? "",
    ...(product.delegates?.flatMap((d) => d?.email ?? "") ?? []),
  ].filter(Boolean);

  return allEmails.some((email) => email.toLowerCase() === userEmail.toLowerCase());
}

export function ProductMaturity({
  product,
  refetchProduct,
}: {
  product: ProductResponse;
  refetchProduct: CallableFunction;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [quizStructure, setQuizStructure] = useState<Quiz | undefined>();
  const [form, setForm] = useState<Record<string, Answer[]>>();
  const { quizzes, isLoadingQuizzes } = useQuizzes({
    quizTypes: [QuizType.DORA],
  });
  const userProfile = useUser();
  const isMember = useMemo(() => {
    return checkIsMember(product, userProfile?.email);
  }, [product, userProfile?.email]);

  const quizResults = product.newCapabilityResult?.quizResults ?? [];
  const completedQuizzesCount = quizResults.filter((i) => i.score).length;
  const totalQuizzesCount = quizResults.length;
  const areAllQuizzesAnswered = completedQuizzesCount === totalQuizzesCount;

  function setupAndOpenModal({ slugs }: { slugs: string[] }) {
    const form: Record<string, Answer[]> = {};
    const quiz = quizzes?.filter((quiz) => slugs.includes(quiz.slug))[0];
    setForm(form);
    setQuizStructure(quiz);
    setIsModalOpen(true);
  }

  return (
    <div className="w-100">
      <Row>
        <Col xs="8">
          {product.newCapabilityResult?.groupedByCategory?.length ? (
            <h5 className="mb-5 fw-light mt-7 ms-6">Product capabilities</h5>
          ) : null}
          {[...(product.newCapabilityResult?.groupedByCategory ?? [])]
            .sort((a, b) => a.categoryName.trim().localeCompare(b.categoryName.trim()))
            .map(({ categoryName, score, quizResults: perQuiz }) => {
              const isQuizAnswered = perQuiz.some((quiz) => quiz.answers.length > 0);
              return (
                <Accordion
                  stayOpen
                  flush
                  className="mb-6 bg-white border w-100 override-accordion-button rounded-5"
                  key={categoryName}
                >
                  <Accordion.Item className="overflow-visible w-100">
                    <Accordion.Header targetId={categoryName} tag="div" className="py-0 pe-7">
                      <div className="d-flex w-100 justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="" style={{ width: 24 }}></div>
                          <div className="py-6 x5-pe-2 ps-4">
                            <ProductCapabilityIcon category={categoryName} height={32} width={32} alt="category icon" />
                          </div>
                          <h5 className="py-6 mb-0 lead fw-light">{categoryName}</h5>
                        </div>
                        <h2 className="mb-0 fw-light me-4">{isQuizAnswered ? getDisplayedScore(score) : INCOMPLETE}</h2>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body accordionId={categoryName}>
                      <div className="pt-5 border-top mt-n3 ps-4">
                        {perQuiz
                          ?.toSorted((a, b) => a.quiz.name.trim().localeCompare(b.quiz.name.trim()))
                          ?.map(({ score, quiz }, index) => {
                            const answers = product.newCapabilityResult?.quizResults.filter(
                              (item) => item.quiz.name === quiz.name,
                            )[0].answers;
                            return (
                              <div
                                key={quiz.slug}
                                className={`d-flex justify-content-between align-items-center py-6 ${
                                  index > 0 ? "border-top" : "pt-0"
                                }`}
                              >
                                <div className="d-flex w-100 justify-content-between align-items-center">
                                  <div className="d-flex align-items-center">
                                    <div style={{ width: 24 }}>
                                      {quiz.isRecentlyUpdated && (
                                        <ChangeIndicator
                                          text="One or more questions have been recently updated"
                                          targetId={`popover-${quiz.slug}`}
                                        />
                                      )}
                                    </div>
                                    <div className="me-3">
                                      <DevOpsCapabilityIcon width={24} capabilityEnum={generateSvgName(quiz.slug)} />
                                    </div>
                                    <p className="mb-0 opacity-100 lead fw-light">{quiz.name}</p>
                                  </div>
                                  <div className="d-flex">
                                    {isMember && product?.l3EntityUid && (
                                      <Button
                                        data-testid={`retake-quiz-`}
                                        onClick={() => {
                                          setupAndOpenModal({ slugs: [quiz.slug] });
                                        }}
                                        type="button"
                                        name="retake-quiz-modal-open"
                                        Icon={Checklist24}
                                        level="quartenary"
                                        theme="standard"
                                        size="sm"
                                        loading={isLoadingQuizzes}
                                      >
                                        {answers?.length ? "Retake quiz" : "Take quiz"}
                                      </Button>
                                    )}
                                    <h4 className="mb-0 fw-light me-4">
                                      {answers?.length ? getDisplayedScore(score) : ""}
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              );
            })}
        </Col>
        <Col xs="4">
          <div className="mt-12 bg-white border w-100 p-7 rounded-5">
            <h5 className="fw-light">Maturity score</h5>
            <div className="display-4">
              {areAllQuizzesAnswered ? getDisplayedScore(product.newCapabilityResult?.score) : INCOMPLETE}
            </div>
            <p className="mb-5 fw-normal opacity-80">
              The score will be calculated once all the capability quizzes have been assessed.
            </p>
            <Progress color="brand" rounded value={(completedQuizzesCount / totalQuizzesCount) * 100} />
            <p className="mt-4 mb-0 fw-normal opacity-80">
              This team has taken {completedQuizzesCount} out of {totalQuizzesCount} quizzes
            </p>
            <hr />
            <div className="mt-6">
              <Link href="/best-practices" className="fw-normal">
                Discover the maturity framework
              </Link>
            </div>
            <div className="mt-4">
              <Link href="/knowledgehub/eRX2B5" className="fw-normal">
                Learn about product capabilities
              </Link>
            </div>
          </div>
          <div className="mt-6 bg-white border w-100 p-7 rounded-5">
            <h5 className="fw-light">Commonly asked questions</h5>
            <Accordion flush>
              {COMMON_QUESTIONS.map(({ question, answer }, index) => {
                return (
                  <Accordion.Item key={index}>
                    <Accordion.Header targetId={String(index)}>
                      <p className="m-0 fw-normal opacity-80">{question}</p>
                    </Accordion.Header>
                    <Accordion.Body accordionId={String(index)}>{answer}</Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </div>
        </Col>
      </Row>
      {product ? (
        <ProductMaturityModal
          product={product}
          form={form}
          setForm={setForm}
          quiz={quizStructure}
          isModalOpen={isModalOpen}
          refetchProduct={refetchProduct}
          setIsModalOpen={setIsModalOpen}
        />
      ) : null}
    </div>
  );
}
