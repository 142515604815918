import { Fragment, useState } from "react";
import { DropdownItem } from "reactstrap";
import { trackEvent } from "../../../../utils/event-tracker";
import { AlertBell24, Date24, Edit24, ExternalLink24, Trash24 } from "@bphxd/ds-core-react/lib/icons/index";
import { Badge } from "@bphxd/ds-core-react";
import { DropdownKebabMenu } from "../../../../components/kebab-menu";
import { GetAccelerateProductSupportGroupDetailsQuery, ProductPermissions } from "../../../../gql/graphql";
import { ConfirmationModal } from "../../../../components/modals/confirmation-modal";
import { COMPLETE_SUPPORT_GROUP_DELETION, DELETE_SUPPORT_GROUP } from "../../../../api/products/gql";
import { useMutation } from "@apollo/client";
import { useToast } from "../../../../hooks/use-toast";
import { useRaiseAlertModal } from "./raise-alert-modal-provider";
import { SUPPORT_GROUP_QUERY } from "./support-groups";

export function SupportGroupActions({
  group,
  permissions,
  onEditSupportGroupClick,
}: {
  group: GetAccelerateProductSupportGroupDetailsQuery["product"]["supportGroups"][0];
  permissions: ProductPermissions;
  onEditSupportGroupClick: (opsGroupId: string) => void;
}) {
  const kebabMenuItems = [];
  const { displayToast } = useToast();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { openModal } = useRaiseAlertModal();

  kebabMenuItems.push(
    <Fragment key={1}>
      <DropdownItem
        tag="div"
        tabIndex={0}
        onClick={() => {
          openModal(group);
          trackEvent({
            name: "button_raise-support-group-alert",
            supportGroupName: group.name,
          });
        }}
        data-testid="raise-alert-dropdown-item"
      >
        <AlertBell24 className="me-2" /> Raise new alert
      </DropdownItem>
      <DropdownItem tag="div" tabIndex={0} divider />
    </Fragment>,
  );

  if (permissions?.edit) {
    if (!group.isOnboarding) {
      if (group.links?.manageScheduleLink) {
        kebabMenuItems.push(
          <DropdownItem
            key={2}
            className="cursor-pointer"
            tag="a"
            target="_blank"
            rel="noreferrer"
            href={group.links.manageScheduleLink}
            tabIndex={0}
            onClick={(ev) => {
              ev.stopPropagation();
              trackEvent({
                name: "button_manage-support-group-schedule_click",
                supportGroupName: group.name,
              });
            }}
            data-testid="manage-schedules-dropdown-item"
          >
            <Date24 className="me-2" />
            <div>Manage schedules</div>
            <Badge color="info-subtle" className="ms-2">
              Needs Zscaler
            </Badge>
            <ExternalLink24 className="ms-auto" />
          </DropdownItem>,
        );
      }
      if (group.links?.manageScheduleLink || group.links?.manageEscalationPoliciesLink) {
        kebabMenuItems.push(<DropdownItem key={4} tag="div" tabIndex={0} divider />);
      }
    }
    kebabMenuItems.push(
      <Fragment key={5}>
        <DropdownItem
          tag="div"
          tabIndex={0}
          onClick={() => {
            onEditSupportGroupClick(group.opsGroupId);
            trackEvent({
              name: "button_edit-support-group-kebab_click",
              supportGroupName: group.name,
            });
          }}
          data-testid="edit-support-group-dropdown-item"
        >
          <Edit24 className="me-2" /> Edit support group details
        </DropdownItem>
        <DropdownItem
          tag="div"
          tabIndex={0}
          onClick={() => {
            setShowDeleteConfirmation(true);
            trackEvent({
              name: "button_delete-support-group-kebab_click",
              supportGroupName: group.name,
            });
          }}
          data-testid="delete-support-group-dropdown-item"
        >
          <Trash24 className="me-2" /> Delete support group
        </DropdownItem>
      </Fragment>,
    );
  }

  const [completeSupportGroupDeletion] = useMutation(COMPLETE_SUPPORT_GROUP_DELETION);
  const [deleteSupportGroup] = useMutation(DELETE_SUPPORT_GROUP, {
    refetchQueries: [SUPPORT_GROUP_QUERY],
    awaitRefetchQueries: true,
    onCompleted: async () => {
      await completeSupportGroupDeletion({
        variables: {
          opsGroupId: group.opsGroupId,
        },
      });
      displayToast(`You have successfully deleted support group ${group.name}.`);
      setShowDeleteConfirmation(false);
      setIsDeleting(false);
    },
    onError: () => {
      setShowDeleteConfirmation(false);
      setIsDeleting(false);
    },
  });
  return (
    <div className="mt-n3">
      {kebabMenuItems.length > 0 && (
        <DropdownKebabMenu
          trackingEventProps={{
            name: "button_support-group-kebab_click",
            supportGroupName: group.name,
          }}
          size="sm"
        >
          {kebabMenuItems}
        </DropdownKebabMenu>
      )}
      <ConfirmationModal
        isOpen={showDeleteConfirmation}
        toggle={() => setShowDeleteConfirmation(!showDeleteConfirmation)}
        title="Delete support group"
        message={`Are you sure you want to delete ${group.name}? Deleting cannot be undone and will result in the loss of any set schedules related to this support group.`}
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        loading={isDeleting}
        onConfirm={async () => {
          setIsDeleting(true);
          await deleteSupportGroup({
            variables: {
              data: {
                opsGroupId: group.opsGroupId,
              },
            },
          });
        }}
        name="delete-support-group"
      />
    </div>
  );
}
