import { useQuery } from "@apollo/client";
import { GET_USER_L3_ENTITIES } from "../../../api/products/gql";
import { L3Entity } from "../../../types/ProfileTypes";

type UseL3EntitiesOutput = {
  userL3EntitiesLoading: boolean;
  userL3Entities: L3Entity[];
};

export function useUserL3Entities(): UseL3EntitiesOutput {
  const { loading: userL3EntitiesLoading, data } = useQuery(GET_USER_L3_ENTITIES);

  return {
    userL3EntitiesLoading,
    userL3Entities: data?.userL3Entities ?? [],
  };
}
