"use client";
import { useMemo, useState } from "react";

import { useMyProducts } from "../profile/hooks/use-my-products";
import { EventContext } from "../../components/event-context";

import { Col, Row } from "reactstrap";
import { Button } from "../../components/button";
import { Link } from "../../components/Link";
import { PageHeader } from "../../components/page-header";
import { PageSection, PageWithNoPadding } from "../../components/page-template";
import { ListGroupItem } from "../../components/list-group";
import { Loader } from "../../components/spinners/loading-spinner";
import { Modal as CreateProductModal } from "../profile/products/create/modal";

import { ProductListItem } from "./product-list-item";
import { CreateProductButton } from "./create-product-button";
import { LinkingSnowAlert } from "../profile/linking-alert-message";
import { CommonlyAskedQuestions } from "./commonly-asked-questions";
import { LinkButton } from "../../components/link-button";
import { useUser } from "../../hooks/use-user";
import { useFeature } from "featurehub-react-sdk";

export const MY_PRODUCTS_PAGE_CONTEXT = "from-my-products-page";

export function MyProductsPage() {
  const breadcrumbs = [
    {
      text: "Home",
      href: `/`,
    },
    {
      text: "My Products",
    },
  ];
  const user = useUser();

  const { myProducts: allMyProducts, loading, refetch } = useMyProducts();
  const myProducts = useMemo(() => {
    return allMyProducts
      ?.filter((product) => user?.email && product.memberIds.includes(user.email.toLowerCase()))
      ?.toSorted((a, b) => a.name.localeCompare(b.name));
  }, [allMyProducts, user?.email]);

  const [isCreateProductModalOpen, setIsCreateProductModalOpen] = useState(false);
  const isAnyProductUnLinked = myProducts?.some((product) => !product.l3EntityUid);
  const isLoading = loading && !myProducts;
  const FE_SIDE_MEGA_MENU = useFeature("FE_SIDE_MEGA_MENU");
  return (
    <PageWithNoPadding name="my-products" title="my products" backgroundClassName="bg-secondary">
      <div className="bg-white pb-9">
        <PageSection>
          <PageHeader pageTitle="My Products" breadcrumbs={FE_SIDE_MEGA_MENU ? [] : breadcrumbs} />
          {myProducts?.length ? (
            <Col xs="12" md="12" xl="8" lg="6">
              <p className="lead fw-light lh-1-5">
                Click on a product to find key information, product maturity scores, improvement areas, and much more
                related to your product.
              </p>
              <Link href="/knowledgehub/H9CR3E" name="intro-to-products-and-teams" className="lead fw-light">
                Find more about Products in Accelerate
              </Link>
            </Col>
          ) : (
            <Col xs="6">
              <p className="lead fw-light lh-1-5">Find out about how to create and join a product </p>
            </Col>
          )}
        </PageSection>
      </div>
      <div className="pt-8 bg-secondary">
        <PageSection>
          {isAnyProductUnLinked && <LinkingSnowAlert />}
          <Row>
            <Col
              xs={`${myProducts?.length ? "12" : "10"}`}
              md="12"
              xl={`${myProducts?.length ? "8" : "10"}`}
              lg={`${myProducts?.length ? "8" : "10"}`}
              className="h-100 d-flex flex-column"
            >
              <EventContext.Provider value={MY_PRODUCTS_PAGE_CONTEXT}>
                <div className="px-6 py-3 rounded-5 bg-white">
                  {isLoading ? (
                    <div className="my-8 d-flex">
                      <Loader size="lg" />
                    </div>
                  ) : (myProducts?.length ?? 0) > 0 ? (
                    myProducts
                      ?.filter((product) => {
                        if (user && user?.email) {
                          return product.memberIds.includes(user.email.toLowerCase());
                        }
                      })
                      ?.toSorted((a, b) => a.name.localeCompare(b.name))
                      .map((product) => {
                        return (
                          <ListGroupItem key={product.id}>
                            <ProductListItem product={product} refetch={refetch} />
                          </ListGroupItem>
                        );
                      })
                  ) : (
                    <div className="py-11 px-14 d-flex flex-column">
                      <h5 className="fw-light align-self-baseline">You don't have any products in Accelerate yet.</h5>
                      <p className="fw-light mb-5">
                        Joining a product allows you to get metrics about your product developer performance, metrics.
                        Your product can supercharge with the help of Axel our AI assistant, providing you with ways to
                        improve.
                      </p>
                      <p className="fw-light mb-5">
                        Everybody can join a product, but only Product managers, Tech leads and Operational delegates
                        can create a product.
                      </p>
                      <Link href="/knowledgehub/H9CR3E" className="mb-6 fw-light">
                        Learn about products in Accelerate
                      </Link>
                      <div className="gap-5 d-flex">
                        <LinkButton
                          className="d-flex align-items-center"
                          data-testid="join-a-product-cta"
                          href="/all-products"
                          name="no-products-join-a-product-cta"
                          level="tertiary"
                          theme="standard"
                          rounded="pill"
                          size="sm"
                        >
                          Join a product
                        </LinkButton>
                        {loading ? (
                          <div className="ms-4 d-flex">
                            <Loader size="md" />
                            <p className="fw-light ms-4">
                              We are loading permissions to verify if your role allows you to create products.
                            </p>
                          </div>
                        ) : (
                          <Button
                            data-testid="identity-create-product-button"
                            onClick={() => {
                              setIsCreateProductModalOpen(true);
                            }}
                            name="create-product-from-empty-my-products"
                            level="tertiary"
                            theme="standard"
                            rounded="pill"
                          >
                            Create a product
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </EventContext.Provider>
            </Col>
            {myProducts?.length ? (
              <Col xs="10" md="12" xl="4" sm="8" lg="4" className="mt-5 mt-xl-0">
                <div className="bg-white p-7 rounded-5">
                  <div className="gap-5 d-flex flex-column">
                    <LinkButton
                      className="bg-white w-100"
                      data-testid="join-a-product-cta"
                      href="/all-products"
                      name="join-a-product-cta"
                      level="tertiary"
                      theme="standard"
                      rounded="pill"
                      size="sm"
                    >
                      Join a product
                    </LinkButton>
                    <CreateProductButton onClick={() => setIsCreateProductModalOpen(true)} />
                  </div>
                </div>
                <div className="mt-6 bg-white p-7 rounded-5">
                  <div className="gap-5 d-flex flex-column">
                    <CommonlyAskedQuestions />
                  </div>
                </div>
              </Col>
            ) : null}
          </Row>
        </PageSection>

        <CreateProductModal
          isModalOpen={isCreateProductModalOpen}
          toggle={() => {
            setIsCreateProductModalOpen(!isCreateProductModalOpen);
          }}
        />
      </div>
    </PageWithNoPadding>
  );
}
