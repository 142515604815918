import { useState } from "react";
import { useCurrentUserProfile } from "../../../../views/profile/hooks/use-current-user-profile";
import { formatMillisToDateTime } from "../../../../utils/dates";
import { trackEvent } from "../../../../utils/event-tracker";

import { DropdownItem } from "reactstrap";
import { Trash24 } from "@bphxd/ds-core-react/lib/icons";
import { Badge } from "../../../../components/badges/badge";
import { DropdownKebabMenu } from "../../../../components/kebab-menu";
import { AlertType } from "./alerts-and-incidents";
import { MoreOrLessSection } from "../../../../views/search/more-or-less-section";
import { useMutation } from "@apollo/client";
import { DELETE_ALERT } from "../../../../api/products/gql";
import { SUPPORT_GROUP_QUERY } from "../../../../views/profile/products/support-groups/support-groups";
import { useToast } from "../../../../hooks/use-toast";
import { Loader } from "../../../../components/spinners/loading-spinner";
import { AlertStatusType } from "../../../../gql/graphql";

type AlertDetailProps = {
  alert: AlertType;
};

export function AlertDetail({ alert }: AlertDetailProps) {
  const { groupName, description, raisedBy, alertStatus, dateRaised, resolutionNote } = alert;
  const { displayToast } = useToast();
  const [isRefetching, setIsRefetching] = useState(false);

  const [deleteAlert, { loading }] = useMutation(DELETE_ALERT, {
    refetchQueries: [SUPPORT_GROUP_QUERY],
    awaitRefetchQueries: true,
    async onCompleted() {
      setIsRefetching(false);
      displayToast(`You have successfully deleted an alert`);
    },
    onError() {
      setIsRefetching(false);
    },
  });

  const handleDeleteAlert = async (id: string) => {
    await deleteAlert({
      variables: {
        deleteAlertId: id,
      },
    });
  };

  const getBadgeColor = (alertStatus: AlertStatusType) => {
    switch (alertStatus) {
      case AlertStatusType.Inprogress:
        return "info-subtle";
      case AlertStatusType.Pending:
        return "warning-subtle";
      case AlertStatusType.Resolved:
        return "success-subtle";
    }
  };
  const { currentUserProfile } = useCurrentUserProfile();
  const isLoading = loading || isRefetching;

  return (
    <div className="d-flex flex-column flex-grow-1 w-100" data-testid={`alert-${alert.id}`}>
      {isLoading ? (
        <Loader size={"sm"} />
      ) : (
        <div className="d-flex justify-content-between align-items-center">
          <div className="col-6">
            <div className="fw-light fs-6 text-truncate text-wrap pe-8">{description}</div>
            <div className="small mb-0 fw-light text-truncate opacity-80">
              Raised on {formatMillisToDateTime(dateRaised)} by {raisedBy?.givenName} {raisedBy?.surname}
            </div>
          </div>
          <p className="fw-light small mb-0 col text-truncate pe-2 text-start">{groupName}</p>
          <div className="col-2 text-end">
            <Badge color={getBadgeColor(alertStatus)} label={alertStatus.replace("INPROGRESS", "IN PROGRESS")} />
          </div>
          {raisedBy?.email === currentUserProfile._id && (
            <DropdownKebabMenu
              trackingEventProps={{
                name: "button_alert-menu_click",
              }}
              size="md"
              id={alert.id}
            >
              <DropdownItem
                onClick={() => {
                  handleDeleteAlert(alert.id);
                  trackEvent({
                    name: "button_alert-delete_click",
                  });
                }}
              >
                <Trash24 className="me-2" /> Delete alert
              </DropdownItem>
            </DropdownKebabMenu>
          )}
        </div>
      )}
      {resolutionNote && (
        <div className="d-flex w-100 align-items-end">
          <MoreOrLessSection buttonPosition="end">
            <p className="fw-light small mb-0">
              Resolution note: <span className="fw-normal">{resolutionNote}</span>
            </p>
          </MoreOrLessSection>
        </div>
      )}
    </div>
  );
}
