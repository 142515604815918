import ActiveStarIcon from "../../icons/brand/star";
import { Star24 as InactiveStarIcon } from "@bphxd/ds-core-react/lib/icons/index";
import { Loader } from "../../components/spinners/loading-spinner";
import { useToggleFavourite } from "./use-toggle-favourite";

export function ProductFavorite({ productId }: { productId: string | null }) {
  const { isFavourite, loading, toggleFavourite } = useToggleFavourite(productId);

  if (loading) {
    return <Loader size="sm" className="me-2" />;
  }

  return isFavourite ? (
    <ActiveStarIcon
      className="cursor-pointer"
      onClick={(e) => {
        e.stopPropagation();
        toggleFavourite();
      }}
    />
  ) : (
    <InactiveStarIcon
      className="cursor-pointer"
      onClick={(e) => {
        e.stopPropagation();
        toggleFavourite();
      }}
    />
  );
}
