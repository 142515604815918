import { UncontrolledTooltip } from "reactstrap";

export function ChangeIndicator({ text, targetId }: { text: string; targetId: string }) {
  return (
    <>
      <UncontrolledTooltip placement="auto" target={targetId}>
        {text}
      </UncontrolledTooltip>
      <div
        data-testid="change-indicator position-relative"
        style={{
          width: 10,
          height: 5,
        }}
        id={targetId}
      >
        <div
          className="position-absolute rounded-circle z-1"
          style={{
            width: 10,
            height: 10,
            backgroundColor: "#218DCC",
          }}
        />
      </div>
    </>
  );
}
