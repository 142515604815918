import { useQuery } from "@apollo/client";
import { GET_ACCELERATE_PRODUCTS } from "../../../api/products/gql";
import { BasicProductFieldsFragmentDoc } from "../../../gql/graphql";
import { getFragmentData } from "../../../gql";

export function useAccelerateProductsQuery() {
  const { data, refetch, loading, error } = useQuery(GET_ACCELERATE_PRODUCTS, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
  });

  return { data, refetch, loading, error };
}

export function useMyProducts() {
  const { data, refetch, loading } = useAccelerateProductsQuery();

  return {
    myProducts: data?.currentUserProfile.products?.map((product) =>
      getFragmentData(BasicProductFieldsFragmentDoc, product),
    ),
    refetch,
    loading,
  };
}
