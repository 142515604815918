import { forwardRef } from "react";
import type { MultiValue, Props } from "react-select";
import Select from "../../../../components/Select";
import { TeamMemberInfo } from "./team-member-info";

export type UserOption = {
  value: string;
  label: string;
  resource: {
    email: string;
    displayName: string;
    givenName: string;
    surname: string;
    picture?: string;
    userPrincipalName: string;
    label?: string;
    value: string;
  };
};
interface ExtendedProps extends Omit<Props, "value" | "onChange" | "onInputChange" | "placeholder"> {
  value: MultiValue<UserOption> | string[];
  options: UserOption[];
  placeholder: string;
  onInputChange: (newValue: string) => void;
}

const formatOptionLabel = (data: UserOption) => {
  return <TeamMemberInfo displayName={data.resource.displayName} email={data.resource.userPrincipalName} />;
};

const TeamMembersDropdown = forwardRef((props: ExtendedProps, ref) => {
  return (
    <Select
      classNamePrefix="react-select"
      isMulti
      hideSelectedOptions={false}
      formatOptionLabel={formatOptionLabel}
      {...props}
      ref={ref}
    />
  );
});

TeamMembersDropdown.displayName = "TeamMembersDropdown";

export default TeamMembersDropdown;
