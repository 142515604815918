"use client";
import { useMemo } from "react";
import { BasicProductFieldsFragment, ProductResponse } from "../gql/graphql";
import { useUser } from "./use-user";

/**
 * Custom hook to check if the current user is a member of the product team
 *
 * @param {ProductResponse | undefined} product - The product object containing member IDs
 * @returns {boolean} - Returns true if the user is a member, otherwise false
 */
export function useIsUserMember(
  product?: ProductResponse | Omit<BasicProductFieldsFragment, " $fragmentName">,
): boolean {
  const user = useUser();

  return useMemo(
    () => product?.memberIds?.some((member) => member.toLowerCase() === (user?.email ?? "").toLowerCase()) ?? false,
    [product, user],
  );
}
