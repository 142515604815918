"use client";

import type { PropsWithChildren } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { MsalAuthProvider } from "./msal-auth-provider";
import { FeatureHubWrapper } from "./feature-hub-wrapper";
import { ToastProvider } from "./toast-provider";
import { DndProvider } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import { msalInstance } from "../utils/helpers/msal-helper";
import { HelmetProvider } from "react-helmet-async";
import { NextProgressBar } from "../components/next-progress-bar";
import { ProductDropdownProvider } from "./product-dropdown-provider";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const AppWrapper: React.FC<PropsWithChildren> = (props) => (
  <HelmetProvider>
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <MsalAuthProvider instance={msalInstance}>
        <DndProvider options={HTML5toTouch}>
          <FeatureHubWrapper>
            <ProductDropdownProvider>
              <ToastProvider>
                <NextProgressBar>{props.children}</NextProgressBar>
              </ToastProvider>
            </ProductDropdownProvider>
          </FeatureHubWrapper>
        </DndProvider>
      </MsalAuthProvider>
    </QueryClientProvider>
  </HelmetProvider>
);
