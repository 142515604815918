"use client";
import { useEffect, useRef, useState } from "react";
import { Tooltip, Button } from "@bphxd/ds-core-react";
import { Info16 } from "@bphxd/ds-core-react/lib/icons";

type TooltipProps = {
  name: string;
  description: string;
};

export const DecoratedTooltip = ({ name, description }: TooltipProps) => {
  const tooltipTarget = useRef<HTMLDivElement | null>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  // Show tooltip with a delay
  useEffect(() => {
    const pointer = setTimeout(() => setShowTooltip(true), 250);
    return () => clearTimeout(pointer);
  }, []);

  return (
    <>
      <div className="d-flex align-items-center">
        <div ref={(ref) => (tooltipTarget.current = ref)}>
          <Button
            className="ps-0 me-3"
            name={`${name}-tooltip`}
            level="quartenary"
            Icon={Info16}
            theme="standard"
            iconPosition="end"
            size="xs"
          >
            {name}
          </Button>
        </div>
      </div>

      {showTooltip && (
        <Tooltip autohide fade flip placement="bottom" target={tooltipTarget.current as HTMLElement}>
          {description}
        </Tooltip>
      )}
    </>
  );
};
