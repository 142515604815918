"use client";
import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";

import { useToast } from "../../../../hooks/use-toast";
import { GET_QUIZ_RESULT, SUBMIT_PRODUCT_QUIZ } from "../../../../api/quizzes/gql";

import { ModalBody, ModalFooter } from "reactstrap";

import { Button } from "../../../../components/button";
import Input from "../../../../components/Input";
import { FormHeadingV2 } from "../../../../components/form-heading";
import { Modal } from "../../../../components/modal";
import { ProgressIndicator } from "../../../../components/progress-indicator";

import { Answer } from "../../../bestPractice/types/Categories";
import { Product } from "../../../../types/ProfileTypes";
import { Question, Quiz } from "../../../../gql/graphql";
import { useFeature } from "featurehub-react-sdk";

function MetricQuestion({
  productName,
  question,
  onChange,
  answerOptionId,
}: {
  productName: string;
  question: Question;
  onChange: (metric: string, answer: Answer) => void;
  answerOptionId?: number | null;
}) {
  function inputId(metric: string, questionId: number, optionId: number) {
    return `${metric}-${questionId}_${optionId}`;
  }

  return (
    <div>
      <p className="pb-2 small fw-light">{question.question.replace(/\[product_name\]/, productName)}</p>
      <form>
        {question.options.map((option, index) => {
          const name = question.metric!;
          return (
            <div
              key={option.id}
              className={`d-flex gap-3 align-items-center ${question.options.length - 1 === index ? "" : "mb-3 pb-2"} `}
            >
              <Input
                type="radio"
                name={name}
                value={option.id}
                id={inputId(name, question.id, option.id)}
                onChange={() =>
                  onChange(name, {
                    question_id: question.id,
                    option_id: option.id,
                  })
                }
                checked={answerOptionId === option.id}
              />
              <label htmlFor={inputId(name, question.id, option.id)} className="ps-2 fw-light fs-6">
                {option.label}
              </label>
            </div>
          );
        })}
      </form>
    </div>
  );
}

export function SetAndEditProductMetricsModal({
  isOpen,
  toggle,
  product,
  quiz,
  selectedMetric,
  previousAnswers,
}: {
  isOpen: boolean;
  toggle: () => void;
  product: Product;
  quiz: Quiz;
  selectedMetric?: string;
  previousAnswers?: Answer[];
}) {
  const { displayToast } = useToast();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState<Answer[]>([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const hideEmpiricalData = useFeature("FE_HIDE_EMPIRICAL_DATA");

  const perceptionQuestions = hideEmpiricalData
    ? quiz.questions
    : quiz.questions?.filter((q) => {
        const existingEmpiricalMetrics = [];
        if (product?.metrics?.leadTime) {
          existingEmpiricalMetrics.push("Lead time");
        }

        if (product?.metrics?.deploymentFrequency) {
          existingEmpiricalMetrics.push("Deploy frequency");
        }

        return !existingEmpiricalMetrics.includes(q.metric!);
      });

  const editMode = typeof selectedMetric !== "undefined";
  const lastQuestion = editMode || (perceptionQuestions && currentQuestionIndex === perceptionQuestions?.length - 1);

  function handleValueSelect(metric: string, answer: Answer) {
    setIsDirty(true);
    setAnswers((answers) => {
      const questionId = quiz.questions.find((q) => q.metric === metric)?.id ?? "";
      const newAnswers = [...answers];
      const prexistingAnswer = newAnswers.find((a) => a.question_id === questionId);
      if (prexistingAnswer) {
        prexistingAnswer.option_id = answer.option_id;
      }
      return newAnswers;
    });
  }

  function handleNextClick() {
    setIsDirty(false);
    if (lastQuestion) {
      handleSubmit();
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  }
  function handlePreviousClick() {
    setIsDirty(true);
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  }

  const [submitQuizResponse] = useMutation(SUBMIT_PRODUCT_QUIZ, {
    onCompleted() {
      toggle();
      setIsSubmitting(false);
      displayToast(
        editMode
          ? "You have successfully edited a metric for this product"
          : "You have successfully added metrics for this product",
      );
    },
    onError() {
      setIsSubmitting(false);
    },
    refetchQueries: [GET_QUIZ_RESULT],
  });

  function resetForm() {
    setAnswers([]);
    setCurrentQuestionIndex(0);
    setIsDirty(false);
  }

  async function handleSubmit() {
    setIsSubmitting(true);
    await submitQuizResponse({
      variables: {
        data: {
          productId: product.id,
          answers: answers.map((answer) =>
            answer.option_id === null
              ? { question_id: answer.question_id, option_id: 1 }
              : { question_id: answer.question_id, option_id: answer.option_id },
          ),
          quizSlug: quiz.slug,
        },
      },
    });
    setIsSubmitting(false);
  }

  useEffect(() => {
    resetForm();
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && previousAnswers) {
      setAnswers(
        [...previousAnswers]
          .map(({ option_id, question_id }) => ({
            question_id,
            option_id,
          }))
          .sort((a, b) => a.question_id - b.question_id),
      );
    } else if (isOpen) {
      setAnswers(
        [...quiz.questions]
          .map(({ id }) => ({
            question_id: id,
            option_id: null,
          }))
          .sort((a, b) => a.question_id - b.question_id),
      );
    }
    if (editMode) {
      setCurrentQuestionIndex(perceptionQuestions.findIndex((q) => q.metric === selectedMetric));
    }
  }, [isOpen, selectedMetric]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={toggle}
      showHeader
      hasCancelDialog
      header={
        editMode
          ? `Update value for ${perceptionQuestions[currentQuestionIndex]?.metric?.toLowerCase()}`
          : previousAnswers
            ? "Update metrics"
            : "Set metrics"
      }
    >
      <ModalBody className="px-6 pb-7">
        {!editMode && (
          <FormHeadingV2
            currentStep={currentQuestionIndex + 1}
            totalSteps={perceptionQuestions.length}
            title={perceptionQuestions[currentQuestionIndex].metric!}
          />
        )}
        <MetricQuestion
          productName={product.name}
          question={perceptionQuestions[currentQuestionIndex]!}
          onChange={handleValueSelect}
          answerOptionId={
            answers.find((a) => a.question_id === perceptionQuestions[currentQuestionIndex].id)?.option_id
          }
        />
      </ModalBody>
      <ModalFooter>
        <div
          className={`w-100 d-flex ${
            perceptionQuestions.length <= 2 ? "justify-content-end gap-5" : "justify-content-between"
          } align-items-center`}
        >
          <div className={perceptionQuestions.length > 2 ? "col" : "col-auto"}>
            {currentQuestionIndex > 0 && !editMode && (
              <Button
                data-testid="previous-metric-button"
                type="submit"
                name={`back-quick-check-question-${currentQuestionIndex + 1}`}
                level="tertiary"
                theme="standard"
                rounded="pill"
                onClick={handlePreviousClick}
              >
                Previous
              </Button>
            )}
          </div>
          {perceptionQuestions.length > 2 && !editMode && (
            <div className="col align-self-center d-flex justify-content-center">
              <ProgressIndicator currentStep={currentQuestionIndex + 1} totalSteps={perceptionQuestions?.length} />
            </div>
          )}

          <div className={`${perceptionQuestions.length > 2 ? "col" : "col-auto"} text-end`}>
            <Button
              data-testid="next-metric-button"
              type="submit"
              name={`answer-quick-check-question-${currentQuestionIndex + 1}`}
              level="primary"
              theme="standard"
              rounded="pill"
              loading={isSubmitting}
              disabled={!isDirty}
              onClick={handleNextClick}
            >
              {lastQuestion ? "Submit" : "Next"}
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
}
