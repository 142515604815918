import { createContext, useContext, useState, useMemo, ReactNode } from "react";
import { useUser } from "../hooks/use-user";
import { useAllProducts } from "../views/profile/hooks/use-all-products";
import { ProductsQuery } from "../gql/graphql";

function sortProductsByName(products: ProductsQuery["products"]) {
  return products.toSorted((a, b) => a.name.localeCompare(b.name));
}

type ProductDropdownContextType = {
  myProductsList: ProductsQuery["products"];
  allProducts: ProductsQuery["products"];
  selectedProduct?: ProductsQuery["products"][number];
  setSelectedProduct: (product: ProductsQuery["products"][number]) => void;
  starredProductId?: string | null;
  refetchUser?: () => void;
};

const ProductDropdownContext = createContext<ProductDropdownContextType>({
  myProductsList: [],
  allProducts: [],
  selectedProduct: undefined,
  setSelectedProduct: () => {},
  starredProductId: null,
  refetchUser: () => {},
});

export function ProductDropdownProvider({ children }: { children: ReactNode }) {
  const user = useUser();
  const refetchUser = user?.refetch;
  const starredProductId = user?.starredProductId;

  // Fetch all products
  const { data } = useAllProducts();
  const allProducts = data?.products || [];

  const myProductsList = useMemo(
    () =>
      sortProductsByName(
        allProducts?.filter((product) => user?.email && product.memberIds.includes(user.email.toLowerCase())) || [],
      ),
    [allProducts, user?.email],
  );

  // State for selected product (only id and name)
  const [selectedProduct, setSelectedProduct] = useState<ProductsQuery["products"][number]>();

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      myProductsList,
      allProducts,
      selectedProduct,
      setSelectedProduct,
      starredProductId,
    }),
    [myProductsList, selectedProduct, starredProductId, allProducts],
  );

  return (
    <ProductDropdownContext.Provider value={{ ...contextValue, refetchUser }}>
      {children}
    </ProductDropdownContext.Provider>
  );
}

export function useProductDropdownContext() {
  const context = useContext(ProductDropdownContext);
  if (!context) {
    throw new Error("useProductDropdownContext must be used within a ProductDropdownProvider");
  }
  return context;
}
