import { type YallaChange } from "../../../../../../../gql/graphql";
import { formatMillisToDateTime } from "../../../../../../../utils/dates";
import { changeKindDisplay } from "../instance/[instance_id]/components/change-info-item";

type ChangeStatusProps = {
  change: YallaChange;
};

export function ChangeStatus({ change }: ChangeStatusProps) {
  const { icon, text } = changeKindDisplay(change.kind);
  return (
    <small className="mt-3 mb-0 fw-light lh-1 opacity-70">
      <span>{icon}</span>
      <span>{`${text} | ${change.serviceName} | ${formatMillisToDateTime(new Date(change.date).valueOf())}`}</span>
    </small>
  );
}
