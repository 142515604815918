import { ReactNode } from "react";
import { Link } from "./Link";

type ListGroupItemProps = {
  small?: boolean;
  large?: boolean;
  href?: string;
  children: ReactNode;
};

export function ListGroupItem({ href, small, children }: ListGroupItemProps) {
  const pt = small ? "3" : "4";
  const pb = small ? "3" : "4";
  return (
    <div
      className={`d-flex pb-${pb} pt-${pt} position-relative align-items-center border-bottom border-op-light-gray-500 last-child:border-bottom-0`}
    >
      {href ? (
        <Link href={href} doNotTrack>
          {children}
        </Link>
      ) : (
        children
      )}
    </div>
  );
}

type ListGroupProps = {
  children: ReactNode;
};
export function ListGroup({ children }: ListGroupProps) {
  return <div className="border px-6 py-3 mt-3 rounded-5 bg-white">{children}</div>;
}
