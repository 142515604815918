"use client";
import { useState, createContext, useContext, ReactNode } from "react";
import { SetupProductSupportGroupModal } from "../setup-product-support-groups/modal";
import { GetAccelerateProductSupportGroupDetailsQuery } from "../../../../gql/graphql";

type ModalContextProps = {
  openModal: (
    product: GetAccelerateProductSupportGroupDetailsQuery["product"],
    group?: GetAccelerateProductSupportGroupDetailsQuery["product"]["supportGroups"][0],
  ) => void;
  closeModal: () => void;
};

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export function SupportGroupProvider({ children }: { children: ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);
  const [product, setProduct] = useState<GetAccelerateProductSupportGroupDetailsQuery["product"] | undefined>();
  const [selectedGroup, setSelectedGroup] = useState<
    GetAccelerateProductSupportGroupDetailsQuery["product"]["supportGroups"][0] | undefined
  >();

  const openModal = (
    product: GetAccelerateProductSupportGroupDetailsQuery["product"],
    group?: GetAccelerateProductSupportGroupDetailsQuery["product"]["supportGroups"][0],
  ) => {
    setProduct(product);
    setSelectedGroup(group);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setProduct(undefined);
    setSelectedGroup(undefined);
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {product && (
        <SetupProductSupportGroupModal
          isOpen={isOpen}
          toggle={closeModal}
          product={product}
          selectedGroup={selectedGroup}
        />
      )}
    </ModalContext.Provider>
  );
}

export function useSetupSupportGroupModal() {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useSetupProductSupportGroupModal must be used within a SupportGroupProvider");
  }
  return context;
}
