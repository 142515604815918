import { Modal } from "../../../../../components/modal";
import { CreateResourceForm } from "./create-resource-form";

import { Product } from "../../../../../types/ProfileTypes";

type CreateResourceModalProps = {
  isModalOpen: boolean;
  toggle: () => void;
  selectedProduct?: Product;
};

export function CreateResourceModal({ isModalOpen, toggle, selectedProduct }: CreateResourceModalProps) {
  return (
    <Modal
      size="lg"
      backdrop="static"
      isOpen={isModalOpen}
      hasCancelDialog
      header={`Add a resource for the '${selectedProduct?.name}' product`}
      onClose={toggle}
    >
      <CreateResourceForm onClose={toggle} selectedProduct={selectedProduct} />
    </Modal>
  );
}
