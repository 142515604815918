"use client";
import { useLocation } from "./use-location";
import { useEffect, useState } from "react";
import { Breadcrumbs } from "../components/page-header";
import { MY_PRODUCTS_PAGE_CONTEXT } from "../views/products/my-products";

export enum PageSource {
  MyProducts = "my-products",
  AllTools = "tools",
  AllProducts = "all-products",
}

export function useBreadCrumbs({
  pageContext,
  loading = false,
  previousLevels = [],
  text,
}: {
  pageContext?: string | undefined | string[];
  loading?: boolean;
  previousLevels?: { text: string; href?: string }[];
  text?: string;
}) {
  const { search } = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumbs[]>([]);

  let sourceBreadCrumb: Record<string, string> = {};

  switch (search.source) {
    case PageSource.AllProducts:
      sourceBreadCrumb = {
        text: "All products",
        href: `/all-products`,
      };
      break;
    case PageSource.MyProducts:
      sourceBreadCrumb = {
        text: "My products",
        href: `/my-products`,
      };
      break;
    case PageSource.AllTools:
      sourceBreadCrumb = {
        text: "All tools",
        href: "/technology/tools?context=tools",
      };
      break;
    default:
      sourceBreadCrumb = {
        text: `My Products`,
        href: "/my-products/",
      };
  }

  useEffect(() => {
    if (!loading) {
      const context = search["context"] ? search["context"] : pageContext;
      switch (context) {
        case MY_PRODUCTS_PAGE_CONTEXT:
        case PageSource.AllTools:
          setBreadcrumbs([
            {
              text: "Home",
              href: `/`,
            },
            {
              text: "All tools",
            },
          ]);
          break;
        default:
          setBreadcrumbs([
            {
              text: "Home",
              href: `/`,
            },
            {
              ...(sourceBreadCrumb as Breadcrumbs),
            },
            ...previousLevels,
            ...(text ? [{ text }] : []),
          ]);
          break;
      }
    }

    return () => {
      setBreadcrumbs([]);
    };
  }, [JSON.stringify(search), loading, text]);

  return [breadcrumbs];
}
