import { Button } from "../../components/button";
import { useToggleFavourite } from "./use-toggle-favourite";

export function ProductFavoriteButton({ productId }: { productId: string | null }) {
  const { isFavourite, toggleFavourite } = useToggleFavourite(productId);
  return (
    <Button
      name="set-default-product"
      type="button"
      level="quartenary"
      theme="standard"
      rounded="pill"
      size="xs"
      className="btn px-0 x5-me-3"
      onClick={(e) => {
        e.stopPropagation();
        toggleFavourite();
      }}
    >
      {isFavourite ? "Default" : "Set default"}
    </Button>
  );
}
