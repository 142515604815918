"use client";
import { useEffect, useRef, useState, ReactNode } from "react";
import { useResizeObserver } from "usehooks-ts";

import { Down16, Up16 } from "@bphxd/ds-core-react/lib/icons";
import { Button } from "../../components/button";

import { Event } from "../../telemetry/enums";

type MoreOrLessSectionProps = {
  buttonPosition: string;
  trackingEventProps?: Record<string, any>;
  testId?: string;
  children: ReactNode;
};

export function MoreOrLessSection({
  buttonPosition,
  trackingEventProps = {},
  children,
  testId = "",
}: MoreOrLessSectionProps) {
  const [sectionExpanded, setSectionExpanded] = useState(false);
  const [areLinesClamped, setAreLinesClamped] = useState(true);

  const sectionRef = useRef<HTMLDivElement>(null);
  const sectionSize = useResizeObserver({ ref: sectionRef });
  const btnText = `Show ${!sectionExpanded ? "more" : "less"}`;

  useEffect(() => {
    const section = sectionRef.current;
    if (section) {
      // if the resolution note is overflowing, show the "see more" button
      if (section.offsetHeight < section.scrollHeight) {
        setAreLinesClamped(true);
        setSectionExpanded(false);
      } else {
        setAreLinesClamped(false);
      }
    }
  }, [sectionSize, sectionRef.current]);

  return (
    <div className="d-flex flex-column">
      <div className={!sectionExpanded ? "line-clamp-2" : ``} data-testid={testId} ref={sectionRef}>
        {children}
      </div>
      {(areLinesClamped || sectionExpanded) && (
        <div className={`d-flex justify-content-${buttonPosition}`}>
          <Button
            onClick={() => {
              setSectionExpanded((prev) => !prev);
            }}
            trackingEventProps={{
              name: Event.ButtonClick,
              description: btnText,
              ...trackingEventProps,
            }}
            Icon={!sectionExpanded ? Down16 : Up16}
            iconPosition="end"
            level="quartenary"
            theme="standard"
            size="sm"
          >
            {btnText}
          </Button>
        </div>
      )}
    </div>
  );
}
