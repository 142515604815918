import { useProductDropdownContext } from "../../providers/product-dropdown-provider";
import { useMutation } from "@apollo/client";
import { FAVORITE_PRODUCT } from "../../api/products/gql";

export function useToggleFavourite(productId: string | null) {
  const { starredProductId, refetchUser } = useProductDropdownContext();
  const [favoriteProductMutation, { loading }] = useMutation(FAVORITE_PRODUCT);

  const isFavourite = starredProductId === productId;

  const toggleFavourite = async () => {
    await favoriteProductMutation({
      variables: {
        productId: isFavourite ? null : productId,
      },
    });
    if (refetchUser) {
      refetchUser();
    }
  };

  return { isFavourite, loading, toggleFavourite };
}
