import { ConnectRepositoriesCard } from "../../../../views/profile/connect-repositories-card";
import { Folder24, Info24 } from "@bphxd/ds-core-react/lib/icons/index";
import { ListGroup } from "../../../../components/list-group";
import { Button } from "../../../../components/button";
import { Link } from "../../../../components/Link";
import { Product } from "../../../../types/ProfileTypes";
import { Popover } from "../../../../components/tooltip/popover";
import { useIsUserPrivileged } from "../../../../views/profile/products/utils";

type RepositoriesProps = {
  product: Product;
  onEditClick: () => void;
};

export function Repositories({ product, onEditClick }: RepositoriesProps) {
  const userHasPermissions = useIsUserPrivileged(product);

  return product.repositoryInfo?.repositories.length === 0 ? (
    <ConnectRepositoriesCard onEditClick={onEditClick} userHasPermissions={userHasPermissions} />
  ) : (
    <ListGroup>
      <div className="mt-5 d-flex justify-content-between">
        <div className="d-flex">
          <h5 className="mb-0">Repositories</h5>
          <div id="reposId" className="cursor-pointer">
            <Info24 className="opacity-60" />
            <Popover
              target="reposId"
              testId="repositories-tooltip-test-id"
              title="Repositories"
              placement="right"
              toolTipText={
                <div>
                  <p>
                    Repositories drive the calculation of automated development velocity metrics for this product like
                    Lead time and Deploy frequency.
                  </p>
                  <p>Only Product managers, Tech leads and Operational delegates can edit this list.</p>
                </div>
              }
            />
          </div>
        </div>
        <Button
          level="tertiary"
          theme="standard"
          data-testid="edit-repos-button"
          rounded="pill"
          size="sm"
          name="Edit"
          onClick={onEditClick}
          disabled={!userHasPermissions}
        >
          Edit
        </Button>
      </div>
      <p className="mt-5 mb-5 small">
        <span className="fw-light">Organisation:</span> <span>{product.repositoryInfo?.organisationName}</span>
      </p>
      <p className="mt-5 mb-5 small">
        <span className="fw-light">Project:</span> <span>{product.repositoryInfo?.projectName}</span>
      </p>
      <div className="mt-3">
        {product.repositoryInfo?.repositories.map((repo, index) => (
          <div key={index} className="d-flex py-4 border-bottom fw-light small">
            <Folder24 className="me-2" />
            <Link href={repo.link} dataTestid={repo.name}>
              {repo.name}
            </Link>
          </div>
        ))}
      </div>
    </ListGroup>
  );
}
