"use client";
import { useState } from "react";

import { ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button, HelpMeImproveMyCapabilityButton } from "../../components/button";
import { Link } from "../../components/Link";
import {
  IndicatorProgressBars,
  METRIC_PROGRESS_BAR_COLORS,
  calculateDeployFrequencyArrowPosition,
  calculateLeadTimeArrowPosition,
} from "../../components/cards/indicator-progress-bars";

import { LAST_12_MONTHS, MetricPeriod } from "./metric-period";

import { Product } from "../../types/ProfileTypes";
import { Metric } from "../../components/cards/metric-info-data";
import { calculateTrendLength, NoMetricData } from "./empirical-metric-card";
import { LinkButton } from "../../components/link-button";
import { useLocation } from "../../hooks/use-location";
import { updateQueryParams } from "../../utils/update-search-params";
import { calculatePeriodLength, SelectedMetrics } from "../../app/profile/product/components/metrics-tab";
import { TABS } from "./profile-product-page";

export const ExploreMetric = ({ metricName }: { metricName: Metric }) => {
  const { pathname, search } = useLocation();

  const metric = Object?.entries(SelectedMetrics)?.find(([, value]) => (value as string) === metricName)?.[0];

  return (
    <LinkButton
      level="tertiary"
      theme="standard"
      rounded="pill"
      size="xs"
      name="explore-metric"
      href={`${pathname}?${new URLSearchParams(
        updateQueryParams(search, {
          metric,
          tab: TABS.metrics,
          id: "",
        }) as Record<string, string>,
      )}`}
    >
      Explore
    </LinkButton>
  );
};

export const ExploreMetricModal = ({
  isOpen,
  toggle,
  product,
  metricName,
}: {
  isOpen: boolean;
  toggle: () => void;
  metricName: string;
  product: Pick<Product, "name" | "metrics">;
}) => {
  const [period, setPeriod] = useState(LAST_12_MONTHS);

  const metric = product.metrics?.deploymentFrequency;
  const allDeployFrequencyNoData = metric?.perPipeline?.every((pipelineInfo) => {
    if (period === LAST_12_MONTHS) {
      return !pipelineInfo.periodDeploymentCount;
    } else {
      return !pipelineInfo.trendingPeriodDeploymentCount;
    }
  });

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} className="border-bottom">
        {metricName} product insights
      </ModalHeader>
      <ModalBody className="px-9 py-7">
        <p className="fw-light">Your {metricName} metrics are calculated from these repositories and pipelines.</p>

        <MetricPeriod
          period={period}
          onPeriodChange={(_, newPeriod) => setPeriod(newPeriod)}
          trendLength={calculateTrendLength(metric?.trendingPeriodStartDate)}
        />

        <div className="overflow-y-scroll overflow-x-hidden" style={{ maxHeight: "calc(50vh)" }}>
          {allDeployFrequencyNoData ? (
            <NoMetricData />
          ) : (
            metric?.perPipeline?.map((pipelineInfo, index) => {
              const { name, id, projectName, organizationName } = pipelineInfo.pipeline;
              let value;

              if (period === LAST_12_MONTHS) {
                value =
                  pipelineInfo.periodDeploymentCount /
                  calculatePeriodLength(pipelineInfo.periodStartDate, pipelineInfo.periodEndDate);
              } else {
                value = pipelineInfo.trendingPeriodDeploymentCount;
              }
              return (
                <ListGroupItem key={index} className={`${index > 0 ? "border-top" : ""} py-5`}>
                  <div className="d-flex justify-content-between w-100 align-items-center">
                    <div className="w-70">
                      <Link
                        href={`https://dev.azure.com/${organizationName}/${projectName}/_build?definitionId=${id}`}
                        hideExternalLinkIcon
                        className="fw-light mb-0"
                      >
                        {name}
                      </Link>
                    </div>
                    <div className="w-30">
                      {"leadTimeHours" in pipelineInfo ? (
                        <IndicatorProgressBars
                          arrowPosition={calculateLeadTimeArrowPosition(value).arrowPosition}
                          progressBars={METRIC_PROGRESS_BAR_COLORS}
                        />
                      ) : "periodDeploymentCount" in pipelineInfo ? (
                        <IndicatorProgressBars
                          arrowPosition={calculateDeployFrequencyArrowPosition(value).arrowPosition}
                          progressBars={METRIC_PROGRESS_BAR_COLORS}
                        />
                      ) : null}
                    </div>
                  </div>
                </ListGroupItem>
              );
            })
          )}
        </div>
      </ModalBody>
      <ModalFooter className="px-8 d-flex justify-content-end">
        <Button
          data-testid="next-select-pipeline-button-1"
          type="button"
          name="next-select-pipeline-step-1"
          level="tertiary"
          theme="standard"
          rounded="pill"
          onClick={toggle}
        >
          Close
        </Button>
        <HelpMeImproveMyCapabilityButton capabilityName={metricName} size="md" />
      </ModalFooter>
    </Modal>
  );
};
