import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { Filter, FilterOption, Filters } from "./filters";

export type FilterGroupProps<T> = {
  items: T[];
  /**
   * Because useState can take a default that is a function returning the default,
   * setFilterFunction needs to be a function that returns a function
   */
  setFilterFunction: (filterFunction: () => (item: T) => boolean) => void;
  filters: Filter<T>[];
  additionalFilterFunction?: (item: T) => boolean;
};

/**
 * AppliedFilters is a map of filter field names to an array of selected filter values.
 * e.g.
 *
 * For a type
 *
 * ```
 * Person = {
 *    name: string;
 *    gender: string;
 * }
 * ```
 *
 * Applying AppliedFilters to Person would look like:
 *
 * ```
 * AppliedFilters<Person> = {
 *   name: string[];
 *   gender: string[];
 * }
 * ```
 */
type AppliedFilters<T> = {
  [key in keyof T]?: Set<string>;
};

type UpdateAppliedFiltersArgs<T> = {
  filter: Filter<T>;
  option: FilterOption;
  current: boolean;
};

function updateAppliedFiltersReducer<T>(
  state: AppliedFilters<T>,
  { filter, option, current }: UpdateAppliedFiltersArgs<T>,
): AppliedFilters<T> {
  const shouldRemoveOption = current;
  const filterName = filter.field;
  const filterValues = state[filterName] || new Set();
  if (shouldRemoveOption) {
    filterValues.delete(option.value);
  } else {
    filterValues.add(option.value);
  }
  return {
    ...state,
    [filterName]: filterValues,
  };
}

export function FilterGroup<T extends object>({
  items,
  filters,
  setFilterFunction,
  additionalFilterFunction,
}: FilterGroupProps<T>) {
  const filtersInitialState: AppliedFilters<T> = filters.reduce((acc: AppliedFilters<T>, filter) => {
    acc[filter.field] = new Set();
    return acc;
  }, {});

  const [appliedFilters, applyFilters] = useReducer(onChangeAppliedFilters, filtersInitialState);

  function itemMatchesFilters<T>(item: T, filters: AppliedFilters<T>): boolean {
    return Object.entries(filters).every(([filterName, filterValues]) => {
      if (!filterValues || !(filterValues instanceof Set) || filterValues.size === 0) {
        return true;
      }
      // Case where value is not an array.
      if (!Array.isArray(item[filterName as keyof T])) {
        return filterValues.has(item[filterName as keyof T]);
      }
      // Case where value is an array
      return (item[filterName as keyof T] as string[]).some((value) => filterValues.has(value));
    });
  }

  function getOverallFilterFunction(appliedFilters: AppliedFilters<T>): (item: T) => boolean {
    if (!additionalFilterFunction) {
      return (item) => itemMatchesFilters(item, appliedFilters);
    }
    return (item) => itemMatchesFilters(item, appliedFilters) && additionalFilterFunction(item);
  }

  function filterItems(appliedFilters: AppliedFilters<T>): T[] {
    return items.filter(getOverallFilterFunction(appliedFilters));
  }

  function onChangeAppliedFilters(state: AppliedFilters<T>, args: UpdateAppliedFiltersArgs<T>): AppliedFilters<T> {
    const updatedState = updateAppliedFiltersReducer(state, args);
    setFilterFunction(() => getOverallFilterFunction(updatedState));
    return updatedState;
  }

  const optionCount = useCallback(
    (filter: Filter<T>, option: FilterOption): number => {
      // We can type cast the filter.field to keyof T given keys of the object are optional for each filterable
      // property
      return filterItems({ [filter.field]: new Set<string>([option.value]) } as AppliedFilters<T>).length;
    },
    [items, additionalFilterFunction, appliedFilters],
  );

  useEffect(() => {
    if (additionalFilterFunction) {
      setFilterFunction(() => getOverallFilterFunction(appliedFilters));
    }
  }, [additionalFilterFunction]);

  const renderFilters = useMemo(() => {
    return filters.map((filter, index) => (
      <Filters
        key={index}
        filter={{
          ...filter,
          options: filter.options.map((option) => ({ ...option, count: optionCount(filter, option) })),
        }}
        onToggleOption={applyFilters}
        checkedOptions={appliedFilters[filter.field] || new Set()}
      />
    ));
  }, [filters, appliedFilters, optionCount]);

  return <>{renderFilters}</>;
}
