import { useMutation } from "@apollo/client";

import { useToast } from "../../../../../hooks/use-toast";
import { DELETE_PRODUCT_LINKED_RESOURCE } from "../../../../../api/products/gql";

import { Modal } from "../../../../../components/modal";
import { Button } from "../../../../../components/button";
import { Product, LinkedResource } from "../../../../../types/ProfileTypes";

type ModalProps = {
  isModalOpen: boolean;
  toggle: () => void;
  product: Product;
  resource: LinkedResource;
};

export function DeleteResourceModal({ isModalOpen, toggle, resource }: ModalProps) {
  const { displayToast } = useToast();
  const [deleteProductLinkedResource, { loading, error, reset }] = useMutation(DELETE_PRODUCT_LINKED_RESOURCE, {
    onCompleted: () => {
      displayToast(`You have successfully removed a key resource link`);
      handleClose();
    },
    onError: () => {},
  });

  function handleClose() {
    toggle();
    reset();
  }

  async function handleDeleteClick() {
    await deleteProductLinkedResource({
      variables: {
        id: resource?.id,
      },
    });
  }

  return (
    <Modal size="lg" isOpen={isModalOpen} hasCancelDialog header={`Delete Resource`} onClose={handleClose}>
      <div className="d-flex flex-column">
        <h3 className="text-center fw-light">{`Delete '${resource?.description}' resource?`}</h3>
        <div className="gap-5 d-flex justify-content-center mt-9">
          <Button
            name="delete-modal-cancel"
            data-testid="delete-modal-cancel"
            onClick={handleClose}
            level="tertiary"
            theme="standard"
            rounded="pill"
            size="lg"
          >
            Cancel
          </Button>
          <Button
            name="delete-modal-submit"
            data-testid="delete-modal-submit"
            disabled={!!error}
            loading={loading}
            onClick={handleDeleteClick}
            level="primary"
            theme="standard"
            rounded="pill"
            size="lg"
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
}
