import * as React from "react";
import { SVGProps } from "react";
const Star = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="#000" fill-rule="evenodd" opacity=".54">
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="m12 6.293 1.854 3.758 4.146.602-3 2.924.708 4.13L12 15.757l-3.708 1.95.708-4.13-3-2.924 4.146-.602z" />
    </g>
  </svg>
);
export default Star;
