import { Nav, NavItem, NavLink } from "reactstrap";
import { Metric } from "../../components/cards/metric-info-data";

export const LAST_12_MONTHS = "last-12-months";
const RECENT_PERIOD = "recent-period";

export function MetricPeriod({
  metric,
  period,
  onPeriodChange,
  trendLength,
}: {
  metric?: Metric;
  period: string;
  trendLength: string;
  onPeriodChange: (metric: Metric | undefined, newPeriod: string) => void;
}) {
  return (
    <div className="d-flex justify-content-center mb-5">
      <div className="w-100">
        <Nav className="nav-standard d-flex">
          <NavItem className="w-50 text-center">
            <NavLink href="#" active={period === RECENT_PERIOD} onClick={() => onPeriodChange(metric, RECENT_PERIOD)}>
              {trendLength}
            </NavLink>
          </NavItem>
          <NavItem className="w-50 text-center">
            <NavLink active={period === LAST_12_MONTHS} href="#" onClick={() => onPeriodChange(metric, LAST_12_MONTHS)}>
              Last 12 months
            </NavLink>
          </NavItem>
        </Nav>
      </div>
    </div>
  );
}
