"use client";
import { useEffect, useState } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { instrumentedFetch } from "../../../../api/instrumented-fetch";

import { ModalFooter } from "reactstrap";
import { FormHeadingV2 } from "../../../../components/form-heading";
import { Button } from "../../../../components/button";
import Select from "../../../../components/Select";
import { Link } from "../../../../components/Link";
import { ProgressIndicator } from "../../../../components/progress-indicator";

import { ACCELERATE_APIS } from "../../../../api/config";
import { ADOResponse, ADO_ORGS, STEPS } from "./add-modal";
import { Loader } from "../../../../components/spinners/loading-spinner";

export type Step1DataType = {
  organisation: string;
  project: string;
};

export function Step1({
  isLoading,
  handleNextClick,
  organisation,
  project,
  disabledRepoError,
}: {
  isLoading: boolean;
  handleNextClick: ({ organisation, project }: Step1DataType) => void;
  organisation?: string;
  project?: string;
  disabledRepoError?: boolean;
}) {
  const [projects, setProjects] = useState<{ label: string; value: string }[]>([]);

  const [isProjectsLoading, setIsProjectsLoading] = useState(false);
  const schemaObject = {
    organisation: yup
      .string()
      .transform((obj) => obj?.value)
      .required("Required"),
    project: yup
      .string()
      .transform((obj) => obj?.value)
      .required("Required"),
  };
  const [error, setError] = useState(false);

  const schema = yup.object(schemaObject).required();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    resetField,
    setValue,
    watch,
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });
  const organisationName = watch("organisation")?.label;

  async function onSubmit(data: FieldValues) {
    handleNextClick(data as Step1DataType);
  }

  async function getProjects() {
    setIsProjectsLoading(true);
    setProjects([]);
    setError(false);
    try {
      const response = await instrumentedFetch<ADOResponse>({
        method: "GET",
        spanName: "get-ado-projects",
        spanAttributes: {},
        apiUrl: ACCELERATE_APIS.microsoftADO.url as string,
        apiResource: `${organisationName}/_apis/projects?api-version=7.1-preview.4`,
      });
      if (response.ok) {
        const {
          body: { value },
        } = response;
        value.sort((a, b) => a.name.localeCompare(b.name));
        setProjects(value?.map(({ name }) => ({ value: name, label: name })));
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
      setProjects([]);
    }
    setIsProjectsLoading(false);
  }

  useEffect(() => {
    if (organisationName === organisation) {
      getProjects();
    } else if (organisationName) {
      resetField("project");
      //@ts-ignore
      setValue("project", null);
      getProjects();
    }
  }, [organisationName]);

  useEffect(() => {
    if (!isLoading) {
      setValue("organisation", { label: organisation, value: organisation });
      setValue("project", { label: project, value: project }, { shouldValidate: true });
    }
  }, [isLoading, organisation, project]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="form-step-3">
        <div className="px-5 pt-5 pb-7">
          <FormHeadingV2 currentStep={1} totalSteps={STEPS.length} title={STEPS[0].title} />
          <p className="fw-light">{STEPS[0].body}</p>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {disabledRepoError && (
                <p className="fw-light small text-danger-dark">
                  One or more of your previously selected repositories are disabled, and therefore can't be used for
                  gathering metrics. Please complete this process again.
                </p>
              )}
              <div className="w-100">
                <label className="mb-3 small fw-light w-100">Organisation</label>
                <Controller
                  name="organisation"
                  control={control}
                  render={({ field }: any) => (
                    <Select
                      {...field}
                      isMulti={false}
                      className="basic-multi-select"
                      options={ADO_ORGS.map(({ name }) => ({
                        label: name,
                        value: name,
                      }))}
                      isDisabled={isSubmitting}
                    />
                  )}
                />
              </div>
              {error && (
                <>
                  <p className="fw-light small mt-4 text-danger-dark">{`The list of Projects under the ${organisationName} ADO Organisation is not available from ADO at the moment.`}</p>
                  <p className="fw-light small text-danger-dark">
                    Please log into <Link href={`https://dev.azure.com/${organisationName}/`}>Azure</Link> and then try
                    again
                  </p>
                </>
              )}
              <div className="mt-6 w-100">
                <label className="mb-3 small fw-light w-100">Project</label>
                <Controller
                  name="project"
                  control={control}
                  render={({ field }: any) => (
                    <Select
                      {...field}
                      isMulti={false}
                      className={`basic-multi-select ${!organisationName || isProjectsLoading ? "bg-secondary" : ""}`}
                      isLoading={isProjectsLoading}
                      placeholder={isProjectsLoading ? "Loading..." : "Select"}
                      options={isProjectsLoading ? [] : projects}
                      isDisabled={isSubmitting || !organisationName || isProjectsLoading}
                    />
                  )}
                />
              </div>
            </>
          )}
        </div>
        <ModalFooter>
          <div className="w-100 d-flex justify-content-end align-items-center">
            <div className="col"></div>
            <div className="col align-self-center d-flex justify-content-center">
              <ProgressIndicator currentStep={1} totalSteps={STEPS.length} />
            </div>
            <div className="col text-end">
              <Button
                data-testid="next-metric-button"
                type="submit"
                name="next-add-repo-step-1"
                level="primary"
                theme="standard"
                rounded="pill"
                loading={isSubmitting}
                disabled={!isValid}
              >
                Next
              </Button>
            </div>
          </div>
        </ModalFooter>
      </form>
    </>
  );
}
