"use client";
import { useState } from "react";
import { useMutation } from "@apollo/client";

import { DropdownItem } from "reactstrap";
import { Add24, Edit24, Link24, LogOut24, Trash24 } from "@bphxd/ds-core-react/lib/icons";
import { DeleteProductModal } from "../views/profile/products/delete-product-modal";
import { Loader } from "./spinners/loading-spinner";
import { Modal as EditProductModal } from "../views/profile/products/create/modal";
import { Modal as EditServiceNowLevel3EntityLinkModal } from "../views/profile/products/link-snow-entity/modal";

import { Product } from "../types/ProfileTypes";
import { trackEvent } from "../utils/event-tracker";
import { DropdownKebabMenu } from "./kebab-menu";
import { useUser } from "../hooks/use-user";
import { JOIN_PRODUCT, LEAVE_PRODUCT } from "../api/products/gql";
import { useToast } from "../hooks/use-toast";
import { useAccelerateProductsQuery } from "../views/profile/hooks/use-my-products";

export type ProductKebabMenuProps = {
  product: Product;
  refetchProduct: CallableFunction;
  l3DataLoading: boolean;
};
export function ProductKebabMenu({ product, refetchProduct, l3DataLoading }: ProductKebabMenuProps) {
  const userProfile = useUser();
  const [isDeleteProductModalOpen, setIsDeleteProductModalOpen] = useState(false);
  const { refetch: refetchMyProducts } = useAccelerateProductsQuery();
  const [isEditProductModalOpen, setIsEditProductModalOpen] = useState(false);
  const [isEditSnowModalOpen, setIsEditSnowModalOpen] = useState(false);
  const [joinProductMutation] = useMutation(JOIN_PRODUCT);
  const [leaveProductMutation] = useMutation(LEAVE_PRODUCT);
  const { displayToast } = useToast();

  const { name, memberIds, l3Entity, permissions } = product;
  const isMember = memberIds?.some((email) => email.toLowerCase() === userProfile?.email?.toLowerCase());
  const canEdit = permissions?.edit || userProfile?.isModerator;
  const canDelete = permissions?.delete || userProfile?.isModerator;
  const numberOfWorkers = memberIds?.length ?? 0;

  async function joinProduct() {
    await joinProductMutation({
      variables: {
        productId: product.id,
      },
    });
    refetchProduct();
    await refetchMyProducts();
    displayToast(`You are now a member of ${product.name}`);
  }

  async function leaveProduct() {
    await leaveProductMutation({
      variables: {
        productId: product.id,
      },
    });
    refetchProduct();
    await refetchMyProducts();
    trackEvent({
      name: "leave-product",
      product: product.name,
    });
    displayToast(`You are no longer a member of ${product.name}`);
  }

  return (
    <div className="position-relative align-self-center">
      <DeleteProductModal
        isModalOpen={isDeleteProductModalOpen}
        toggle={() => {
          setIsDeleteProductModalOpen(!isDeleteProductModalOpen);
        }}
        selectedProduct={product}
      />
      <EditProductModal
        isModalOpen={isEditProductModalOpen}
        toggle={() => {
          setIsEditProductModalOpen(!isEditProductModalOpen);
        }}
        selectedProduct={product}
      />
      <EditServiceNowLevel3EntityLinkModal
        isModalOpen={isEditSnowModalOpen}
        toggle={() => {
          setIsEditSnowModalOpen(!isEditSnowModalOpen);
        }}
        selectedProduct={product}
      />
      <DropdownKebabMenu
        trackingEventProps={{
          name: "button_product-kebab-menu_click",
          product: product.name,
        }}
        size="md"
      >
        <DropdownItem header>
          <div className="px-2">
            <p className="fw-light">{name}</p>
            <p className="mb-0 small">ServiceNow Level Three platform or service:</p>
            <small className="">
              {l3DataLoading ? (
                <Loader size="sm" />
              ) : l3Entity?.tier?.parentName ? (
                `${l3Entity?.tier?.parentName} > ${l3Entity?.tier?.name} > ${l3Entity?.name}`
              ) : (
                "No linked entity"
              )}
            </small>
            <p className="mt-4 mb-0 small">
              Number of people working on this product:
              <small className="ms-2">{numberOfWorkers}</small>
            </p>
          </div>
        </DropdownItem>

        <DropdownItem divider />
        {isMember ? (
          <DropdownItem
            className="ps-5"
            onClick={() => {
              leaveProduct();
              trackEvent({
                name: "button_product-kebab-menu-leave-product_click",
                product: name,
              });
            }}
            data-testid={"leave-product-dropdown-item"}
          >
            <LogOut24 className="btn-icon-prefix" />
            Leave product
          </DropdownItem>
        ) : (
          <DropdownItem
            className="ps-5"
            onClick={() => {
              joinProduct();
              trackEvent({
                name: "button_product-kebab-menu-join-product_click",
                product: name,
              });
            }}
            data-testid={"join-product-dropdown-item"}
          >
            <Add24 className="btn-icon-prefix" />
            Join product
          </DropdownItem>
        )}
        {canEdit && (
          <DropdownItem
            className="ps-5"
            onClick={() => {
              setIsEditProductModalOpen(true);
              trackEvent({
                name: "button_product-kebab-menu-edit-product_click",
                product: name,
              });
            }}
            data-testid={"edit-product-dropdown-item"}
          >
            <Edit24 className="btn-icon-prefix" /> Edit product
          </DropdownItem>
        )}
        {canEdit && (
          <DropdownItem
            className="ps-5"
            onClick={() => {
              setIsEditSnowModalOpen(true);
              trackEvent({
                name: `button_product-kebab-menu-${l3Entity?.tier?.parentName ? "edit" : "link"}-snow_click`,
                product: name,
              });
            }}
          >
            <Link24 className="btn-icon-prefix" />
            {l3Entity?.tier?.parentName
              ? "Edit the ServiceNow Level 3 entity link"
              : "Link a ServiceNow Level 3 entity"}
          </DropdownItem>
        )}

        {canDelete && (
          <DropdownItem
            className="ps-5"
            onClick={() => {
              setIsDeleteProductModalOpen(true);
              trackEvent({
                name: "button_product-kebab-menu-delete-product_click",
                product: name,
              });
            }}
            data-testid={"delete-product-dropdown-item"}
          >
            <Trash24 className="btn-icon-prefix" />
            Delete product
          </DropdownItem>
        )}
      </DropdownKebabMenu>
    </div>
  );
}
