import { PropsWithChildren } from "react";

import { textToSlug } from "../../utils/helpers/utils";
import { ExternalLink16, TriangleDown16, TriangleUp16, Info24 } from "@bphxd/ds-core-react/lib/icons";
import { Badge } from "@bphxd/ds-core-react";
import { Link } from "../Link";
import { Popover } from "../tooltip/popover";

import { Product } from "../../types/ProfileTypes";

type MetricCardProp = {
  id?: string;
  metricName: string;
  toolTipText: string | React.ReactNode;
  isEnabled?: boolean;
  showBadge?: boolean;
};

function getDifferencePercentage(product?: Product) {
  const currentMonthEstimate = Number(product?.cloudSpending?.currentMonthEstimate);
  const previousMonthEstimate = Number(product?.cloudSpending?.previousMonth);
  const difference = (currentMonthEstimate / previousMonthEstimate - 1) * 100;
  return parseFloat(difference.toFixed(2));
}

export function CloudSpendingV2({ product }: { product?: Product }) {
  if (!product?.cloudSpending?.currentMonthEstimate) {
    return (
      <div>
        <p className="fw-light">No data available</p>
        <hr />
        <Link href="/knowledgehub/Yw7fLL#Cloud_spend_FAQs" className="fw-light small float-end">
          How can I fix this?
        </Link>
      </div>
    );
  }

  const differencePercentage = getDifferencePercentage(product);
  return (
    <div>
      <div className="d-flex align-items-center">
        <h2 className="mb-0 fw-light">
          {`$${parseFloat(Number(product?.cloudSpending?.currentMonthEstimate).toFixed(2)).toLocaleString()}`}
        </h2>
        <p
          className={`x5-ms-1 small fw-normal mb-0 ${
            differencePercentage > 0 ? "text-danger-dark" : "text-bp-dark-green-800"
          }`}
        >
          {differencePercentage.toLocaleString()}%{differencePercentage > 0 ? <TriangleUp16 /> : <TriangleDown16 />}
        </p>
      </div>
      <hr />

      <Link
        className="float-end fw-light small"
        name="see-report-cloudability"
        hideExternalLinkIcon={true}
        href="https://app-eu.apptio.com/cloudability#/"
      >
        See report
        <ExternalLink16 />
      </Link>
    </div>
  );
}

export function MetricCard({
  metricName,
  isEnabled,
  children,
  toolTipText,
  showBadge,
}: PropsWithChildren<MetricCardProp>) {
  const tooltipId = `metric-tooltip-${textToSlug(metricName)}`;
  return (
    <div
      className={`p-5 col-auto h-100 border rounded-5 d-flex flex-column justify-content-between ${
        isEnabled ? "bg-white" : "bg-light-gray-300"
      }`}
      style={{ minHeight: "180px", minWidth: "240px" }}
    >
      <div>
        <div className="d-flex w-100 justify-content-between mb-3">
          <div className="d-flex align-items-center">
            <p className="mb-0 fw-light">{metricName}</p>

            <div id={tooltipId} data-testid={`${metricName}-metric-tooltip`} className="cursor-pointer">
              <Info24 className="opacity-60" name={tooltipId} type="button" tabIndex={0} />
              <Popover
                trigger="focus"
                target={tooltipId}
                testId={`${metricName}-metric-tooltip-text1`}
                title={metricName}
                placement="right"
                toolTipText={toolTipText}
              />
            </div>
          </div>
          {showBadge && (
            <div className="float-end">
              <Badge color="info-subtle">Empirical</Badge>
            </div>
          )}
        </div>
        <div>{children}</div>
      </div>
      {!isEnabled ? <p className="mb-0 fw-light small">Coming soon</p> : null}
    </div>
  );
}
