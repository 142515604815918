"use client";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@apollo/client";

import { useToast } from "../../../../../hooks/use-toast";
import { CREATE_PRODUCT_LINKED_RESOURCE } from "../../../../../api/products/gql";

import { Form } from "reactstrap";
import { FormHeading } from "../../../../../components/form-heading";
import { FormGroup, Label } from "../../../../../components/form-group";
import Input from "../../../../../components/Input";
import { ModalFooter } from "../../../../../components/modal-footer";

import { Product } from "../../../../../types/ProfileTypes";

type WizardProps = {
  onClose: () => void;
  selectedProduct?: Product;
};

type StepData = {
  description: string;
  url: string;
};

export const URL_REGEX = /^https:\/\//;
const URL_ERROR = "The URL field should be valid and start with https://";

export function CreateResourceForm({ onClose, selectedProduct }: WizardProps) {
  const { displayToast } = useToast();
  const [stepData, setStepData] = useState<StepData>({
    description: "",
    url: "",
  });

  const schemaObject = {
    description: yup
      .string()
      .strict()
      .trim("The resource name cannot have leading or trailing white spaces.")
      .matches(/^[ a-zA-Z0-9_-]*$/, "Valid characters include a-z, A-Z, 0-9, _, -, and white spaces.")
      .required("Required"),
    url: yup.string().url(URL_ERROR).matches(URL_REGEX, URL_ERROR).required("Required"),
  };

  const schema = yup.object(schemaObject).required();

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: stepData,
  });

  const [createProductLinkedResource] = useMutation(CREATE_PRODUCT_LINKED_RESOURCE, {
    onCompleted: () => {
      displayToast(`You have successfully added a key resource link`);
      onClose();
    },
  });

  async function onSubmit(data: StepData) {
    setStepData(data);
    await createProductLinkedResource({
      variables: {
        description: data.description,
        url: data.url,
        productId: selectedProduct?.id,
      },
    });
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} data-testid="create-resource-form">
      <FormHeading title="" />
      <FormGroup>
        <Label htmlFor="resource-name">Resource name</Label>
        <Controller
          name="description"
          control={control}
          render={({ field, fieldState: { error } }: any) => (
            <>
              <Input
                id="resource-name"
                data-testid="resource-name"
                type="text"
                placeholder="For example: Product 2x2, Roadmap, Operating plan"
                {...field}
                error={error?.message}
                invalid={!!error}
              />
              {!error ? (
                <div className="fs-6">
                  The resource name can only use a-z, A-Z, 0.9, &, -, (, ., ), white spaces and commas. It should not
                  contain leading or trailing spaces.
                </div>
              ) : null}
            </>
          )}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="url">URL</Label>
        <Controller
          name="url"
          control={control}
          render={({ field, fieldState: { error } }: any) => (
            <>
              <Input
                id="url"
                data-testid="resource-url"
                type="text"
                placeholder="For example: https://accelerate.bpglobal.com/knowledgehub/mydocument"
                {...field}
                error={error?.message}
                invalid={!!error}
              />
              {!error ? <div className="fs-6">The URL should start with https://</div> : null}
            </>
          )}
        />
      </FormGroup>
      <ModalFooter
        testIdContext="add-product-linked-resource-button"
        nextButtonEventName="add-product-linked-resource-submit"
        nextButtonText="Submit"
        handleNextClick={onSubmit}
        isNextButtonLoading={isSubmitting}
        isNextButtonDisabled={!isValid}
      />
    </Form>
  );
}
