"use client";
import { useEffect, useState } from "react";
import _ from "lodash";

import { instrumentedFetch } from "../../../../api/instrumented-fetch";

import { ModalFooter, Table } from "reactstrap";

import { Loader } from "../../../../components/spinners/loading-spinner";
import { Button } from "../../../../components/button";
import { Link } from "../../../../components/Link";
import { FormHeadingV2 } from "../../../../components/form-heading";
import { ProgressIndicator } from "../../../../components/progress-indicator";

import { ACCELERATE_APIS } from "../../../../api/config";
import { ADOResponse, STEPS } from "./add-modal";
import { DoraMetrics } from "../../../../gql/graphql";

type PipelineData = {
  name?: string;
  runName?: string;
  link?: string;
};

export function Step3({
  productName,
  metrics,
  handleBackClick,
  handleCloseClick,
}: {
  productName: string;
  metrics?: DoraMetrics;
  handleBackClick: () => void;
  handleCloseClick: () => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [pipelines, setPipelines] = useState<PipelineData[]>([]);

  async function getPipelineData() {
    setIsLoading(true);
    const responses = await Promise.all(
      _.uniqBy(
        [
          ...(metrics?.leadTime?.perPipeline?.map(({ pipeline }) => pipeline) ?? []),
          ...(metrics?.deploymentFrequency?.perPipeline?.map(({ pipeline }) => pipeline) ?? []),
        ],
        function (p) {
          return `${p.organizationName}/${p.projectName}/${p.id}`;
        },
      ).map((pipeline) => {
        return instrumentedFetch<ADOResponse>({
          method: "GET",
          spanName: "get-pipeline runs",
          spanAttributes: {},
          apiUrl: ACCELERATE_APIS.microsoftADO.url as string,
          apiResource: `${pipeline.organizationName}/${pipeline.projectName}/_apis/pipelines/${pipeline.id}/runs?api-version=7.1-preview.1&$top=1`,
        });
      }),
    );
    setPipelines(
      responses.map(({ body }) => {
        const latestRun = body?.value?.[0];
        return {
          name: latestRun?.pipeline?.name,
          runName: latestRun?.name,
          link: latestRun?._links?.["pipeline.web"]?.href,
        };
      }),
    );
    setIsLoading(false);
  }
  useEffect(() => {
    getPipelineData();
  }, [metrics]);

  return (
    <>
      {!isLoading && pipelines.length === 0 ? (
        <div className="d-flex flex-column pt-5 pb-7 px-5">
          <FormHeadingV2 currentStep={3} totalSteps={3} title={STEPS[2].title} />
          <h5 className="fw-light mb-3">We could not identify any suitable pipelines</h5>
          <p className="fw-light small mb-7">
            This may be because you are using classic pipelines instead of YAML or you don’t have a stage called “prod”
            in your pipelines. <br />
            <br />
            We have saved your repository data, but you’ll need to make a change for your metrics to appear.
          </p>
          <p className="fw-light small">
            <Link href="/knowledgehub/5mA8xV" hideExternalLinkIcon>
              Find out how we've linked the pipelines and how to make changes.
            </Link>
          </p>
        </div>
      ) : (
        <div className="px-5 pt-5 pb-7">
          <FormHeadingV2 currentStep={3} totalSteps={3} title={STEPS[2].title} />
          <p className="fw-light">
            These are the pipelines we have identified as your production pipelines. Only .yaml pipelines are supported.
          </p>
          <Link href="/knowledgehub/5mA8xV" hideExternalLinkIcon>
            <span className="fs-6 fw-light">Find out how we've linked the pipelines and how to make changes.</span>
          </Link>

          <Table className="w-100 mt-5">
            <thead>
              <tr>
                <th>CICD pipeline</th>
                <th>Last run</th>
              </tr>
            </thead>
            {isLoading ? (
              <tr className="d-flex align-items-center">
                <td></td>
                <td>Loading...</td>
                <td>
                  <Loader size="lg" />
                </td>
              </tr>
            ) : (
              pipelines
                .filter((pipeline) => pipeline.name)
                .map(({ name, runName, link }) => {
                  return (
                    <tr className="mt-3 border-bottom" key={name}>
                      <td className="fs-6">
                        <Link
                          name="pipeline-confirmation"
                          href={link}
                          trackingEventProps={{
                            product: productName,
                            pipelineName: name,
                          }}
                          hideExternalLinkIcon
                          style={{
                            color: "rgb(var(--bs-link-color-rgb))",
                          }}
                        >
                          {name}
                        </Link>
                      </td>
                      <td className="fw-light fs-6">{runName}</td>
                    </tr>
                  );
                })
            )}
          </Table>
        </div>
      )}

      <ModalFooter className="px-0">
        <div className="w-100 d-flex justify-content-between align-items-center">
          <div className="col">
            <Button
              data-testid="previous-add-repo-button-3"
              type="button"
              name={`back-add-repo-step-3`}
              level="tertiary"
              theme="standard"
              rounded="pill"
              onClick={handleBackClick}
            >
              Previous
            </Button>
          </div>
          <div className="col align-self-center d-flex justify-content-center">
            <ProgressIndicator currentStep={3} totalSteps={STEPS.length} />
          </div>
          <div className="col text-end">
            <Button
              data-testid="next-add-repo-button-3"
              type="button"
              name="next-add-repo-button-step-3"
              level="primary"
              theme="standard"
              rounded="pill"
              onClick={() => {
                handleCloseClick();
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </ModalFooter>
    </>
  );
}
