import { Fragment } from "react";
import { Check16 } from "@bphxd/ds-core-react/lib/icons";

export function ProgressIndicator({ currentStep, totalSteps }: { currentStep: number; totalSteps: number }) {
  function backgroundColor(step: number) {
    if (step === currentStep) {
      return "black";
    }
    return step > currentStep ? "#ededed" : "#15ad5c";
  }
  return (
    <div className="position-relative d-flex">
      {Array.from({ length: totalSteps }, (_, i) => i + 1).map((step) => {
        return (
          <Fragment key={step}>
            <div
              className="d-flex"
              style={{
                width: "1rem",
                height: "1rem",
                borderRadius: "999%",
                backgroundColor: backgroundColor(step),
              }}
            >
              {step < currentStep ? <Check16 className="text-white" /> : null}
            </div>
            {step < totalSteps && (
              <div
                className={`border-top ${step < currentStep ? "border-dark" : "border-gray"}`}
                style={{
                  width: 14,
                  height: 1,
                  marginTop: 7,
                }}
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );
}
