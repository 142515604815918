import {
  Favourite16,
  TailDown16,
  SpinnerCircle16,
  Alert16,
  Question16,
  Check16,
  TailUp16,
  Preview16,
} from "@bphxd/ds-core-react/lib/icons";
import { YallaInstanceHealthStatus, YallaInstanceSyncStatus } from "../../../../gql/graphql";

export function healthStatus({ status }: { status: YallaInstanceHealthStatus }) {
  const statusMap = {
    [YallaInstanceHealthStatus.Healthy]: { Icon: Favourite16, label: "Healthy", className: "text-success" },
    [YallaInstanceHealthStatus.Degraded]: { Icon: TailDown16, label: "Degraded", className: "text-bp-orange-500" },
    [YallaInstanceHealthStatus.Progressing]: { Icon: SpinnerCircle16, label: "Progressing", className: "text-default" },
    [YallaInstanceHealthStatus.Suspended]: { Icon: Alert16, label: "Suspended", className: "text-danger-dark" },
    [YallaInstanceHealthStatus.Unknown]: { Icon: Question16, label: "Unknown", className: "text-default" },
    [YallaInstanceHealthStatus.Missing]: { Icon: Preview16, label: "Missing", className: "text-default" },
  };

  return statusMap[status] ?? statusMap.UNKNOWN;
}

export function syncStatus({ status }: { status: YallaInstanceSyncStatus }) {
  const iconMap = {
    [YallaInstanceSyncStatus.Synced]: { Icon: Check16, label: "Synced", className: "text-success" },
    [YallaInstanceSyncStatus.OutOfSync]: { Icon: TailUp16, label: "Out of sync", className: "text-danger-dark" },
    [YallaInstanceSyncStatus.Syncing]: { Icon: SpinnerCircle16, label: "Syncing", className: "text-default" },
    [YallaInstanceSyncStatus.Unknown]: { Icon: Question16, label: "Unknown", className: "text-default" },
  };

  return iconMap[status] ?? iconMap.UNKNOWN;
}

function statusBadgeFactory<T extends YallaInstanceHealthStatus | YallaInstanceSyncStatus>(
  propFactory: (arg0: { status: T }) => { Icon: React.ElementType; label: string; className: string },
) {
  return function StatusBadge({ status, labelClassName }: { status: T; labelClassName?: string }) {
    const { Icon, label, className } = propFactory({ status });
    return (
      <div className="d-flex align-items-center justify-content-center gap-2">
        <Icon className={className} />
        <span className={labelClassName}>{label}</span>
      </div>
    );
  };
}
export const HealthBadge = statusBadgeFactory(healthStatus);
export const SyncBadge = statusBadgeFactory(syncStatus);
