"use client";
import { MetricsPeriodState, Product } from "../../types/ProfileTypes";
import { empiricalMetricTooltipTexts, Metric, metricRange } from "../../components/cards/metric-info-data";
import { LAST_12_MONTHS, MetricPeriod } from "./metric-period";
import { MetricCard } from "../../components/cards/metric-card";
import {
  calculateDeployFrequencyArrowPosition,
  calculateLeadTimeArrowPosition,
  IndicatorProgressBars,
  METRIC_PROGRESS_BAR_COLORS,
} from "../../components/cards/indicator-progress-bars";
import { ExploreMetric } from "./explore-metric";
import { Link } from "../../components/Link";
import { PropsWithChildren, useState } from "react";
import { DateTime } from "luxon";
import { MetricCardHighlight } from "../../app/profile/product/components/metric-highlight";

type EmpiricalMetricCardProps = {
  metricName: Metric;
  product?: Product;
};

export function calculateTrendLength(trendLength: number | undefined) {
  return trendLength ? `Last ${DateTime.now().diff(DateTime.fromMillis(trendLength)).toFormat("d")} days` : "Last days";
}

export function NoMetricData() {
  return (
    <>
      <p className="fw-light small">There is no data available</p>
      <Link href="/knowledgehub/5mA8xV" className="fw-light small">
        Find out how we've linked the pipelines and how to make changes.
      </Link>
    </>
  );
}

export function EmpiricalMetricCard({ metricName, product, children }: PropsWithChildren<EmpiricalMetricCardProps>) {
  const [metricsPeriod, setMetricsPeriod] = useState<MetricsPeriodState>({
    [Metric.DeployFrequency]: LAST_12_MONTHS,
    [Metric.LeadTime]: LAST_12_MONTHS,
    [Metric.ChangeFailPercentage]: LAST_12_MONTHS,
    [Metric.TimeToRestore]: LAST_12_MONTHS,
    [Metric.CloudSpend]: LAST_12_MONTHS,
    [Metric.CoderPercentage]: LAST_12_MONTHS,
  });

  const handlePeriodChange = (metric: string | undefined, newPeriod: string) => {
    setMetricsPeriod((prevState) => ({
      ...prevState,
      [metric as string]: newPeriod,
    }));
  };
  const hasData = !!product?.metrics;
  const value =
    metricName === Metric.LeadTime
      ? product?.metrics?.leadTime?.leadTimeHours
      : product?.metrics?.deploymentFrequency?.periodDeploymentCount;

  const trend =
    metricName === Metric.LeadTime
      ? product?.metrics?.leadTime?.trendingLeadTimeHours
      : product?.metrics?.deploymentFrequency?.trendingPeriodDeploymentCount;

  const display = metricsPeriod[metricName] === LAST_12_MONTHS ? value : trend;
  const yourScoreTrend = metricsPeriod[metricName] === LAST_12_MONTHS ? "Your score: " : "Your trend: ";

  const trendLength =
    metricName === Metric.LeadTime
      ? product?.metrics?.leadTime?.trendingPeriodStartDate
      : product?.metrics?.deploymentFrequency?.trendingPeriodStartDate;

  return (
    <MetricCard
      metricName={metricName}
      isEnabled
      toolTipText={empiricalMetricTooltipTexts[metricName]}
      showBadge={true}
    >
      {hasData && display !== undefined ? (
        <>
          <MetricPeriod
            key={metricName}
            metric={metricName}
            period={metricsPeriod[metricName]}
            onPeriodChange={handlePeriodChange}
            trendLength={calculateTrendLength(trendLength)}
          />
          {display ? (
            <p className="small fw-light">
              {yourScoreTrend}
              {metricName === Metric.LeadTime
                ? calculateLeadTimeArrowPosition(display).leadTimeTrend
                : calculateDeployFrequencyArrowPosition(display).deploymentFrequency}
            </p>
          ) : null}
          {children}
          {(value || trend) && display ? (
            <>
              <IndicatorProgressBars
                arrowPosition={
                  metricName === Metric.LeadTime
                    ? calculateLeadTimeArrowPosition(display).arrowPosition
                    : calculateDeployFrequencyArrowPosition(display).arrowPosition
                }
                progressBars={METRIC_PROGRESS_BAR_COLORS}
              >
                <div className="pb-3 d-flex justify-content-between border-bottom">
                  <small>{metricRange[metricName].start}</small>
                  <small>{metricRange[metricName].end}</small>
                </div>
              </IndicatorProgressBars>
              <MetricCardHighlight
                metricName={metricName}
                metrics={product?.metrics}
                period={metricsPeriod[metricName]}
              />
            </>
          ) : (
            <NoMetricData />
          )}

          {hasData && display ? (
            <div className="pt-4 d-flex justify-content-end h-100 align-items-end">
              <ExploreMetric metricName={metricName} />
            </div>
          ) : null}
        </>
      ) : (
        <NoMetricData />
      )}
    </MetricCard>
  );
}
