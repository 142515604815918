import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
} from "recharts";
import { DeploymentChartData, LeadTimeChartData } from "../../app/profile/product/components/metrics-tab";

export const MetricsChart = ({
  bpTarget,
  data,
  aSeriesName,
  aSeriesProp,
  bSeriesName,
  bSeriesProp,
}: {
  data: LeadTimeChartData[] | DeploymentChartData[];
  bpTarget?: number;
  aSeriesName: string;
  aSeriesProp: string;
  bSeriesName?: string;
  bSeriesProp?: string;
}) => {
  return (
    <div className="ratio ratio-16x9">
      <ResponsiveContainer minHeight={"100%"} height={300} aspect={16 / 9}>
        <BarChart
          data={data}
          margin={{
            top: 40,
          }}
        >
          <CartesianGrid strokeDasharray="1 2" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey={aSeriesProp} stackId="a" fill="var(--recharts-color-2-1)" name={aSeriesName} />
          {bSeriesProp && <Bar dataKey={bSeriesProp} stackId="a" fill="var(--recharts-color-2-2)" name={bSeriesName} />}
          {bpTarget && <ReferenceLine y={bpTarget} label="bp's target" stroke="red" />}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
