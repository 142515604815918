import { Link } from "../../../../components/Link";
import { Button } from "../../../../components/button";

type NoSNOWEntityProps = {
  onClose: () => void;
};

export function NoSNOWEntity({ onClose }: NoSNOWEntityProps) {
  return (
    <div
      className="d-flex flex-column align-content-center align-items-center h-100 "
      data-testid="form-failure-no-snow-entity"
    >
      <h3>Can't find your platform or service here?</h3>
      <div className="lead mb-5">
        {`Make sure it exists in `}
        <Link href="https://bp.service-now.com/myservicehub?id=msh_activity_model" style={{ lineHeight: 1.5 }}>
          ServiceNow
        </Link>
        {` and then try again.`}
      </div>

      <Button
        data-testid={`close-create-product-step-3`}
        onClick={onClose}
        name={"close-create-product-step-3"}
        type="button"
        level="tertiary"
        theme="darker"
        rounded="pill"
        size="lg"
      >
        Close
      </Button>
    </div>
  );
}
