"use client";
import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Controller, FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useToast } from "../../hooks/use-toast";

import { Close, Datepicker } from "@bphxd/ds-core-react";
import { ModalBody, ModalFooter, Modal, ModalHeader } from "reactstrap";
import { Button } from "../../components/button";

import { GET_IMPROVEMENT_GOALS, ImprovementGoal, UPSERT_IMPROVEMENT_GOAL } from "../../api/products/gql";

import Select from "../../components/Select";
import Input from "../../components/Input";

import { QuizResult } from "../../gql/graphql";

type RemoveImprovementAreaModalProps = {
  isOpen: boolean;
  productId: string;
  goal?: ImprovementGoal;
  quizResults?: QuizResult[];
  toggle: () => void;
};

const currentDate = new Date();
const oneYearFromNow = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate());

export function CreateEditImprovementAreaModal({
  isOpen,
  productId,
  goal,
  quizResults,
  toggle,
}: RemoveImprovementAreaModalProps) {
  const { displayToast } = useToast();
  const schemaObject = {
    quizSlug: yup
      .string()
      .transform((obj) => obj.value)
      .required("Required"),
    addScoreAndDate: yup.boolean().required("Required"),
    targetScore: yup
      .number()
      .typeError("Target score must be a number greater than the current score and less than 10")
      .max(10, "Target score must be less than or equal to 10")
      .test("is-valid-score", "Target score must be higher than the current score", function (value) {
        const results = quizResults?.find((result) => result.quiz.slug === this.parent.quizSlug);
        if (!this.parent.addScoreAndDate) {
          return true;
        }
        if (typeof results?.score === "number" && value && value > results?.score) {
          return true;
        }
        return false;
      }),
    completionDate: yup
      .date()
      .when("addScoreAndDate", {
        is: true,
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema.optional(),
      })
      .min(new Date(), "Date must be in the future"),
  };

  const schema = yup.object(schemaObject).required();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting, isValid },
    watch,
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });
  const [upsertImprovementGoal] = useMutation(UPSERT_IMPROVEMENT_GOAL, {
    onCompleted: () => {
      displayToast(`You have successfully ${goal ? "edited" : "created"} an improvement area for this product`);
      toggle();
    },
    refetchQueries: [GET_IMPROVEMENT_GOALS],
  });
  const addScoreAndDate = watch("addScoreAndDate");
  const selectedQuizSlug = watch("quizSlug")?.value;
  const selectedQuizScore = quizResults?.find((result) => result.quiz.slug === selectedQuizSlug)?.score ?? 0;

  useEffect(() => {
    if (isOpen && goal) {
      setValue(
        "quizSlug",
        capabilityOptions.find((c) => c.value === goal?.quizSlug),
      );
      setValue("addScoreAndDate", goal?.completionDate ? "true" : "false");
      setValue("targetScore", goal?.targetScore);
      setValue("completionDate", goal?.completionDate ? new Date(goal.completionDate) : undefined);
    } else {
      reset();
    }
  }, [isOpen]);

  const capabilityOptions =
    quizResults
      ?.filter(({ score }) => {
        return typeof score === "number" && score > -1;
      })
      .map(({ score, quiz }) => ({
        label: (
          <div className="d-flex justify-content-between">
            <div>{quiz.name} </div>
            <div className="ms-auto float-end pe-5">{Number(score).toFixed(1)}</div>
          </div>
        ),
        value: quiz.slug,
      })) ?? [];
  const addOptions = ["true", "false"];

  async function onSubmit(data: FieldValues) {
    const variables: ImprovementGoal = {
      quizSlug: data.quizSlug,
      productId,
    };
    if (data.addScoreAndDate) {
      variables.targetScore = data.targetScore;
      variables.completionDate = data.completionDate?.getTime();
    }

    await upsertImprovementGoal({ variables });
  }

  return (
    <Modal size="md" isOpen={isOpen} toggle={toggle} className="modal-dialog-centered">
      <ModalHeader className="" close={<Close onClick={toggle} />}>
        {goal ? "Edit Improvement Area" : "Add Improvement Area"}
      </ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="form-step-3">
        <ModalBody className="pt-0 px-9 pb-7 align-self-end">
          {!goal ? (
            <p className="fw-light mb-7">
              Add a capability as an area for improvement. You can also a target score and date if you want to make it
              specific and time-bound.{" "}
            </p>
          ) : null}
          <div className="w-100">
            <label className="small fw-light w-100 mb-3">Select a capability as an area for improvement</label>
            <Controller
              name="quizSlug"
              control={control}
              render={({ field }: any) => (
                <Select
                  {...field}
                  isMulti={false}
                  className={`basic-multi-select ${goal ? "bg-secondary" : ""}`}
                  placeholder="Start typing to search for capability"
                  options={capabilityOptions}
                  isDisabled={isSubmitting || !!goal}
                />
              )}
            />
          </div>
          <div className="w-100 mt-7">
            <label className="small fw-light w-100 mb-4 pb-2">Would you like to add a target score and date?</label>
            <Controller
              name="addScoreAndDate"
              control={control}
              render={({ field }) => (
                <div className="d-flex flex-column gap-4">
                  {addOptions.map((value, index) => (
                    <label
                      htmlFor={`add-score-and-date-${value}`}
                      className="fw-light cursor-pointer fs-6 d-flex align-items-center w-fit"
                      key={index}
                    >
                      <Input
                        {...field}
                        className="me-3"
                        id={`add-score-and-date-${value}`}
                        data-testid={`add-score-and-date-${index}`}
                        trackingName="add-score-and-date-radio"
                        type="radio"
                        value={value}
                        checked={value === addScoreAndDate}
                      />
                      {value == "true" ? "Yes" : "No"}
                    </label>
                  ))}
                </div>
              )}
            />
          </div>
          {addScoreAndDate === "true" ? (
            <>
              <div className="w-100 mt-7">
                <label className="small fw-light w-100 mb-3 pb-2">Target score</label>
                <Controller
                  name="targetScore"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <div className="d-flex flex-column gap-3">
                      <Input
                        {...field}
                        className="me-3"
                        id="targetScore"
                        data-testid="targetScore"
                        trackingName="targetScore"
                        type="number"
                        error={error?.message}
                        invalid={!!error?.message}
                      />
                      {!error?.message ? (
                        <small className="opacity-70">
                          Enter a number between {Number(selectedQuizScore).toFixed(1)} and 10.0.
                        </small>
                      ) : null}
                    </div>
                  )}
                />
              </div>
              <div className="w-100 mt-7">
                <label className="small fw-light w-100 mb-3 pb-2">Target date</label>
                <Controller
                  name="completionDate"
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    const selectedDate = new Date(field.value);
                    const year = selectedDate.getFullYear();
                    const month = selectedDate.getMonth();
                    const date = selectedDate.getDate();
                    const utcDate = new Date(Date.UTC(year, month, date));
                    return (
                      <div className="d-flex flex-column gap-3">
                        <Datepicker
                          options={{
                            ...field,
                            locale: {
                              firstDayOfWeek: 1,
                            },
                            minDate: new Date(),
                            maxDate: oneYearFromNow,
                          }}
                          invalid={!!error?.message}
                          value={utcDate}
                        />
                        {error?.message ? (
                          <small className="text-danger-dark opacity-100 fw-normal">{error.message}</small>
                        ) : (
                          <small className="opacity-70">
                            Select a date that is achievable - for instance two or three sprints away.
                          </small>
                        )}
                      </div>
                    );
                  }}
                />
              </div>
            </>
          ) : null}
        </ModalBody>
        <ModalFooter className="py-4 d-flex gap-1 justify-content-end">
          <Button
            data-testid="confirm-add-improvement-area"
            type="submit"
            name="confirm-add-improvement-area"
            level="primary"
            theme="standard"
            rounded="pill"
            loading={isSubmitting}
            disabled={!isValid}
          >
            {goal ? "Save" : "Add to list"}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
