"use client";
import { useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useToast } from "../../../../hooks/use-toast";
import Pluralise from "../../../../utils/pluralise";

import { EDIT_ACCELERATE_PRODUCT, SEARCH_L3_ENTITIES } from "../../../../api/products/gql";

import { Search24 } from "@bphxd/ds-core-react/lib/icons";
import { Form, FormGroup } from "reactstrap";
import { Loader } from "../../../../components/spinners/loading-spinner";
import Input from "../../../../components/Input";
import { ModalFooter } from "../../../../components/modal-footer";
import { Button } from "../../../../components/button";

import { Link } from "../../../../components/Link";

import { L3Entity, Product } from "../../../../types/ProfileTypes";

export function LinkSnowEntityForm({
  selectedProduct,
  onFormSubmit,
}: {
  selectedProduct: Product;
  onFormSubmit: () => void;
}) {
  const { l3Entity } = selectedProduct;

  const [searchInput, setSearchInput] = useState("");
  const { displayToast } = useToast();

  const schemaObject = {
    l3Uid: yup.string().required("Required"),
  };

  const schema = yup.object(schemaObject).required();
  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
  } = useForm<{ l3Uid: string }>({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const [getSnowEntities, { data, loading }] = useLazyQuery<{
    searchL3Entities: L3Entity[];
  }>(SEARCH_L3_ENTITIES, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  });

  const [editProduct, { loading: editProductLoading }] = useMutation(EDIT_ACCELERATE_PRODUCT, {
    onCompleted: () => {
      displayToast(
        `You have successfully  ${l3Entity ? "edited" : "linked"} the ServiceNow Level 3 entity for this product.`,
      );
      onFormSubmit();
    },
  });

  const results = [...(data?.searchL3Entities ? data.searchL3Entities : l3Entity ? [l3Entity] : [])].sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  function handleSearch() {
    setValue("l3Uid", "");
    search();
  }

  async function search() {
    await getSnowEntities({
      variables: { search: searchInput, pageSize: 50, page: 0 },
    });
  }

  async function handleFormSubmit({ l3Uid }: { l3Uid: string }) {
    await editProduct({
      variables: {
        id: selectedProduct.id,
        l3Uid,
      },
    });
  }

  return (
    <>
      <p className="fw-light mb-4">Find a Level 3 entity in ServiceNow for this product to link to.</p>
      <form
        className="d-flex align-items-center mt-4 mb-7"
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch();
        }}
        data-testid="link-snow-entity-search-form"
      >
        <Input
          data-testid="snow-entity-search-form"
          type="text"
          value={searchInput}
          className="me-5 py-3 px-3"
          placeholder="Enter at least three characters from the Level 3 entity as it appears in ServiceNow"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            setSearchInput(e.target.value);
          }}
          icon={<Search24 />}
        />
        <div>
          <Button
            data-testid="snow-entity-search-submit"
            name="snow-entity-search-submit"
            type="submit"
            disabled={(searchInput?.length ?? 0) < 2}
            loading={loading}
            level="tertiary"
            theme="standard"
            rounded="pill"
            size="sm"
          >
            Search
          </Button>
        </div>
      </form>
      <Form onSubmit={handleSubmit(handleFormSubmit)} data-testid="form-step-1">
        {loading ? (
          <Loader />
        ) : data?.searchL3Entities.length === 0 ? (
          <p className="fw-light lead w-75 text-center mx-auto my-9">
            We could not find that Level 3 entity in ServiceNow. Please check the spelling and try again.{" "}
            <Link
              href="/knowledgehub/f6W9jJ#Check_2:_Does_the_product_in_Accelerate_link_to_the_correct_ServiceNow_location?"
              className="d-inline"
            >
              Find more details about how products link to ServiceNow entities.
            </Link>
          </p>
        ) : (
          <>
            {results.length > 0 ? (
              <p className="fw-light">
                Showing <span className="fw-normal">{results.length}</span> result{Pluralise(results.length)}
              </p>
            ) : null}
            <Controller
              name="l3Uid"
              control={control}
              render={({ field }) => (
                <>
                  {results.map(({ serviceNowId, name, tier }, index) => (
                    <div key={serviceNowId} className="p-5 mt-3 mb-3 border border-dark-subtle border-1 rounded">
                      <FormGroup check className="mt-0 mb-0 d-flex align-items-center gap-4">
                        <Input
                          {...field}
                          id={serviceNowId}
                          data-testid={`form-step-1-radio-${index}`}
                          trackingName="create-product-radio"
                          type="radio"
                          value={serviceNowId}
                          checked={serviceNowId === control._formValues.l3Uid}
                        />
                        <label htmlFor={serviceNowId}>
                          <p className="fw-light mb-0">{name}</p>
                          {tier ? (
                            <p className="small fw-light mb-0 opacity-70">{`${tier?.parentName} > ${tier?.name}`}</p>
                          ) : null}
                        </label>
                      </FormGroup>
                    </div>
                  ))}
                </>
              )}
            />
          </>
        )}
        <ModalFooter
          testIdContext="snow-entity-search-modal-footer"
          nextButtonEventName="link-snow-entity-to-product"
          nextButtonText="Submit"
          isNextButtonDisabled={!isValid}
          isNextButtonLoading={editProductLoading}
        />
      </Form>
    </>
  );
}
