"use client";
import { useEffect } from "react";
import { useToggle } from "../../../../hooks/useToggle";

import { Divider } from "../../../../components";
import { Button } from "../../../../components/button";
import { Link } from "../../../../components/Link";
import { Loader } from "../../../../components/spinners/loading-spinner";
import { ResourceMenu } from "./edit-delete-resource/resource-menu";
import { CreateAreaModal } from "./create-area-modal";
import { CreateResourceModal } from "./create-resource/create-resource-modal";

import { Product } from "../../../../types/ProfileTypes";

export const PRODUCT_AREA_RESOURCE_DESCRIPTION = "Product area in Knowledge Hub";

function KeyResourceItem({ productName, url, description }: { productName: string; url: string; description: string }) {
  return (
    <Link
      name="product-linked-resource"
      href={url}
      trackingEventProps={{ product: productName, resource: description }}
    >
      <span className="me-2 fw-light">{description}</span>
    </Link>
  );
}

export function ProductLinkedResources({
  product,
  isLoading,
  refetchProduct,
  openLinkResourceModal,
  openProductAreaModal,
}: {
  product?: Product;
  isLoading: boolean;
  refetchProduct: CallableFunction;
  openLinkResourceModal?: boolean;
  openProductAreaModal?: boolean;
}) {
  const [isCreateProductResourceModalOpen, toggleIsCreateProductResourceModalOpen] = useToggle();
  const [isCreateProductAreaModalOpen, toggleIsCreateProductAreaModalOpen] = useToggle();

  const canUserCreateOrEdit = product?.permissions?.edit;
  const linkedResources = [...(product?.linkedResources ?? [])];
  const productAreaResourceIndex = linkedResources.findIndex(
    ({ description }) => description === PRODUCT_AREA_RESOURCE_DESCRIPTION,
  );
  const productAreaResource =
    productAreaResourceIndex > -1 ? linkedResources.splice(productAreaResourceIndex, 1)[0] : undefined;

  useEffect(() => {
    if (openLinkResourceModal) {
      toggleIsCreateProductResourceModalOpen();
    }
  }, [openLinkResourceModal]);

  useEffect(() => {
    if (openProductAreaModal) {
      toggleIsCreateProductAreaModalOpen();
    }
  }, [openProductAreaModal]);

  if (isLoading || typeof product === "undefined") {
    return (
      <div className="bg-white rounded-5 border px-5 py-6">
        <h5 className="mb-5">Key resources</h5>
        <Loader />
      </div>
    );
  }

  return (
    <div className="bg-white rounded-5 border px-5 py-6">
      <h5 className="mb-5">Key resources</h5>
      {productAreaResource ? (
        <>
          <Link
            dataTestid="product-area-link"
            href={productAreaResource.url}
            name="product-area"
            trackingEventProps={{ product: product.name }}
          >
            <span className="fw-light">{PRODUCT_AREA_RESOURCE_DESCRIPTION}</span>
          </Link>
          <Divider pTop="S" pBottom="S" />
        </>
      ) : canUserCreateOrEdit && product?.l3Entity ? (
        <>
          <p className="small opacity-100 fw-normal mb-3">Knowledge Hub product area</p>
          <p className="small opacity-90 fw-light mb-4">
            Your product can have a dedicated area in Knowledge Hub where you can keep documentation in one,
            easy-to-find, place.
          </p>
          <Button
            data-testid="create-product-area-button"
            onClick={toggleIsCreateProductAreaModalOpen}
            type="button"
            name="create-product-area"
            trackingEventProps={{ product: product.name }}
            level="tertiary"
            theme="standard"
            rounded="pill"
            size="sm"
          >
            Create product area
          </Button>
          <Divider pTop="S" pBottom="S" />
        </>
      ) : product?.l3EntityUid ? (
        <>
          <p className="small opacity-100 fw-normal mb-3">Knowledge Hub product area</p>
          <p className="small opacity-90 fw-light mb-4">
            Ask this product's leads to make a dedicated area in Knowledge Hub where you can read their documentation in
            one, easy-to-find, place.
          </p>
        </>
      ) : (
        <>
          <p className="small opacity-100 fw-normal mb-3">Knowledge Hub product area</p>
          <p className="small opacity-90 fw-light mb-4">
            Ask this product's leads to link this product to a Service Now L3 entity, so that you can read their
            documentation in one, easy-to-find, place.
          </p>
        </>
      )}

      {linkedResources.length === 0 && canUserCreateOrEdit ? (
        <>
          <p className="small opacity-100 fw-normal mb-3">Resource links</p>
          <p className="small opacity-90 fw-light mb-4">
            Additionally you can add shortcuts to any link that is important for your product so you can have quick
            access when you need it.
          </p>
        </>
      ) : (
        linkedResources
          .sort((a, b) => a.description.localeCompare(b.description))
          .map((item) => (
            <div
              data-testid="key-resource-item"
              key={item.id}
              className="d-flex justify-content-between align-items-baseline border-bottom pb-3 mb-3 d-block w-100"
            >
              <KeyResourceItem url={item.url} description={item.description} productName={product.name} />
              {canUserCreateOrEdit && <ResourceMenu resource={item} product={product} />}
            </div>
          ))
      )}

      {canUserCreateOrEdit && (
        <Button
          name="add-linked-resource-to-product"
          data-testid="add-resource-item"
          onClick={toggleIsCreateProductResourceModalOpen}
          className="mt-3 mb-0"
          trackingEventProps={{ product: product.name }}
          level="tertiary"
          theme="darker"
          rounded="pill"
          size="sm"
        >
          Add resource link
        </Button>
      )}

      <CreateResourceModal
        isModalOpen={isCreateProductResourceModalOpen}
        toggle={toggleIsCreateProductResourceModalOpen}
        selectedProduct={product}
      />
      <CreateAreaModal
        isModalOpen={isCreateProductAreaModalOpen}
        toggle={toggleIsCreateProductAreaModalOpen}
        selectedProduct={product}
        refetch={refetchProduct}
      />
    </div>
  );
}
