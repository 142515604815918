"use client";
import { useState } from "react";

export const useToggle = () => {
  const [isShowing, setIsShowing] = useState(false);

  function toggle() {
    setIsShowing(!isShowing);
  }

  return [isShowing, toggle] as const;
};
