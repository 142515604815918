"use client";
import { useState } from "react";
import { TABS } from "../profile/profile-product-page";
import { Link } from "../../components/Link";
import Pluralise from "../../utils/pluralise";
import { DateTime } from "luxon";
import { getDisplayedScore } from "../bestPractice/MaturityComponents/display-score";
import { Modal as EditProductModal } from "../profile/products/create/modal";
import { LinkingBadge, LinkingSnowButton } from "../profile/linking-alert-message";
import { Button } from "../../components/button";
import { useMutation } from "@apollo/client";
import { JOIN_PRODUCT, LEAVE_PRODUCT } from "../../api/products/gql";
import { useToast } from "../../hooks/use-toast";
import { DropdownKebabMenu } from "../../components/kebab-menu";
import { DropdownItem } from "reactstrap";
import { LogOut24 } from "@bphxd/ds-core-react/lib/icons";
import { trackEvent } from "../../utils/event-tracker";
import { BasicProductFields } from "../../pages/all-products";
import { useIsUserMember } from "../../hooks/use-is-user-member";
import { ProductAdminTooltip } from "../profile/products/product-admin-tool-tip";
import { useUserIsAdmin } from "../../hooks/use-user-is-admin";
import { PageSource } from "../../hooks/use-breadcrumbs";
import { useUser } from "../../hooks/use-user";
import { ProductFavorite } from "./product-favorite";
import { useFeature } from "featurehub-react-sdk";
import { ProductFavoriteButton } from "./product-favorite-button";
import { useAccelerateProductsQuery } from "../profile/hooks/use-my-products";
import { useUserL3Entities } from "../profile/hooks/use-user-l3-entities";

export function ProductListItem({ product, refetch }: { product: BasicProductFields; refetch: CallableFunction }) {
  const { userL3Entities, userL3EntitiesLoading } = useUserL3Entities();

  const isOwnerOrDelegate = userL3Entities.length > 0;
  const [joinProductMutation] = useMutation(JOIN_PRODUCT);
  const [leaveProductMutation] = useMutation(LEAVE_PRODUCT);
  const { displayToast } = useToast();
  const { refetch: refetchMyProducts } = useAccelerateProductsQuery();
  const [isEditProductModalOpen, setIsEditProductModalOpen] = useState(false);
  const userIsMember = useIsUserMember(product);
  const userIsAdmin = useUserIsAdmin(product);
  const user = useUser();
  const memberCount = product.memberIds.length - (userIsMember ? 1 : 0);
  const FE_SIDE_MEGA_MENU = useFeature("FE_SIDE_MEGA_MENU");

  async function joinProduct(productId: string) {
    await joinProductMutation({
      variables: {
        productId,
      },
    });
    await refetch();
    await refetchMyProducts();
    displayToast(`You are now a member of ${product.name}`);
  }

  async function leaveProduct(productId: string) {
    await leaveProductMutation({
      variables: {
        productId,
      },
    });
    await refetch();
    await refetchMyProducts();
    trackEvent({
      name: "leave-product",
      product: product.name,
    });
    displayToast(`You are no longer a member of ${product.name}`);
  }

  return (
    <>
      <div className="w-100">
        <div className="d-flex w-100 justify-content-between" data-testid={`searched-product-${product.id}`}>
          <div className="w-70 pe-6 border-end">
            <Link
              role="productItem"
              className="mb-2 lead"
              href={{
                pathname: `/profile/product/${product.id}`,
                query: {
                  tab: TABS.overview,
                  source:
                    user && user?.email && product.memberIds.includes(user.email.toLowerCase())
                      ? PageSource.MyProducts
                      : PageSource.AllProducts,
                  product_name: product.name,
                  user_is_admin: userIsAdmin,
                },
              }}
              onClick={(ev) => ev.stopPropagation()}
            >
              {product.name}
            </Link>
            <p className="mb-4 small fw-light component-card-truncated-text" style={{ minHeight: "2rem" }}>
              {product.description}
            </p>
            <div className="d-flex align-items-center">
              {!userIsMember ? (
                <Button
                  className="me-4"
                  name={`join-product-${product.name}`}
                  data-testid={`join-product-${product.name}`}
                  onClick={() => joinProduct(product.id)}
                  level="tertiary"
                  theme="standard"
                  rounded="pill"
                  size="xs"
                >
                  Join product
                </Button>
              ) : (
                <div className="d-flex align-items-center">
                  {FE_SIDE_MEGA_MENU ? (
                    <>
                      <ProductFavorite productId={product.id} />
                      <ProductFavoriteButton productId={product.id} />
                    </>
                  ) : null}
                  {userIsAdmin && <ProductAdminTooltip />}
                  <DropdownKebabMenu
                    trackingEventProps={{
                      name: "button_joined-product-kebab-menu_click",
                      product: product.name,
                    }}
                    size="sm"
                  >
                    <DropdownItem onClick={() => leaveProduct(product.id)}>
                      <LogOut24 />
                      Leave product
                    </DropdownItem>
                  </DropdownKebabMenu>
                </div>
              )}
              <p className="mb-0 small fw-light">
                {userIsMember
                  ? memberCount > 0
                    ? `You and ${memberCount} team member${Pluralise(memberCount)}`
                    : "You are the only team member"
                  : `${memberCount} team member${Pluralise(memberCount)}`}
              </p>
            </div>
          </div>
          <div className="w-30 ps-6 d-flex flex-column">
            <div className="mb-2 h1 fw-light">{getDisplayedScore(product?.newCapabilityResult?.score)}</div>
            {!product.l3EntityUid && <LinkingBadge />}
            <Link
              className="mb-1 w-fit"
              href={`/profile/product/${product.id}?tab=${TABS.maturity}`}
              onClick={(ev) => ev.stopPropagation()}
            >
              <small>Product maturity score</small>
            </Link>
            <small className="lh-1-5">
              {product?.newCapabilityResult?.lastUpdated ? (
                <>
                  Updated:{" "}
                  {DateTime.fromMillis(product.newCapabilityResult.lastUpdated).toLocaleString(DateTime.DATE_MED)}
                </>
              ) : !product.l3EntityUid && isOwnerOrDelegate && !userL3EntitiesLoading ? (
                <LinkingSnowButton
                  handleButtonClick={() => {
                    setIsEditProductModalOpen(true);
                  }}
                  productName={product?.name}
                />
              ) : !product.l3EntityUid ? null : (
                "Complete the product maturity assessment to get this score"
              )}
            </small>
            {}
          </div>
        </div>
      </div>
      <EditProductModal
        isModalOpen={isEditProductModalOpen}
        toggle={() => {
          setIsEditProductModalOpen(!isEditProductModalOpen);
        }}
        selectedProduct={product}
      />
    </>
  );
}
