import { useMutation } from "@apollo/client";

import { useToast } from "../../hooks/use-toast";

import { Close } from "@bphxd/ds-core-react";
import { ModalBody, ModalFooter, Modal, ModalHeader } from "reactstrap";
import { Button } from "../../components/button";

import { DELETE_IMPROVEMENT_GOAL, GET_IMPROVEMENT_GOALS, ImprovementGoal } from "../../api/products/gql";

type RemoveImprovementAreaModalProps = {
  isOpen: boolean;
  goal: ImprovementGoal;
  toggle: () => void;
};

export function RemoveImprovementAreaModal({ isOpen, goal, toggle }: RemoveImprovementAreaModalProps) {
  const { displayToast } = useToast();
  const [deleteImprovementGoal, { loading }] = useMutation(DELETE_IMPROVEMENT_GOAL, {
    onCompleted: () => {
      displayToast("You have successfully removed an improvement area for this product");
      toggle();
    },
    refetchQueries: [GET_IMPROVEMENT_GOALS],
  });

  async function handleRemoveImprovementArea() {
    await deleteImprovementGoal({
      variables: {
        quizSlug: goal.quizSlug,
        productId: goal.productId,
      },
    });
  }

  return (
    <Modal size="md" isOpen={isOpen} toggle={toggle} className="modal-dialog-centered">
      <ModalHeader className="border-0" close={<Close onClick={toggle} />} />
      <ModalBody className="pt-0 px-9 pb-7 text-center align-self-end">
        <h4 className="fw-light mb-0">Are you sure you want to remove this area for improvement?</h4>
      </ModalBody>
      <ModalFooter className="py-4 d-flex gap-1 justify-content-center">
        <Button
          data-testid="cancel-remove-improvement-area"
          onClick={toggle}
          type="button"
          name="cancel-remove-improvement-area"
          level="tertiary"
          theme="standard"
          rounded="pill"
        >
          Cancel
        </Button>
        <Button
          data-testid="confirm-remove-improvement-area"
          onClick={handleRemoveImprovementArea}
          type="button"
          name="confirm-remove-improvement-area"
          level="primary"
          theme="standard"
          rounded="pill"
          loading={loading}
        >
          Remove
        </Button>
      </ModalFooter>
    </Modal>
  );
}
