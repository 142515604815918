import { Accordion } from "@bphxd/ds-core-react";
import { Link } from "../../components/Link";
import { ReactNode } from "react";

const defaultQuestionAnswers = [
  {
    question: "Why don't I see my Product?",
    answer: (
      <>
        <p className="fw-regular small-md">
          This is because EITHER your product is not represented in Accelerate, OR you have not joined as a member of
          the product.
        </p>
        <p className="fw-regular small-md">
          1.{" "}
          <Link href="/all-products" name="commonly-asked-queston-search-for-product">
            Search for your product
          </Link>{" "}
          and confirm it exists in Accelerate. If it does exist, join the product via the Members tab.
        </p>
        <p className="fw-regular small-md">
          2. If the Product does not exist, create the Product and link it to the correct ServiceNow Level 3 entity.
          Note that only those who are Product Managers, Pod Tech Leads and Operational Delegates in ServiceNow can
          create Products in Accelerate.
        </p>
      </>
    ),
  },
  {
    question: `My Product is marked as          "Pending ServiceNow link". What does this mean?`,
    answer: (
      <>
        <p className="fw-regular small-md">
          This means that a Product Owner, Tech Lead or Operational Delegate has not yet linked the Product in
          Accelerate to their ServiceNow Level 3 entity. It is important to complete this Product setup activity, as
          features such as maturity assessments and product monthly cloud costs are only available if this link is in
          place.
        </p>
        <p className="fw-regular small-md">
          1. Only Product Managers, Tech Leads or Operational Delegates linked to a Level 3 entity in ServiceNow can
          link their Product in Accelerate to ServiceNow.
        </p>
        <p className="fw-regular small-md">
          2.{" "}
          <Link href="/all-products" name="commonly-asked-question-maturity-framework">
            Search for your Product
          </Link>
        </p>
        <p className="fw-regular small-md">
          3. Click on your Product, view the “Overview” tab, and click on “Link to ServiceNow Level 3 entity”.
        </p>
        <p className="fw-regular small-md">
          4. Associate your Product with one of the ServiceNow Level 3 entities that is listed. Note that you will only
          see ServiceNow Level 3 entities where you are a Product Manager, Tech Lead or Operational Delegate.
        </p>
      </>
    ),
  },
  {
    question: "Why is my Product maturity score not set?",
    answer: (
      <>
        <p className="fw-regular small-md">
          This means that EITHER the Product in Accelerate is not linked to a ServiceNow Level 3 the entity, OR that not
          all of the maturity assessment capability quizzes have been completed for the Product. As soon as all of the
          assessment capability questions have been answered, the Product maturity score will appear.
        </p>
        <p className="fw-regular small-md">
          1. To link the Product to a ServiceNow Level 3 entity, please refer to the answer for ‘My Product is marked as
          “Pending ServiceNow link”. What does this mean?’
        </p>
        <p className="fw-regular small-md">
          2. To understand the Product maturity assessment, please refer to the{" "}
          <Link href="/best-practices" name="commonly-asked-question-maturity-framework">
            Software and platform engineering maturity framework.
          </Link>
        </p>
        <p className="fw-regular small-md">
          3. To complete a Product assessment, join a Product, go to the "Maturity" tab on the Product page, and answer
          the Product capability quizzes.
        </p>
      </>
    ),
  },
  {
    question: "How is my Product's maturity score calculated?",
    answer: (
      <>
        <p className="fw-regular small-md">
          We calculate the Product score from the average of scores for each quiz in each of the of the Product
          capability groups; Cultural, Measurement, Process and Technical.
        </p>
      </>
    ),
  },
];
type CommonlyAskedQuestionsProps = { questionAnswers?: { question: string; answer: ReactNode }[] };

export function CommonlyAskedQuestions({ questionAnswers }: CommonlyAskedQuestionsProps) {
  if (!questionAnswers) {
    questionAnswers = defaultQuestionAnswers;
  }
  return (
    <Accordion stayOpen flush className="w-100 override-accordion-button">
      <h5 className="Item-title">Commonly asked questions</h5>
      {questionAnswers.map(({ question, answer }, index) => (
        <Accordion.Item key={index} className="py-4 border-bottom">
          <Accordion.Header targetId={String(index)}>{question}</Accordion.Header>
          <Accordion.Body accordionId={String(index)} className="ps-0 border-bottom-0">
            {answer}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}
