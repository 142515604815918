import { Modal as M } from "../../../../components/modal";
import { Wizard } from "./wizard";

import { useUserL3Entities } from "../../hooks/use-user-l3-entities";
import { BasicProductFieldsFragment } from "../../../../gql/graphql";

type ModalProps = {
  isModalOpen: boolean;
  toggle: () => void;
  selectedProduct?: BasicProductFieldsFragment;
};

export function Modal({ isModalOpen, toggle, selectedProduct }: ModalProps) {
  const { userL3EntitiesLoading, userL3Entities } = useUserL3Entities();
  return (
    <div className="scroll-behavior-smooth">
      <M
        size={"lg"}
        backdrop="static"
        isOpen={isModalOpen}
        hasCancelDialog
        header={selectedProduct ? `Edit ${selectedProduct.name}` : "Create a product"}
        onClose={toggle}
      >
        <Wizard
          l3Entities={userL3Entities}
          loading={userL3EntitiesLoading}
          onClose={toggle}
          selectedProduct={selectedProduct}
        />
      </M>
    </div>
  );
}
