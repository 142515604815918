import { Dispatch, SetStateAction } from "react";

import { Left24, Right24, DoubleArrowRight24, DoubleArrowLeft24 } from "@bphxd/ds-core-react/lib/icons";
import { Pagination, PaginationItem, PaginationLink, Input } from "reactstrap";

type PageRange = { start: number; end: number };

type PaginationHOCProps = {
  count: number;
  paginateLimit: number;
  setPaginateLimit: (items: number) => void;
  setPaginateSkip?: (page: number) => void;
  activePage: number;
  setActivePage: (count: number) => void;
  paginationRangeCount: number;
  paginationRange: PageRange;
  setPaginationRange: Dispatch<SetStateAction<PageRange>>;
};

export function PaginationHOC({
  count,
  paginateLimit,
  setPaginateLimit,
  setPaginateSkip,
  activePage,
  setActivePage,
  paginationRangeCount,
  paginationRange,
  setPaginationRange,
}: PaginationHOCProps) {
  let start =
    (activePage + 1) * paginateLimit - paginateLimit === 0 ? 1 : (activePage + 1) * paginateLimit - paginateLimit;
  const end = count < (activePage + 1) * paginateLimit ? count : (activePage + 1) * paginateLimit;
  const recordsPerPage = [5, 10, 25, 50];

  if ((activePage + 1) * paginateLimit > count && count > paginateLimit) {
    start = count - paginateLimit;
  }
  return (
    <div className="d-flex align-items-center w-100 mt-6">
      <div data-testid="pagination-footer" className="d-flex align-items-center w-40">
        <Pagination aria-label="Search pagination" className="mb-n4">
          <PaginationItem className="page-item-word" disabled={false}>
            <PaginationLink
              previous
              href="#"
              className="rounded-pill"
              onClick={(e) => {
                e.preventDefault();
                setActivePage(0);
                setPaginationRange((state) => ({
                  ...state,
                  start: 0,
                  end: paginationRangeCount,
                }));
                setPaginateSkip && setPaginateSkip(0);
              }}
            >
              <DoubleArrowLeft24 />
            </PaginationLink>
          </PaginationItem>
          <PaginationItem className="page-item-word" disabled={activePage === 0}>
            <PaginationLink
              previous
              href="#"
              className="rounded-pill"
              data-testid="paginate-left-button"
              onClick={(e) => {
                e.preventDefault();
                const count = activePage - 1;
                setActivePage(count);
                if (count < paginationRange?.start) {
                  setPaginationRange((state) => ({
                    ...state,
                    start: paginationRange?.start - paginationRangeCount,
                    end: paginationRange?.end - paginationRangeCount,
                  }));
                }
                setPaginateSkip && setPaginateSkip(count * paginateLimit);
              }}
            >
              <Left24 />
            </PaginationLink>
          </PaginationItem>
          {Array.from(Array(Math.ceil(count / paginateLimit)), (_, i) => {
            return i >= paginationRange?.start && i < paginationRange?.end ? (
              <PaginationItem className="page-item-circle" active={i === activePage} key={i}>
                <PaginationLink
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setActivePage(i);
                    setPaginateSkip && setPaginateSkip(i * paginateLimit);
                  }}
                >
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            ) : null;
          })}
          <PaginationItem className="page-item-word" disabled={activePage + 1 === Math.ceil(count / paginateLimit)}>
            <PaginationLink
              next
              href="#"
              className="rounded-pill"
              data-testid="paginate-right-button"
              onClick={(e) => {
                e.preventDefault();
                const nextActivePage = activePage + 1;
                setActivePage(nextActivePage);
                if (nextActivePage === paginationRange?.end) {
                  setPaginationRange((state) => ({
                    ...state,
                    start: paginationRange?.start + paginationRangeCount,
                    end: paginationRange?.end + paginationRangeCount,
                  }));
                }
                setPaginateSkip && setPaginateSkip(nextActivePage * paginateLimit);
              }}
            >
              <Right24 />
            </PaginationLink>
          </PaginationItem>
          <PaginationItem className="page-item-word" disabled={false}>
            <PaginationLink
              next
              href="#"
              className="rounded-pill"
              onClick={(e) => {
                e.preventDefault();
                const lastPage = Math.ceil(count / paginateLimit) - 1;
                setActivePage(lastPage);
                setPaginationRange((state) => ({
                  ...state,
                  start: Math.ceil(count / paginateLimit) - paginationRangeCount,
                  end: Math.ceil(count / paginateLimit),
                }));
                setPaginateSkip && setPaginateSkip(lastPage * paginateLimit);
              }}
            >
              <DoubleArrowRight24 />
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </div>
      <div className="w-20 text-center">
        Showing
        <strong> {`${start} - ${end}`} </strong>
        of
        <strong> {count} </strong>
      </div>
      <div className="d-flex align-items-center w-40 justify-content-end  pe-3">
        Show
        <div className="mx-4">
          <Input
            type="select"
            name="select-pagination-limit"
            data-testid="paginate-limit-input"
            className="bg-white"
            onChange={(e) => {
              e.preventDefault();
              setActivePage(0);
              setPaginateLimit(parseInt(e.target.value));
              setPaginationRange((state) => ({
                ...state,
                start: 0,
                end: paginationRangeCount,
              }));
            }}
            value={paginateLimit}
          >
            {recordsPerPage.map((numberOfRecordsPerPage) => (
              <option value={numberOfRecordsPerPage} key={numberOfRecordsPerPage}>
                {numberOfRecordsPerPage}
              </option>
            ))}
          </Input>
        </div>
        items
      </div>
    </div>
  );
}
